import React from 'react';
import {
    Row,
    Col,
    Spin,
    Empty,
} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { Bar, Line } from "react-chartjs-2";



const ChartTaches = ({ loadingTask_allTeam, chartTaskTem }) => {

    return (
        <>
            <Row>
                <Col span={24}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loadingTask_allTeam}
                    >
                        <h3 style={{ textAlign: 'center' }}>L'EVOLUTION DE CRÉATION DES TÂCHES PAR ÉQUIPE</h3>
                        <div style={{ marginLeft: 5, marginRight: 15 }}>
                            {Object.keys(chartTaskTem).length > 0 ? (
                                <Line
                                    height={85}
                                    data={chartTaskTem}
                                />
                            ) : (
                                <Empty description={false} />
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>
        </>
    )
}

export default ChartTaches;