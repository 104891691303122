import React from 'react';
import {
    Row,
    Col,
    Spin,
    Empty,
} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { Bar, Line as LineChart } from "react-chartjs-2";
import {
    taskActions
} from "../../../../redux/_actions";


const ChartEATeam = ({ chartEA, chartCH, setChooseTeam }) => {

    const dispatch = useDispatch();
    const loading_chart = useSelector((state) => state.task.loading_chart);
    const equipes = useSelector((state) => state.team.items);
    const filter_chart = useSelector((state) => state.task.filter_chart);



    const optionsGrouped = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        onClick: function (evt, element) {
            if (element.length > 0) {
                let choixEquipe = equipes.filter((p) => p.libelle === chartEA.labels[element[0].index])[0];
                setChooseTeam(choixEquipe);
                // Tabs Projets 
                dispatch(taskActions.getChartEAProjets({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                dispatch(taskActions.getChartCHUsers({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                // Tabs Equipes/Usres
                dispatch(taskActions.getDataChartEquipe({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                dispatch(taskActions.getDataChartUsers({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
            }
        },
    }

    const optionsStacked = {
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                min: 0,
                max: 176,
            }
        },
        onClick: function (evt, element) {
            if (element.length > 0) {
                let choixEquipe = equipes.filter((p) => p.libelle === chartEA.labels.sort()[element[0].index])[0];
                setChooseTeam(choixEquipe);
                // Tabs Projets 
                dispatch(taskActions.getChartEAProjets({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                dispatch(taskActions.getChartCHUsers({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                // Tabs Equipes/Usres
                dispatch(taskActions.getDataChartEquipe({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
                dispatch(taskActions.getDataChartUsers({
                    id_equipe: choixEquipe.id_equipe,
                    date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                    date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
                }));
            }
        },
    }

    return (
        <>
            <Row>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart}
                    >
                        <h3 style={{ textAlign: 'center' }}>L'ETAT D'AVANCEMENT DES ÉQUIPES</h3>
                        <div style={{ marginLeft: 5, marginRight: 15 }}>
                            {Object.keys(chartEA).length > 0 ? (
                                <Bar
                                    height={380}
                                    data={chartEA}
                                    options={optionsGrouped}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            ) : (
                                <>
                                    <Empty description={false} />
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart}
                    >
                        <h3 style={{ textAlign: 'center' }}>LES CHARGES HORAIRES DU MOIS (176h) DES ÉQUIPES</h3>
                        <div style={{ marginLeft: 15, marginRight: 15 }}>
                            {Object.keys(chartCH).length > 0 ? (
                                <Bar
                                    height={380}
                                    data={chartCH}
                                    options={optionsStacked}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            ) : (
                                <>
                                    <Empty description={false} />
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>
        </>
    )
}

export default ChartEATeam;