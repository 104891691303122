export const projectConstants = {
    GETALL_PROJECT_REQUEST: "GETALL_PROJECT_REQUEST",
    GETALL_PROJECT_SUCCESS: "GETALL_PROJECT_SUCCESS",
    GETALL_PROJECT_FAILURE: "GETALL_PROJECT_FAILURE",

    GETALL_PROJECT_HAVE_TICKET_REQUEST: "GETALL_PROJECT_HAVE_TICKET_REQUEST",
    GETALL_PROJECT_HAVE_TICKET_SUCCESS: "GETALL_PROJECT_HAVE_TICKET_SUCCESS",
    GETALL_PROJECT_HAVE_TICKET_FAILURE: "GETALL_PROJECT_HAVE_TICKET_FAILURE",

    GETALLSEARCH_PROJECT_REQUEST: "GETALLSEARCH_PROJECT_REQUEST",
    GETALLSEARCH_PROJECT_SUCCESS: "GETALLSEARCH_PROJECT_SUCCESS",
    GETALLSEARCH_PROJECT_FAILURE: "GETALLSEARCH_PROJECT_FAILURE",

    GETALL_PROJECT_WITHOUT_TEAM_REQUEST: "GETALL_PROJECT_WITHOUT_TEAM_REQUEST",
    GETALL_PROJECT_WITHOUT_TEAM_SUCCESS: "GETALL_PROJECT_WITHOUT_TEAM_SUCCESS",
    GETALL_PROJECT_WITHOUT_TEAM_FAILURE: "GETALL_PROJECT_WITHOUT_TEAM_FAILURE",

    GETALL_PROJECT_TASK_REQUEST: "GETALL_PROJECT_TASK_REQUEST",
    GETALL_PROJECT_TASK_SUCCESS: "GETALL_PROJECT_TASK_SUCCESS",
    GETALL_PROJECT_TASK_ERROR: "GETALL_PROJECT_TASK_ERROR",

    ADD_PROJECT_TASK_REQUEST: "ADD_PROJECT_TASK_REQUEST",
    ADD_PROJECT_TASK_SUCCESS: "ADD_PROJECT_TASK_SUCCESS",
    ADD_PROJECT_TASK_FAILURE: "ADD_PROJECT_TASK_FAILURE",

    DRAG_IN_DROP_TASK_REQUEST: "DRAG_IN_DROP_TASK_REQUEST",
    DRAG_IN_DROP_TASK_SUCCESS: "DRAG_IN_DROP_TASK_SUCCESS",
    DRAG_IN_DROP_TASK_ERROR: "DRAG_IN_DROP_TASK_ERROR",

    SELECTED_PROJECT_TASK_SUCCESS: "SELECTED_PROJECT_TASK_SUCCESS",

    UPDATE_TASK_PROJECT_REQUEST: "UPDATE_TASK_PROJECT_REQUEST",
    UPDATE_TASK_PROJECT_SUCCESS: "UPDATE_TASK_PROJECT_SUCCESS",
    UPDATE_TASK_PROJECT_FAILURE: "UPDATE_TASK_PROJECT_FAILURE",

    STOP_REQUEST: "STOP_REQUEST",

    ADD_WITHOUT_TEAM_PROJECT_REQUEST: "ADD_WITHOUT_TEAM_PROJECT_REQUEST",
    ADD_WITHOUT_TEAM_PROJECT_SUCCESS: "ADD_WITHOUT_TEAM_PROJECT_SUCCESS",
    ADD_WITHOUT_TEAM_PROJECT_FAILURE: "ADD_WITHOUT_TEAM_PROJECT_FAILURE",

    UPDATE_WITHOUT_TEAM_PROJECT_REQUEST: "UPDATE_WITHOUT_TEAM_PROJECT_REQUEST",
    UPDATE_WITHOUT_TEAM_PROJECT_SUCCESS: "UPDATE_WITHOUT_TEAM_PROJECT_SUCCESS",
    UPDATE_WITHOUT_TEAM_PROJECT_FAILURE: "UPDATE_WITHOUT_TEAM_PROJECT_FAILURE",

    GETALL_MENBER_TASK_REQUEST: "GETALL_MENBER_TASK_REQUEST",
    GETALL_MENBER_TASK_SUCCESS: "GETALL_MENBER_TASK_SUCCESS",
    GETALL_MENBER_TASK_FAILURE: "GETALL_MENBER_TASK_FAILURE",

    RESET_USER_TASK: "RESET_USER_TASK",

    FILETR_USER_TASK: "FILETR_USER_TASK",

    INCREMENT_UNDER_TASK_NUMBER: "INCREMENT_UNDER_TASK_NUMBER",

    DELETE_PROJECT_TASK_REQUEST: "DELETE_PROJECT_TASK_REQUEST",
    DELETE_PROJECT_TASK_SUCCESS: "DELETE_PROJECT_TASK_SUCCESS",
    DELETE_PROJECT_TASK_FAILURE: "DELETE_PROJECT_TASK_FAILURE",

    FILTER_TASK_NOT_DONE: "FILTER_TASK_NOT_DONE",
    FILTER_TASK_DONE: "FILTER_TASK_DONE",
    FILTER_TASK_ALL: "FILTER_TASK_ALL",

    DELETE_PROJECT_WITHOUT_TEAM_REQUEST: "DELETE_PROJECT_WITHOUT_TEAM_REQUEST",
    DELETE_PROJECT_WITHOUT_TEAM_SUCCESS: "DELETE_PROJECT_WITHOUT_TEAM_SUCCESS",
    DELETE_PROJECT_WITHOUT_TEAM_FAILURE: "DELETE_PROJECT_WITHOUT_TEAM_FAILURE",

    ACTIVE_EXISTANT: "ACTIVE_EXISTANT",
};
