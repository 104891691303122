import { defineAbility } from "@casl/ability";

export default function defineAbilityFor(user) {
    return defineAbility((can) => {
        user.actions.map((p) =>
            p.check ? can(p.libelle, p.libelle_module) : null
        );
        // if (user.item.libelle_profil === "Admin") {
        //     user.actions.map((p) =>
        //         p.check ? can(p.libelle, p.libelle_module) : null
        //     );
        // } else if (user.item.libelle_profil === "Chef d'equipe") {
        //     user.actions.map((p) =>
        //         p.check ? can(p.libelle, p.libelle_module) : null
        //     );
        // } else if (user.item.libelle_profil === "Utilisateur") {
        //     user.actions.map((p) =>
        //         p.check ? can(p.libelle, p.libelle_module) : null
        //     );
        // }
    });
}
