import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Breadcrumb,
    Typography as Text,
    Row,
    Col,
    Image
} from "antd";
import {
    HomeOutlined,
    AuditOutlined,
    FileProtectOutlined,
    TeamOutlined,
    ProfileOutlined
} from "@ant-design/icons";
import "./Analyse.css";
import HeaderApp from "../../parchalse/HeaderApp";
import ChartAnalyse from "../../components/Analyse/ChartAnalyse";
import {
    taskActions
} from "../../../redux/_actions";
import equipe from '../../../assets/equipe.png';
import pro from '../../../assets/pro.png';
import ticket from '../../../assets/ticket.png';
import tache from '../../../assets/taches.jpg';

const Analyse = () => {

    const profil_chef_projet = 2;
    const profil_chef_commercial = 4;

    const dispatch = useDispatch();

    const [id_jalonTermine, setJalonTerminee] = useState({});
    const [id_jalonExecute, setJalonExecutee] = useState({});

    const user_login_profil = useSelector((state) => state.login.item);
    const teams = useSelector((state) => state.team.items);
    const projets = useSelector((state) => state.project.items);
    const tasks = useSelector((state) => state.task.items).filter((o) => o.id_parent == null);
    const jalons = useSelector((state) => state.jalon.items);
    const tickets = useSelector((state) => state.ticket.items)[0];
    const users = useSelector((state) => state.user.items);

    useEffect(() => {
        setJalonTerminee(jalons.filter((p) => p.terminate === 1)[0]);
        setJalonExecutee(jalons.filter((p) => p.execute === 1)[0]);
        dispatch(taskActions.getDataChart());
        dispatch(taskActions.getDataChartDetail());
    }, []);

    const style = {
        borderRadius: 8,
        background: '#fff',
        borderLeft: "5px solid #1890ff",
        height: 120
    };

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* Header de l'application  */}
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            {/* L'entete de la page  */}
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Analyses</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* le Body de la page  */}
            <div
                style={{
                    padding: 18,
                    flex: 0.2,
                    display: "flex",
                    flexDirection: "col",
                    justifyContent: "space-between",
                    height: "20.4%",
                    marginTop: -10
                }}
            >
                <div
                    style={{
                        flex: 1,
                        borderRadius: 5,
                    }}
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                            <div style={style} >
                                <div
                                    className="centreVerticalement"
                                    style={{ float: 'left', fontSize: 40, marginTop: -10, marginLeft: -20 }}
                                >
                                    <Text style={{ fontSize: 20, marginTop: 60, transform: 'rotate(-90deg)' }}>
                                        ÉQUIPES
                                    </Text>
                                </div>
                                <div
                                    className="centreVerticalement"
                                    style={{ marginLeft: -10, marginTop: 15, overflow: 'auto', height: '100px', width: '200px' }}
                                >
                                    {
                                        // si c'est un chef de pojet
                                        (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                            (
                                                teams.map((p) => p.id_equipe === user_login_profil.id_equipe ? (
                                                    <>
                                                        <Text strong={true} style={{ fontSize: 20 }}>{p.libelle} : {users.filter((pp) => pp.id_equipe === p.id_equipe).length}</Text>
                                                    </>
                                                ) : null)
                                            ) :
                                            // si c'est un admin
                                            (
                                                teams.map((p) => p.type === 1 ? (
                                                    <>
                                                        <Text strong={true}>{p.libelle} : {users.filter((pp) => pp.id_equipe === p.id_equipe).length}</Text>
                                                    </>
                                                ) : null)
                                            )
                                    }
                                </div>
                                {/* <TeamOutlined style={{ float: 'right', fontSize: 40, marginTop: 30, marginRight: 10 }} /> */}
                                <Image
                                    width={50}
                                    preview={false}
                                    src={equipe}
                                    style={{ float: 'right', marginTop: 20, marginRight: '-80%' }}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style} >
                                <div
                                    className="centreVerticalement"
                                    style={{ float: 'left', fontSize: 40, marginTop: -10, marginLeft: -20 }}
                                >
                                    <Text strong={true} style={{ fontSize: 20, marginTop: 60, transform: 'rotate(-90deg)' }}>
                                        PROJETS
                                    </Text>
                                </div>
                                <div
                                    className="centreVerticalement"
                                    style={{ marginLeft: -10 }}
                                >
                                    <Text strong={true} style={{ marginTop: 5 }}>
                                        ARCHIVE : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                projets.filter((p) => p.id_statut_projet === 2 && p.id_equipe === user_login_profil.id_equipe).length
                                                : projets.filter((p) => p.id_statut_projet === 2).length
                                        }
                                    </Text>
                                    <Text strong={true} style={{ marginTop: 5 }}>
                                        EN COURS : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                projets.filter((p) => p.id_statut_projet === 1 && p.id_equipe === user_login_profil.id_equipe).length
                                                : projets.filter((p) => p.id_statut_projet === 1).length
                                        }
                                    </Text>
                                </div>
                                <Image
                                    width={45}
                                    preview={false}
                                    src={pro}
                                    style={{ float: 'right', marginTop: 15, marginRight: '-280%' }}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style} >
                                <div
                                    className="centreVerticalement"
                                    style={{ float: 'left', fontSize: 40, marginTop: -10, marginLeft: -20 }}
                                >
                                    <Text strong={true} style={{ fontSize: 20, marginTop: 60, transform: 'rotate(-90deg)' }}>
                                        TACHES
                                    </Text>
                                </div>
                                <div
                                    className="centreVerticalement"
                                    style={{ marginLeft: -10 }}
                                >
                                    <Text strong={true}>
                                        EN COURS : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tasks.filter((p) => p.ide === user_login_profil.id_equipe).length - tasks.filter((p) => p.etat === 1 && p.ide === user_login_profil.id_equipe).length - tasks.filter((p) => p.id_jalon === id_jalonTermine.id_jalon && p.ide === user_login_profil.id_equipe).length - tasks.filter((p) => p.id_jalon === id_jalonExecute.id_jalon && p.ide === user_login_profil.id_equipe).length
                                                : tasks.length - tasks.filter((p) => p.etat === 1).length - tasks.filter((p) => p.id_jalon === id_jalonTermine.id_jalon).length - tasks.filter((p) => p.id_jalon === id_jalonExecute.id_jalon).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        EXÉCUTÉE : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tasks.filter((p) => p.id_jalon === id_jalonExecute.id_jalon && p.ide === user_login_profil.id_equipe).length
                                                : tasks.filter((p) => p.id_jalon === id_jalonExecute.id_jalon).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        TERMINÉE : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tasks.filter((p) => p.id_jalon === id_jalonTermine.id_jalon && p.ide === user_login_profil.id_equipe).length
                                                : tasks.filter((p) => p.id_jalon === id_jalonTermine.id_jalon).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        BLOQUÉE  : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tasks.filter((p) => p.etat === 1 && p.ide === user_login_profil.id_equipe).length
                                                : tasks.filter((p) => p.etat === 1).length
                                        }
                                    </Text>
                                </div>
                                <Image
                                    width={60}
                                    src={tache}
                                    preview={false}
                                    style={{ float: 'right', marginTop: 7, marginRight: '-210%' }}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style} >
                                <div
                                    className="centreVerticalement"
                                    style={{ float: 'left', fontSize: 40, marginTop: -10, marginLeft: -20 }}
                                >
                                    <Text strong={true} style={{ fontSize: 20, marginTop: 60, transform: 'rotate(-90deg)' }}>
                                        TICKETS
                                    </Text>
                                </div>
                                <div
                                    className="centreVerticalement"
                                    style={{ marginLeft: -10 }}
                                >
                                    <Text strong={true}>
                                        INITIAL  : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tickets.filter((p) => p.id_state === 1 && p.id_equipe === user_login_profil.id_equipe).length
                                                : tickets.filter((p) => p.id_state === 1).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        PLANIFIÉ  : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tickets.filter((p) => p.id_state === 4 && p.id_equipe === user_login_profil.id_equipe).length
                                                : tickets.filter((p) => p.id_state === 4).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        EN COURS  : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tickets.filter((p) => p.id_state === 3 && p.id_equipe === user_login_profil.id_equipe).length
                                                : tickets.filter((p) => p.id_state === 3).length
                                        }
                                    </Text>
                                    <Text strong={true}>
                                        TERMINÉ  : {
                                            (user_login_profil.id_profil === profil_chef_projet) || (user_login_profil.id_profil === profil_chef_commercial) ?
                                                tickets.filter((p) => p.id_state === 2 && p.id_equipe === user_login_profil.id_equipe).length
                                                : tickets.filter((p) => p.id_state === 2).length
                                        }
                                    </Text>
                                </div>
                                <Image
                                    width={50}
                                    src={ticket}
                                    preview={false}
                                    style={{ float: 'right', marginTop: 15, marginRight: '-280%' }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
            <div
                style={{
                    padding: 18,
                    flex: 1,
                    display: "flex",
                    flexDirection: "col",
                    justifyContent: "space-between",
                    height: "85.4%",
                }}
            >
                <div
                    style={{
                        flex: 2,
                        backgroundColor: '#fff',
                        padding: 0,
                        borderRight: "2px solid #f0f0f0",
                    }}
                >
                    <ChartAnalyse />
                </div>
            </div>
        </div >
    );
};

export default Analyse;
