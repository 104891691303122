export const customerConstants = {
  ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",

  GETALL_CUSTOMER_REQUEST: "GETALL_CUSTOMER_REQUEST",
  GETALL_CUSTOMER_SUCCESS: "GETALL_CUSTOMER_SUCCESS",
  GETALL_CUSTOMER_FAILURE: "GETALL_CUSTOMER_FAILURE",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",

  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  CHECK_CUSTOMER_REQUEST: "CHECK_CUSTOMER_REQUEST",
  DROP_CHECK_CUSTOMER_REQUEST: "DROP_CHECK_CUSTOMER_REQUEST",

};
