import { project_statusConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    item: {},
    error: "",
};

export const project_status = (state = initialState, action) => {
    switch (action.type) {
        case project_statusConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case project_statusConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.project_status,
            };

        case project_statusConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
