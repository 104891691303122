export const actionConstants = {

    GETALL_REQUEST: "ACTION_GETALL_REQUEST",
    GETALL_SUCCESS: "ACTION_GETALL_SUCCESS",
    GETALL_FAILURE: "ACTION_GETALL_FAILURE",

    GET_PROFILE_ACTION_REQUEST: "GET_PROFILE_ACTION_REQUEST",
    GET_PROFILE_ACTION_SUCCESS: "GET_PROFILE_ACTION_SUCCESS",
    GET_PROFILE_ACTION_FAILURE: "GET_PROFILE_ACTION_FAILURE",

    GET_MODULE_ACTION_REQUEST: 'GET_MODULE_ACTION_REQUEST',
    GET_MODULE_ACTION_SUCCESS: 'GET_MODULE_ACTION_SUCCESS',
    GET_MODULE_ACTION_FAILURE: 'GET_MODULE_ACTION_FAILURE',

    
    CHECKED: "DROIT_CHECKED",
    UNCHECKED: "DROIT_UNCHECKED",


    CHECKALL_REQUEST: "DROIT_CHECKALL_REQUEST",
    CHECKALL_SUCCESS: "DROIT_CHECKALL_SUCCESS",
    CHECKALL_FAILURE: "DROIT_CHECKALL_FAILURE",

    CHECKALL: "DROIT_CHECKALL",
    UNCHECKALL: "DROIT_UNCHECKALL"
}