import React, { useState } from "react";
import { Card, Avatar, Input, Select, Row, Col, Dropdown, Menu } from "antd";
import { SaveOutlined, UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { projectActions, modalActions } from "../../../redux/_actions";
import { makeAvatar } from "../../helpers/makeAvatar";

const { Option } = Select;
const { TextArea } = Input;

const AddTask = (props) => {
    const [addMember, setAddMember] = useState(false);
    const [taskMember, setTaskMember] = useState("");
    const [taskTitle, setTaskTitle] = useState("");
    const [userS, setUserS] = useState("");
    const [taskHover, setTaskHover] = useState();

    const dispatch = useDispatch();

    const auth = useSelector((state) => state.login.item);

    const team_menbers = useSelector((state) => state.user.project_member);
    const taches = useSelector((state) => state.project.project_task);
    const jalons = useSelector((state) => state.jalon.items);

    const addTaskOperation = (taskMember, taskTitle, id_projet, id_jalon) => {
        taskMember === ""
            ? dispatch(
                projectActions.addProjectTask({
                    libelle: taskTitle,
                    id_projet: parseInt(id_projet),
                    ordre: 0,
                    // id_statut: 2,
                    online: auth.id_user,
                    create_by: auth.id_user,
                    id_jalon: parseInt(id_jalon)
                })
            )
            : dispatch(
                projectActions.addProjectTask({
                    id_user: taskMember,
                    libelle: taskTitle,
                    id_projet: parseInt(id_projet),
                    ordre: 0,
                    // id_statut: 2,
                    online: auth.id_user,
                    create_by: auth.id_user,
                    id_jalon: parseInt(id_jalon)
                })
            );
        // setTimeout(() => {
        //     dispatch(
        //         projectActions.getAllProjectTask({
        //             id_project: parseInt(id_projet),
        //         })
        //     );
        // }, 5000);
        dispatch(modalActions.close_add_task());
        setTimeout(() => {
            var t = [];
            jalons.map((p) =>
                t.push({
                    name: p.libelle,
                    color: p.color,
                    items: taches
                        .filter(
                            (pp) =>
                                // eslint-disable-next-line eqeqeq
                                pp.id_projet == props.id_projet &&
                                // eslint-disable-next-line eqeqeq
                                pp.id_jalon == p.id_jalon
                        )
                        .sort((a, b) => b.ordre - a.ordre),
                })
            );
            props.setColumns(t);
        }, 100);
    };

    const handleMenuClick = (value) => {
        setUserS(value.key);
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {team_menbers !== undefined
                ? team_menbers.map((p) => (
                    <Menu.Item
                        key={p.id_user}
                        onMouseEnter={() => setTaskHover(p.id_user)}
                        onMouseLeave={() => setTaskHover(0)}
                        style={{
                            borderRight:
                                taskHover === p.id_user
                                    ? "4px solid #40a9ff"
                                    : null,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Avatar
                                size="small"
                                style={{
                                    border: "1px solid #252526",
                                    backgroundColor: "#f8f8ff",
                                    color: "#252526",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 10,
                                    marginRight: 10,
                                }}
                            >
                                {makeAvatar(p.first_name)}
                            </Avatar>{" "}
                            {p.first_name}
                        </div>
                    </Menu.Item>
                ))
                : null}
        </Menu>
    );

    return (
        <Card
            style={{
                width: "98%",
                borderRadius: 4,
                margin: 3,
            }}
            bodyStyle={{
                padding: "10px 10px",
            }}
        >
            <h3 style={{ width: "100%" }}>
                <TextArea
                    name="titre_tache"
                    placeholder="Ajouter une tâche"
                    onChange={(values) => setTaskTitle(values.target.value)}
                    autoFocus
                    style={{
                        display: "inline-block",
                        fontSize: 13,
                    }}
                />
            </h3>
            <div style={{ marginTop: 10 }}>
                <Row>
                    <Col span={12}>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Avatar
                                size="small"
                                onClick={() =>
                                    addMember ? setAddMember(false) : setAddMember(true)
                                }
                                style={{
                                    cursor: "pointer",
                                    border: "1px solid #252526",
                                    backgroundColor: "#f8f8ff",
                                    color: "#252526",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 10,
                                }}
                            >
                                {userS !== "" ? (
                                    makeAvatar(
                                        team_menbers.find((p) => p.id_user == userS)
                                            .first_name
                                    )
                                ) : (
                                    <UserOutlined />
                                )}
                            </Avatar>
                        </Dropdown>
                    </Col>
                    <Col span={12}>
                        <DeleteOutlined
                            style={{
                                marginTop: 5,
                                marginLeft: taskTitle !== "" ? "60%" : "80%",
                                cursor: 'pointer',
                                color: 'red',
                                fontSize: 15
                            }}
                            onClick={() => dispatch(modalActions.show_add_task())}
                        />
                        {taskTitle !== "" ? (
                            <>
                                <div
                                    style={{
                                        float: "right",
                                        marginTop: 30
                                    }}
                                >
                                    <SaveOutlined
                                        style={{
                                            cursor: "pointer",
                                            fontSize: 15,
                                            position: "absolute",
                                            bottom: 10,
                                            right: 10,
                                        }}
                                        onClick={() =>
                                            addTaskOperation(
                                                userS,
                                                taskTitle,
                                                props.id_projet,
                                                props.id_jalon
                                            )
                                        }
                                        key="flag"
                                    />
                                </div>
                            </>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </Card>
    );
};

export default AddTask;
