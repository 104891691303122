import React from "react";
import { Card } from "antd";

const BlankCard = (props) => {
    return (
        <Card
            style={{
                border: "none",
                height: "auto",
                padding: 0,
                marginLeft: props.marginLeft,
            }}
            bodyStyle={{
                height: props.height,
                backgroundColor: "white",
                padding: props.padding
            }}
            loading={false}
        >
            {props.children}
        </Card>
    );
};

export default BlankCard;
