import { modalConstants } from "../_constants";

export const modal = (state = {}, action) => {
    switch (action.type) {
        case modalConstants.SHOW_ADD_PROFILE:
            return {
                ...state,
                closable_add_profil: true,
            };

        case modalConstants.SHOW_VALIDATE_COTATION:
            return {
                ...state,
                closable_validate_cotation: true,
            };

        case modalConstants.CLOSE_VALIDATE_COTATION:
            return {
                ...state,
                closable_validate_cotation: false,
            };

        case modalConstants.CLOSE_ADD_PROFILE:
            return {
                ...state,
                closable_add_profil: false,
            };
        case modalConstants.SHOW_ADD_REF_FACTURE_DEMANDES:
            return {
                ...state,
                closable_add_ref_facture: true,
            };

        case modalConstants.CLOSE_REF_FACTURE_DEMANDES:
            return {
                ...state,
                closable_add_ref_facture: false,
            };

        case modalConstants.SHOW_UPDATE_PROFILE:
            return {
                ...state,
                closable_update_profil: true,
            };

        case modalConstants.CLOSE_UPDATE_PROFILE:
            return {
                ...state,
                closable_update_profil: false,
            };

        case modalConstants.SHOW_ADD_TEAM:
            return {
                ...state,
                closable_add_equipe: true,
            };

        case modalConstants.CLOSE_ADD_TEAM:
            return {
                ...state,
                closable_add_equipe: false,
            };

        case modalConstants.SHOW_MORE_TEAM:
            return {
                ...state,
                closable_more_team: true,
            };

        case modalConstants.CLOSE_MORE_TEAM:
            return {
                ...state,
                closable_more_team: false,
            };

        case modalConstants.SHOW_SETTING_TEAM:
            return {
                ...state,
                closable_setting_team: true,
            };

        case modalConstants.CLOSE_SETTING_TEAM:
            return {
                ...state,
                closable_setting_team: false,
            };

        case modalConstants.SHOW_ADD_TEAM_MENBER:
            return {
                ...state,
                closable_add_team_menber: true,
            };

        case modalConstants.CLOSE_ADD_TEAM_MENBER:
            return {
                ...state,
                closable_add_team_menber: false,
            };

        case modalConstants.SHOW_ADD_TEAM_PROJECT:
            return {
                ...state,
                closable_add_team_project: true,
            };

        case modalConstants.CLOSE_ADD_TEAM_PROJECT:
            return {
                ...state,
                closable_add_team_project: false,
            };

        case modalConstants.SHOW_AFFECT_TEAM_PROJECT:
            return {
                ...state,
                closable_affect_team_project: true,
            };

        case modalConstants.CLOSE_AFFECT_TEAM_PROJECT:
            return {
                ...state,
                closable_affect_team_project: false,
            };

        case modalConstants.SHOW_DETAIL_TASK:
            return {
                ...state,
                closable_detail_task: true,
            };

        case modalConstants.CLOSE_DETAIL_TASK:
            return {
                ...state,
                closable_detail_task: false,
            };

        case modalConstants.SHOW_ADD_TASK:
            return {
                ...state,
                closable_add_task: !state.closable_add_task,
            };

        case modalConstants.CLOSE_ADD_TASK:
            return {
                ...state,
                closable_add_task: false,
            };

        case modalConstants.SHOW_MORE_PROJECT:
            return {
                ...state,
                closable_more_project: true,
            };

        case modalConstants.CLOSE_MORE_PROJECT:
            return {
                ...state,
                closable_more_project: false,
            };

        case modalConstants.SHOW_ADD_PROJECT:
            return {
                ...state,
                closable_add_project: true,
            };

        case modalConstants.CLOSE_ADD_PROJECT:
            return {
                ...state,
                closable_add_project: false,
            };

        case modalConstants.SHOW_ADD_ALLPROJECT:
            return {
                ...state,
                closable_add_allproject: true,
            };

        case modalConstants.CLOSE_ADD_ALLPROJECT:
            return {
                ...state,
                closable_add_allproject: false,
            };

        case modalConstants.SHOW_ADD_COLOR:
            return {
                ...state,
                closable_add_color: true,
            };

        case modalConstants.CLOSE_ADD_COLOR:
            return {
                ...state,
                closable_add_color: false,
            };

        case modalConstants.SHOW_ADD_TASK_STATUT:
            return {
                ...state,
                closable_add_task_statut: true,
            };

        case modalConstants.CLOSE_ADD_TASK_STATUT:
            return {
                ...state,
                closable_add_task_statut: false,
            };

        case modalConstants.SHOW_ADD_JALON:
            return {
                ...state,
                closable_add_jalon: true,
            };

        case modalConstants.CLOSE_ADD_JALON:
            return {
                ...state,
                closable_add_jalon: false,
            };

        case modalConstants.SHOW_USER_PROFIL:
            return {
                ...state,
                closable_user_profil: true,
            };

        case modalConstants.CLOSE_USER_PROFIL:
            return {
                ...state,
                closable_user_profil: false,
            };

        case modalConstants.SHOW_AUTO_LOGOUT:
            return {
                ...state,
                closable_auto_logout: true,
            };

        case modalConstants.CLOSE_AUTO_LOGOUT:
            return {
                ...state,
                closable_auto_logout: false,
            };

        case modalConstants.SHOW_ADD_DIRECTION:
            return {
                ...state,
                closable_add_direction: true,
            };

        case modalConstants.CLOSE_ADD_DIRECTION:
            return {
                ...state,
                closable_add_direction: false,
            };

        case modalConstants.SHOW_NETWORK:
            return {
                ...state,
                closable_network: true,
            };

        case modalConstants.CLOSE_NETWORK:
            return {
                ...state,
                closable_network: false,
            };

        case modalConstants.SHOW_ADD_CUSTOMERS:
            return {
                ...state,
                closable_add_customers: true,
            };

        case modalConstants.CLOSE_ADD_CUSTOMERS:
            return {
                ...state,
                closable_add_customers: false,
            };

        case modalConstants.SHOW_ADD_CUSTOMER:
            return {
                ...state,
                closable_add_customer: true,
            };

        case modalConstants.CLOSE_ADD_CUSTOMER:
            return {
                ...state,
                closable_add_customer: false,
            };
        case modalConstants.SHOW_ADD_STATUT_DEMANDE:
            return {
                ...state,
                closable_add_statut_demande: true,
            };

        case modalConstants.CLOSE_ADD_STATUT_DEMANDE:
            return {
                ...state,
                closable_add_statut_demande: false,
            };

        case modalConstants.SHOW_ADD_DEMANE_OF_QUOTATION:
            return {
                ...state,
                closable_add_demande_of_quotation: true,
            };

        case modalConstants.CLOSE_ADD_DEMANE_OF_QUOTATION:
            return {
                ...state,
                closable_add_demande_of_quotation: false,
            };

        case modalConstants.SHOW_FILTER_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                closable_filter_demande_of_quotation: true,
            };

        case modalConstants.CLOSE_FILTER_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                closable_filter_demande_of_quotation: false,
            };

        case modalConstants.SHOW_TYPE_TICKET:
            return {
                ...state,
                closable_type_ticket: true,
            };

        case modalConstants.CLOSE_TYPE_TICKET:
            return {
                ...state,
                closable_type_ticket: false,
            };

        case modalConstants.SHOW_TICKET_STATE:
            return {
                ...state,
                closable_ticket_state: true,
            };

        case modalConstants.CLOSE_TICKET_STATE:
            return {
                ...state,
                closable_ticket_state: false,
            };

        case modalConstants.SHOW_PRIORITY_TICKET:
            return {
                ...state,
                closable_priority_ticket: true,
            };

        case modalConstants.CLOSE_PRIORITY_TICKET:
            return {
                ...state,
                closable_priority_ticket: false,
            };

        case modalConstants.SHOW_FILTRER_TICKET:
            return {
                ...state,
                closable_filtrer_ticket: true,
            };

        case modalConstants.CLOSE_FILTRER_TICKET:
            return {
                ...state,
                closable_filtrer_ticket: false,
            };

        case modalConstants.SHOW_MODAL_ADD_CLIENT:
            return {
                ...state,
                closable_modal_add_client: true,
            };

        case modalConstants.CLOSE_MODAL_ADD_CLIENT:
            return {
                ...state,
                closable_modal_add_client: false,
            };

        default:
            return state;
    }
};
