export const team_projectConstants = {
    AFFECT_TEAM_PROJECT_REQUEST: "AFFECT_TEAM_PROJECT_REQUEST",
    AFFECT_TEAM_PROJECT_SUCCESS: "AFFECT_TEAM_PROJECT_SUCCESS",
    AFFECT_TEAM_PROJECT_FAILURE: "AFFECT_TEAM_PROJECT_FAILURE",

    ADD_TEAM_PROJECT_REQUEST: "ADD_TEAM_PROJECT_REQUEST",
    ADD_TEAM_PROJECT_SUCCESS: "ADD_TEAM_PROJECT_SUCCESS",
    ADD_TEAM_PROJECT_FAILURE: "ADD_TEAM_PROJECT_FAILURE",

    GETALL_TEAM_PROJECT_REQUEST: "GETALL_TEAM_PROJECT_REQUEST",
    GETALL_TEAM_PROJECT_SUCCESS: "GETALL_TEAM_PROJECT_SUCCESS",
    GETALL_TEAM_PROJECT_FAILURE: "GETALL_TEAM_PROJECT_FAILURE",

    GETALL_TEAM_MEMBER_PROJECT_REQUEST: "GETALL_TEAM_MEMBER_PROJECT_REQUEST",
    GETALL_TEAM_MEMBER_PROJECT_SUCCESS: "GETALL_TEAM_MEMBER_PROJECT_SUCCESS",
    GETALL_TEAM_MEMBER_PROJECT_FAILURE: "GETALL_TEAM_MEMBER_PROJECT_FAILURE",

    UPDATE_TEAM_PROJECT_REQUEST: "UPDATE_TEAM_PROJECT_REQUEST",
    UPDATE_TEAM_PROJECT_SUCCESS: "UPDATE_TEAM_PROJECT_SUCCESS",
    UPDATE_TEAM_PROJECT_FAILURE: "UPDATE_TEAM_PROJECT_FAILURE",

    SELECTED_PROJECT: "SELECTED_PROJECT",
    RESET_FILTER_PROJECT_USER: "RESET_FILTER_PROJECT_USER",

    GETALL_REQUEST: "GETALL_PROJECT_REQUEST",
    GETALL_SUCCESS: "GETALL_PROJECT_SUCCESS",
    GETALL_FAILURE: "GETALL_PROJECT_FAILURE",

    ADD_REQUEST: "ADD_PROJECT_REQUEST",
    ADD_SUCCESS: "ADD_PROJECT_SUCCESS",
    ADD_FAILURE: "ADD_PROJECT_FAILURE",

    UPDATE_REQUEST: "UPDATE_PROJECT_REQUEST",
    UPDATE_SUCCESS: "UPDATE_PROJECT_SUCCESS",
    UPDATE_FAILURE: "UPDATE_PROJECT_FAILURE",

    SEARCH_PROJECT: "SEARCH_PROJECT",

    SEARCH_PROJECT_CHEF: "SEARCH_PROJECT_CHEF",

    SEARCH_PROJECT_DEV: "SEARCH_PROJECT_DEV",

    RESET_TEAM_PROJECT: "RESET_TEAM_PROJECT",

    FILTER_IN_PROGRESS_PROJECT: "FILTER_IN_PROGRESS_PROJECT",
    FILTER_ARCHIVED_PROJECT: "FILTER_ARCHIVED_PROJECT",
    FILTER_ALL_PROJECT: "FILTER_ALL_PROJECT",

    RESET_SEARCH: "RESET_SEARCH",

    DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",
};
