import { project_colorConstants } from "../_constants";
import { project_colorServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const project_colorActions = {
    getAll,
    add,
    update
};

function update (color) {
    return function (dispatch) {
        dispatch(request());
        project_colorServices.update(color)
        .then(res => {
            dispatch(success(res.data.message))
            dispatch(alertActions.success("Modification effectuée !"));
        })
        .catch(err => {
            dispatch(failure(err.message))
            dispatch(alertActions.error("Echec de modification !"));
            if(err.response !== undefined && err.response.status === 403) {
                dispatch(loginActions.logout());
            }
        })
    }
    function request () {
        return {
            type: project_colorConstants.UPDATE_REQUEST
        }
    }
    function success (color) {
        return {
            type: project_colorConstants.UPDATE_SUCCESS,
            color
        }
    }
    function failure (error) {
        return {
            type: project_colorConstants.UPDATE_FAILURE,
            error
        }
    }
}

function add(color) {
    return function (dispatch) {
        dispatch(request());
        project_colorServices
            .add(color)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.success("Echec d'ajout !"));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: project_colorConstants.ADD_REQUEST,
        };
    }
    function success(color) {
        return {
            type: project_colorConstants.ADD_SUCCESS,
            color,
        };
    }
    function failure(error) {
        return {
            type: project_colorConstants.ADD_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        project_colorServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: project_colorConstants.GETALL_REQUEST,
        };
    }
    function success(project_color) {
        return {
            type: project_colorConstants.GETALL_SUCCESS,
            project_color,
        };
    }
    function failure(error) {
        return {
            type: project_colorConstants.GETALL_FAILURE,
            error,
        };
    }
}
