import { type_ticketConstants } from "../_constants";
import { type_ticketServices } from "../_services";
import { alertActions } from "../_actions";

export const type_ticketActions = {
    getAll,
    add,
    update,
    remove,
};

function update(type_ticket) {
    return function (dispatch) {
        dispatch(request());
        type_ticketServices
            .update(type_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: type_ticketConstants.UPDATE_TYPE_TICKET_REQUEST,
        };
    }
    function success(type_ticket) {
        return {
            type: type_ticketConstants.UPDATE_TYPE_TICKET_SUCCESS,
            type_ticket,
        };
    }
    function failure(error) {
        return {
            type: type_ticketConstants.UPDATE_TYPE_TICKET_FAILURE,
            error,
        };
    }
}

function add(type_ticket) {
    return function (dispatch) {
        dispatch(request());
        type_ticketServices
            .add(type_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec d'ajout."));
            });
    };
    function request() {
        return {
            type: type_ticketConstants.ADD_TYPE_TICKET_REQUEST,
        };
    }
    function success(type_ticket) {
        return {
            type: type_ticketConstants.ADD_TYPE_TICKET_SUCCESS,
            type_ticket,
        };
    }
    function failure(error) {
        return {
            type: type_ticketConstants.ADD_TYPE_TICKET_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        type_ticketServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: type_ticketConstants.GETALL_TYPE_TICKET_REQUEST,
        };
    }
    function success(type_ticket) {
        return {
            type: type_ticketConstants.GETALL_TYPE_TICKET_SUCCESS,
            type_ticket,
        };
    }
    function failure(error) {
        return {
            type: type_ticketConstants.GETALL_TYPE_TICKET_FAILURE,
            error,
        };
    }
}

function remove(type_ticket) {
    return function (dispatch) {
        dispatch(request());
        type_ticketServices
            .remove(type_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppression effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de suppression."));
            });
    };
    function request() {
        return {
            type: type_ticketConstants.DELETE_TYPE_TICKET_REQUEST,
        };
    }
    function success(type_ticket) {
        return {
            type: type_ticketConstants.DELETE_TYPE_TICKET_SUCCESS,
            type_ticket,
        };
    }
    function failure(error) {
        return {
            type: type_ticketConstants.DELETE_TYPE_TICKET_FAILURE,
            error,
        };
    }
}
