import React, { useEffect } from "react";
import {
    Modal,
    Form,
    Button,
    Select,
    Input,
    Avatar,
    Typography,
    Popconfirm,
    DatePicker
} from "antd";
import "./ModalStyle.css";
import { useSelector, useDispatch } from "react-redux";
import {
    modalActions,
    team_projectActions,
    team_menberActions,
} from "../../../redux/_actions";
import {
    SaveOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import { isEmpty } from "lodash";


Moment.globalMoment = moment;
Moment.globalLocale = "fr";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const ModalAjouterProjetEquipe = (props) => {
    const closable_add_team_project = useSelector(
        (state) => state.modal.closable_add_team_project
    );
    const project_colors = useSelector((state) => state.project_color.items);
    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const teams = useSelector((state) => state.team.items);
    const auth = useSelector((state) => state.login.item);
    const statut_projets = useSelector((state) => state.statut_projet.items);
    const clients = useSelector((state) => state.customer.items);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const id_profil_admin = 1;

    useEffect(() => {
        form.setFieldsValue({
            id_equipe:
                props.teamSelected === 0 ? auth.id_equipe : props.teamSelected,
            libelle:
                props.projectSelected.libelle !== ""
                    ? props.projectSelected.libelle
                    : null,
            color:
                props.projectSelected.id_color !== ""
                    ? props.projectSelected.id_color
                    : null,
            id_statut_projet: props.projectSelected.id_statut_projet,
            description:
                props.projectSelected.description !== ""
                    ? props.projectSelected.description
                    : null,
            id_equipe_membre: props.projectSelected.id_equipe_membre,
            client: props.projectSelected.id_client,
        });

        if (!isEmpty(props.projectSelected.echeance)) {
            form.setFieldsValue({
                echeance: moment(props.projectSelected.echeance),
            });
        }
    });

    const submitAddTeamPorject = (values) => {
        if (Object.keys(props.projectSelected).length === 0) {
            const data = {
                libelle: values.libelle,
                color: values.color,
                id_equipe: values.id_equipe,
                id_equipe_membre: values.id_equipe_membre,
                description: values.description,
                echeance: values.echeance === undefined ? null : moment(values.echeance).format("YYYY-MM-DD"),
                id_statut: 1,
                create_by: auth.id_user,
                id_client: values.client,
            };
            dispatch(team_projectActions.addTeamProject(data));
            dispatch(modalActions.close_add_team_project());
        } else {
            const data = {
                id_projet: props.projectSelected.id_projet,
                libelle: values.libelle,
                color: values.color,
                id_equipe: values.id_equipe,
                echeance: values.echeance === undefined ? null : moment(values.echeance).format("YYYY-MM-DD"),
                id_equipe_membre: values.id_equipe_membre,
                description: values.description,
                id_statut: values.id_statut_projet,
                id_client: values.client,
            };

            dispatch(team_projectActions.updateTeamProject(data));
            dispatch(modalActions.close_add_team_project());
            if (auth.id_equipe !== null) {
                dispatch(
                    team_projectActions.getAllTeamMemberProject(
                        auth.id_equipe_membre
                    )
                );
            }

            // mettre a jour la liste des projets dans le cas d'une equipe choisi
            if (props.teamSelected !== 0) {
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: props.teamSelected,
                    })
                );
            }
            form.resetFields();
        }
    };

    const afterCloseModal = () => {
        form.resetFields();
        props.setProjectSelected({});
    };

    return (
        <Modal
            title={
                Object.keys(props.projectSelected).length === 0 ? (
                    <Text strong={true}>Ajouter un projet</Text>
                ) : (
                    <Text strong={true}>Modifier un projet</Text>
                )
            }
            visible={closable_add_team_project}
            footer={null}
            onCancel={() => {
                props.setProjectSelected({});
                dispatch(modalActions.close_add_team_project());
            }}
            afterClose={() => afterCloseModal()}
            maskClosable={false}
        >
            <Form form={form} onFinish={submitAddTeamPorject} layout="vertical">
                <Form.Item>
                    <Form.Item
                        name="id_equipe"
                        label={<Text strong={true}>Équipes</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner le nom de l'équipe!",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            // disabled={
                            //     Object.keys(props.projectSelected).length === 0
                            //         ? false
                            //         : true
                            // }
                            disabled={id_profil_admin === auth.id_profil ? false : true}
                        >
                            {teams.map((p) => (
                                <Option value={p.id_equipe}>{p.libelle}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="id_equipe_membre"
                        label={<Text strong={true}>Membre</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner un membre !"
                        >
                            {team_menbers.map((p) => (
                                <Option value={p.id_equipe_membre}>
                                    {p.first_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="libelle"
                        label={<Text strong={true}>Libelle</Text>}
                        style={{
                            display: "inline-block",
                            width:
                                Object.keys(props.projectSelected).length !== 0
                                    ? "calc(32% - 0px)"
                                    : "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: "Veuillez saisir le nom du projet !",
                            },
                        ]}
                    >
                        <Input
                            type="text"
                            size="large"
                            placeholder="Libellé du projet"
                        />
                    </Form.Item>
                    <Form.Item
                        name="color"
                        label={<Text strong={true}>Couleur</Text>}
                        style={{
                            display: "inline-block",
                            width:
                                Object.keys(props.projectSelected).length !== 0
                                    ? "calc(32% - 0px)"
                                    : "calc(49% - 0px)",
                            marginRight: 2,
                            marginLeft: 4,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner la couleur du projet !",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Choisir une couleur."
                            defaultValue={[props.projectSelected.id_color]}
                        >
                            {project_colors.map((p) => (
                                <Option value={p.id_color}>
                                    <Avatar
                                        size="small"
                                        style={{
                                            backgroundColor: p.color,
                                        }}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {Object.keys(props.projectSelected).length !== 0 ? (
                        <Form.Item
                            name="id_statut_projet"
                            label={<Text strong={true}>Statut</Text>}
                            style={{
                                display: "inline-block",
                                width: "calc(32% - 0px)",
                                marginBottom: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Veuillez sélectionner la couleur du projet !",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                                placeholder="Choisir une couleur."
                                defaultValue={[
                                    props.projectSelected.id_statut_projet,
                                ]}
                            >
                                {statut_projets.map((p) => (
                                    <Option value={p.id_statut_projet}>
                                        {p.libelle}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : null}
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="client"
                        label={<Text strong={true}>Client</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            allowClear
                            style={{
                                // width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner un client."
                            defaultValue={[props.projectSelected.id_client]}
                        >
                            {clients.map((p) => (
                                <Option value={p.id_client}>{p.nom_client}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="echeance"
                        label={<Text strong={true}>Date d'échéance</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                    >
                        <DatePicker format="DD/MM/YYYY" name="echeance" size="large" style={{ width: "100%" }} />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="description"
                    label={<Text strong={true}>Description</Text>}
                >
                    <TextArea
                        row={4}
                        placeholder="Veuillez saisir la description du projet !"
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{
                            display: "inline-block",
                            width: "calc(72% - 0px)",
                            marginBottom: 0,
                        }}
                    >
                        <Button
                            size="large"
                            type="default"
                            onClick={() =>
                                dispatch(modalActions.close_add_team_project())
                            }
                        >
                            <CloseCircleOutlined /> Annuler
                        </Button>{" "}
                        <Button size="large" type="primary" htmlType="submit">
                            <SaveOutlined /> Enregistrer
                        </Button>
                    </Form.Item>
                    {props.projectSelected.delete_project == true &&
                        Object.keys(props.projectSelected).length !== 0 ? (
                        <Form.Item
                            style={{
                                display: "inline-block",
                                width: "calc(20% - 0px)",
                                marginRight: 2,
                                marginBottom: 0,
                            }}
                        >
                            <Popconfirm
                                title="Etes vous sur de vouloir supprimer ce projet ?"
                                onConfirm={() => {
                                    dispatch(
                                        team_projectActions.delete_project(
                                            parseInt(
                                                props.projectSelected.id_projet
                                            )
                                        )
                                    );
                                    dispatch(
                                        modalActions.close_add_team_project()
                                    );
                                }}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button size="large" danger>
                                    <DeleteOutlined /> Supprimer
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    ) : null}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterProjetEquipe;
