export const userConstants = {
    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    LOGOUT: "USERS_LOGOUT",

    GETALL_REQUEST: "USERS_GETALL_REQUEST",
    GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
    GETALL_FAILURE: "USERS_GETALL_FAILURE",

    GETONE_REQUEST: "USERS_GETONE_REQUEST",
    GETONE_SUCCESS: "USERS_GETONE_SUCCESS",
    GETONE_FAILURE: "USERS_GETONE_FAILURE",

    UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
    UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
    UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

    ADD_REQUEST: "USERS_ADD_REQUEST",
    ADD_SUCCESS: "USERS_ADD_SUCCESS",
    ADD_FAILURE: "USERS_ADD_FAILURE",

    DELETE_REQUEST: "USERS_DELETE_REQUEST",
    DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
    DELETE_FAILURE: "USERS_DELETE_FAILURE",

    USER_WITHOUT_TEAM_REQUEST: "USER_WITHOUT_TEAM_REQUEST",
    USER_WITHOUT_TEAM_SUCCESS: "USER_WITHOUT_TEAM_SUCCESS",
    USER_WITHOUT_TEAM_FAILURE: "USER_WITHOUT_TEAM_FAILURE",

    USER_NOTIFICATION_REQUEST: "USER_NOTIFICATION_REQUEST",
    USER_NOTIFICATION_SUCCESS: "USER_NOTIFICATION_SUCCESS",
    USER_NOTIFICATION_FAILURE: "USER_NOTIFICATION_FAILURE",

    USER_OPEN_NOTIFICATION_REQUEST: "USER_OPEN_NOTIFICATION_REQUEST",
    USER_OPEN_NOTIFICATION_SUCCESS: "USER_OPEN_NOTIFICATION_SUCCESS",
    USER_OPEN_NOTIFICATION_FAILURE: "USER_OPEN_NOTIFICATION_FAILURE",

    GETALL_CHIEF_WITHOUT_TEAM_REQUEST: "GETALL_CHIEF_WITHOUT_TEAM_REQUEST",
    GETALL_CHIEF_WITHOUT_TEAM_SUCCESS: "GETALL_CHIEF_WITHOUT_TEAM_SUCCESS",
    GETALL_CHIEF_WITHOUT_TEAM_FAILURE: "GETALL_CHIEF_WITHOUT_TEAM_FAILURE",

    GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_REQUEST:
        "GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_REQUEST",
    GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_SUCCESS:
        "GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_SUCCESS",
    GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_FAILURE:
        "GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_FAILURE",

    GETALL_PROJECT_MEMBER_REQUEST: "GETALL_PROJECT_MEMBER_REQUEST",
    GETALL_PROJECT_MEMBER_SUCCESS: "GETALL_PROJECT_MEMBER_SUCCESS",
    GETALL_PROJECT_MEMBER_FAILURE: "GETALL_PROJECT_MEMBER_FAILURE",

    CHECK_LOGIN_REQUEST: "CHECK_LOGIN_REQUEST",
    CHECK_LOGIN_SUCCESS: "CHECK_LOGIN_SUCCESS",
    CHECK_LOGIN_FAILURE: "CHECK_LOGIN_FAILURE",

    CHECK_LOGIN_UPDATE_REQUEST: "CHECK_LOGIN_UPDATE_REQUEST",
    CHECK_LOGIN_UPDATE_SUCCESS: "CHECK_LOGIN_UPDATE_SUCCESS",
    CHECK_LOGIN_UPDATE_FAILURE: "CHECK_LOGIN_UPDATE_FAILURE",

    CHECK_MAIL_REQUEST: "CHECK_MAIL_REQUEST",
    CHECK_MAIL_SUCCESS: "CHECK_MAIL_SUCCESS",
    CHECK_MAIL_FAILURE: "CHECK_MAIL_FAILURE",

    CHECK_MAIL_UPDATE_REQUEST: "CHECK_MAIL_UPDATE_REQUEST",
    CHECK_MAIL_UPDATE_SUCCESS: "CHECK_MAIL_UPDATE_SUCCESS",
    CHECK_MAIL_UPDATE_FAILURE: "CHECK_MAIL_UPDATE_FAILURE",

    RESET_EXIST: "RESET_EXIST",

    CHANGE_USER_PASSWORD_REQUEST: "CHANGE_USER_PASSWORD_REQUEST",
    CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
    CHANGE_USER_PASSWORD_FAILURE: "CHANGE_USER_PASSWORD_FAILURE",

    SEND_REMEMBER_MAIL_REQUEST: "SEND_REMEMBER_MAIL_REQUEST",
    SEND_REMEMBER_MAIL_SUCCESS: "SEND_REMEMBER_MAIL_SUCCESS",
    SEND_REMEMBER_MAIL_FAILURE: "SEND_REMEMBER_MAIL_FAILURE",
};
