export const statut_demandeConstants = {
    GETALL_STATUT_DEMANDE_REQUEST: "GETALL_STATUT_DEMANDE_REQUEST",
    GETALL_STATUT_DEMANDE_SUCCESS: "GETALL_STATUT_DEMANDE_SUCCESS",
    GETALL_STATUT_DEMANDE_FAILURE: "GETALL_STATUT_DEMANDE_FAILURE",

    ADD_STATUT_DEMANDE_REQUEST: "ADD_STATUT_DEMANDE_REQUEST",
    ADD_STATUT_DEMANDE_SUCCESS: "ADD_STATUT_DEMANDE_SUCCESS",
    ADD_STATUT_DEMANDE_FAILURE: "ADD_STATUT_DEMANDE_FAILURE",

    UPDATE_STATUT_DEMANDE_REQUEST: "UPDATE_STATUT_DEMANDE_REQUEST",
    UPDATE_STATUT_DEMANDE_SUCCESS: "UPDATE_STATUT_DEMANDE_SUCCESS",
    UPDATE_STATUT_DEMANDE_FAILURE: "UPDATE_STATUT_DEMANDE_FAILURE",

    DELETE_STATUT_DEMANDE_REQUEST: "DELETE_STATUT_DEMANDE_REQUEST",
    DELETE_STATUT_DEMANDE_SUCCESS: "DELETE_STATUT_DEMANDE_SUCCESS",
    DELETE_STATUT_DEMANDE_FAILURE: "DELETE_STATUT_DEMANDE_FAILURE",
}