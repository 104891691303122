import { actionConstants } from "../_constants";
import { actionServices } from "../_services";
import { loginActions } from "../_actions";

export const actionActions = {
    //add,
    getAll,
    //update,
    // getOne,
    // remove,
    getProfileAction,
    checked,
    unchecked,
    async_checkall,
    checkall,
    uncheckall
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        actionServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return {
            type: actionConstants.GETALL_REQUEST,
        };
    }
    function success(action) {
        return {
            type: actionConstants.GETALL_SUCCESS,
            action,
        };
    }
    function failure(error) {
        return {
            type: actionConstants.GETALL_FAILURE,
            error,
        };
    }
}

function getProfileAction(profile) {
    return function (dispatch) {
        dispatch(request());
        actionServices
            .getProfileAction(profile)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return { type: actionConstants.GET_PROFILE_ACTION_REQUEST };
    }
    function success(action) {
        return { type: actionConstants.GET_PROFILE_ACTION_SUCCESS, action };
    }
    function failure(error) {
        return { type: actionConstants.GET_PROFILE_ACTION_FAILURE, error };
    }
}

function checked(action) {
    return {
        type: actionConstants.CHECKED,
        action,
    };
}

function unchecked(action) {
    return {
        type: actionConstants.UNCHECKED,
        action,
    };
}

function async_checkall(check) {
    return function (dispatch) {
        dispatch(request());
        actionServices
            .checkall(check)
            .then((res) => {
                // console.log("Donnee: ", res.data.message)
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: actionConstants.CHECKALL_REQUEST,
        };
    }
    function success(check) {
        return {
            type: actionConstants.CHECKALL_SUCCESS,
            check,
        };
    }
    function failure(error) {
        return {
            type: actionConstants.CHECKALL_FAILURE,
            error,
        };
    }
}

function checkall(id_module) {
    return {
        type: actionConstants.CHECKALL,
        id_module
    }
}

function uncheckall(id_module) {
    return {
        type: actionConstants.UNCHECKALL,
        id_module
    }
}
