import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";
import * as store from "./redux/_helpers";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store.store}>
            <ConfigProvider locale={frFR}>
                <PersistGate persistor={store.persistor}>
                    <App />
                </PersistGate>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
