export const droitConstants = {
    ADD_REQUEST: "DROIT_ADD_REQUEST",
    ADD_SUCCESS: "DROIT_ADD_SUCCESS",
    ADD_FAILURE: "DROIT_ADD_FAILURE",

    GETALL_REQUEST: "DROIT_GETALL_REQUEST",
    GETALL_SUCCESS: "DROIT_GETALL_SUCCESS",
    GETALL_FAILURE: "DROIT_GETALL_FAILURE",

    DELETE_REQUEST: "DROIT_DELETE_REQUEST",
    DELETE_SUCCESS: "DROIT_DELETE_SUCCESS",
    DELETE_FAILURE: "DROIT_DELETE_FAILURE",

    CHECkALL_NEW_REQUEST: "CHECkALL_NEW_REQUEST",
    CHECkALL_NEW_SUCCESS: "CHECkALL_NEW_SUCCESS",
    CHECkALL_NEW_FAILURE: "CHECkALL_NEW_FAILURE",
};
