import { jalonConstants } from "../_constants";

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export const jalon = (state = initialState, action) => {
    switch (action.type) {
        case jalonConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case jalonConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.jalon,
            };

        case jalonConstants.GETALL_ERROR:
            return {
                ...state,
                loading: false,
                items: action.error,
            };

        case jalonConstants.ADD_JALON_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case jalonConstants.ADD_JALON_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.jalon],
            };

        case jalonConstants.ADD_JALON_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case jalonConstants.UPDATE_JALON_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case jalonConstants.UPDATE_JALON_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_jalon === action.jalon.id_jalon) {
                            Object.assign(p, action.jalon);
                        }
                        return p;
                    }),
                ],
            };

        case jalonConstants.UPDATE_JALON_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
