import axios from "../../axios/index";
export const profileService = {
    add,
    getAll,
    // getById,
    update,
    // remove,
};

async function add(profile) {
    return await axios.post(`profils`, profile);
}

async function getAll() {
    return await axios.get(`profils`);
}

async function update(profile) {
    return await axios.put(`profils/${profile.id_profil}`, profile);
}
