export const jalonConstants = {
    GETALL_REQUEST: "GETALL_JALON_REQUEST",
    GETALL_SUCCESS: "GETALL_JALON_SUCCESS",
    GETALL_ERROR: "GETALL_JALON_ERROR",

    ADD_JALON_REQUEST: "ADD_JALON_REQUEST",
    ADD_JALON_SUCCESS: "ADD_JALON_SUCCESS",
    ADD_JALON_FAILURE: "ADD_JALON_FAILURE",

    DELETE_JALON_REQUEST: "DELETE_JALON_REQUEST",
    DELETE_JALON_SUCCESS: "DELETE_JALON_SUCCESS",
    DELETE_JALON_FAILURE: "DELETE_JALON_FAILURE",

    UPDATE_JALON_REQUEST: "UPDATE_JALON_REQUEST",
    UPDATE_JALON_SUCCESS: "UPDATE_JALON_SUCCESS",
    UPDATE_JALON_FAILURE: "UPDATE_JALON_FAILURE",
};
