import React, { useState } from "react";
import { Modal, Button, Select, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { modalActions, ticketActions } from "../../../redux/_actions";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

const ModalFiltrerTicket = () => {
    const closable_filtrer_ticket = useSelector(
        (state) => state.modal.closable_filtrer_ticket
    );
    const auth = useSelector((state) => state.login.item);
    const projects = useSelector((state) => state.project.items);
    const customers = useSelector((state) => state.customer.items);
    const type_tickets = useSelector((state) => state.type_ticket.items);
    const priority_tickets = useSelector(
        (state) => state.priority_ticket.items
    );
    const ticket_states = useSelector((state) => state.ticket_state.items);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const filtrerTickets = (value) => {
        dispatch(ticketActions.filtrer_ticket(value));
        dispatch(modalActions.close_filtrer_ticket());
    };

    return (
        <Modal
            title="Filtrer les tickets"
            visible={closable_filtrer_ticket}
            footer={null}
            onCancel={() => dispatch(modalActions.close_filtrer_ticket())}
            bodyStyle={{
                paddingTop: 10,
            }}
        >
            <Form
                form={form}
                onFinish={filtrerTickets}
                initialValues={{
                    id_state: null,
                    id_client: null,
                    id_type_demande: null,
                    id_priority: null,
                }}
            >
                <Form.Item>
                    <Form.Item
                        label="État"
                        name="id_state"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select allowClear>
                            {ticket_states.map((p) => (
                                <Option value={p.id_state}>
                                    {p.state_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Client"
                        name="id_client"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select allowClear>
                            {customers.map((p) => (
                                <Option value={p.id_client}>
                                    {p.nom_client}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Form.Item
                        label="Priorite"
                        name="id_priority"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select allowClear>
                            {priority_tickets.map((p) => (
                                <Option value={p.id_priority}>
                                    {p.priority_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Type demande"
                        name="id_type_demande"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select allowClear>
                            {type_tickets.map((p) => (
                                <Option value={p.id}>{p.type_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<FilterOutlined />}
                        style={{ marginRight: 10 }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="default"
                        onClick={() =>
                            dispatch(modalActions.close_filtrer_ticket())
                        }
                    >
                        Annuler
                    </Button>
                    <Button
                        type="default"
                        style={{
                            float: "right",
                            marginRight: 10,
                            borderColor: "red",
                            color: "red",
                        }}
                        onClick={() => {
                            dispatch(ticketActions.reset_filtrer_ticket());
                            form.resetFields();
                        }}
                    >
                        Effacer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalFiltrerTicket;
