import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { projectActions, taskActions } from "../../../redux/_actions";
import { Collapse, Typography } from "antd";
import AddTask from "../../components/Card/AddTask";
import { useDispatch, useSelector } from "react-redux";
import { CaretRightOutlined } from "@ant-design/icons";
import ListeItem from "../../components/Card/ListeItem";
import "./Liste.css";

const { Panel } = Collapse;
const { Text } = Typography;

const Liste = (props) => {
    let tab = [];

    const [dragTask, setDragTask] = useState("");
    const [dragInDropTitle, setDragInDropTitle] = useState("");
    const [columns, setColumns] = useState(tab);

    const dispatch = useDispatch();

    const taches = useSelector((state) => state.project.project_task);
    const auth = useSelector((state) => state.login.item);
    const jalons = useSelector((state) => state.jalon.items);
    const closable_add_task = useSelector(
        (state) => state.modal.closable_add_task
    );

    const columnGenerate = () => {
        jalons.map((p) =>
            tab.push({
                name: <Text>{p.libelle}</Text>,
                items: taches
                    .filter(
                        (pp) =>
                            // eslint-disable-next-line eqeqeq
                            pp.id_projet == props.id_projet &&
                            // eslint-disable-next-line eqeqeq
                            pp.id_jalon == p.id_jalon
                    )
                    .sort((a, b) => a.ordre - b.ordre),
            })
        );
    };

    columnGenerate();

    // const team_menbers = useSelector((state) => state.team_menber.menbers);
    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
            // Confirmation des changement sur la tache niveau base de donnee.
            dispatch(
                projectActions.dragInDropTask({
                    id_tache: parseInt(dragTask),
                    id_jalon: parseInt(destination.droppableId) + 1,
                    libelle: dragInDropTitle,
                    // description: "Haaaaaaaaaaaaaaaa",
                    ordre: parseInt(destination.index) + 1,
                    id_projet: parseInt(props.id_projet),
                })
            );

            var quitter = columns[parseInt(source.droppableId)];
            var venir = columns[parseInt(destination.droppableId)];

            dispatch(
                taskActions.addTaskLog({
                    id_tache: parseInt(dragTask),
                    // description: `a déplacé la tache de ${quitter.name} à ${venir.name}`,
                    description: `a deplace la tache de ${quitter.name} a ${venir.name}`,
                    create_by: auth.id_user,
                })
            );
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
            // Confirmation des changement sur la tache niveau base de donnee.
            dispatch(
                projectActions.dragInDropTask({
                    id_tache: parseInt(dragTask),
                    // id_jalon: parseInt(destination.droppableId) + 1,
                    libelle: dragInDropTitle,
                    // description: "Haaaaaaaaaaaaaaaa",
                    ordre: parseInt(destination.index) + 1,
                    id_projet: parseInt(props.id_projet),
                })
            );
        }
    };

    const dragUpdate = (id_tache, libelle_tache) => {
        setDragTask(id_tache);
        setDragInDropTitle(libelle_tache);
    };

    return (
        <div>
            <div className="liste"
            >
                <br />
                <DragDropContext
                    onDragEnd={(result) =>
                        onDragEnd(result, columns, setColumns)
                    }
                >
                    {Object.entries(columns).map(([id, column], index) => {
                        return (
                            <div key={id}>
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={["0"]}
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined
                                            rotate={isActive ? 90 : 0}
                                        />
                                    )}
                                    className="site-collapse-custom-collapse"
                                    style={{
                                        position: "relative"
                                    }}
                                >
                                    <Panel
                                        header={<Text>{column.name}</Text>}
                                        key={index}
                                        className="site-collapse-custom-panel"
                                    >
                                        {index === 0 &&
                                            closable_add_task === true ? (
                                            <AddTask
                                                id_projet={props.id_projet}
                                            />
                                        ) : null}
                                        <Droppable droppableId={id} key={id}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                            width: "100%",
                                                            marginBottom: 20,
                                                            padding: 3,
                                                        }}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {column.items
                                                            .sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    a.ordre -
                                                                    b.ordre
                                                                );
                                                            })
                                                            .map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            draggableId={
                                                                                item.id
                                                                            }
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {(
                                                                                provided,
                                                                                snapshot
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <div
                                                                                            onMouseDown={() =>
                                                                                                dragUpdate(
                                                                                                    parseInt(
                                                                                                        item.id
                                                                                                    ),
                                                                                                    item.libelle_tache
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ListeItem
                                                                                                item={
                                                                                                    item
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                }
                                                            )}
                                                        {provided.placeholder}
                                                    </div>
                                                );
                                            }}
                                        </Droppable>
                                    </Panel>
                                </Collapse>
                            </div>
                        );
                    })}
                </DragDropContext>
                <br />
            </div>
        </div>
    );
};

export default Liste;
