import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Skeleton, List, Popconfirm, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
    team_menberActions,
    team_projectActions,
} from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import { makeAvatar } from "../../helpers/makeAvatar";

const { Text } = Typography;

const Membre = (props) => {
    const [editHide, setEditHide] = useState(0);
    const [memberSelected, setMemberSelected] = useState(0);
    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const dispatch = useDispatch();

    function confirm(value) {
        dispatch(
            team_menberActions.deleteTeamMenber({ id_equipe_membre: value })
        );
        setTimeout(() => {
            dispatch(
                team_projectActions.getAllTeamProject({
                    id_equipe: props.teamSelected,
                })
            );
        }, 100);
    }

    useEffect(() => {
        if (
            team_menbers[0] !== undefined &&
            team_menbers[0].id_equipe_membre !== undefined
        ) {
            setMemberSelected(team_menbers[0].id_equipe_membre);
            dispatch(
                team_menberActions.getAllTaskTeamMember({
                    id_user: team_menbers[0].id_user,
                })
            );
        }
    }, [team_menbers, dispatch]);

    const user = useSelector((state) => state.login);
    const ability = defineAbilityFor(user);

    return (
        <List
            size="small"
            itemLayout="horizontal"
            dataSource={team_menbers}
            style={{
                border: 0
            }}
            renderItem={(item) => (
                <List.Item
                    style={{
                        backgroundColor:
                            editHide === item.id_equipe_membre ||
                            memberSelected === item.id_equipe_membre
                                ? "#40a9ff"
                                : "#f8f8ff",
                        borderRadius: 5,
                        margin: "10px 0px",
                        cursor: "pointer",
                        border: "1px solid #dedede",
                    }}
                    onMouseEnter={() => setEditHide(item.id_equipe_membre)}
                    onMouseLeave={() => setEditHide(0)}
                    onClick={() => setMemberSelected(item.id_equipe_membre)}
                >
                    <Skeleton
                        loading={false}
                        active
                        paragraph={{ rows: 1 }}
                        avatar={{
                            size: "large",
                        }}
                        title={{ width: 50 }}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar>{makeAvatar(item.first_name)}</Avatar>
                            }
                            title={
                                <Text
                                    style={{
                                        color:
                                            editHide ===
                                                item.id_equipe_membre ||
                                            memberSelected ===
                                                item.id_equipe_membre
                                                ? "white"
                                                : null,
                                        fontSize: 13,
                                    }}
                                >
                                    {item.first_name}
                                </Text>
                            }
                            description={
                                <Text
                                    style={{
                                        color:
                                            editHide ===
                                                item.id_equipe_membre ||
                                            memberSelected ===
                                                item.id_equipe_membre
                                                ? "white"
                                                : null,
                                        fontSize: 13,
                                    }}
                                >
                                    {/* <span
                                        style={{
                                            border: "1px solid f8f8ff",
                                            padding: "0px 5px",
                                            color: "#2f4f4f",
                                            borderRadius: 5
                                        }}
                                    >
                                        {item.libelle_profil}
                                    </span> */}
                                </Text>
                            }
                        />
                        <Can I="RetirerMembre" a="Mes-projets" ability={ability}>
                            {(editHide === item.id_equipe_membre ||
                                memberSelected === item.id_equipe_membre) &&
                            item.chef_check === false ? (
                                <Popconfirm
                                    placement="top"
                                    title="Voulez-vous retire ce membre de cette équipe ?"
                                    onConfirm={() =>
                                        confirm(item.id_equipe_membre)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteOutlined
                                        style={{
                                            color:
                                                editHide ===
                                                    item.id_equipe_membre ||
                                                memberSelected ===
                                                    item.id_equipe_membre
                                                    ? "white"
                                                    : null,
                                        }}
                                        onClick={() =>
                                            setMemberSelected(
                                                item.id_equipe_membre
                                            )
                                        }
                                    />
                                </Popconfirm>
                            ) : null}
                        </Can>
                    </Skeleton>
                </List.Item>
            )}
        />
    );
};

export default Membre;
