import axios from "../../axios/index";
export const actionServices = {
    //add,
    getAll,
    // getById,
    //update,
    // remove,
    getProfileAction,
    checkall
};

async function getAll () {
    return await axios.get(`actions`);
}

async function getProfileAction (profile) {
    return await axios.get(`profils/action/${profile.id_profil}`, profile)
}

async function checkall (check) {
    return await axios.post(`profilActions/GetChecked`, check)
}