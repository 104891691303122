import { team_menberConstants } from "../_constants";
import { team_menberServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const team_menberActions = {
    add,
    getAllTeamMenber,
    deleteTeamMenber,
    getAllTaskTeamMember,
    selectTeamMember,
    selectedTeamMembre,
    resetUserTask,
    resetMembers,
    getAllTaskMemberMenu,
};

function getAllTaskMemberMenu() {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .getAllTaskTeamMember()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: team_menberConstants.GETALL_TASK_MEMBER_MENU_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: team_menberConstants.GETALL_TASK_MEMBER_MENU_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: team_menberConstants.GETALL_TASK_MEMBER_MENU_FAILURE,
            error,
        };
    }
}

function resetMembers() {
    return {
        type: team_menberConstants.RESET_MEMBERS,
    };
}

function resetUserTask() {
    return {
        type: team_menberConstants.RESET_USER_TASK,
    };
}

function selectedTeamMembre(id) {
    return {
        type: team_menberConstants.SELECTED_TEAM_MEMBRE,
        id,
    };
}

function selectTeamMember(team_menber) {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .getAllTeamMenber(team_menber)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
        function request() {
            return {
                type: team_menberConstants.SELECT_TEAM_MEMBER_REQUEST,
            };
        }
        function success(team_menber) {
            return {
                type: team_menberConstants.SELECT_TEAM_MEMBER_SUCCESS,
                team_menber,
            };
        }
        function failure(error) {
            return {
                type: team_menberConstants.SELECT_TEAM_MEMBER_FAILURE,
                error,
            };
        }
    };
}
function getAllTaskTeamMember(team_menber) {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .getAllTaskTeamMember(team_menber)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: team_menberConstants.GETALL_MENBER_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: team_menberConstants.GETALL_MENBER_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: team_menberConstants.GETALL_MENBER_FAILURE,
            error,
        };
    }
}

function add(team_menber) {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .add(team_menber)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de l'ajout."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: team_menberConstants.ADD_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: team_menberConstants.ADD_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: team_menberConstants.ADD_FAILURE,
            error,
        };
    }
}

function getAllTeamMenber(team_menber) {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .getAllTeamMenber(team_menber)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: team_menberConstants.GETALL_TEAM_MENBER_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: team_menberConstants.GETALL_TEAM_MENBER_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: team_menberConstants.GETALL_TEAM_MENBER_FAILURE,
            error,
        };
    }
}

function deleteTeamMenber(team_menber) {
    return function (dispatch) {
        dispatch(request());
        team_menberServices
            .deleteTeamMenber(team_menber)
            .then((res) => {
                dispatch(success(team_menber.id_equipe_membre));
                dispatch(alertActions.success("La suppression effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de la suppression."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: team_menberConstants.DELETE_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: team_menberConstants.DELETE_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: team_menberConstants.DELETE_FAILURE,
            error,
        };
    }
}
