import axios from "../../axios/index";

export const statut_demandeServices = {
    getAll,
    add,
    update,
    remove
}

async function getAll () {
    return await axios.get(`statut_demande`);
}

async function add (statut_demande) {
    return await axios.post(`statut_demande`, statut_demande);
}

async function update (statut_demande) {
    return await axios.put(`statut_demande/${statut_demande.id_statut}`, statut_demande);
}

async function remove (customer) {
    return await axios.get(`statut_demande/${customer}`);
}