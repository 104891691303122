import { customerConstants } from "../_constants";

const initialState = {
  loading: false,
  item: {},
  items: [],
  item_choose: {},
  error: "",
};

export const customer = (state = initialState, action) => {
  switch (action.type) {
    case customerConstants.ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case customerConstants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.customer],
      };

    case customerConstants.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case customerConstants.GETALL_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case customerConstants.GETALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.customer,
      };

    case customerConstants.GETALL_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case customerConstants.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case customerConstants.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [
          ...state.items.filter(
            (p) => parseInt(p.id_client) !== parseInt(action.customer)
          ),
        ],
      };

    case customerConstants.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case customerConstants.UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case customerConstants.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items.map(p => {
          if (parseInt(p.id_client) === parseInt(action.customer.id_client)) {
            Object.assign(p, action.customer)
          }
          return p;
        })]
      }

    case customerConstants.CHECK_CUSTOMER_REQUEST:
      return {
        ...state,
        item_choose: { ...action.customer }
      };

    case customerConstants.DROP_CHECK_CUSTOMER_REQUEST:
      return {
        ...state,
        item_choose: {}
      };


    default:
      return state;
  }
};
