import React, { useState, useEffect } from "react";
import "./Parametres.css";
import ConfigApp from "../ConfigApp";
import { Tabs, Row, Col, Button, Card, Typography, Popconfirm } from "antd";
import BlankCard from "../../../components/Card/BlankCard";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    modalActions,
    userActions,
    jalonActions,
    directionActions,
    statut_demandeActions,
    type_ticketActions,
    ticket_stateActions,
    priority_ticketActions,
} from "../../../../redux/_actions";
import ModalAjouterCouleur from "../../../components/Modal/ModalAjouterCouleur";
import ModalAjouterJalon from "../../../components/Modal/ModalAjouterJalon";
import ModalAjouterStatutTache from "../../../components/Modal/ModalAjouterStatutTache";
import { Scrollbars } from "react-custom-scrollbars";
import defineAbilityFor from "../../../../config/defineAbility";
import { Can } from "../../../../config/Can";
import HeaderApp from "../../../parchalse/HeaderApp";
import ModalAjouterDirection from "../../../components/Modal/ModalAjouterDirection";
import ModalStatutDemande from "../../../components/Modal/ModalStatutDemande";
import ModalAjouterTypeTicket from "../../../components/Modal/ModalAjouterTypeTicket";
import ModalAjouterTicketState from "../../../components/Modal/ModalAjouterTicketState";
import ModalAjouterPriorityTicket from "../../../components/Modal/ModalAjouterPriorityTicket";

const { TabPane } = Tabs;
const { Text } = Typography;

const Parametres = () => {
    const dispatch = useDispatch();

    const colors = useSelector((state) => state.project_color.items);
    const statuts = useSelector((state) => state.statut.items);
    const jalons = useSelector((state) => state.jalon.items);
    const directions = useSelector((state) => state.direction.items);
    const statut_demandes = useSelector((state) => state.statut_demande.items);
    const type_tickets = useSelector((state) => state.type_ticket.items);
    const ticket_states = useSelector((state) => state.ticket_state.items);
    const priority_tickets = useSelector((state) => state.priority_ticket.items);

    const [colorSelected, setColorSelected] = useState({});
    const [taskStatutSelected, setTaskStatutSelected] = useState({});
    const [jalonSelected, setJalonSelected] = useState({});
    const [directionSelected, setDirectionSelected] = useState({});
    const [statutDemendeSelected, setStatutDemandeSelected] = useState({});
    const [typeTicket, setTypeTicket] = useState({});
    const [ticketState, setTicketState] = useState({});
    const [priorityTicket, setPriorityTicket] = useState({});

    const user = useSelector((state) => state.login);

    const ability = defineAbilityFor(user);

    useEffect(() => {
        document.title = "2iProject-Paramètres";
        dispatch(userActions.userNotification(user.item.id_user));
        dispatch(jalonActions.getAll());
        dispatch(type_ticketActions.getAll());
        dispatch(ticket_stateActions.getAll());
        dispatch(priority_ticketActions.getAll());
        dispatch(modalActions.close_type_ticket());
        dispatch(modalActions.close_ticket_state());
        dispatch(modalActions.close_priority_ticket());
    }, [dispatch, user.item.id_user]);

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: "50",
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    height: 129,
                }}
            >
                <ConfigApp />
            </div>
            <div
                style={{
                    flex: 1,
                    padding: 18,
                    paddingTop: 0,
                }}
            >
                <Tabs type="card">
                    <TabPane tab="Affichage" key="1">
                        <Row gutter={18}>
                            <Can I="LireJalon" a="Parametres" ability={ability}>
                                <Col sm={6} md={6} xl={6}>
                                    <BlankCard>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Text>Jalon des tâches</Text>
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<PlusOutlined />}
                                                size="middle"
                                                onClick={() => {
                                                    dispatch(modalActions.show_add_jalon())
                                                }}
                                            />
                                        </div>
                                        <br />
                                        <Scrollbars
                                            autoHide
                                            style={{
                                                height: "43vh",
                                            }}
                                        >
                                            {jalons
                                                .sort((a, b) => a.end - b.end)
                                                .map((item, index) => (
                                                    <Card
                                                        key={index}
                                                        bodyStyle={{
                                                            padding: 10,
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    background:
                                                                        item.color,
                                                                    borderRadius:
                                                                        "50%",
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                flex: 2,
                                                                paddingTop: 5,
                                                            }}
                                                        >
                                                            {item.libelle}
                                                        </div>
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                paddingTop: 5,
                                                            }}
                                                        >
                                                            <Can
                                                                I="ModifierJalon"
                                                                a="Parametres"
                                                                ability={
                                                                    ability
                                                                }
                                                            >
                                                                <EditOutlined
                                                                    style={{
                                                                        display:
                                                                            "inline-block",
                                                                        float: "right",
                                                                    }}
                                                                    onClick={() => {
                                                                        setJalonSelected(item);
                                                                        dispatch(modalActions.show_add_jalon());
                                                                    }}
                                                                />
                                                            </Can>
                                                        </div>
                                                    </Card>
                                                ))}
                                        </Scrollbars>
                                    </BlankCard>
                                </Col>
                            </Can>

                            <Can
                                I="AjouterStatut"
                                a="Parametres"
                                ability={ability}
                            >
                                <Col sm={6} md={6} xl={6}>
                                    <BlankCard>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Text>Statut des tâches</Text>
                                            <Can
                                                I="AjouterStatut"
                                                a="Parametres"
                                                ability={ability}
                                            >
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<PlusOutlined />}
                                                    size="middle"
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_add_task_statut()
                                                        )
                                                    }
                                                />
                                            </Can>
                                        </div>
                                        <br />
                                        <Scrollbars
                                            autoHide
                                            style={{
                                                height: "43vh",
                                            }}
                                        >
                                            {statuts.map((item) => (
                                                <Card
                                                    bodyStyle={{
                                                        padding: 10,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <div style={{ flex: 1 }}>
                                                        {item.end == 1 ? (
                                                            <div
                                                                style={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    background:
                                                                        item.color,
                                                                    borderRadius:
                                                                        "50%",
                                                                }}
                                                            >
                                                                {/* {item.color} */}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    background:
                                                                        item.color,
                                                                    borderRadius:
                                                                        "50%",
                                                                }}
                                                            >
                                                                {/* {item.color} */}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        {item.libelle}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        <Can
                                                            I="ModifierStatut"
                                                            a="Parametres"
                                                            ability={ability}
                                                        >
                                                            <EditOutlined
                                                                style={{
                                                                    display:
                                                                        "inline-block",
                                                                    float: "right",
                                                                }}
                                                                onClick={() => {
                                                                    setTaskStatutSelected(
                                                                        item
                                                                    );
                                                                    dispatch(
                                                                        modalActions.show_add_task_statut()
                                                                    );
                                                                }}
                                                            />
                                                        </Can>
                                                    </div>
                                                </Card>
                                            ))}
                                        </Scrollbars>
                                    </BlankCard>
                                </Col>
                            </Can>
                            <Can
                                I="LireCouleur"
                                a="Parametres"
                                ability={ability}
                            >
                                <Col sm={6} md={6} xl={6}>
                                    <BlankCard>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Text>Couleurs</Text>
                                            <Can
                                                I="AjouterCouleur"
                                                a="Parametres"
                                                ability={ability}
                                            >
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<PlusOutlined />}
                                                    size="middle"
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_add_color()
                                                        )
                                                    }
                                                />
                                            </Can>
                                        </div>
                                        <br />
                                        <Scrollbars
                                            autoHide
                                            style={{
                                                height: "43vh",
                                            }}
                                        >
                                            {colors.map((item) => (
                                                <Card
                                                    bodyStyle={{
                                                        padding: 10,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <div style={{ flex: 1 }}>
                                                        <div
                                                            style={{
                                                                width: 32,
                                                                height: 32,
                                                                background:
                                                                    item.color,
                                                                borderRadius:
                                                                    "50%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 2,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        {item.color}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        <Can
                                                            I="ModifierCouleur"
                                                            a="Parametres"
                                                            ability={ability}
                                                        >
                                                            <EditOutlined
                                                                style={{
                                                                    display:
                                                                        "inline-block",
                                                                    float: "right",
                                                                }}
                                                                onClick={() => {
                                                                    setColorSelected(
                                                                        item
                                                                    );
                                                                    dispatch(
                                                                        modalActions.show_add_color()
                                                                    );
                                                                }}
                                                            />
                                                        </Can>
                                                    </div>
                                                </Card>
                                            ))}
                                        </Scrollbars>
                                    </BlankCard>
                                </Col>
                            </Can>
                            {/* <Can
                                I="LireCouleur"
                                a="Parametres"
                                ability={ability}
                            > */}
                            <Col sm={6} md={6} xl={6}>
                                <BlankCard>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text>Statut demande</Text>
                                        <Can
                                            I="AjouterCouleur"
                                            a="Parametres"
                                            ability={ability}
                                        >
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<PlusOutlined />}
                                                size="middle"
                                                onClick={() =>
                                                    dispatch(
                                                        modalActions.show_add_statut_demande()
                                                    )
                                                }
                                            />
                                        </Can>
                                    </div>
                                    <br />
                                    <Scrollbars
                                        autoHide
                                        style={{
                                            height: "43vh",
                                        }}
                                    >
                                        {statut_demandes.map((item) => (
                                            <Card
                                                bodyStyle={{
                                                    padding: 10,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            background:
                                                                item.color,
                                                            borderRadius: "50%",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 3,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {item.libelle}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    <Can
                                                        I="ModifierCouleur"
                                                        a="Parametres"
                                                        ability={ability}
                                                    >
                                                        <EditOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                //float: "right",
                                                                marginRight: 10,
                                                            }}
                                                            onClick={() => {
                                                                setStatutDemandeSelected(
                                                                    item
                                                                );
                                                                dispatch(
                                                                    modalActions.show_add_statut_demande()
                                                                );
                                                            }}
                                                        />
                                                    </Can>
                                                    <Popconfirm
                                                        title="Voulez-vous supprimer ce statut de demande ?"
                                                        onConfirm={() =>
                                                            dispatch(
                                                                statut_demandeActions.remove(
                                                                    item.id_statut
                                                                )
                                                            )
                                                        }
                                                        okText="Oui"
                                                        cancelText="Non"
                                                    >
                                                        <DeleteOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                // float: "right",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        ))}
                                    </Scrollbars>
                                </BlankCard>
                            </Col>
                            {/* </Can> */}
                        </Row>
                    </TabPane>
                    <TabPane tab="Organisation" key="2">
                        <Row gutter={18}>
                            <Col sm={8} md={8} xl={8}>
                                <BlankCard>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text>Directions</Text>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            size="middle"
                                            onClick={() => {
                                                dispatch(
                                                    modalActions.show_add_direction()
                                                );
                                                setDirectionSelected({});
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <Card
                                        bodyStyle={{
                                            padding: 10,
                                            display: "flex",
                                            flexDirection: "row",
                                            textAlign: "start",
                                            backgroundColor: "#40a9ff",
                                            color: "white",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 2,
                                                paddingTop: 5,
                                            }}
                                        >
                                            Direction
                                        </div>
                                        <div
                                            style={{
                                                flex: 2,
                                                paddingTop: 5,
                                            }}
                                        >
                                            Chef
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                paddingTop: 5,
                                            }}
                                        >
                                            Action
                                        </div>
                                    </Card>
                                    <Scrollbars
                                        autoHide
                                        style={{
                                            height: "38vh",
                                        }}
                                    >
                                        {directions
                                            .sort((a, b) => a.end - b.end)
                                            .map((item, index) => (
                                                <Card
                                                    key={index}
                                                    bodyStyle={{
                                                        padding: 10,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            flex: 2,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        {item.libelle}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 2,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        {item.first_name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            paddingTop: 5,
                                                        }}
                                                    >
                                                        {/* <Can
                                                            I="ModifierJalon"
                                                            a="Parametres"
                                                            ability={ability}
                                                        > */}
                                                        <EditOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                            }}
                                                            onClick={() => {
                                                                setDirectionSelected(
                                                                    item
                                                                );
                                                                dispatch(
                                                                    modalActions.show_add_direction()
                                                                );
                                                            }}
                                                        />
                                                        {item.canBeRemoved ? (
                                                            <Popconfirm
                                                                placement="top"
                                                                title="Voulez-vous supprimer cette direction ?"
                                                                onConfirm={() =>
                                                                    dispatch(
                                                                        directionActions.remove(
                                                                            item.id_direction
                                                                        )
                                                                    )
                                                                }
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteOutlined
                                                                    style={{
                                                                        display:
                                                                            "inline-block",
                                                                        marginLeft: 10,
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        ) : null}
                                                        {/* </Can> */}
                                                    </div>
                                                </Card>
                                            ))}
                                    </Scrollbars>
                                </BlankCard>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Configuration 2iTracking" key="3">
                        <Row gutter={18}>
                            <Col sm={6} md={6} xl={6}>
                                <BlankCard>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text>Type ticket</Text>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            size="middle"
                                            onClick={() => {
                                                dispatch(
                                                    modalActions.show_type_ticket()
                                                );
                                                setDirectionSelected({});
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <Scrollbars
                                        autoHide
                                        style={{
                                            height: "43vh",
                                        }}
                                    >
                                        {type_tickets.map((item) => (
                                            <Card
                                                bodyStyle={{
                                                    padding: 10,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            background:
                                                                item.color,
                                                            borderRadius: "50%",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 3,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {item.type_name}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    <Can
                                                        I="ModifierCouleur"
                                                        a="Parametres"
                                                        ability={ability}
                                                    >
                                                        <EditOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                //float: "right",
                                                                marginRight: 10,
                                                            }}
                                                            onClick={() => {
                                                                setTypeTicket(
                                                                    item
                                                                );
                                                                dispatch(
                                                                    modalActions.show_type_ticket()
                                                                );
                                                            }}
                                                        />
                                                    </Can>
                                                    <Popconfirm
                                                        title="Voulez-vous supprimer ce statut de demande ?"
                                                        onConfirm={() =>
                                                            dispatch(
                                                                type_ticketActions.remove(
                                                                    item.id
                                                                )
                                                            )
                                                        }
                                                        okText="Oui"
                                                        cancelText="Non"
                                                    >
                                                        <DeleteOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                // float: "right",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        ))}
                                    </Scrollbars>
                                </BlankCard>
                            </Col>
                            <Col sm={6} md={6} xl={6}>
                                <BlankCard>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text>Ticket state</Text>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            size="middle"
                                            onClick={() => {
                                                dispatch(
                                                    modalActions.show_ticket_state()
                                                );
                                                setTicketState({});
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <Scrollbars
                                        autoHide
                                        style={{
                                            height: "43vh",
                                        }}
                                    >
                                        {ticket_states.map((item) => (
                                            <Card
                                                bodyStyle={{
                                                    padding: 10,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            background:
                                                                item.color,
                                                            borderRadius: "50%",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 3,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {item.state_name}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    <Can
                                                        I="ModifierCouleur"
                                                        a="Parametres"
                                                        ability={ability}
                                                    >
                                                        <EditOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                //float: "right",
                                                                marginRight: 10,
                                                            }}
                                                            onClick={() => {
                                                                setTicketState(
                                                                    item
                                                                );
                                                                dispatch(
                                                                    modalActions.show_ticket_state()
                                                                );
                                                            }}
                                                        />
                                                    </Can>
                                                    <Popconfirm
                                                        title="Voulez-vous supprimer ce statut de demande ?"
                                                        onConfirm={() =>
                                                            dispatch(
                                                                ticket_stateActions.remove(
                                                                    item.id_state
                                                                )
                                                            )
                                                        }
                                                        okText="Oui"
                                                        cancelText="Non"
                                                    >
                                                        <DeleteOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                // float: "right",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        ))}
                                    </Scrollbars>
                                </BlankCard>
                            </Col>
                            <Col sm={6} md={6} xl={6}>
                                <BlankCard>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text>Priorité Ticket</Text>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            size="middle"
                                            onClick={() => {
                                                dispatch(
                                                    modalActions.show_priority_ticket()
                                                );
                                                setPriorityTicket({});
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <Scrollbars
                                        autoHide
                                        style={{
                                            height: "43vh",
                                        }}
                                    >
                                        {priority_tickets.map((item) => (
                                            <Card
                                                bodyStyle={{
                                                    padding: 10,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            background:
                                                                item.color,
                                                            borderRadius: "50%",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 3,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {item.priority_name}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    <Can
                                                        I="ModifierCouleur"
                                                        a="Parametres"
                                                        ability={ability}
                                                    >
                                                        <EditOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                //float: "right",
                                                                marginRight: 10,
                                                            }}
                                                            onClick={() => {
                                                                setPriorityTicket(
                                                                    item
                                                                );
                                                                dispatch(
                                                                    modalActions.show_priority_ticket()
                                                                );
                                                            }}
                                                        />
                                                    </Can>
                                                    <Popconfirm
                                                        title="Voulez-vous supprimer ce statut de demande ?"
                                                        onConfirm={() =>
                                                            dispatch(
                                                                priority_ticketActions.remove(
                                                                    item.id_priority
                                                                )
                                                            )
                                                        }
                                                        okText="Oui"
                                                        cancelText="Non"
                                                    >
                                                        <DeleteOutlined
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                // float: "right",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        ))}
                                    </Scrollbars>
                                </BlankCard>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </div>
            <ModalAjouterCouleur
                colorSelected={colorSelected}
                setColorSelected={setColorSelected}
            />
            <ModalAjouterStatutTache
                taskStatutSelected={taskStatutSelected}
                setTaskStatutSelected={setTaskStatutSelected}
            />
            <ModalAjouterJalon
                jalonSelected={jalonSelected}
                setJalonSelected={setJalonSelected}
            />
            <ModalAjouterDirection
                directionSelected={directionSelected}
                setDirectionSelected={setDirectionSelected}
            />
            <ModalStatutDemande
                statutDemendeSelected={statutDemendeSelected}
                setStatutDemandeSelected={setStatutDemandeSelected}
            />
            <ModalAjouterTypeTicket
                typeTicket={typeTicket}
                setTypeTicket={setTypeTicket}
            />
            <ModalAjouterTicketState
                ticketState={ticketState}
                setTicketState={setTicketState}
            />
            <ModalAjouterPriorityTicket
                priorityTicket={priorityTicket}
                setPriorityTicket={setPriorityTicket}
            />
        </div>
    );
};

export default Parametres;
