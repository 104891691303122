export const project_colorConstants = {
    GETALL_REQUEST: "GETALL_PROJECT_COLOR_REQUEST",
    GETALL_SUCCESS: "GETALL_PROJECT_COLOR_SUCCESS",
    GETALL_FAILURE: "GETALL_PROJECT_COLOR_FAILURE",

    ADD_REQUEST: "ADD_PROJECT_COLOR_REQUEST",
    ADD_SUCCESS: "ADD_PROJECT_COLOR_SUCCESS",
    ADD_FAILURE: "ADD_PROJECT_COLOR_FAILURE",

    UPDATE_REQUEST: "UPDATE_PROJECT_COLOR_REQUEST",
    UPDATE_SUCCESS: "UPDATE_PROJECT_COLOR_SUCCESS",
    UPDATE_FAILURE: "UPDATE_PROJECT_COLOR_FAILURE",
};
