import { modalConstants } from "../_constants";

export const modalActions = {
    show_add_profile,
    close_add_profile,

    show_validate_cotation,
    close_validate_cotation,

    show_update_profile,
    close_update_profile,

    show_add_team,
    close_add_team,

    show_more_team,
    close_more_team,

    show_setting_team,
    close_setting_team,

    show_add_team_menber,
    close_add_team_menber,

    show_add_team_project,
    close_add_team_project,

    show_affect_team_project,
    close_affect_team_project,

    show_add_project_team,
    close_add_project_team,

    show_detail_task,
    close_detail_task,

    show_add_task,
    close_add_task,

    show_more_project,
    close_more_project,

    show_add_allproject,
    close_add_allproject,

    show_add_color,
    close_add_color,

    show_add_task_statut,
    close_add_task_statut,

    show_add_jalon,
    close_add_jalon,

    show_user_profil,
    close_user_profil,

    show_auto_logout,
    close_auto_logout,

    show_add_direction,
    close_add_direction,

    show_network,
    close_network,

    show_add_customers,
    close_add_customers,

    show_add_customer,
    close_add_customer,

    show_add_statut_demande,
    close_add_statut_demande,

    show_add_demande_of_quotation,
    close_add_demande_of_quotation,

    show_filter_demande_of_cotation,
    close_filter_demande_of_cotation,

    show_type_ticket,
    close_type_ticket,

    show_ticket_state,
    close_ticket_state,

    show_priority_ticket,
    close_priority_ticket,

    show_filtrer_ticket,
    close_filtrer_ticket,

    show_modal_add_client,
    close_modal_add_client

};


function show_modal_add_client() {
    return {
        type: modalConstants.SHOW_MODAL_ADD_CLIENT
    }
}

function close_modal_add_client() {
    return {
        type: modalConstants.CLOSE_MODAL_ADD_CLIENT
    }
}

function show_filtrer_ticket() {
    return {
        type: modalConstants.SHOW_FILTRER_TICKET
    }
}

function close_filtrer_ticket() {
    return {
        type: modalConstants.CLOSE_FILTRER_TICKET
    }
}

function show_priority_ticket() {
    return {
        type: modalConstants.SHOW_PRIORITY_TICKET,
    };
}

function close_priority_ticket() {
    return {
        type: modalConstants.CLOSE_PRIORITY_TICKET,
    };
}

function show_ticket_state() {
    return {
        type: modalConstants.SHOW_TICKET_STATE,
    };
}

function close_ticket_state() {
    return {
        type: modalConstants.CLOSE_TICKET_STATE,
    };
}

function show_type_ticket() {
    return {
        type: modalConstants.SHOW_TYPE_TICKET,
    };
}

function close_type_ticket() {
    return {
        type: modalConstants.CLOSE_TYPE_TICKET,
    };
}

function show_filter_demande_of_cotation() {
    return {
        type: modalConstants.SHOW_FILTER_DEMANDE_OF_QUOTATION,
    };
}

function close_filter_demande_of_cotation() {
    return {
        type: modalConstants.CLOSE_FILTER_DEMANDE_OF_QUOTATION,
    };
}

function show_add_demande_of_quotation() {
    return {
        type: modalConstants.SHOW_ADD_DEMANE_OF_QUOTATION,
    };
}

function close_add_demande_of_quotation() {
    return {
        type: modalConstants.CLOSE_ADD_DEMANE_OF_QUOTATION,
    };
}

function show_add_statut_demande() {
    return {
        type: modalConstants.SHOW_ADD_STATUT_DEMANDE,
    };
}

function close_add_statut_demande() {
    return {
        type: modalConstants.CLOSE_ADD_STATUT_DEMANDE,
    };
}

function show_add_customer() {
    return {
        type: modalConstants.SHOW_ADD_CUSTOMER,
    };
}

function close_add_customer() {
    return {
        type: modalConstants.CLOSE_ADD_CUSTOMER,
    };
}

function show_add_customers() {
    return {
        type: modalConstants.SHOW_ADD_CUSTOMERS,
    };
}

function close_add_customers() {
    return {
        type: modalConstants.CLOSE_ADD_CUSTOMERS,
    };
}

function show_network() {
    return {
        type: modalConstants.SHOW_NETWORK,
    };
}

function close_network() {
    return {
        type: modalConstants.CLOSE_NETWORK,
    };
}

function show_add_direction() {
    return {
        type: modalConstants.SHOW_ADD_DIRECTION,
    };
}

function close_add_direction() {
    return {
        type: modalConstants.CLOSE_ADD_DIRECTION,
    };
}

function show_auto_logout() {
    return {
        type: modalConstants.SHOW_AUTO_LOGOUT,
    };
}

function close_auto_logout() {
    return {
        type: modalConstants.CLOSE_AUTO_LOGOUT,
    };
}

function show_user_profil() {
    return {
        type: modalConstants.SHOW_USER_PROFIL,
    };
}

function close_user_profil() {
    return {
        type: modalConstants.CLOSE_USER_PROFIL,
    };
}

function show_add_jalon() {
    return {
        type: modalConstants.SHOW_ADD_JALON,
    };
}

function close_add_jalon() {
    return {
        type: modalConstants.CLOSE_ADD_JALON,
    };
}
function show_add_task_statut() {
    return {
        type: modalConstants.SHOW_ADD_TASK_STATUT,
    };
}

function close_add_task_statut() {
    return {
        type: modalConstants.CLOSE_ADD_TASK_STATUT,
    };
}

function show_add_color() {
    return {
        type: modalConstants.SHOW_ADD_COLOR,
    };
}

function close_add_color() {
    return {
        type: modalConstants.CLOSE_ADD_COLOR,
    };
}

function show_add_allproject() {
    return {
        type: modalConstants.SHOW_ADD_ALLPROJECT,
    };
}

function close_add_allproject() {
    return {
        type: modalConstants.CLOSE_ADD_ALLPROJECT,
    };
}

function show_add_profile() {
    return {
        type: modalConstants.SHOW_ADD_PROFILE,
    };
}

function show_validate_cotation() {
    return {
        type: modalConstants.SHOW_VALIDATE_COTATION,
    };
}
function close_validate_cotation() {
    return {
        type: modalConstants.CLOSE_VALIDATE_COTATION,
    };
}

function show_add_project_team() {
    return {
        type: modalConstants.SHOW_ADD_PROJECT,
    };
}

function close_add_project_team() {
    return {
        type: modalConstants.CLOSE_ADD_PROJECT,
    };
}

function close_add_profile() {
    return {
        type: modalConstants.CLOSE_ADD_PROFILE,
    };
}

function show_update_profile() {
    return {
        type: modalConstants.SHOW_UPDATE_PROFILE,
    };
}

function close_update_profile() {
    return {
        type: modalConstants.CLOSE_UPDATE_PROFILE,
    };
}

function show_add_team() {
    return {
        type: modalConstants.SHOW_ADD_TEAM,
    };
}

function close_add_team() {
    return {
        type: modalConstants.CLOSE_ADD_TEAM,
    };
}

function show_more_team() {
    return {
        type: modalConstants.SHOW_MORE_TEAM,
    };
}

function close_more_team() {
    return {
        type: modalConstants.CLOSE_MORE_TEAM,
    };
}

function show_setting_team() {
    return {
        type: modalConstants.SHOW_SETTING_TEAM,
    };
}

function close_setting_team() {
    return {
        type: modalConstants.CLOSE_SETTING_TEAM,
    };
}

function show_add_team_menber() {
    return {
        type: modalConstants.SHOW_ADD_TEAM_MENBER,
    };
}

function close_add_team_menber() {
    return {
        type: modalConstants.CLOSE_ADD_TEAM_MENBER,
    };
}

function show_add_team_project() {
    return {
        type: modalConstants.SHOW_ADD_TEAM_PROJECT,
    };
}

function close_add_team_project() {
    return {
        type: modalConstants.CLOSE_ADD_TEAM_PROJECT,
    };
}

function show_affect_team_project() {
    return {
        type: modalConstants.SHOW_AFFECT_TEAM_PROJECT,
    };
}

function close_affect_team_project() {
    return {
        type: modalConstants.CLOSE_AFFECT_TEAM_PROJECT,
    };
}

function show_detail_task() {
    return {
        type: modalConstants.SHOW_DETAIL_TASK,
    };
}

function close_detail_task() {
    return {
        type: modalConstants.CLOSE_DETAIL_TASK,
    };
}

function show_add_task() {
    return {
        type: modalConstants.SHOW_ADD_TASK,
    };
}

function close_add_task() {
    return {
        type: modalConstants.CLOSE_ADD_TASK,
    };
}

function show_more_project() {
    return {
        type: modalConstants.SHOW_MORE_PROJECT,
    };
}

function close_more_project() {
    return {
        type: modalConstants.CLOSE_MORE_PROJECT,
    };
}
