import React, { useContext, useState, useEffect, useRef } from "react";
import "./DrawerDemandeDeCotation.css";
import {
    Drawer,
    Button,
    Form,
    Input,
    Popover,
    Table,
    Select,
    DatePicker,
    InputNumber,
    Spin,
    Row,
    Col,
    Space,
    Tooltip
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    modalActions,
    team_menberActions,
    demande_cotationActions,
    userActions,
    taskActions
} from "../../../redux/_actions";
import {
    PlusOutlined,
    MinusOutlined,
    PrinterOutlined,
    EditOutlined,
    SaveOutlined
} from "@ant-design/icons";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import { atanh, json, parse } from "mathjs";
import { isEmpty, isNumber, isObject, parseInt, values } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { ImprimerDemandeCotation } from "../../helpers/makeAvatar";
import ModalValiderDemandeCotation from "../../components/Modal/ModalValiderDemandeCotation";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";



Moment.globalMoment = moment;

Moment.globalLocale = "fr";

const { Option } = Select;

var i = 0;

const DrawerDemandeDeCotation = (props) => {
    const [inputList, setInputList] = useState([]);
    const [save, setSave] = useState(false);
    const [projectSelect, setProjectSelected] = useState(0);
    const [clientExiste, setClientExiste] = useState('');
    const [formGlobal] = Form.useForm();

    const dispatch = useDispatch();

    const closable_add_demande_of_quotation = useSelector(
        (state) => state.modal.closable_add_demande_of_quotation
    );
    const project_member = useSelector((state) => state.user.project_member);
    const team_members = useSelector((state) => state.team_menber.menbers);
    const type_projets = useSelector((state) => state.type_project.items);
    const projects = useSelector((state) => state.project.items);
    const teams = useSelector((state) => state.team.items);
    const customers = useSelector((state) => state.customer.items);
    const auth = useSelector((state) => state.login.item);
    const user_login = useSelector((state) => state.login);
    const ability = defineAbilityFor(user_login);
    const task = useSelector((state) => state.task.items);
    const demande_cotations = useSelector(
        (state) => state.demande_cotation.items
    );
    const statut_error = useSelector(
        (state) => state.demande_cotation.error
    );
    // const project_perso = projects.map((p) => p.id_equipe === auth.id_equipe ? p : null);

    // Editable table
    const [count, setCount] = useState(0);
    useEffect(() => {
        // get all data taches 
        dispatch(taskActions.getAll());

        if (projectSelect !== 0) {
            const project_perso = projects.filter((p) => p.id_equipe === auth.id_equipe ? p : null);
            if (project_perso.length !== 0) {
                const data = project_perso.filter((p) => parseInt(p.id_projet) === projectSelect ? p : null);
                const infos_client = customers.filter((i) => i.id_client === data[0].id_client ? i : null);

                if (infos_client.length !== 0) {
                    // setClientExiste(infos_client[0].nom_client);
                    formGlobal.setFieldsValue({
                        client: infos_client[0].id_client,
                    });

                    // if (Object.keys(props.demandeSelected).length > 0) {
                    //     formGlobal.setFieldsValue({
                    //         projet: infos_client[0].id_projet,
                    //     });
                    // }

                }
                else {
                    setClientExiste('');
                    setProjectSelected(0)
                    formGlobal.setFieldsValue({
                        client: null,
                    });
                }

                // if (props.typeProjet === 1) {
                //     setClientExiste('');
                //     setProjectSelected(0)
                //     formGlobal.setFieldsValue({
                //         client: null,
                //     });
                // }
            }
        }

    }, []);



    useEffect(() => {
        if (props.demandeDetail.id_demande !== undefined) {
            // console.log('valLog', props.demandeDetail.id_demande)
            // get all data ligne for cotation
            dispatch(demande_cotationActions.getLineDemande(props.demandeDetail.id_demande));

        }
    }, [props.demandeDetail.id_demande]);

    useEffect(() => {
        if (Object.keys(props.demandeSelected).length > 0) {

            dispatch(demande_cotationActions.getLineDemande(props.demandeSelected.id_demande));
            const objetRecherche = demande_cotations.find(objet => objet.id_demande === props.demandeSelected.id_demande);
            props.setDemandeSelected(objetRecherche);
        }
    }, [props.demandeSelected]);


    const submitDemandeDeCotation = (values) => {
        const data = {
            libelle_demande: values.libelle_demande,
            type_projet: values.type_projet,
            id_projet: values.projet,
            id_equipe: parseInt(values.equipe),
            id_user: parseInt(values.responsable),
            id_client: parseInt(values.client),
            date_edition: moment(values.date_edition).format("YYYY-MM-DD"),
            created_by: parseInt(auth.id_user),
            cotation: inputList,
        };

        dispatch(demande_cotationActions.add(data));

    };

    useEffect(() => {
        if (statut_error === 0) {
            formGlobal.resetFields();
            setInputList([]);
            props.setDemandeSelected({});
            props.setduplicateDemande(0);
            props.setDemandeDetail({});
            props.setTypeProjet(1);
            setClientExiste('');
            setCount(0);
            setSave(false);
            i = 0;
            dispatch(modalActions.close_add_demande_of_quotation());
        }
    }, [statut_error]);

    const updateDemandeDeCotation = (values) => {
        if (auth.id_profil === 3) {
            var data = {
                id_demande: props.demandeSelected.id_demande,
                libelle_demande: values.libelle_demande,
                type_projet: props.demandeSelected.id_type,
                id_projet:
                    props.typeProjet == 2
                        ? props.demandeSelected.id_projet
                        : props.demandeSelected.libelle_projet,
                id_equipe: props.demandeSelected.id_equipe,
                id_user: props.demandeSelected.id_responsable_demande,
                id_client: props.demandeSelected.id_client,
                date_edition: moment(props.demandeSelected.date_edition).format(
                    "YYYY-MM-DD"
                ),
                modified_by: parseInt(auth.id_user),
                cotation: inputList,
            };
        } else {
            var data = {
                id_demande: props.demandeSelected.id_demande,
                libelle_demande: values.libelle_demande,
                type_projet: values.type_projet,
                id_projet: values.projet,
                id_equipe: parseInt(values.equipe),
                id_user: parseInt(values.responsable),
                id_client: parseInt(values.client),
                date_edition: moment(values.date_edition).format("YYYY-MM-DD"),
                modified_by: parseInt(auth.id_user),
                cotation: inputList,
            };
        }

        dispatch(demande_cotationActions.update(data));
        formGlobal.resetFields();
        setInputList([]);
        props.setDemandeSelected({});
        props.setduplicateDemande(0);
        props.setDemandeDetail({});
        props.setTypeProjet(1);
        setClientExiste('');
        setCount(0);
        setSave(false);
        i = 0;
        dispatch(modalActions.close_add_demande_of_quotation());
    };

    const scr = {
        y: 335,
    };

    useEffect(() => {
        if (Object.keys(props.demandeSelected).length > 0 && props.duplicateDemande === 0) {
            setClientExiste(props.demandeSelected.nom_client);
            setProjectSelected(props.demandeSelected.id_projet)
            formGlobal.setFieldsValue({
                client: props.demandeSelected.id_client,
            });
        }

        if (props.typeProjet === 1) {
            // setClientExiste('');
            // setProjectSelected(0);
            // formGlobal.setFieldsValue({
            //     client: null,
            // });
        } else {
            if (Object.keys(props.demandeSelected).length > 0 && props.duplicateDemande === 0) {
                // setClientExiste(props.demandeSelected.nom_client);
                // setProjectSelected(props.demandeSelected.id_projet)
                formGlobal.setFieldsValue({
                    client: props.demandeSelected.id_client,
                    projet: props.demandeSelected.id_projet,
                    responsable: props.demandeSelected.id_responsable_demande,
                });
            }
        }

    }, [props]);

    // handle input change
    const handleInputChange = (e, index) => {
        if (isObject(e)) {
            let { name, value } = e.target;
            const list = [...inputList];
            var n = name.split("-");
            list[index][n[0]] = value;
            setInputList(list);
        } else {
            // let { name, value } = e.target;
            const list = [...inputList];
            var name = "duree-" + index;
            var n = name.split("-");
            list[index][n[0]] = e;
            setInputList(list);
        }
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList.filter((p) => p.key !== index)];
        setCount(count - 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        const newData = {
            key: count,
            module: "",
            tache: "",
            description: "",
            duree: null,
            observation: "",
        };

        setInputList([...inputList, newData]);

        setCount(count + 1);
    };

    const handleAddAtIndex = (index) => {
        let tab = [];

        tab = [...inputList];

        const newData = {
            key: index,
            module: "",
            tache: "",
            description: "",
            duree: null,
            observation: "",
        };

        index = index + 1;

        tab.splice(index, 0, newData);

        tab.map((p, i) => {
            if (i >= index) {
                p.key = i;
            }
        });

        setInputList(tab);
        setCount(count + 1);
    };

    const dureeTotalJour = (duree) => {
        if (duree !== undefined && duree !== null) {
            let sum = 0;
            let i = 0;

            while (i < duree.length) {

                if (isNumber(duree[i]["duree"])) {
                    sum = sum + parseFloat(duree[i]["duree"]);
                }
                i++;
            }

            let result = sum / 8;
            // let result = sum;
            return result.toFixed(2);
        }
    };

    const dureeTotalHeure = (duree) => {
        if (duree !== undefined && duree !== null) {
            let sum = 0;
            let i = 0;

            while (i < duree.length) {

                if (isNumber(duree[i]["duree"])) {
                    sum = sum + parseFloat(duree[i]["duree"]);
                }
                i++;
            }

            return sum.toFixed(2);
        }
    };

    const dureeTReel = (id_demande) => {
        let tache_cotation = task.filter((p) => p.id_demande === id_demande);
        let somme = 0;

        tache_cotation.forEach(element => {
            somme += element.duree
        });

        let total = parseFloat(somme / 8).toFixed(2);

        return total;
    }

    const numDemande = (id_demande) => {
        var num;
        if (parseInt(id_demande) < 10) {
            num = `000${id_demande}`;
        } else if (parseInt(id_demande) >= 10 && parseInt(id_demande) < 100) {
            num = `00${id_demande}`;
        } else if (parseInt(id_demande) >= 100) {
            num = `0${id_demande}`;
        } else {
            num = `${id_demande}`;
        }
        return num;
    };

    const columns_cotat = [
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
            width: 200,
        },
        {
            title: "Tâche",
            dataIndex: "tache",
            key: "tache",
            width: 200,
            render: (record) => {
                // return <Input.TextArea
                //     name="tache"
                //     value={record}
                //     autoSize={true}
                //     readOnly={true}
                //     className="textarea"
                // />
                return <p>{record}</p>
            }
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: 200,
        },
        {
            title: "Durée (h)",
            dataIndex: "duree",
            key: "duree",
            width: 50,
        },
    ];

    const myFormatDate = (inputDate) => {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return (
                date.getDate() +
                "/" +
                (parseInt(date.getMonth()) + 1) +
                "/" +
                date.getFullYear()
            );
        }
    };

    const onFinish = (values) => {
        dispatch(demande_cotationActions.faturer_cotation({
            'ref': values.ref,
            'id_demande': props.demandeDetail.id_demande
        }));
    };

    const handleTitle = () => {
        return (
            <>
                <span>Référence de la facture</span>
                {/* <CloseOutlined style={{ cursor: 'pointer', float: 'right', marginTop: 5 }} /> */}
            </>
        );
    }

    const content = () => {

        if (Object.keys(props.demandeDetail).length > 0) {

            let demande = demande_cotations.filter((p) => p.id_demande === props.demandeDetail.id_demande)[0];

            if (demande.ref_facture !== null) {
                formGlobal.setFieldsValue({
                    ref: demande.ref_facture,
                });
            } else {
                formGlobal.resetFields();
            }
        }

        return (
            <div>
                <Form
                    form={formGlobal}
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="ref" rules={[{ required: true, message: 'la référence est obligatoire!' }]}>
                        <Input style={{ width: 140 }} placeholder="la référence... " />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    return (
        <Drawer
            title={
                Object.keys(props.demandeSelected).length === 0 &&
                    Object.keys(props.demandeDetail).length === 0
                    ? "Ajouter une demande de cotation"
                    : Object.keys(props.demandeSelected).length !== 0 &&
                        Object.keys(props.demandeDetail).length === 0
                        ? props.duplicateDemande === 0 ?
                            "Modifier une demande de cotation" : "Dupliquer une demande de cotation"
                        : Object.keys(props.demandeSelected).length === 0 &&
                            Object.keys(props.demandeDetail).length !== 0
                            ? "Détail d'une demande de cotation"
                            : null
            }
            placement="right"
            // closable={false}
            width={"100%"}
            mask={false}
            style={{
                marginTop: 50,
            }}
            onClose={() => {
                props.setDemandeSelected({});
                props.setduplicateDemande(0);
                props.setDemandeDetail({});
                formGlobal.resetFields();
                setInputList([]);
                setCount(0);
                setSave(false);
                i = 0;
                setClientExiste('');
                dispatch(modalActions.close_add_demande_of_quotation());
                props.setLoadingCotation(true);
                props.setSelectedDemande(0);
                // dispatch(demande_cotationActions.getAll());
            }}
            visible={closable_add_demande_of_quotation}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            closable={true}
            className="demande"
            afterVisibleChange={(visible) => {
                if (visible) {
                    if (Object.keys(props.demandeSelected).length !== 0) {
                        // props.setTypeProjet(props.demandeSelected.id_type);
                        setInputList(
                            props.demandeSelected.cotations !== undefined
                                ? props.demandeSelected.cotations
                                : []
                        );
                        if (props.duplicateDemande === 0) {
                            formGlobal.setFieldsValue({
                                libelle_demande: props.demandeSelected.libelle_demande,
                                type_projet: props.demandeSelected.id_type,
                                projet:
                                    props.typeProjet == 2
                                        ? props.demandeSelected.id_projet
                                        : props.demandeSelected.libelle_projet,
                                equipe: props.demandeSelected.id_equipe,
                                responsable:
                                    props.demandeSelected.id_responsable_demande,
                                client: props.demandeSelected.id_client,
                                date_edition: moment(
                                    props.demandeSelected.date_edition
                                ),
                                modified_by: parseInt(auth.id_user),
                                cotation: props.demandeSelected.cotation,
                            });
                        }
                        setCount(props.demandeSelected.cotations.length);
                        props.setLoadingCotation(false);
                    } else if (Object.keys(props.demandeDetail).length !== 0) {
                        setInputList(
                            props.demandeDetail.cotations !== undefined
                                ? props.demandeDetail.cotations
                                : []
                        );
                        formGlobal.setFieldsValue({
                            libelle_demande: props.demandeDetail.libelle_demande,
                            type_projet: props.demandeDetail.id_type,
                            projet:
                                props.typeProjet == 2
                                    ? props.demandeDetail.id_projet
                                    : props.demandeDetail.libelle_projet,
                            equipe: props.demandeDetail.id_equipe,
                            responsable:
                                props.demandeDetail.id_responsable_demande,
                            client: props.demandeDetail.id_client,
                            date_edition: moment(
                                props.demandeDetail.date_edition
                            ),
                            cotation: props.demandeDetail.cotation,
                        });
                        props.setLoadingCotation(false);
                    } else {
                        props.setLoadingCotation(false);
                    }
                }
            }}
            extra={
                <Space>
                    <Button type="primary" danger
                        onClick={() => {
                            props.setduplicateDemande(0);
                            props.setDemandeSelected({});
                            props.setDemandeDetail({});
                            formGlobal.resetFields();
                            setInputList([]);
                            setCount(0);
                            setSave(false);
                            i = 0;
                            setClientExiste('');
                            dispatch(modalActions.close_add_demande_of_quotation());
                            props.setLoadingCotation(true);
                            props.setSelectedDemande(0);
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            }
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "78vh",
                }}
            >
                <div style={{ flex: 1 }}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={props.loadingCotation}
                    >
                        {Object.keys(props.demandeDetail).length === 0 ? (
                            <Form
                                form={formGlobal}
                                name="basic"
                                onFinish={
                                    Object.keys(
                                        inputList.filter(
                                            (p) =>
                                                p.tache === "" ||
                                                p.duree === null
                                        )
                                    ).length === 0
                                        ? Object.keys(props.demandeSelected)
                                            .length === 0
                                            ? submitDemandeDeCotation
                                            : props.duplicateDemande === 1
                                                ? submitDemandeDeCotation
                                                : updateDemandeDeCotation
                                        : null
                                }
                                layout="vertical"
                            >
                                <Form.Item>
                                    <Form.Item
                                        label="Libelle"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 6,
                                            marginRight: 10,
                                            marginBottom: 0,
                                        }}
                                        name="libelle_demande"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Veuillez renseigner le libelle de la fiche de cotation",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Libelle fiche de cotation"
                                            disabled={
                                                auth.id_profil === 3
                                                    ? true
                                                    : false
                                            }
                                            maxLength="50"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Type projet"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 5,
                                            marginRight: 6,
                                            marginBottom: 0,
                                        }}
                                        name="type_projet"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Veuillez sélectionner le type de projet",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(values) => {
                                                props.setTypeProjet(values);
                                                if (auth.id_profil === 2) {
                                                    dispatch(
                                                        team_menberActions.getAllTeamMenber(
                                                            {
                                                                id_equipe:
                                                                    auth.id_equipe,
                                                            }
                                                        )
                                                    );
                                                    formGlobal.setFieldsValue({
                                                        projet: null,
                                                        responsable: null,
                                                        client: '',
                                                    });
                                                } else {
                                                    formGlobal.setFieldsValue({
                                                        equipe: null,
                                                        projet: null,
                                                        client: '',
                                                    });
                                                }
                                            }}
                                            disabled={
                                                auth.id_profil === 3
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {type_projets.map((p) => (
                                                <Option value={p.id_type}>
                                                    {p.libelle_type}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Projet"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 6,
                                            marginRight: 10,
                                            marginBottom: 0,
                                        }}
                                        name="projet"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Veuillez sélectionner le projet",
                                            },
                                        ]}
                                    >
                                        {props.typeProjet === 1 ? (
                                            <Input
                                                disabled={
                                                    auth.id_profil === 3
                                                        ? true
                                                        : false
                                                }
                                            />
                                        ) : props.typeProjet === 2 ? (
                                            <Select
                                                defaultValue={
                                                    Object.keys(props.demandeSelected).length > 0 && props.duplicateDemande === 0 ?
                                                        parseInt(props.demandeSelected.id_projet) : null
                                                }
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={(values) => {
                                                    setProjectSelected(values);
                                                    dispatch(
                                                        userActions.getAllMemberProject(
                                                            parseInt(values)
                                                        )
                                                    );
                                                    if (
                                                        projects.find(
                                                            (p) =>
                                                                p.id_projet ==
                                                                values
                                                        ).id_equipe !==
                                                        undefined
                                                    ) {
                                                        formGlobal.setFieldsValue(
                                                            {
                                                                equipe: parseInt(
                                                                    projects.find(
                                                                        (p) =>
                                                                            p.id_projet ==
                                                                            values
                                                                    ).id_equipe
                                                                ),
                                                            }
                                                        );
                                                    } else {
                                                        formGlobal.setFieldsValue(
                                                            {
                                                                equipe: null,
                                                                responsable:
                                                                    null,
                                                            }
                                                        );
                                                    }

                                                    if (
                                                        projects.find(
                                                            (p) => parseInt(p.id_projet) === parseInt(values)
                                                        ).id_client !== null
                                                    ) {
                                                        var id_client = projects.find(
                                                            (p) => parseInt(p.id_projet) === parseInt(values)
                                                        ).id_client;

                                                        setClientExiste(
                                                            customers.find((p) => parseInt(p.id_client) === parseInt(id_client)).nom_client
                                                        );
                                                        formGlobal.setFieldsValue({
                                                            client: parseInt(
                                                                projects.find(
                                                                    (p) => parseInt(p.id_projet) === parseInt(values)
                                                                ).id_client
                                                            ),
                                                        })
                                                    } else {
                                                        setClientExiste('');
                                                        formGlobal.setFieldsValue({
                                                            client: null,
                                                        })
                                                    }
                                                }}
                                                disabled={
                                                    auth.id_profil === 3
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {auth.id_profil === 2
                                                    ? projects
                                                        .filter(
                                                            (pp) =>
                                                                pp.id_equipe ==
                                                                auth.id_equipe
                                                        )
                                                        .map((p) => (
                                                            <Option
                                                                value={parseInt(
                                                                    p.id_projet
                                                                )}
                                                                style={{
                                                                    borderLeft: `5px solid ${p.color}`,
                                                                    margin: 2,
                                                                }}
                                                            >
                                                                {p.libelle}
                                                            </Option>
                                                        ))
                                                    : projects.map((p) => (
                                                        <Option
                                                            value={parseInt(
                                                                p.id_projet
                                                            )}
                                                            style={{
                                                                borderLeft: `5px solid ${p.color}`,
                                                                margin: 2,
                                                            }}
                                                        >
                                                            {p.libelle}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        ) : (
                                            <Input disabled={true} />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Équipe"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 4,
                                            marginRight: 10,
                                            marginBottom: 0,
                                        }}
                                        name="equipe"
                                        initialValue={
                                            auth.id_profil === 2
                                                ? auth.id_equipe
                                                : null
                                        }
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={
                                                auth.id_profil === 2 ||
                                                    props.typeProjet === 2 ||
                                                    auth.id_profil === 3
                                                    ? true
                                                    : false
                                            }
                                            onChange={(values) => {
                                                dispatch(
                                                    team_menberActions.getAllTeamMenber(
                                                        {
                                                            id_equipe: values,
                                                        }
                                                    )
                                                );
                                                setClientExiste('');
                                                formGlobal.setFieldsValue({
                                                    responsable: null,
                                                });
                                            }}
                                        >
                                            {teams.map((p) => (
                                                <Option
                                                    value={parseInt(
                                                        p.id_equipe
                                                    )}
                                                    style={{
                                                        borderLeft: `5px solid ${p.color}`,
                                                        margin: 2,
                                                    }}
                                                >
                                                    {p.libelle}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Responsable"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 4,
                                            marginRight: 10,
                                            marginBottom: 0,
                                        }}
                                        name="responsable"
                                    >
                                        <Select
                                            defaultValue={
                                                Object.keys(props.demandeSelected).length > 0 && props.duplicateDemande === 0 ?
                                                    parseInt(props.demandeSelected.id_responsable_demande) : null
                                            }
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={
                                                auth.id_profil === 3
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {props.typeProjet === 1
                                                ? team_members.map((p) => (
                                                    <Option
                                                        value={parseInt(
                                                            p.id_user
                                                        )}
                                                        style={{
                                                            margin: 2,
                                                        }}
                                                    >
                                                        {p.first_name}
                                                    </Option>
                                                ))
                                                : project_member.map((p) => (
                                                    <Option
                                                        value={parseInt(
                                                            p.id_user
                                                        )}
                                                        style={{
                                                            margin: 2,
                                                        }}
                                                    >
                                                        {p.first_name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Client"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 6,
                                            marginRight: 10,
                                            marginBottom: 0,
                                        }}
                                        name="client"
                                        rules={
                                            parseInt(props.typeProjet) !== 1 ? (
                                                [
                                                    {
                                                        required: true,
                                                        message:
                                                            "Ce projet existe déja, alors il doit appartenir à un client !",
                                                    },
                                                ]
                                            ) : null
                                        }
                                        initialValue={
                                            Object.keys(props.demandeSelected).length > 0 ?
                                                props.demandeSelected.id_client != null ?
                                                    props.demandeSelected.id_client : null : null
                                        }
                                    >
                                        {
                                            clientExiste ? (
                                                <>
                                                    <Input
                                                        disabled={
                                                            auth.id_profil === 3 || clientExiste !== ''
                                                                ? true
                                                                : false
                                                        }
                                                        placeholder={clientExiste}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Select
                                                        defaultValue={
                                                            Object.keys(props.demandeSelected).length > 0 && props.duplicateDemande === 0 ?
                                                                props.demandeSelected.id_client != null ?
                                                                    props.demandeSelected.id_client : '' : ''
                                                        }
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        onChange={(values) => {
                                                            formGlobal.setFieldsValue({
                                                                client: values,
                                                            });
                                                        }}
                                                        disabled={
                                                            auth.id_profil === 3
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {customers.map((p) => (
                                                            <>
                                                                <Option
                                                                    value={parseInt(p.id_client)}
                                                                // style={{
                                                                //     margin: 2,
                                                                // }}
                                                                >
                                                                    {p.nom_client}
                                                                </Option>
                                                            </>
                                                        ))}
                                                    </Select>
                                                </>

                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        label="Date d'édition"
                                        style={{
                                            display: "inline-block",
                                            width: "calc(13% - 0px)",
                                            marginLeft: 6,
                                            marginBottom: 0,
                                        }}
                                        name="date_edition"
                                        initialValue={
                                            Object.keys(props.demandeSelected)
                                                .length === 0
                                                ? moment()
                                                : null
                                        }
                                    >
                                        <DatePicker
                                            allowClear={true}
                                            allowEmpty={false}
                                            format="DD/MM/YYYY"
                                            style={{ width: "100%" }}
                                            disabled={
                                                auth.id_profil === 3
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Form.Item>
                                </Form.Item>
                                {/* <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columnss}
                    size="small"
                    pagination={false}
                    scroll={scr}
                /> */}
                                <p
                                    style={{
                                        fontSize: 14,
                                        marginBottom: 5,
                                        textAlign: "right",
                                    }}
                                >
                                    Durée:{" "}
                                    {isNaN(
                                        dureeTotalHeure(
                                            inputList.filter(
                                                (p) => p.duree !== null
                                            )
                                        )
                                    ) !== true
                                        ? dureeTotalHeure(inputList)
                                        : null}{" H / "}
                                    {isNaN(
                                        dureeTotalJour(
                                            inputList.filter(
                                                (p) => p.duree !== null
                                            )
                                        )
                                    ) !== true
                                        ? dureeTotalJour(inputList)
                                        : null}{" "}
                                    jours
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "#f0f0f0",
                                        padding: 5,
                                        color: "rgba(0, 0, 0, 0.85)",
                                    }}
                                >
                                    <div style={{ flex: 4 }}>Module</div>
                                    <div style={{ flex: 4 }}>tâche</div>
                                    <div style={{ flex: 4 }}>Description</div>
                                    <div style={{ flex: 4 }}>Durée</div>
                                    <div style={{ flex: 4 }}>Observation</div>
                                    {Object.keys(props.demandeDetail).length ===
                                        0 ? (
                                        <div
                                            style={{ flex: 1, paddingLeft: 10 }}
                                        >
                                            <PlusOutlined
                                                onClick={handleAddClick}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <Scrollbars autoHide style={{ height: "55vh" }}>
                                    {inputList.map((p, i) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "5px 0px",
                                                borderBottom:
                                                    "1px solid #f0f0f0",
                                            }}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    flex: 4,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <Form.Item
                                                    //name={"module-" + i}
                                                    //initialValue={p.module}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input
                                                        name="module"
                                                        value={p.module}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 4,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <Form.Item
                                                    //name={"tache-" + i}

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Libelle de la tache est obligatoire",
                                                        },
                                                    ]}
                                                    //initialValue={p.tache}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input.TextArea
                                                        name="tache"
                                                        value={p.tache}
                                                        autoSize={true}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                        style={{
                                                            borderColor: save
                                                                ? p.tache === ""
                                                                    ? "red"
                                                                    : null
                                                                : null,
                                                        }}
                                                    />
                                                    {save ? (
                                                        p.tache === "" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Libelle de la
                                                                tache est
                                                                obligatoire
                                                            </span>
                                                        ) : null
                                                    ) : null}
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 4,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <Form.Item
                                                    //name={"description-" + i}

                                                    //initialValue={p.description}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input.TextArea
                                                        name="description"
                                                        value={p.description}
                                                        autoSize={true}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 4,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <Form.Item
                                                    //name={"duree-" + i}

                                                    //initialValue={p.duree}
                                                    style={{ marginBottom: 0 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "La durée de la tache est obligatoire",
                                                        },
                                                    ]}
                                                >
                                                    <Tooltip placement="top" title="la durée est en heure">
                                                        <InputNumber
                                                            name="duree"
                                                            min="0"
                                                            max="8"
                                                            step="0.00"
                                                            value={p.duree > 8 ? 8.00 : p.duree}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            style={{
                                                                width: "100%",
                                                                borderColor: save
                                                                    ? p.duree ===
                                                                        null
                                                                        ? "red"
                                                                        : null
                                                                    : null,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {save ? (
                                                        p.duree === null ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                La durée de la
                                                                tache est
                                                                obligatoire
                                                            </span>
                                                        ) : null
                                                    ) : null}
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 4,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <Form.Item
                                                    //name={"observation-" + i}

                                                    //initialValue={p.observation}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input.TextArea
                                                        name="observation"
                                                        value={p.observation}
                                                        autoSize={true}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                            {
                                                Object.keys(props.demandeDetail)
                                                    .length === 0 ? (
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            paddingLeft: 10,
                                                        }}
                                                    >
                                                        {inputList.length !== 0 && (
                                                            <>
                                                                <MinusOutlined
                                                                    onClick={() =>
                                                                        handleRemoveClick(
                                                                            p.key
                                                                        )
                                                                    }
                                                                />
                                                                <PlusOutlined
                                                                    onClick={() =>
                                                                        handleAddAtIndex(
                                                                            i
                                                                        )
                                                                    }
                                                                    style={{
                                                                        marginLeft: 20,
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    ))}

                                    {Object.keys(props.demandeDetail).length ===
                                        0 &&
                                        closable_add_demande_of_quotation ===
                                        true ? (
                                        <Form.Item
                                            style={{
                                                marginTop: 20,
                                                float: "right",
                                            }}
                                        >
                                            <Form.Item
                                                style={{
                                                    position: "fixed",
                                                    bottom: 0,
                                                    right: 0,
                                                    padding: 50,
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ marginRight: 4 }}
                                                    htmlType={"submit"}
                                                    onClick={() =>
                                                        Object.keys(
                                                            inputList.filter(
                                                                (p) =>
                                                                    p.tache ===
                                                                    "" ||
                                                                    p.duree ===
                                                                    null
                                                            )
                                                        ).length > 0
                                                            ? setSave(true)
                                                            : setSave(false)
                                                    }
                                                >
                                                    Enregistrer
                                                </Button>
                                                <Button
                                                    style={{ marginLeft: 4 }}
                                                    onClick={() => {
                                                        formGlobal.resetFields();
                                                        setInputList([]);
                                                        props.setDemandeSelected({});
                                                        props.setduplicateDemande(0);
                                                        props.setDemandeDetail({});
                                                        props.setTypeProjet(1);
                                                        setClientExiste('');
                                                        setCount(0);
                                                        setSave(false);
                                                        i = 0;
                                                        dispatch(
                                                            modalActions.close_add_demande_of_quotation()
                                                        )
                                                    }
                                                    }
                                                >
                                                    Annuler
                                                </Button>
                                            </Form.Item>
                                        </Form.Item>
                                    ) : null}
                                </Scrollbars>
                            </Form>
                        ) : (
                            <div>
                                <h3
                                    style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                    }}
                                >
                                    FICHE DE COTATION N°{" "}
                                    {numDemande(props.demandeDetail.id_demande)}
                                    {" "}
                                    {props.demandeDetail.ref_facture !== null
                                        ? (
                                            <>
                                                / Réf N° {props.demandeDetail.ref_facture} {" "}
                                                <Can
                                                    I="FacturerDemandeCotation"
                                                    a="Fiche de cotation"
                                                    ability={ability}
                                                >
                                                    <Tooltip placement="right" title={'Modifier la référence de la facture'}>
                                                        <Popover
                                                            content={content}
                                                            title={handleTitle}
                                                            trigger="click" width="130"
                                                        >
                                                            <EditOutlined
                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                            />
                                                        </Popover>
                                                    </Tooltip>
                                                </Can>
                                            </>
                                        )
                                        : null}
                                </h3>
                                <Button
                                    type="primary"
                                    icon={<PrinterOutlined />}
                                    size="small"
                                    style={{ float: "right" }}
                                    onClick={() =>
                                        ImprimerDemandeCotation(
                                            props.demandeDetail
                                        )
                                    }
                                />
                                <Row gutter={18} style={{ marginBottom: 10 }}>
                                    <Col md={5} sm={5} lg={5}></Col>
                                    <Col md={10} sm={10} lg={10}>
                                        <h3>
                                            Projet:{" "}
                                            {props.demandeDetail.libelle_projet}
                                        </h3>
                                        <h3>
                                            Équipe:{" "}
                                            {props.demandeDetail.libelle_equipe}
                                        </h3>
                                        <h3>
                                            Client:{" "}
                                            {props.demandeDetail.nom_client}
                                        </h3>
                                        <h3>
                                            Date de livraison:{" "}
                                            {props.demandeDetail.date_livraison !== null ?
                                                (
                                                    <>
                                                        {moment(props.demandeDetail.date_livraison).format(
                                                            "DD/MM/YYYY"
                                                        )}  {" "}
                                                        <Can
                                                            I="FacturerDemandeCotation"
                                                            a="Fiche de cotation"
                                                            ability={ability}
                                                        >
                                                            <EditOutlined
                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                onClick={() => {
                                                                    dispatch(modalActions.show_validate_cotation());
                                                                }} />
                                                        </Can>
                                                    </>
                                                )
                                                : "..."}
                                        </h3>
                                    </Col>
                                    <Col md={7} sm={7} lg={7}>
                                        <h3>
                                            Responsable:{" "}
                                            {props.demandeDetail.first_name}
                                        </h3>
                                        <h3>
                                            Date d'édition:{" "}
                                            {moment(props.demandeDetail.date_edition).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </h3>
                                        <h3>
                                            Durée estimée:{" "}
                                            {isNaN(
                                                dureeTotalHeure(
                                                    inputList.filter(
                                                        (p) => p.duree !== null
                                                    )
                                                )
                                            ) !== true
                                                ? dureeTotalHeure(inputList)
                                                : null}{" H / "}
                                            {dureeTotalJour(
                                                props.demandeDetail.cotations
                                            )}{" "}
                                            {parseInt(
                                                dureeTotalJour(
                                                    props.demandeDetail
                                                        .cotations
                                                )
                                            ) <= 1
                                                ? "jour"
                                                : "jours"}
                                        </h3>
                                        <h3>
                                            Durée réelle:{" "}
                                            {dureeTReel(
                                                props.demandeDetail.id_demande
                                            )}{" "}
                                            {parseInt(
                                                dureeTotalJour(
                                                    props.demandeDetail
                                                        .cotations
                                                )
                                            ) <= 1
                                                ? "jour"
                                                : "jours"}
                                        </h3>
                                    </Col>
                                </Row>
                                <Table
                                    dataSource={props.demandeDetail.cotations}
                                    columns={columns_cotat}
                                    pagination={false}
                                    size="small"
                                    scroll={scr}
                                />
                            </div>
                        )}
                    </Spin>
                </div>
            </div>
            <ModalValiderDemandeCotation
                selectedDemande={props.selectedDemande}
                setSelectedDemande={props.setSelectedDemande}
            />
        </Drawer >
    );
};

export default DrawerDemandeDeCotation;
