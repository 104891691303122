import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { loginConstants } from "../_constants";
import storage from "redux-persist/lib/storage/session";
import { profile } from "./profile.reducers";
import { alert } from "./alert.reducers";
import { modal } from "./modal.reducers";
import { group } from "./group.reducers";
import { action } from "./action.reducers";
import { user } from "./user.reducers";
import { droit } from "./droit.reducers";
import { direction } from "./direction.reducers";
import { team } from "./team.reducers";
import { team_menber } from "./team_menber.reducers";
import { project } from "./project.reducers";
import { team_project } from "./team_project.reducers";
import { project_color } from "./project_color.reducers";
import { project_status } from "./project_status.reducers";
import { task } from "./task.reducers";
import { jalon } from "./jalon.reducers";
import { statut } from "./statut.reducers";
import { login } from "./login.reducers";
import { statut_projet } from "./statut_projet.reducers";
import { customer } from "./customer.reducers";
import { statut_demande } from "./statut_demande.reducers";
import { type_project } from "./type_project.reducers";
import { demande_cotation } from "./demande_cotation.reducers";
import { type_ticket } from "./type_ticket.reducers";
import { ticket_state } from "./ticket_state.reducers";
import { priority_ticket } from "./priority_ticket.reducers";
import { ticket } from "./ticket.reducers";
import { filtre } from "./filtre.reducers";

import { withReduxStateSync } from "redux-state-sync";

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: [
        "alert",
        "profile",
        "modal",
        "group",
        "action",
        "user",
        "droit",
        "direction",
        "statut",
        "team",
        "team_menber",
        "project",
        "team_project",
        "project_color",
        "project_status",
        "task",
        "jalon",
        "login",
        "statut_projet",
        "customer",
        "statut_demande",
        "type_project",
        "demande_cotation",
        "type_ticket",
        "ticket_state",
        "priority_ticket",
        "ticket",
        "filtre"
    ],
};
const appReducer = combineReducers({
    profile,
    alert,
    modal,
    group,
    action,
    user,
    droit,
    direction,
    team,
    team_menber,
    project,
    team_project,
    project_color,
    project_status,
    task,
    jalon,
    statut,
    login,
    statut_projet,
    customer,
    statut_demande,
    type_project,
    demande_cotation,
    type_ticket,
    ticket_state,
    priority_ticket,
    ticket,
    filtre
});

const rootReducer = (state, action) => {
    // if (action.type === storeConstants.RESET_STORE) state = undefined;
    if (action.type === loginConstants.LOGOUT_SUCCESS) {
        // for all keys defined in your persistConfig(s)
        storage.removeItem("persist:root");
        // storage.removeItem('persist:otherKey')

        state = undefined;
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig, withReduxStateSync(rootReducer));
