import { statutConstants } from "../_constants";
import { statutServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const statutActions = {
    getAll,
    add,
    update,
};

function update(statut) {
    return function (dispatch) {
        dispatch(request());
        statutServices
            .update(statut)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec modification !"));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: statutConstants.UPDATE_STATUT_REQUEST,
        };
    }
    function success(statut) {
        return {
            type: statutConstants.UPDATE_STATUT_SUCCESS,
            statut,
        };
    }
    function failure(error) {
        return {
            type: statutConstants.UPDATE_STATUT_FAILURE,
            error,
        };
    }
}

function add(statut) {
    return function (dispatch) {
        dispatch(request());
        statutServices
            .add(statut)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec d'ajout !"));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: statutConstants.ADD_STATUT_REQUEST,
        };
    }
    function success(statut) {
        return {
            type: statutConstants.ADD_STATUT_SUCCESS,
            statut,
        };
    }
    function failure(error) {
        return {
            type: statutConstants.ADD_STATUT_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        statutServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: statutConstants.GETALL_STATUT_REQUEST,
        };
    }
    function success(statut) {
        return {
            type: statutConstants.GETALL_STATUT_SUCCESS,
            statut,
        };
    }
    function failure(error) {
        return {
            type: statutConstants.GETALL_STATUT_FAILURE,
            error,
        };
    }
}
