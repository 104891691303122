import React, { useState } from "react";
import "./VoirTousLesProjets.css";
import { Drawer, Typography } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import Projet from "../../components/Projet/Projet";
import { useSelector, useDispatch } from "react-redux";
import { modalActions } from "../../../redux/_actions";

const { Text } = Typography;

const VoirTousLesProjets = (props) => {
    const team_projects = useSelector(
        (state) => state.team_project.team_projects
    );
    const closable_more_project = useSelector(
        (state) => state.modal.closable_more_project
    );
    const project_without_team = useSelector(
        (state) => state.project.project_without_team
    );

    const dispatch = useDispatch();

    return (
        <Drawer
            className="voirtousprojet"
            title={<Text strong={true}>Projets</Text>}
            placement="right"
            mask={false}
            onClose={() => dispatch(modalActions.close_more_project())}
            visible={closable_more_project}
            style={{ marginTop: 50 }}
            bodyStyle={{
                padding: 16,
            }}
            width={650}
        >
            <Scrollbars
                style={{
                    height: 590,
                }}
            >
                {props.teamSelected !== 0
                    ? team_projects.map((p) => <Projet p={p} setProjectSelected={
                        props.setProjectSelected
                    } />)
                    : project_without_team.map((p) => <Projet p={p} setProjectSelected={
                        props.setProjectSelected
                    } />)}
            </Scrollbars>
        </Drawer>
    );
};

export default VoirTousLesProjets;
