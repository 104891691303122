import { filtreConstants } from "../_constants";

export const filtreActions = {
    choose_equipe,
    reset_equipe,

    choose_projet,
    reset_projet,

    choose_membre,
    reset_membre,

    choose_jalon,
    reset_jalon,

    choose_date,
    reset_date,
}

function choose_equipe(id) {
    return {
        type: filtreConstants.CHOOSE_EQUIPE,
        id
    }
}

function reset_equipe() {
    return {
        type: filtreConstants.RESET_EQUIPE
    }
}

function choose_projet(id) {
    return {
        type: filtreConstants.CHOOSE_PROJET,
        id
    }
}

function reset_projet() {
    return {
        type: filtreConstants.RESET_PROJET
    }
}

function choose_membre(id) {
    return {
        type: filtreConstants.CHOOSE_MEMBRE,
        id
    }
}

function reset_membre() {
    return {
        type: filtreConstants.RESET_MEMBRE
    }
}

function choose_jalon(id) {
    return {
        type: filtreConstants.CHOOSE_JALON,
        id
    }
}

function reset_jalon() {
    return {
        type: filtreConstants.RESET_JALON
    }
}

function choose_date(tab) {
    return {
        type: filtreConstants.CHOOSE_DATE,
        tab
    }
}

function reset_date() {
    return {
        type: filtreConstants.RESET_DATE
    }
}