import { actionConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    item: {},
    checkedAll: false,
    error: "",
};

export function action(state = initialState, action) {
    switch (action.type) {
        case actionConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actionConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.action,
            };

        case actionConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case actionConstants.GET_PROFILE_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actionConstants.GET_PROFILE_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...action.action],
            };

        case actionConstants.GET_PROFILE_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case actionConstants.GET_MODULE_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actionConstants.GET_MODULE_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter((p) => p.id_module === action.action),
                ],
            };

        case actionConstants.GET_MODULE_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.action,
            };

        case actionConstants.CHECKED:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_action === action.action) {
                            p.check = true;
                        }
                        return p;
                    }),
                ],
            };

        case actionConstants.UNCHECKED:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_action === action.action) {
                            p.check = false;
                        }
                        return p;
                    }),
                ],
            };

        case actionConstants.CHECKALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actionConstants.CHECKALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...action.check]
            };

        case actionConstants.CHECKALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case actionConstants.CHECKALL:
            return {
                ...state,
                items: [
                    ...state.items
                        .filter((p) => p.id_module === action.id_module)
                        .map((p) => {
                            p.check = true;
                            return p;
                        }),
                ],
                // items: [...state.items.map((p) => {
                //     p.check = true
                //     return p;
                // })],
                checkedAll: true,
            };

        case actionConstants.UNCHECKALL:
            return {
                ...state,
                items: [
                    ...state.items
                        .filter((p) => p.id_module === action.id_module)
                        .map((p) => {
                            p.check = false;
                            return p;
                        }),
                ],
                // items: [
                //     ...state.items.map((p) => {
                //         p.check = false;
                //         return p;
                //     }),
                // ],
                checkedAll: false,
            };

        default:
            return state;
    }
}
