import React, { useState, useEffect } from "react";
import "./Project.css";
import ModalAjouterEquipeProjet from "../../components/Modal/ModalAjouterEquipeProjet.js";
import ModalAjouterProjet from "../../components/Modal/ModalAjouterProjet";
import ModalMenbreEquipe from "../../components/Modal/ModalMenbreEquipe";
import Container from "../../components/Container/Container";
import Membre from "../../components/Membre/Membre";
import BlankCard from "../../components/Card/BlankCard";
import Projet from "../../components/Projet/Projet";
import ModalAjouterProjetEquipe from "../../../components/components/Modal/ModalAjouterProjetEquipe";
import ModalMenbreEquipeChefProjet from "../../components/Modal/ModalMenbreEquipeChefProjet";
import { useSelector, useDispatch } from "react-redux";
import {
    Menu,
    Dropdown,
    Select,
    Avatar,
    Divider,
    Spin,
    AutoComplete,
    Card,
    Input,
    Empty,
    Typography,
    Tabs,
    Breadcrumb,
    Button,
    Popover
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import {
    PlusOutlined,
    HomeOutlined,
    ProjectOutlined,
    UserOutlined,
    FileOutlined,
} from "@ant-design/icons";
import HeaderApp from "../../parchalse/HeaderApp";
import { modalActions } from "../../../redux/_actions";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import {
    team_projectActions,
    userActions,
    team_menberActions,
    projectActions,
    taskActions,
} from "../../../redux/_actions";
import ProjetNew from "../../components/Projet/ProjetNew";
import MembreNew from "../../components/Membre/MembreNew";
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import DetailTache from "../../components/Drawer/DetailTache";
import { makeAvatar } from "../../helpers/makeAvatar";

const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const Project = () => {
    const [filterProjectState, setFilterProjectState] = useState("En cours");

    const tab = useSelector((state) => state.team_project.tab);
    const user = useSelector((state) => state.login);
    const filterProjectUser = useSelector(
        (state) => state.team_project.filterProjectUser
    );

    const [teamSelected, setTeamSelected] = useState(0);
    const [filtreUser, setFiltreUser] = useState(false);
    const [tabUserFilter, setTabUserFilter] = useState([]);

    const dispatch = useDispatch();
    // creation de la pagination
    // const numEachPage = 15;
    // const [state, setState] = useState({
    //     minValue: 0,
    //     maxValue: 15,
    // });
    const [projectSelected, setProjectSelected] = useState({});

    // const handleChangePagination = (value) => {
    //     setState({
    //         minValue: (value - 1) * numEachPage,
    //         maxValue: value * numEachPage,
    //     });
    // };

    // fin de la paginiation
    // recuperation de l'ensemble des projets de la personne connecter

    const team_projects = useSelector(
        (state) => state.team_project.team_projects
    );
    const filterProject = useSelector(
        (state) => state.team_project.filterProject
    );
    const searchVeri = useSelector((state) => state.team_project.searchVeri);

    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const teams = useSelector((state) => state.team.items);
    const loadingTask = useSelector((state) => state.task.loading);

    // prises en compte du chef de projet qui c'est connecter
    // const [teamSelected, setTeamSelected] = useState(1);
    const loadingTeamProjet = useSelector(
        (state) => state.team_project.loading
    );
    const loadingTeamMembre = useSelector((state) => state.team_menber.loading);

    const [searchDataChef, setSearchDataChef] = useState("");

    const handleChangeChef = (e) => {
        setSearchDataChef(e.target.value);
        dispatch(team_projectActions.searchProjectChef(e.target.value));
    };

    const [searchDataDev, setSearchDataDev] = useState("");

    const handleChangeDev = (e) => {
        setSearchDataDev(e.target.value);
        dispatch(team_projectActions.searchProjectDev(e.target.value));
    };

    // var total = round(team_projects.length / numEachPage);

    const ability = defineAbilityFor(user);

    useEffect(() => {
        dispatch(team_projectActions.resetTeamProject());
        if (user.item.id_equipe !== null) {
            let equipe = teams.find((p) => p.id_equipe == user.item.id_equipe);

            // pour les utilisateurs qui ont des equipes 
            if (
                equipe.id_user == user.item.id_user ||
                user.item.id_profil === 1
            ) {
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: user.item.id_equipe,
                    })
                );
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: user.item.id_equipe,
                    })
                );
            }
            // pour les utilisateurs dans equipe (ex: Admin)
            else {
                dispatch(
                    team_projectActions.getAllTeamMemberProject(
                        user.item.id_equipe_membre
                    )
                );
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: user.item.id_equipe,
                    })
                );
            }
        }
        dispatch(userActions.userNotification(user.item.id_user));
        dispatch(modalActions.close_detail_task());
    }, [
        dispatch,
        teams,
        user.item.id_equipe,
        user.item.id_equipe_membre,
        user.item.id_profil,
        user.item.id_user,
    ]);

    // useEffect(() => {
    //     dispatch(team_projectActions.resetSearch());
    // }, [])

    // useEffect(() => {
    //     return () => {
    //         dispatch(team_projectActions.resetSearch());
    //     };
    // }, [dispatch]);

    const tasks = useSelector((state) => state.task.allTeamPlaningTask);

    // console.log('valLog AllProjects --- 2', tasks)


    const openPlan = (obj) => {
        dispatch(projectActions.selectedProjectTask(obj.data));
        dispatch(taskActions.getAllTaskLogs(parseInt(obj.data.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(obj.data.id_tache)));
        dispatch(userActions.getAllMemberProject(parseInt(obj.data.id_projet)));
        setTimeout(() => {
            dispatch(modalActions.show_detail_task());
        }, 100);
    };

    const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment {...restProps} onClick={openPlan}>
            <Popover
                style={{
                    borderLeft: `10px solid ${restProps.data.color}`,
                }}
                content={
                    <div>
                        <p>
                            <FileOutlined /> {restProps.data.libelle_tache}
                        </p>
                        {restProps.data.first_name !== null &&
                            restProps.data.first_name !== undefined ? (
                            <p style={{ marginBottom: 0 }}>
                                <UserOutlined /> {restProps.data.first_name}
                            </p>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <ProjectOutlined /> {restProps.data.libelle_projet}
                    </>
                }
            >
                <div
                    style={{
                        height: "100%",
                        padding: 3,
                        borderLeft: `10px solid ${restProps.data.color}`,
                    }}
                >
                    <Avatar size="small" style={{ float: "left" }}>
                        {makeAvatar(restProps.data.first_name)}
                    </Avatar>
                    {children}
                </div>
            </Popover>
        </Appointments.Appointment>
    );

    const handleProjectStatus = (value) => {
        if (value === "En cours") {
            setFilterProjectState(value);
            dispatch(team_projectActions.filterInProgressProject());
        } else if (value === "Archivée") {
            setFilterProjectState(value);
            dispatch(team_projectActions.filterArchivedProject());
        } else if (value === "Tous les projets") {
            setFilterProjectState(value);
            dispatch(team_projectActions.filterAllProject());
        }
    };

    const menuEtat = (
        <Menu
            onClick={(value) => {
                if (value.key === "En cours") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterInProgressProject());
                } else if (value.key === "Archivée") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterArchivedProject());
                } else if (value.key === "Tous les projets") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterAllProject());
                }
            }}
        >
            <Menu.Item key="En cours" className="m">
                En cours
            </Menu.Item>
            <Menu.Item key="Archivée" className="m">
                Archivée
            </Menu.Item>
            <Menu.Item key="Tous les projets" className="m">
                Tous les projets
            </Menu.Item>
        </Menu>
    );

    const handlePlaningUser = (id_user) => {
        setTabUserFilter(
            tasks.filter((p) => p.id_user === id_user)
        );
    }

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Mes projets</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    padding: 18,
                    paddingTop: 0,
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: team_projects.length > 7 ? "100%" : "82%",
                }}
            >
                {user.item.id_equipe === null ? (
                    <h3
                        style={{
                            position: "absolute",
                            top: 400,
                            left: 700,
                        }}
                    >
                        Vous n'avez pas d'équipe !
                    </h3>
                ) : null}

                {/* pour un developpeur, voir son profil */}
                <Can I="LireMembre" a="Mes Projets" ability={ability}>
                    <div
                        style={{
                            flex: 2,
                            backgroundColor:
                                user.item.id_equipe !== null
                                    ? "white"
                                    : "#f8f8ff",
                            padding: 10,
                            borderRight: "2px solid #f0f0f0",
                        }}
                    >
                        <Text>
                            {user.item.id_equipe !== null
                                ? user.item.libelle_equipe
                                : "Membres"}
                        </Text>
                        <Divider
                            style={{
                                margin: "10px 0",
                                backgroundColor: "#f0f0f0",
                            }}
                        />
                        <Can
                            I="AjouterMembre"
                            a="Mes Projets"
                            ability={ability}
                        >
                            <Card
                                style={{
                                    margin: 5,
                                    borderRadius: 5,
                                    backgroundColor: "#dedede",
                                    border: "2px dashed black",
                                    height: 64,
                                    minWidth: 260,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    if (user.item.id_equipe !== null) {
                                        dispatch(
                                            userActions.getUserWithoutTeam()
                                        );
                                        dispatch(
                                            modalActions.show_add_team_menber()
                                        );
                                    }
                                }}
                            >
                                <PlusOutlined />
                            </Card>
                        </Can>
                        {user.item.id_equipe !== null ? (
                            <Scrollbars
                                autoHide
                                style={{
                                    background: "white",
                                    height: "63vh",
                                    // team_projects.length > 7
                                    //     ? "68vh"
                                    //     : "62vh",
                                }}
                                className="membre-mes-project"
                            >
                                <Spin
                                    tip="Chargement en cours..."
                                    spinning={loadingTeamMembre}
                                >
                                    {team_menbers.map((p) => (
                                        <MembreNew p={p} />
                                    ))}
                                </Spin>
                            </Scrollbars>
                        ) : null}
                    </div>
                </Can>

                {/* Pour voir les membres de l'equipe et faire des operations sur les membres de l'equipe */}
                <Can I="LireProjetChef" a="Mes Projets" ability={ability}>
                    <div
                        style={{
                            flex: 8,
                            backgroundColor:
                                user.item.id_equipe !== null
                                    ? "white"
                                    : "#f8f8ff",
                            padding: 10,
                        }}
                    >
                        <Tabs
                            onChange={(value) =>
                                value === "2" ?
                                    (
                                        <>
                                            {dispatch(
                                                taskActions.getAllPlaningTaskOfTeam(
                                                    user.item.id_equipe
                                                )
                                            )}
                                            {setFiltreUser(true)}
                                        </>
                                    )
                                    : (
                                        setFiltreUser(false)
                                    )
                            }
                            defaultActiveKey="1"
                            tabBarExtraContent={
                                <>
                                    {filtreUser ? (
                                        <>
                                            <Select
                                                style={{
                                                    position: "relative",
                                                    bottom: 5,
                                                    right: 20,
                                                    width: 190,
                                                }}
                                                showSearch
                                                placeholder="choisir un membre..."
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={handlePlaningUser}
                                            >
                                                <Option value={0}>Toute la liste</Option>
                                                {
                                                    team_menbers.length
                                                        ? team_menbers.map((i) => {
                                                            return (
                                                                <Option value={i.id_user}>
                                                                    {i.first_name}
                                                                </Option>
                                                            )
                                                        }) : null
                                                }
                                            </Select>
                                        </>
                                    ) : null}
                                    <Dropdown
                                        overlay={menuEtat}
                                        trigger={["click"]}
                                        disabled={
                                            user.item.id_equipe === null
                                                ? true
                                                : false
                                        }
                                    >
                                        <Button
                                            style={{
                                                position: "relative",
                                                bottom: 5,
                                                right: 10,
                                                width: 130,
                                            }}
                                            disabled={
                                                user.item.id_equipe === null
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <span style={{ marginRight: 20 }}>
                                                {filterProjectState}
                                            </span>
                                        </Button>
                                    </Dropdown>
                                    {/* <AutoComplete
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            right: 0,
                                            bottom: 5,
                                            width: 210,
                                        }}
                                        disabled={
                                            user.item.id_equipe === null
                                                ? true
                                                : false
                                        }
                                    > */}
                                    <Input.Search
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            right: 0,
                                            bottom: 5,
                                            width: 210,
                                        }}
                                        disabled={
                                            user.item.id_equipe === null
                                                ? true
                                                : false
                                        }
                                        autoFocus={
                                            searchVeri !== "" ? true : false
                                        }
                                        value={searchVeri}
                                        placeholder="rechercher un projet"
                                        enterButton
                                        onChange={handleChangeChef}
                                    />
                                    {/* </AutoComplete> */}
                                </>
                            }
                        >
                            <TabPane tab={<Text>Mes projets</Text>} key="1">
                                <Scrollbars
                                    style={{
                                        height: "70vh",
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Spin
                                        tip="Chargement en cours..."
                                        spinning={loadingTeamProjet}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            <Can
                                                I="AjouterProjet"
                                                a="Mes Projets"
                                                ability={ability}
                                            >
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            "#DEDEDE",
                                                        border:
                                                            "2px dashed black",
                                                        width: "24%",
                                                        textAlign: "center",
                                                        height: 120,
                                                        margin: 4,
                                                        borderRadius: 3,
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            user.item
                                                                .id_equipe !==
                                                            null
                                                        ) {
                                                            dispatch(
                                                                modalActions.show_add_team_project()
                                                            );
                                                            setFilterProjectState(
                                                                "En cours"
                                                            );
                                                            dispatch(
                                                                team_projectActions.filterInProgressProject()
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <PlusOutlined />
                                                </Card>
                                            </Can>

                                            {user.item.id_equipe !== null ? (
                                                filterProject.length ? (
                                                    searchDataChef !== "" ||
                                                        searchVeri !== "" ? (
                                                        tab.length > 0 ? (
                                                            tab.map((p) => (
                                                                <ProjetNew
                                                                    p={p}
                                                                    setProjectSelected={
                                                                        setProjectSelected
                                                                    }
                                                                />
                                                            ))
                                                        ) : (
                                                            <Empty
                                                                description={
                                                                    <Text>
                                                                        Aucun
                                                                        projet
                                                                        trouvé.
                                                                    </Text>
                                                                }
                                                            />
                                                        )
                                                    ) : (
                                                        filterProject.map(
                                                            (p) => (
                                                                <ProjetNew
                                                                    p={p}
                                                                    setProjectSelected={
                                                                        setProjectSelected
                                                                    }
                                                                />
                                                            )
                                                        )
                                                    )
                                                ) : (
                                                    <div>
                                                        <Empty
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </Spin>
                                </Scrollbars>
                            </TabPane>
                            <TabPane tab={<Text>planning</Text>} key="2">
                                <Spin
                                    tip="Chargement en cours..."
                                    spinning={loadingTask}
                                >
                                    <Paper
                                        style={{
                                            // height: "100%",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Scheduler
                                            data={
                                                user.item.id_equipe === null
                                                    ? []
                                                    : tabUserFilter.length !== 0
                                                        ? tabUserFilter
                                                        : tasks
                                            }
                                            height={450}
                                            locale="fr-FR"
                                            firstDayOfWeek={1}
                                        >
                                            <ViewState />
                                            <MonthView />
                                            <Appointments
                                                appointmentComponent={
                                                    Appointment
                                                }
                                            />
                                            <Toolbar />
                                            <DateNavigator />
                                        </Scheduler>
                                    </Paper>
                                </Spin>
                            </TabPane>
                            {/* <TabPane tab="Tab 3" key="3">
                                Content of Tab Pane 3
                            </TabPane> */}
                        </Tabs>
                        {/* <Divider
                            style={{
                                margin: "10px 0",
                                backgroundColor: "#f0f0f0",
                            }}
                        /> */}
                    </div>
                </Can>

                {/* voir les membres de l'equipe */}
                <Can
                    I="LireProjetUtilisateur"
                    a="Mes Projets"
                    ability={ability}
                >
                    <div
                        style={{
                            flex: 2,
                            backgroundColor: "white",
                            padding: 10,
                            borderRight: "2px solid #f0f0f0",
                        }}
                    >
                        <Text>
                            {user.item.id_equipe !== null
                                ? user.item.libelle_equipe
                                : "Membres"}
                        </Text>
                        <Divider
                            style={{
                                margin: "10px 0",
                                backgroundColor: "#f0f0f0",
                            }}
                        />
                        <Scrollbars
                            autoHide
                            style={{
                                background: "white",
                                height: "73vh",
                            }}
                            className="membre-mes-project"
                        >
                            <Spin
                                tip="Chargement en cours..."
                                spinning={loadingTeamMembre}
                            >
                                {team_menbers
                                    .filter(
                                        (pp) => pp.id_user == user.item.id_user
                                    )
                                    .map((p) => (
                                        <MembreNew p={p} />
                                    ))}
                            </Spin>
                        </Scrollbars>
                    </div>
                    <div
                        style={{
                            flex: 8,
                            backgroundColor: "white",
                            padding: 10,
                        }}
                    >
                        <Tabs
                            onChange={(value) =>
                                value === "2"
                                    ? dispatch(
                                        taskActions.getAllPlaningTaskOfUser(
                                            user.item.id_equipe_membre
                                        )
                                    )
                                    : null
                            }
                            defaultActiveKey="1"
                            tabBarExtraContent={
                                <>
                                    <Select
                                        defaultValue="En cours"
                                        style={{
                                            width: 180,
                                            position: "relative",
                                            right: 0,
                                            bottom: 5,
                                            marginRight: 10,
                                        }}
                                        onChange={handleProjectStatus}
                                    >
                                        <Option value="En cours">
                                            En cours
                                        </Option>
                                        <Option value="Archivée">
                                            Archivée
                                        </Option>
                                        <Option value="Tous les projets">
                                            Tous les projects
                                        </Option>
                                    </Select>
                                    {/* <AutoComplete
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            right: 0,
                                            bottom: 5,
                                            width: 210,
                                        }}
                                    > */}
                                    <Input.Search
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            right: 0,
                                            bottom: 5,
                                            width: 210,
                                        }}
                                        autoFocus={
                                            searchVeri !== "" ? true : false
                                        }
                                        value={searchVeri}
                                        placeholder="rechercher un projet"
                                        enterButton
                                        onChange={handleChangeDev}
                                    />
                                    {/* </AutoComplete> */}
                                </>
                            }
                        >
                            <TabPane tab={<Text>Mes Projets</Text>} key="1">
                                <Scrollbars
                                    style={{
                                        height: "72vh",
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Spin
                                        tip="Chargement en cours..."
                                        spinning={loadingTeamProjet}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            {filterProjectUser.length ? (
                                                searchDataDev !== "" ||
                                                    searchVeri !== "" ? (
                                                    tab.length > 0 ? (
                                                        tab.map((p) => (
                                                            <ProjetNew
                                                                p={p}
                                                                setProjectSelected={
                                                                    setProjectSelected
                                                                }
                                                            />
                                                        ))
                                                    ) : (
                                                        <Empty
                                                            description={
                                                                <Text>
                                                                    Aucun projet
                                                                    trouvé.
                                                                </Text>
                                                            }
                                                        />
                                                    )
                                                ) : (
                                                    filterProjectUser.map(
                                                        (p) => (
                                                            <ProjetNew
                                                                p={p}
                                                                setProjectSelected={
                                                                    setProjectSelected
                                                                }
                                                            />
                                                        )
                                                    )
                                                )
                                            ) : (
                                                <div>
                                                    <Empty
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            textAlign: "center",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Spin>
                                </Scrollbars>
                            </TabPane>
                            <TabPane tab={<Text>planning</Text>} key="2">
                                <Spin
                                    tip="Chargement en cours..."
                                    spinning={loadingTask}
                                >
                                    <Paper
                                        style={{
                                            // height: "100%",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Scheduler
                                            data={tasks}
                                            height={450}
                                            locale="fr-FR"
                                            firstDayOfWeek={1}
                                        >
                                            <ViewState />
                                            <MonthView />
                                            <Appointments
                                                appointmentComponent={
                                                    Appointment
                                                }
                                            />
                                            <Toolbar />
                                            <DateNavigator />
                                        </Scheduler>
                                    </Paper>
                                </Spin>
                            </TabPane>
                            {/* <TabPane tab="Tab 3" key="3">
                                Content of Tab Pane 3
                            </TabPane> */}
                        </Tabs>
                    </div>
                </Can>
            </div >
            <ModalAjouterEquipeProjet />
            <ModalAjouterProjet
                projectSelected={projectSelected}
                setProjectSelected={setProjectSelected}
            />
            <ModalAjouterProjetEquipe
                teamSelected={teamSelected}
                projectSelected={projectSelected}
                setProjectSelected={setProjectSelected}
            />
            <ModalMenbreEquipeChefProjet teamSelected={teamSelected} />
            <DetailTache />
        </div >
    );
};

export default Project;
