import axios from "../../axios/index";

export const droitServices = {
    add,
    getAll,
    delete_droit,
    checkAllNew
}

async function add(droit) {
    return await axios.post(`profilActions`, droit);
}

async function getAll() {
    return await axios.get(`profilActions`);
}

async function delete_droit(droit) {
    return await axios.delete(`profilActions/${droit.id_profil}/${droit.id_action}`, droit)
}

async function checkAllNew(droit) {
    return await axios.post(`checkAllNew`, droit);
}