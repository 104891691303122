import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Select,
  Input,
  Avatar,
  Typography,
  Popconfirm,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { modalActions, projectActions, team_projectActions } from "../../../redux/_actions";
import {
  SaveOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const ModalAjouterProjet = (props) => {
  const closable_add_allproject = useSelector(
    (state) => state.modal.closable_add_allproject
  );
  const project_colors = useSelector((state) => state.project_color.items);
  const user = useSelector((state) => state.login.item);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(props.projectSelected).length !== 0) {
      form.setFieldsValue({
        // id_equipe: user.id_equipe,
        libelle:
          props.projectSelected.libelle !== ""
            ? props.projectSelected.libelle
            : null,
        color:
          props.projectSelected.id_color !== ""
            ? props.projectSelected.id_color
            : null,
        description:
          props.projectSelected.description !== ""
            ? props.projectSelected.description
            : null,
      });
    }
  });

  const submitAddTeamPorject = (values) => {
    if (Object.keys(props.projectSelected).length === 0) {
      const data = {
        libelle: values.libelle,
        color: values.color,
        description: values.description,
        id_statut: 1,
        create_by: user.id_user,
      };
      dispatch(projectActions.addWithoutTeamProject(data));
      dispatch(modalActions.close_add_allproject());
      form.resetFields();
    } else {
      const data = {
        id_projet: props.projectSelected.id_projet,
        libelle: values.libelle,
        color: values.color,
        description: values.description,
        id_statut: 1,
        create_by: user.id_user,
      };
      dispatch(projectActions.updateWithoutTeamProject(data));
      dispatch(modalActions.close_add_allproject());
      form.resetFields();
    }
  };

  const afterCloseModal = () => {
    form.resetFields();
    props.setProjectSelected({});
  };

  return (
    <Modal
      title={
        Object.keys(props.projectSelected).length === 0 ? (
          <Text strong={true}>Ajouter un projet</Text>
        ) : (
          <Text strong={true}>Modifier un projet</Text>
        )
      }
      visible={closable_add_allproject}
      footer={null}
      onCancel={() => {
        form.resetFields();
        dispatch(modalActions.close_add_allproject());
      }}
      afterClose={() => afterCloseModal()}
      maskClosable={false}
    >
      <Form form={form} onFinish={submitAddTeamPorject} layout="vertical">
        <Form.Item>
          <Form.Item
            name="libelle"
            label={<Text strong={true}>Libelle</Text>}
            style={{
              display: "inline-block",
              width: "calc(49% - 0px)",
              marginRight: 2,
              marginBottom: 0,
            }}
            rules={[
              {
                required: true,
                message: "Veuillez saisir le nom du projet !",
              },
            ]}
          >
            <Input
              type="text"
              size="large"
              placeholder="Saisir le libellé du projet."
            />
          </Form.Item>
          <Form.Item
            name="color"
            label={<Text strong={true}>Couleur</Text>}
            style={{
              display: "inline-block",
              width: "calc(49% - 0px)",
              marginRight: 2,
              marginBottom: 0,
            }}
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner la couleur du projet !",
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
              size="large"
              placeholder="Sélectionner une couleur."
            >
              {project_colors.map((p) => (
                <Option value={p.id_color}>
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: p.color,
                    }}
                  />
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="description"
          label={<Text strong={true}>Description</Text>}
        >
          <TextArea row={4} placeholder="Saisir la description du projet..." />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(72% - 0px)",
              marginBottom: 0,
            }}
          >
            <Button
              size="large"
              type="default"
              onClick={() => dispatch(modalActions.close_add_allproject())}
            >
              <CloseCircleOutlined /> Annuler
            </Button>{" "}
            <Button size="large" type="primary" htmlType="submit">
              <SaveOutlined /> Enregistrer
            </Button>
          </Form.Item>
          {Object.keys(props.projectSelected).length !== 0 ? (
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(20% - 0px)",
                marginRight: 2,
                marginBottom: 0,
              }}
            >
              <Popconfirm
                title="Etes vous sur de vouloir supprimer ce projet ?"
                onConfirm={() => {
                  dispatch(
                    projectActions.delete_project_without_team(
                      parseInt(props.projectSelected.id_projet)
                    )
                  );
                  dispatch(modalActions.close_add_allproject());
                }}
                okText="Oui"
                cancelText="Non"
              >
                <Button size="large" danger>
                  <DeleteOutlined /> Supprimer
                </Button>
              </Popconfirm>
            </Form.Item>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAjouterProjet;
