import React, { useEffect } from "react";
import { Avatar, Popover } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import dateFormat from "dateformat";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
    modalActions,
    taskActions,
    projectActions,
    userActions
} from "../../../redux/_actions";
import { makeAvatar, decryptWithAES } from "../../helpers/makeAvatar";
import { useParams } from "react-router-dom";
import {
    ProjectOutlined,
    UserOutlined,
    FileOutlined,
} from "@ant-design/icons";


const Demo = () => {
    var { id_projet } = useParams();
    id_projet = decryptWithAES(id_projet);
    const dataTask = [];
    const dispatch = useDispatch();
    // const tasks = useSelector((state) => state.project);

    // const transform = () => {
    //     if (tasks.project_task !== 0) {
    //         tasks.project_task.map((task) => {
    //             if (task.id_projet > 0) {
    //                 var ds = dateFormat(task.date_debut, "yyyy, m , d, H, M ");
    //                 var dss = ds.split(",");
    //                 dss[1] = dss[1] - 1;
    //                 var de = dateFormat(task.echeance, "yyyy, m , d, H, M ");
    //                 var dee = de.split(",");
    //                 dee[1] = dee[1] - 1;
    //                 dataTask.push({
    //                     id: task.id,
    //                     title: task.libelle_tache,
    //                     startDate: dss,
    //                     endDate: dee,
    //                     color: task.color,
    //                     date_debut: task.date_debut,
    //                     description: task.description,
    //                     duree: task.duree,
    //                     echeance: task.echeance,
    //                     first_name: task.first_name,
    //                     id_jalon: task.id_jalon,
    //                     id_projet: task.id_projet,
    //                     id_statut: task.id_statut,
    //                     id_user: task.id_user,
    //                     libelle_projet: task.libelle_projet,
    //                     libelle_tache: task.libelle_tache,
    //                     ordre: task.ordre,
    //                     statut_libelle: task.statut_libelle,
    //                     tache_description: task.tache_description,
    //                 });
    //             }
    //             return dataTask;
    //         });
    //     }
    // };

    // transform();

    const state = {
        data: dataTask,
        visible: false,
    };

    useEffect(() => {
        dispatch(
            taskActions.getAllPlaningTaskOfProject(
                parseInt(id_projet)
            )
        );
    }, []);

    const { data } = state;

    const handleClick = (obj) => {
        // dispatch(projectActions.selectedProjectTask(restProps.data));
        // dispatch(taskActions.getAllTaskLogs(parseInt(restProps.data.id)));
        // dispatch(taskActions.getAllUnderTask(parseInt(restProps.data.id)));
        // dispatch(modalActions.show_detail_task());

        dispatch(projectActions.selectedProjectTask(obj.data));
        dispatch(taskActions.getAllTaskLogs(parseInt(obj.data.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(obj.data.id_tache)));
        dispatch(userActions.getAllMemberProject(parseInt(obj.data.id_projet)));
        setTimeout(() => {
            dispatch(modalActions.show_detail_task());
        }, 100);
    };

    const tasks = useSelector((state) => state.task.allTeamPlaningTask);

    const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment {...restProps} onClick={handleClick}>
            <Popover
                style={{
                    borderLeft: `10px solid ${restProps.data.color}`,
                }}
                content={
                    <div>
                        <p>
                            <FileOutlined /> {restProps.data.libelle_tache}
                        </p>
                        {restProps.data.first_name !== null &&
                            restProps.data.first_name !== undefined ? (
                            <p style={{ marginBottom: 0 }}>
                                <UserOutlined /> {restProps.data.first_name}
                            </p>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <ProjectOutlined /> {restProps.data.libelle_projet}
                    </>
                }
            >
                <div
                    style={{
                        height: "100%",
                        padding: 3,
                        borderLeft: `10px solid ${restProps.data.color}`,
                    }}
                >
                    <Avatar size="small" style={{ float: "left" }}>
                        {makeAvatar(restProps.data.first_name)}
                    </Avatar>
                    {children}
                </div>
            </Popover>
        </Appointments.Appointment>
    );

    return (
        <Paper
            style={{
                marginTop: 10,
                marginBottom: 10,
            }}
        >
            <Scheduler
                data={tasks}
                height={455}
                locale="fr-FR"
                firstDayOfWeek={1}
                onClick={handleClick}
            >
                <ViewState />
                <MonthView />
                <Appointments appointmentComponent={Appointment} />
                <Toolbar />
                <DateNavigator />
            </Scheduler>
        </Paper>
    );
};

export default Demo;
