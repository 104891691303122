export const taskConstants = {

    FILTER_DATE_IN_CHART: "FILTER_DATE_IN_CHART",
    RESET_FILTER_DATE_IN_CHART: "RESET_FILTER_DATE_IN_CHART",

    GET_CHART_EA_PROJECT_REQUEST: "GET_CHART_EA_PROJECT_REQUEST",
    GET_CHART_EA_PROJECT_SUCCESS: "GET_CHART_EA_PROJECT_SUCCESS",
    GET_CHART_EA_PROJECT_ERROR: "GET_CHART_EA_PROJECT_ERROR",

    GET_CHART_CH_USERS_REQUEST: "GET_CHART_CH_USERS_REQUEST",
    GET_CHART_CH_USERS_SUCCESS: "GET_CHART_CH_USERS_SUCCESS",
    GET_CHART_CH_USERS_ERROR: "GET_CHART_CH_USERS_ERROR",

    GET_DATA_CHART_USERS_REQUEST: "GET_DATA_CHART_USERS_REQUEST",
    GET_DATA_CHART_USERS_SUCCESS: "GET_DATA_CHART_USERS_SUCCESS",
    GET_DATA_CHART_USERS_ERROR: "GET_DATA_CHART_USERS_ERROR",

    GET_DATA_CHART_EQUIPE_REQUEST: "GET_DATA_CHART_EQUIPE_REQUEST",
    GET_DATA_CHART_EQUIPE_SUCCESS: "GET_DATA_CHART_EQUIPE_SUCCESS",
    GET_DATA_CHART_EQUIPE_ERROR: "GET_DATA_CHART_EQUIPE_ERROR",

    GET_DATA_CHART_EA_TEAM_REQUEST: "GET_DATA_CHART_EA_TEAM_REQUEST",
    GET_DATA_CHART_EA_TEAM_SUCCESS: "GET_DATA_CHART_EA_TEAM_SUCCESS",
    GET_DATA_CHART_EA_TEAM_ERROR: "GET_DATA_CHART_EA_TEAM_ERROR",

    GET_DATA_CHART_CH_TEAM_REQUEST: "GET_DATA_CHART_CH_TEAM_REQUEST",
    GET_DATA_CHART_CH_TEAM_SUCCESS: "GET_DATA_CHART_CH_TEAM_SUCCESS",
    GET_DATA_CHART_CH_TEAM_ERROR: "GET_DATA_CHART_CH_TEAM_ERROR",

    GET_DATA_CHART_ALL_TEAM_REQUEST: "GET_DATA_CHART_ALL_TEAM_REQUEST",
    GET_DATA_CHART_ALL_TEAM_SUCCESS: "GET_DATA_CHART_ALL_TEAM_SUCCESS",
    GET_DATA_CHART_ALL_TEAM_ERROR: "GET_DATA_CHART_ALL_TEAM_ERROR",

    GET_DATA_CHART_DETAIL_REQUEST: "GET_DATA_CHART_DETAIL_REQUEST",
    GET_DATA_CHART_DETAIL_SUCCESS: "GET_DATA_CHART_DETAIL_SUCCESS",
    GET_DATA_CHART_DETAIL_ERROR: "GET_DATA_CHART_DETAIL_ERROR",

    GET_DATA_CHART_REQUEST: "GET_DATA_CHART_REQUEST",
    GET_DATA_CHART_SUCCESS: "GET_DATA_CHART_SUCCESS",
    GET_DATA_CHART_ERROR: "GET_DATA_CHART_ERROR",

    ADD_TASK_REQUEST: "ADD_TASK_REQUEST",
    ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
    ADD_TASK_ERROR: "ADD_TASK_ERROR",

    GETALL_REQUEST: "GETALL_TASK_REQUEST",
    GETALL_SUCCESS: "GETALL_TASK_SUCCESS",
    GETALL_ERROR: "GETALL_TASK_ERROR",

    DROP_TASK_CLIENT: "DROP_TASK_CLIENT",

    GETALL_TASK_OF_CUSTOMER_REQUEST: "GETALL_TASK_OF_CUSTOMER_REQUEST",
    GETALL_TASK_OF_CUSTOMER_SUCCESS: "GETALL_TASK_OF_CUSTOMER_SUCCESS",
    GETALL_TASK_OF_CUSTOMER_FAILURE: "GETALL_TASK_OF_CUSTOMER_FAILURE",

    UPDATE_REQUEST: "UPDATE_TASK_REQUEST",
    UPDATE_SUCCESS: "UPDATE_TASK_SUCCESS",
    UPDATE_FAILURE: "UPDATE_TASK_FAILURE",

    SELECTED_TASK_SUCCESS: "SELECTED_TASK_SUCCESS",

    GETALL_TASK_LOG_REQUEST: "GET_TASK_LOG_REQUEST",
    GETALL_TASK_LOG_SUCCESS: "GET_TASK_LOG_SUCCESS",
    GETALL_TASK_LOG_FAILURE: "GET_TASK_LOG_FAILURE",

    ADD_TASK_LOG_REQUEST: "ADD_TASK_LOG_REQUEST",
    ADD_TASK_LOG_SUCCESS: "ADD_TASK_LOG_SUCCESS",
    ADD_TASK_LOG_FAILURE: "ADD_TASK_LOG_FAILURE",

    GETALL_UNDER_TASK_REQUEST: "GETALL_UNDER_TASK_REQUEST",
    GETALL_UNDER_TASK_SUCCESS: "GETALL_UNDER_TASK_SUCCESS",
    GETALL_UNDER_TASK_FAILURE: "GETALL_UNDER_TASK_FAILURE",

    ADD_UNDER_TASK_REQUEST: "ADD_UNDER_TASK_REQUEST",
    ADD_UNDER_TASK_SUCCESS: "ADD_UNDER_TASK_SUCCESS",
    ADD_UNDER_TASK_FAILURE: "ADD_UNDER_TASK_FAILURE",

    UPDATE_UNDER_TASK_REQUEST: "UPDATE_UNDER_TASK_REQUEST",
    UPDATE_UNDER_TASK_SUCCESS: "UPDATE_UNDER_TASK_SUCCESS",
    UPDATE_UNDER_TASK_FAILURE: "UPDATE_UNDER_TASK_FAILURE",

    GETALL_USER_TASK_REQUEST: "GETALL_USER_TASK_REQUEST",
    GETALL_USER_TASK_SUCCESS: "GETALL_USER_TASK_SUCCESS",
    GETALL_USER_TASK_FAILURE: "GETALL_USER_TASK_FAILURE",

    DELETE_UNDER_TASK_REQUEST: "DELETE_UNDER_TASK_REQUEST",
    DELETE_UNDER_TASK_SUCCESS: "DELETE_UNDER_TASK_SUCCESS",
    DELETE_UNDER_TASK_FAILURE: "DELETE_UNDER_TASK_FAILURE",

    RESET_TASK_LOGS: "RESET_TASK_LOGS",
    RESET_UNDER_TASK: "RESET_UNDER_TASK",
    RESET_CHART_DATA_EQUIPE: "RESET_CHART_DATA_EQUIPE",

    GETALL_PLANING_TASK_OF_TEAM_REQUEST: "GETALL_PLANING_TASK_OF_TEAM_REQUEST",
    GETALL_PLANING_TASK_OF_TEAM_SUCCESS: "GETALL_PLANING_TASK_OF_TEAM_SUCCESS",
    GETALL_PLANING_TASK_OF_TEAM_FAILURE: "GETALL_PLANING_TASK_OF_TEAM_FAILURE",

    GETALL_RECENTLY_TASK_REQUEST: "GETALL_RECENTLY_TASK_REQUEST",
    GETALL_RECENTLY_TASK_SUCCESS: "GETALL_RECENTLY_TASK_SUCCESS",
    GETALL_RECENTLY_TASK_FAILURE: "GETALL_RECENTLY_TASK_FAILURE",

    GETALL_PLANING_TASK_OF_USER_REQUEST: "GETALL_PLANING_TASK_OF_USER_REQUEST",
    GETALL_PLANING_TASK_OF_USER_SUCCESS: "GETALL_PLANING_TASK_OF_USER_SUCCESS",
    GETALL_PLANING_TASK_OF_USER_FAILURE: "GETALL_PLANING_TASK_OF_USER_FAILURE",

    GETALL_PLANING_TASK_OF_PROJECT_REQUEST: "GETALL_PLANING_TASK_OF_PROJECT_REQUEST",
    GETALL_PLANING_TASK_OF_PROJECT_SUCCESS: "GETALL_PLANING_TASK_OF_PROJECT_SUCCESS",
    GETALL_PLANING_TASK_OF_PROJECT_FAILURE: "GETALL_PLANING_TASK_OF_PROJECT_FAILURE",

    GENERATE_TASK_FROM_TICKET_REQUEST: "GENERATE_TASK_FROM_TICKET_REQUEST",
    GENERATE_TASK_FROM_TICKET_SUCCESS: "GENERATE_TASK_FROM_TICKET_SUCCESS",
    GENERATE_TASK_FROM_TICKET_FAILURE: "GENERATE_TASK_FROM_TICKET_FAILURE",

    GET_FILTERE_REQUEST: "GET_FILTERE_REQUEST",
    GET_FILTERE_SUCCESS: "GET_FILTERE_SUCCESS",
    GET_FILTERE_FAILURE: "GET_FILTERE_FAILURE",

    RESET_FILTERED: "RESET_FILTERED",

    UPDATE_TASK_FILTERE: "UPDATE_TASK_FILTERE",
};
