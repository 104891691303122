import React, { useState, useEffect } from "react";
import "./Equipes.css";
import { Row, Col, Card, Divider, Button, Spin, Typography } from "antd";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ModalEquipe from "../../../components/Modal/ModalEquipe";
import ModalParametreEquipe from "../../../components/Modal/ModalParametreEquipe";
import { useSelector } from "react-redux";
import {
    modalActions,
    team_menberActions,
    team_projectActions,
    userActions,
    projectActions,
    teamActions,
} from "../../../../redux/_actions";
import { Scrollbars } from "react-custom-scrollbars";
import ModalMenbreEquipe from "../../../components/Modal/ModalMenbreEquipe";
import ModalAjouterProjetEquipe from "../../../components/Modal/ModalAjouterProjetEquipe";
import ModalAffecterProjetEquipe from "../../../components/Modal/ModalAffecterProjetEquipe";
import EquipeCard from "../../../components/Equipe/EquipeCard";
import VoirToutesLesEquipes from "../../../components/Drawer/VoirToutesLesEquipes";
import Membre from "../../../components/Membre/Membre";
import Projet from "../../../components/Projet/Projet";
import BlankCard from "../../../components/Card/BlankCard";
import Container from "../../../components/Container/Container";
import VoirTousLesProjets from "../../../components/Drawer/VoirTousLesProjets";
import ConfigApp from "../ConfigApp";
import defineAbilityFor from "../../../../config/defineAbility";
import { Can } from "../../../../config/Can";
import ModalAjouterProjet from "../../../components/Modal/ModalAjouterProjet";
import HeaderApp from "../../../parchalse/HeaderApp";
import MembreNew from "../../../components/Membre/MembreNew";

const { Text, Title } = Typography;

const gridStyle = {
    width: "22%",
    textAlign: "center",
    height: 120,
    margin: 9,
    padding: 5,
    paddingTop: 20,
    borderRadius: 3,
    cursor: "pointer",
};
const Equipes = () => {
    const [updatedEquipe, setUpdatedEquipe] = useState({});
    const [teamSelected, setTeamSelected] = useState(0);
    const [search, setSearch] = useState("");
    const [projectSelected, setProjectSelected] = useState({});

    const teams = useSelector((state) => state.team.items);
    const team_projects = useSelector(
        (state) => state.team_project.team_projects
    );
    const loadPro = useSelector((state) => state.team_project.loading);
    const loading = useSelector((state) => state.project.loading);
    const project_without_team = useSelector(
        (state) => state.project.project_without_team
    );

    const dispatch = useDispatch();

    const user = useSelector((state) => state.login);
    const selectedTeamState = useSelector((state) => state.team.selectedTeam);

    const ability = defineAbilityFor(user);

    const openDrawerShowMore = () => {
        setSearch("");
        dispatch(modalActions.show_more_team());
    };

    const selectedTeam = (value) => {
        dispatch(team_menberActions.getAllTeamMenber({ id_equipe: value }));
        setTeamSelected(value);
    };


    useEffect(() => {
        setUpdatedEquipe(
            teams.find((p) => p.id_equipe === selectedTeamState)
        );
    }, [selectedTeamState])

    useEffect(() => {
        document.title = "2iProject-Équipes";
        if (teams[0] !== undefined && teams[0].id_equipe !== undefined) {
            if (selectedTeamState === null) {
                setTeamSelected(teams[0].id_equipe);
                setUpdatedEquipe(teams[0]);
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: teams[0].id_equipe,
                    })
                );
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: teams[0].id_equipe,
                    })
                );
            } else {
                setTeamSelected(selectedTeamState);
                setUpdatedEquipe(
                    teams.find((p) => p.id_equipe === selectedTeamState)
                );
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: selectedTeamState,
                    })
                );
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: selectedTeamState,
                    })
                );
            }
        }
        dispatch(projectActions.getAllProjectWithoutTeam());
        dispatch(team_projectActions.resetTeamProject());
        dispatch(userActions.userNotification(user.item.id_user));
        dispatch(modalActions.close_more_team());
        dispatch(modalActions.close_more_project());
    }, [teams, dispatch, user.item.id_user, selectedTeamState]);

    const showModalAddTeamMember = () => {
        dispatch(userActions.getUserWithoutTeam());
        dispatch(modalActions.show_add_team_menber());
    };

    const showModalAddTeam = () => {
        dispatch(userActions.getAllChiefWithoutTeam());
        dispatch(modalActions.show_add_team());
    };

    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const loadingTeamMembers = useSelector(
        (state) => state.team_menber.loading
    );

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: "50",
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    height: 129,
                }}
            >
                <ConfigApp />
            </div>
            <div
                style={{
                    flex: 1,
                    paddingTop: 18,
                    paddingRight: 18,
                    paddingLeft: 18,
                    paddingBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        flex: 2,
                        marginRight: 9,
                        backgroundColor: "white",
                        padding: 5,
                        height: "100%",
                    }}
                >
                    <Text>Équipes </Text>
                    <Divider
                        style={{
                            margin: "10px 0",
                        }}
                    />
                    <Can I="AjouterEquipe" a="Equipes" ability={ability}>
                        <Card
                            style={{
                                width: "100%",
                                height: 48,
                                marginTop: 5,
                                padding: 5,
                                borderRadius: 10,
                                textAlign: "center",
                                backgroundColor: "#dedede",
                                border: "2px dashed black",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            bodyStyle={{
                                padding: 10,
                            }}
                            hoverable={true}
                            onClick={() => showModalAddTeam()}
                        >
                            <PlusOutlined />
                        </Card>
                    </Can>
                    {teams
                        .sort(function (a, b) {
                            if (a.id_equipe < b.libelle) {
                                return -1;
                            }
                            if (a.id_equipe > b.libelle) {
                                return 1;
                            }
                            return 0;
                        })
                        .slice(0, 5)
                        .map((p) => (
                            <EquipeCard
                                p={p}
                                teamSelected={teamSelected}
                                setTeamSelected={setTeamSelected}
                                selectedTeam={selectedTeam}
                                setUpdatedEquipe={setUpdatedEquipe}
                            />
                        ))}
                    {teams.length > 5 ? (
                        <Button
                            style={{
                                width: "100%",
                                borderRadius: 5,
                                marginTop: 5,
                            }}
                            onClick={() => openDrawerShowMore()}
                        >
                            Voir plus
                        </Button>
                    ) : null}
                    <Card
                        style={{
                            width: "100%",
                            marginTop: 5,
                            padding: 5,
                            borderRadius: 10,
                            textAlign: "center",
                            backgroundColor: "#dedede",
                            border: "2px dashed black",
                        }}
                        bodyStyle={{
                            padding: 10,
                        }}
                        hoverable={true}
                        onClick={() => {
                            setTeamSelected(0);
                            dispatch(projectActions.getAllProjectWithoutTeam());
                        }}
                    >
                        Autre
                    </Card>
                    <VoirToutesLesEquipes
                        teamSelected={teamSelected}
                        setTeamSelected={setTeamSelected}
                        selectedTeam={selectedTeam}
                        setSearch={setSearch}
                        search={search}
                        setUpdatedEquipe={setUpdatedEquipe}
                    />
                </div>
                <div
                    style={{
                        flex: 8,
                        backgroundColor: "white",
                        marginLeft: 9,
                        padding: 5,
                        display: "flex",
                        flexDirection: "row",
                        height: "70vh",
                        minHeight: 447,
                    }}
                >
                    {teamSelected !== 0 ? (
                        <div
                            style={{
                                flex: 3,
                                marginRight: 18,
                                borderRight: "1px solid #dcdcdc",
                                paddingRight: 5,
                            }}
                        >
                            <Can I="LireMembre" a="Equipes" ability={ability}>
                                <>
                                    <Text>Membres</Text>
                                    <Divider
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 15,
                                        }}
                                    />
                                    <Can
                                        I="AjouterMembre"
                                        a="Equipes"
                                        ability={ability}
                                    >
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                marginBottom: 5,
                                                border: "2px dashed black",
                                                borderRadius: 15,
                                                padding: 5,
                                                backgroundColor: "#DEDEDE",
                                            }}
                                            onClick={() =>
                                                showModalAddTeamMember()
                                            }
                                        >
                                            <PlusOutlined
                                                style={{
                                                    textAlign: "center",
                                                    border: "2px dashed black",
                                                    height: 35,
                                                    width: 35,
                                                    borderRadius: 15,
                                                    padding: 10,
                                                }}
                                            />{" "}
                                            <span
                                                style={{
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <Text>Ajouter un membre</Text>
                                            </span>
                                        </div>
                                    </Can>
                                    {/* <Membre teamSelected={teamSelected} /> */}
                                    <Spin
                                        tip="Chargement en cours..."
                                        spinning={loadingTeamMembers}
                                    >
                                        <Scrollbars
                                            autoHide
                                            style={{
                                                background: "white",
                                                height: "52vh",
                                            }}
                                            className="membre-mes-project"
                                        >
                                            {team_menbers.map((p) => (
                                                <MembreNew
                                                    p={p}
                                                    teamSelected={teamSelected}
                                                />
                                            ))}
                                        </Scrollbars>
                                    </Spin>
                                </>
                            </Can>
                        </div>
                    ) : null}
                    <div
                        style={{
                            flex: 8,
                        }}
                    >
                        <Can I="LireProjet" a="Equipes" ability={ability}>
                            <Text>Projets</Text>
                            <Divider
                                style={{
                                    margin: "10px 0",
                                    marginTop: 1,
                                }}
                            />
                            <Spin
                                tip="Chargement en cours..."
                                size="large"
                                spinning={loadPro}
                            >
                                <div>
                                    {teamSelected !== 0 ? (
                                        <>
                                            <Can
                                                I="AjouterProjet"
                                                a="Equipes"
                                                ability={ability}
                                            >
                                                <Card.Grid
                                                    hoverable={false}
                                                    className="ajouter"
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_add_team_project()
                                                        )
                                                    }
                                                >
                                                    <PlusOutlined />
                                                </Card.Grid>
                                            </Can>
                                            <Can
                                                I="AffecterProjet"
                                                a="Equipes"
                                                ability={ability}
                                            >
                                                <Card.Grid
                                                    hoverable={false}
                                                    className="affecter"
                                                    onClick={() => {
                                                        dispatch(
                                                            projectActions.getAllProjectWithoutTeam()
                                                        );
                                                        dispatch(
                                                            modalActions.show_affect_team_project()
                                                        );
                                                    }}
                                                >
                                                    <LinkOutlined />
                                                </Card.Grid>
                                            </Can>
                                        </>
                                    ) : (
                                        <Card.Grid
                                            hoverable={false}
                                            className="ajouterSimple"
                                            onClick={() =>
                                                dispatch(
                                                    modalActions.show_add_allproject()
                                                )
                                            }
                                        >
                                            <PlusOutlined />
                                        </Card.Grid>
                                    )}

                                    {teamSelected !== 0 ? (
                                        <>
                                            {team_projects
                                                .slice(0, 11)
                                                .map((p) => (
                                                    <Projet
                                                        p={p}
                                                        style={gridStyle}
                                                        setProjectSelected={
                                                            setProjectSelected
                                                        }
                                                    />
                                                ))}
                                            {team_projects.length > 11 ? (
                                                <Button
                                                    style={{
                                                        transform:
                                                            "rotate(90deg)",
                                                        position: "absolute",
                                                        top: 180,
                                                        right: -40,
                                                        display: "inline-block",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_more_project()
                                                        )
                                                    }
                                                >
                                                    Voir plus
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            {project_without_team
                                                .slice(0, 11)
                                                .map((p) => (
                                                    <Projet
                                                        p={p}
                                                        style={gridStyle}
                                                        setProjectSelected={
                                                            setProjectSelected
                                                        }
                                                    />
                                                ))}
                                            {project_without_team.length >
                                                11 ? (
                                                <Button
                                                    style={{
                                                        transform:
                                                            "rotate(90deg)",
                                                        position: "absolute",
                                                        top: 180,
                                                        right: -40,
                                                        display: "inline-block",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_more_project()
                                                        )
                                                    }
                                                >
                                                    Voir plus
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </Spin>
                        </Can>
                    </div>
                    {/* 
                    <div
                        style={{
                            flex: 8,
                        }}
                    >
                        <Can I="LireProjet" a="Equipes" ability={ability}>
                            <Text>Projets</Text>
                            <Divider
                                style={{
                                    margin: "10px 0",
                                }}
                            />
                            <Spin
                                tip="Loading..."
                                size="large"
                                spinning={loadPro}
                            >
                                <div>
                                    {teamSelected !== 0 ? (
                                        <>
                                            <Can
                                                I="AjouterProjet"
                                                a="Equipes"
                                                ability={ability}
                                            >
                                                <Card.Grid
                                                    hoverable={false}
                                                    className="ajouter"
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_add_team_project()
                                                        )
                                                    }
                                                >
                                                    <PlusOutlined />
                                                </Card.Grid>
                                            </Can>
                                            <Can
                                                I="AffecterProjet"
                                                a="Equipes"
                                                ability={ability}
                                            >
                                                <Card.Grid
                                                    hoverable={false}
                                                    className="affecter"
                                                    onClick={() => {
                                                        dispatch(
                                                            projectActions.getAllProjectWithoutTeam()
                                                        );
                                                        dispatch(
                                                            modalActions.show_affect_team_project()
                                                        );
                                                    }}
                                                >
                                                    <LinkOutlined />
                                                </Card.Grid>
                                            </Can>
                                        </>
                                    ) : (
                                        <Card.Grid
                                            hoverable={false}
                                            className="ajouterSimple"
                                            onClick={() =>
                                                dispatch(
                                                    modalActions.show_add_allproject()
                                                )
                                            }
                                        >
                                            <PlusOutlined />
                                        </Card.Grid>
                                    )}

                                    {teamSelected !== 0 ? (
                                        <>
                                            {team_projects
                                                .slice(0, 11)
                                                .map((p) => (
                                                    <Projet
                                                        p={p}
                                                        style={gridStyle}
                                                        setProjectSelected={
                                                            setProjectSelected
                                                        }
                                                    />
                                                ))}
                                            {team_projects.length > 11 ? (
                                                <Button
                                                    style={{
                                                        transform:
                                                            "rotate(90deg)",
                                                        position: "absolute",
                                                        top: 180,
                                                        right: -40,
                                                        display: "inline-block",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_more_project()
                                                        )
                                                    }
                                                >
                                                    Voir plus
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            {project_without_team
                                                .slice(0, 11)
                                                .map((p) => (
                                                    <Projet
                                                        p={p}
                                                        style={gridStyle}
                                                        setProjectSelected={
                                                            setProjectSelected
                                                        }
                                                    />
                                                ))}
                                            {project_without_team.length >
                                            11 ? (
                                                <Button
                                                    style={{
                                                        transform:
                                                            "rotate(90deg)",
                                                        position: "absolute",
                                                        top: 180,
                                                        right: -40,
                                                        display: "inline-block",
                                                    }}
                                                    onClick={() =>
                                                        dispatch(
                                                            modalActions.show_more_project()
                                                        )
                                                    }
                                                >
                                                    Voir plus
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </Spin>
                        </Can>
                    </div> */}
                </div>
            </div>
            <ModalEquipe />
            <ModalParametreEquipe updatedEquipe={updatedEquipe} selectedTeamState={selectedTeamState} />
            <ModalMenbreEquipe teamSelected={teamSelected} />
            <ModalAjouterProjetEquipe
                teamSelected={teamSelected}
                projectSelected={projectSelected}
                setProjectSelected={setProjectSelected}
            />
            <ModalAffecterProjetEquipe teamSelected={teamSelected} />
            <VoirTousLesProjets
                teamSelected={teamSelected}
                setProjectSelected={setProjectSelected}
            />
            <ModalAjouterProjet
                projectSelected={projectSelected}
                setProjectSelected={setProjectSelected}
            />
            {/* <ConfigApp />
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Row gutter={18}>
                    <Can I="LireEquipe" a="Equipes" ability={ability}>
                        <Col
                            sm={4}
                            md={4}
                            xl={4}
                            style={{
                                paddingRight: 0,
                            }}
                        >
                            <div
                                className="equipes"
                                style={{
                                    border: "2px solid white",
                                    padding: 5,
                                    backgroundColor: "white",
                                    height: 500,
                                }}
                            >
                                <Text>Équipes </Text>
                                <Divider
                                    style={{
                                        margin: "10px 0",
                                    }}
                                />
                                <Can
                                    I="AjouterEquipe"
                                    a="Equipes"
                                    ability={ability}
                                >
                                    <Card
                                        style={{
                                            width: "100%",
                                            marginTop: 5,
                                            padding: 5,
                                            borderRadius: 10,
                                            textAlign: "center",
                                            backgroundColor: "#dedede",
                                            border: "2px dashed black",
                                        }}
                                        bodyStyle={{
                                            padding: 10,
                                        }}
                                        hoverable={true}
                                        onClick={() => showModalAddTeam()}
                                    >
                                        <PlusOutlined />
                                    </Card>
                                </Can>
                                {teams
                                    .sort(function (a, b) {
                                        if (a.id_equipe < b.libelle) {
                                            return -1;
                                        }
                                        if (a.id_equipe > b.libelle) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .slice(0, 4)
                                    .map((p) => (
                                        <EquipeCard
                                            p={p}
                                            teamSelected={teamSelected}
                                            setTeamSelected={setTeamSelected}
                                            selectedTeam={selectedTeam}
                                            setUpdatedEquipe={setUpdatedEquipe}
                                        />
                                    ))}
                                {teams.length > 4 ? (
                                    <Button
                                        style={{
                                            width: "100%",
                                            borderRadius: 5,
                                            marginTop: 10,
                                        }}
                                        onClick={() => openDrawerShowMore()}
                                    >
                                        Voir plus
                                    </Button>
                                ) : null}
                                <Card
                                    style={{
                                        width: "100%",
                                        marginTop: 10,
                                        padding: 5,
                                        borderRadius: 10,
                                        textAlign: "center",
                                        backgroundColor: "#dedede",
                                        border: "2px dashed black",
                                    }}
                                    bodyStyle={{
                                        padding: 10,
                                    }}
                                    hoverable={true}
                                    onClick={() => {
                                        setTeamSelected(0);
                                        dispatch(
                                            projectActions.getAllProjectWithoutTeam()
                                        );
                                    }}
                                >
                                    Autre
                                </Card>
                                <VoirToutesLesEquipes
                                    teamSelected={teamSelected}
                                    setTeamSelected={setTeamSelected}
                                    selectedTeam={selectedTeam}
                                    setSearch={setSearch}
                                    search={search}
                                    setUpdatedEquipe={setUpdatedEquipe}
                                />
                            </div>
                        </Col>
                    </Can>
                    <Col sm={20} md={20} xl={20}>
                        <BlankCard height={500} marginLeft={9}>
                            <Row gutter={18}>
                                <Can
                                    I="LireMembre"
                                    a="Equipes"
                                    ability={ability}
                                >
                                    {teamSelected !== 0 ? (
                                        <Col
                                            sm={6}
                                            md={6}
                                            xl={6}
                                            style={{
                                                borderRight:
                                                    "1px solid #f0f0f0",
                                            }}
                                        >
                                            <Text>Membres</Text>
                                            <Divider
                                                style={{
                                                    margin: "10px 0",
                                                }}
                                            />
                                            <Can
                                                I="AjouterMembre"
                                                a="Equipes"
                                                ability={ability}
                                            >
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        marginBottom: 10,
                                                        border:
                                                            "2px dashed black",
                                                        borderRadius: 15,
                                                        padding: 5,
                                                        backgroundColor:
                                                            "#DEDEDE",
                                                    }}
                                                    onClick={() =>
                                                        showModalAddTeamMember()
                                                    }
                                                >
                                                    <PlusOutlined
                                                        style={{
                                                            textAlign: "center",
                                                            border:
                                                                "2px dashed black",
                                                            height: 35,
                                                            width: 35,
                                                            borderRadius: 15,
                                                            padding: 10
                                                        }}
                                                    />{" "}
                                                    <span
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <Text>
                                                            Ajouter un membre
                                                        </Text>
                                                    </span>
                                                </div>
                                            </Can>

                                            <Scrollbars
                                                style={{
                                                    height: 360,
                                                }}
                                            >
                                                <Membre
                                                    teamSelected={teamSelected}
                                                />
                                            </Scrollbars>
                                        </Col>
                                    ) : null}
                                </Can>
                                <Can
                                    I="LireProjet"
                                    a="Equipes"
                                    ability={ability}
                                >
                                    <Col
                                        sm={teamSelected !== 0 ? 18 : 24}
                                        md={teamSelected !== 0 ? 18 : 24}
                                        xl={teamSelected !== 0 ? 18 : 24}
                                    >
                                        <Text>Projets</Text>
                                        <Divider
                                            style={{
                                                margin: "10px 0",
                                            }}
                                        />
                                        <Spin
                                            tip="Loading..."
                                            size="large"
                                            spinning={loadPro}
                                        >
                                            <div>
                                                {teamSelected !== 0 ? (
                                                    <>
                                                        <Can
                                                            I="AjouterProjet"
                                                            a="Equipes"
                                                            ability={ability}
                                                        >
                                                            <Card.Grid
                                                                hoverable={
                                                                    false
                                                                }
                                                                className="ajouter"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        modalActions.show_add_team_project()
                                                                    )
                                                                }
                                                            >
                                                                <PlusOutlined />
                                                            </Card.Grid>
                                                        </Can>
                                                        <Can
                                                            I="AffecterProjet"
                                                            a="Equipes"
                                                            ability={ability}
                                                        >
                                                            <Card.Grid
                                                                hoverable={
                                                                    false
                                                                }
                                                                className="affecter"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        projectActions.getAllProjectWithoutTeam()
                                                                    );
                                                                    dispatch(
                                                                        modalActions.show_affect_team_project()
                                                                    );
                                                                }}
                                                            >
                                                                <LinkOutlined />
                                                            </Card.Grid>
                                                        </Can>
                                                    </>
                                                ) : (
                                                    <Card.Grid
                                                        hoverable={false}
                                                        className="ajouterSimple"
                                                        onClick={() =>
                                                            dispatch(
                                                                modalActions.show_add_allproject()
                                                            )
                                                        }
                                                    >
                                                        <PlusOutlined />
                                                    </Card.Grid>
                                                )}

                                                {teamSelected !== 0 ? (
                                                    <>
                                                        {team_projects
                                                            .slice(0, 11)
                                                            .map((p) => (
                                                                <Projet
                                                                    p={p}
                                                                    style={
                                                                        gridStyle
                                                                    }
                                                                    setProjectSelected={
                                                                        setProjectSelected
                                                                    }
                                                                />
                                                            ))}
                                                        {team_projects.length >
                                                        11 ? (
                                                            <Button
                                                                style={{
                                                                    transform:
                                                                        "rotate(90deg)",
                                                                    position:
                                                                        "absolute",
                                                                    top: 180,
                                                                    right: -40,
                                                                    display:
                                                                        "inline-block",
                                                                }}
                                                                onClick={() =>
                                                                    dispatch(
                                                                        modalActions.show_more_project()
                                                                    )
                                                                }
                                                            >
                                                                Voir plus
                                                            </Button>
                                                        ) : null}
                                                    </>
                                                ) : (
                                                    <>
                                                        {project_without_team
                                                            .slice(0, 11)
                                                            .map((p) => (
                                                                <Projet
                                                                    p={p}
                                                                    style={
                                                                        gridStyle
                                                                    }
                                                                    setProjectSelected={
                                                                        setProjectSelected
                                                                    }
                                                                />
                                                            ))}
                                                        {project_without_team.length >
                                                        11 ? (
                                                            <Button
                                                                style={{
                                                                    transform:
                                                                        "rotate(90deg)",
                                                                    position:
                                                                        "absolute",
                                                                    top: 180,
                                                                    right: -40,
                                                                    display:
                                                                        "inline-block",
                                                                }}
                                                                onClick={() =>
                                                                    dispatch(
                                                                        modalActions.show_more_project()
                                                                    )
                                                                }
                                                            >
                                                                Voir plus
                                                            </Button>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        </Spin>
                                    </Col>
                                </Can>
                            </Row>
                        </BlankCard>
                    </Col>
                </Row>
                <ModalEquipe />
                <ModalParametreEquipe updatedEquipe={updatedEquipe} />
                <ModalMenbreEquipe teamSelected={teamSelected} />
                <ModalAjouterProjetEquipe
                    teamSelected={teamSelected}
                    projectSelected={projectSelected}
                    setProjectSelected={setProjectSelected}
                />
                <ModalAffecterProjetEquipe teamSelected={teamSelected} />
                <VoirTousLesProjets
                    teamSelected={teamSelected}
                    setProjectSelected={setProjectSelected}
                />
                <ModalAjouterProjet
                    projectSelected={projectSelected}
                    setProjectSelected={setProjectSelected}
                />
            </div> */}
        </div>
    );
};

export default Equipes;
