import axios from "../../axios/index";

export const customerServices = {
    getAll,
    add,
    update,
    remove
}

async function getAll () {
    return await axios.get(`clients`);
}

async function add (customer) {
    return await axios.post(`clients`, customer);
}

async function update (customer) {
    return await axios.put(`clients/${customer.id_client}`, customer)
}

async function remove (customer) {
    return await axios.get(`clients/${customer}`);
}