import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Spin,
    Popover,
    Avatar,
} from "antd"
import Paper from "@material-ui/core/Paper";
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";

import {
    projectActions,
    taskActions,
    userActions,
    modalActions
} from "../../../redux/_actions";
import { makeAvatar } from "../../helpers/makeAvatar";

import {
    FileOutlined,
    UserOutlined,
    ProjectOutlined,
} from "@ant-design/icons"
import { useEffect } from 'react';


const CustomerPlanning = () => {

    const loadingTask = useSelector((state) => state.task.loading);
    const tasks = useSelector((state) => state.task.allTeamPlaningTask);
    const customerChoose = useSelector((state) => state.customer.item_choose);
    const customer_task = useSelector((state) => state.task.items_customer);

    const dispatch = useDispatch();

    useEffect(() => {
        if (Object.keys(customerChoose).length > 0) {
            dispatch(taskActions.getAllTaskForCustomer(customerChoose.id_client));
        }
    }, [customerChoose]);

    const openPlan = (obj) => {
        dispatch(projectActions.selectedProjectTask(obj.data));
        dispatch(taskActions.getAllTaskLogs(parseInt(obj.data.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(obj.data.id_tache)));
        dispatch(userActions.getAllMemberProject(parseInt(obj.data.id_projet)));
        setTimeout(() => {
            dispatch(modalActions.show_detail_task());
        }, 100);
    };

    const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment {...restProps} onClick={openPlan}>
            <Popover
                style={{
                    borderLeft: `10px solid ${restProps.data.color}`,
                }}
                content={
                    <div>
                        <p>
                            <FileOutlined /> {restProps.data.libelle_tache}
                        </p>
                        {restProps.data.first_name !== null &&
                            restProps.data.first_name !== undefined ? (
                            <p style={{ marginBottom: 0 }}>
                                <UserOutlined /> {restProps.data.first_name}
                            </p>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <ProjectOutlined /> {restProps.data.libelle_projet}
                    </>
                }
            >
                <div
                    style={{
                        height: "100%",
                        padding: 3,
                        borderLeft: `10px solid ${restProps.data.color}`,
                    }}
                >
                    <Avatar size="small" style={{ float: "left" }}>
                        {makeAvatar(restProps.data.first_name)}
                    </Avatar>
                    {children}
                </div>
            </Popover>
        </Appointments.Appointment>
    );

    return (
        <>
            <Spin
                tip="Chargement en cours..."
                spinning={loadingTask}
            >
                <Paper
                    style={{
                        height: "72vh",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <Scheduler
                        data={customer_task}
                        height={550}
                        locale="fr-FR"
                        firstDayOfWeek={1}
                    >
                        <ViewState />
                        <MonthView />
                        <Appointments
                            appointmentComponent={
                                Appointment
                            }
                        />
                        <Toolbar />
                        <DateNavigator />
                    </Scheduler>
                </Paper>
            </Spin>
        </>
    )
}

export default CustomerPlanning