import { userConstants } from "../_constants";

export const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    login_exist: false,
    email_exist: false,
    user_without_team: [],
    user_without_team_and_current_team: [],
    project_member: [],
    chiefs: [],
    item: {},
    error: "",
    notifications: [],
    add: false,
};

export const user = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.user,
                // items: action.user.sort(function (a, b) {
                //     if (a.first_name < b.first_name) {
                //         return -1;
                //     }
                //     if (a.first_name > b.first_name) {
                //         return 1;
                //     }
                //     return 0;
                // }),
            };

        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                add: true,
                items: [...state.items, action.user],
            };

        case userConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_user === action.user.id_user) {
                            p.first_name = action.user.first_name;
                            p.libelle = action.user.libelle;
                            p.login = action.user.login;
                            p.phone = action.user.phone;
                            p.email = action.user.email;
                            p.id_profil = action.user.id_profil;
                        }
                        return p;
                    }),
                ],
                exist: false,
            };

        case userConstants.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.USER_WITHOUT_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.USER_WITHOUT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                user_without_team: action.user,
            };

        case userConstants.USER_WITHOUT_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.USER_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.USER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.user,
            };

        case userConstants.USER_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.USER_OPEN_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.USER_OPEN_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.notification,
            };

        case userConstants.USER_OPEN_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                chiefs: action.user,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_SUCCESS:
            return {
                ...state,
                loading: false,
                user_without_team_and_current_team: action.user,
            };

        case userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.GETALL_PROJECT_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.GETALL_PROJECT_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                project_member: action.user,
            };

        case userConstants.GETALL_PROJECT_MEMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.CHECK_LOGIN_REQUEST:
            return {
                ...state,
                Loading: false,
            };

        case userConstants.CHECK_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                login_exist: action.user,
            };

        case userConstants.CHECK_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.CHECK_LOGIN_UPDATE_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case userConstants.CHECK_LOGIN_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                login_exist: action.user,
            };

        case userConstants.CHECK_LOGIN_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.CHECK_MAIL_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case userConstants.CHECK_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                email_exist: action.user,
            };

        case userConstants.CHECK_MAIL_FAILURE:
            return {
                ...state,
                Loading: false,
                error: action.error,
            };

        case userConstants.CHECK_MAIL_UPDATE_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case userConstants.CHECK_MAIL_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                email_exist: action.user,
            };

        case userConstants.CHECK_MAIL_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.RESET_EXIST:
            return {
                ...state,
                login_exist: false,
                email_exist: false,
                add: false,
            };

        case userConstants.CHANGE_USER_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case userConstants.CHANGE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case userConstants.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter((p) => p.id_user !== action.user),
                ],
            };

        case userConstants.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
