import { jalonConstants } from "../_constants";
import { jalonServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const jalonActions = {
    getAll,
    add,
    update,
    remove
};

function update(jalon) {
    return function (dispatch) {
        dispatch(request());
        jalonServices
            .update(jalon)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: jalonConstants.UPDATE_JALON_REQUEST,
        };
    }
    function success(jalon) {
        return {
            type: jalonConstants.UPDATE_JALON_SUCCESS,
            jalon,
        };
    }
    function failure(error) {
        return {
            type: jalonConstants.UPDATE_JALON_FAILURE,
            error,
        };
    }
}

function add(jalon) {
    return function (dispatch) {
        dispatch(request());
        jalonServices
            .add(jalon)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: jalonConstants.ADD_JALON_REQUEST,
        };
    }
    function success(jalon) {
        return {
            type: jalonConstants.ADD_JALON_SUCCESS,
            jalon,
        };
    }
    function failure(error) {
        return {
            type: jalonConstants.ADD_JALON_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        jalonServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: jalonConstants.GETALL_REQUEST,
        };
    }
    function success(jalon) {
        return {
            type: jalonConstants.GETALL_SUCCESS,
            jalon,
        };
    }
    function failure(error) {
        return {
            type: jalonConstants.GETALL_ERROR,
            error,
        };
    }
}

function remove(id_jalon) {
    return function (dispatch) {
        dispatch(request());
        jalonServices
            .remove(id_jalon)
            .then((res) => {
                dispatch(jalonActions.getAll());
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: jalonConstants.DELETE_JALON_REQUEST,
        };
    }
    function success() {
        return {
            type: jalonConstants.DELETE_JALON_SUCCESS,
        };
    }
    function failure(error) {
        return {
            type: jalonConstants.DELETE_JALON_FAILURE,
            error,
        };
    }
}
