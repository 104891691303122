import { team_menberConstants, team_projectConstants } from "../_constants";

const initialState = {
    loading: false,
    loadingSelect: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    menbers: [],
    allTaskMemberMenu: [],
    allTask_member: [],
    item: {},
    checkedAll: false,
    selectTeamMember: [],
    selectedTeamMembre: [],
    tab: [],
    error: "",
};

export const team_menber = (state = initialState, action) => {
    switch (action.type) {
        case team_menberConstants.GETALL_TEAM_MENBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case team_menberConstants.GETALL_TEAM_MENBER_SUCCESS:
            return {
                ...state,
                loading: false,
                menbers: action.team_menber.sort(function (a, b) {
                    if (a.first_name < b.first_name) {
                        return -1;
                    }
                    if (a.first_name > b.first_name) {
                        return 1;
                    }
                    return 0;
                }),
            };

        case team_menberConstants.GETALL_TEAM_MENBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case team_menberConstants.GETALL_MENBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case team_menberConstants.GETALL_MENBER_SUCCESS:
            return {
                ...state,
                loading: false,
                allTask_member: action.team_menber,
            };

        case team_menberConstants.GETALL_MENBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case team_menberConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case team_menberConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                menbers: [...state.menbers, action.team_menber],
            };

        case team_menberConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case team_menberConstants.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case team_menberConstants.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                menbers: [
                    ...state.menbers.filter(
                        (p) => p.id_equipe_membre !== action.team_menber
                    ),
                ],
            };

        case team_menberConstants.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case team_menberConstants.SELECT_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                loadingSelect: true,
            };

        case team_menberConstants.SELECT_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                selectTeamMember: action.team_menber,
            };

        case team_menberConstants.SELECT_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case team_menberConstants.SELECTED_TEAM_MEMBRE:
            return {
                ...state,
                selectedTeamMembre: action.id,
            };

        case team_menberConstants.RESET_USER_TASK:
            return {
                ...state,
                tab: [],
                allTask_member: [],
            };

        case team_menberConstants.RESET_MEMBERS:
            return {
                ...state,
                menbers: [],
            };

        case team_menberConstants.GETALL_TASK_MEMBER_MENU_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case team_menberConstants.GETALL_TASK_MEMBER_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                allTaskMemberMenu: action.team_menber,
            };

        case team_menberConstants.GETALL_TASK_MEMBER_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
