import axios from "../../axios/index";

export const project_colorServices = {
    add,
    getAll,
    update
};

async function getAll() {
    return await axios.get(`colors`);
}

async function add (color) {
    return await axios.post(`colors`, color)
}

async function update(color) {
    return await axios.put(`colors/${color.id_color}`, color)
}