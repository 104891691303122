export const ticketConstants = {
    FILTER_TICKETS: "FILTER_TICKETS",

    FILTER_TICKET_DATE_REQUEST: "FILTER_TICKET_DATE_REQUEST",
    FILTER_TICKET_DATE_SUCCESS: "FILTER_TICKET_DATE_SUCCESS",
    FILTER_TICKET_DATE_FAILURE: "FILTER_TICKET_DATE_FAILURE",

    GETALL_TICKET_REQUEST: "GETALL_TICKET_REQUEST",
    GETALL_TICKET_SUCCESS: "GETALL_TICKET_SUCCESS",
    GETALL_TICKET_FAILURE: "GETALL_TICKET_FAILURE",

    FILTRER_TICKET: "FILTRER_TICKET",
    RESET_FILTRER_TICKET: "RESET_FILTRER_TICKET",
    RESET_FILTRER_TICKET_FROM_GETALL: "RESET_FILTRER_TICKET_FROM_GETALL",

    FILTRER_TICKET_BY_PROJECT: "FILTRER_TICKET_BY_PROJECT",

    FILTER_FROM_TASK: "FILTER_FROM_TASK",

    FILTRER_TICKET_BY_PROJECT_FROM_GETALL_REQUEST: "FILTRER_TICKET_BY_PROJECT_FROM_GETALL_REQUEST",
    FILTRER_TICKET_BY_PROJECT_FROM_GETALL_SUCCESS: "FILTRER_TICKET_BY_PROJECT_FROM_GETALL_SUCCESS",
    FILTRER_TICKET_BY_PROJECT_FROM_GETALL_FAILURE: "FILTRER_TICKET_BY_PROJECT_FROM_GETALL_FAILURE",
};
