import React from "react";
import {
  Row,
  Col,
  Divider,
  Spin,
  AutoComplete,
  Card,
  Input,
  Pagination,
  Empty,
  Typography,
  Skeleton,
  Popconfirm,
  Avatar,
} from "antd";
import { makeAvatar } from "../../helpers/makeAvatar";
import { DeleteOutlined } from "@ant-design/icons";
import {
  team_menberActions,
  team_projectActions,
} from "../../../redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";

const { Meta } = Card;
const { Title, Text } = Typography;

const MembreNew = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.login.item);
  const user = useSelector((state) => state.login);
  const selectedTeamState = useSelector((state) => state.team.selectedTeam);

  const ability = defineAbilityFor(user);

  function confirm(value) {
    dispatch(team_menberActions.deleteTeamMenber({ id_equipe_membre: value }));
    setTimeout(() => {
      dispatch(
        team_projectActions.getAllTeamProject({
          id_equipe:
            selectedTeamState !== null
              ? selectedTeamState
              : auth.id_equipe !== null
              ? auth.id_equipe
              : props.teamSelected,
        })
      );
    }, 100);
  }

  return (
    <>
      <Card
        style={{
          margin: 5,
          borderRadius: 5,
          backgroundColor: "#f8f8ff",
          minWidth: 200,
        }}
        bodyStyle={{
          padding: 10,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        hoverable={true}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              flex: 1,
              width: 36,
              height: 36,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #252526",
              backgroundColor: "#f8f8ff",
              color: "#252526",
            }}
          >
            {makeAvatar(props.p.first_name)}
          </Avatar>
        </div>
        <div
          style={{
            flex: 7,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            paddingLeft: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <Text strong={true} style={{ fontSize: 13 }}>
              {props.p.first_name.toUpperCase()}
            </Text>
          </div>
          <div style={{ flex: 1, fontSize: 12 }}>
            <Text>{props.p.libelle_profil}</Text>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Can I="RetirerMembre" a="Mes Projets" ability={ability}>
            {props.p.chef_check === false ? (
              <Popconfirm
                placement="top"
                title="Voulez-vous retirer un membre de cette équipe ?"
                onConfirm={() => confirm(props.p.id_equipe_membre)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : null}
          </Can>
        </div>
      </Card>
    </>
  );
};

export default MembreNew;
