import { groupConstants } from '../_constants';

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export const group = (state = initialState, action) => {
    switch(action.type) {
        case groupConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };

        case groupConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.group
            };

        case groupConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}