import React, { useEffect, useState } from "react";
import "./TaskCard.css";
import {
    Card,
    Avatar,
    Typography,
    Popconfirm,
    Menu,
    Dropdown,
    Tooltip,
    Modal,
    Popover,
    DatePicker,
    InputNumber,
    Button
} from "antd";
import {
    CheckCircleTwoTone,
    SwitcherOutlined,
    BulbTwoTone,
    FileImageFilled,
    CalendarOutlined,
    FieldTimeOutlined
} from "@ant-design/icons";
import {
    modalActions,
    taskActions,
    projectActions,
    alertActions,
} from "../../../redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { makeAvatar } from "../../helpers/makeAvatar";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment"
import { isEmpty } from "lodash";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const TaskCard = (props) => {

    const etat_bloque = 1;
    const jalon_initial = 1;

    const dispatch = useDispatch();
    const [trashHover, setTrashHover] = useState(0);
    const [caldrier, setCaldrier] = useState(0);
    const [conf, setConf] = useState(false);
    const [modal, setModal] = useState(false);
    const [duree, setDuree] = useState("");
    const [date, setDate] = useState([]);

    const id_userAuth = useSelector((state) => state.login.item.id_user);
    const info_user = useSelector((state) => state.user.items).filter((p) => p.id_user === parseInt(id_userAuth))[0];
    const jalons = useSelector((state) => state.jalon.items).filter((p) => p.terminate === 1 || p.execute === 1);
    const jalons_items = useSelector((state) => state.jalon.items);
    const demande_cotation = useSelector((state) => state.demande_cotation.items);


    useEffect(() => {
        setDuree(props.item.duree);

        if (props.item.date_debut !== null & props.item.echeance !== null) {

            let tab = [];
            tab.push(moment(props.item.date_debut.split(' ')[0]));
            tab.push(moment(props.item.echeance.split(' ')[0]));

            setDate(tab);

        } else {
            setDate([]);
        }

    }, [props.item.duree, props.item.date_debut, props.item.echeance]);

    const taskOperation = () => {
        if (conf === false) {
            dispatch(modalActions.show_detail_task());
            dispatch(projectActions.selectedProjectTask(props.item));
            dispatch(taskActions.getAllTaskLogs(parseInt(props.item.id)));
            dispatch(taskActions.getAllUnderTask(parseInt(props.item.id)));
        }
    };

    const menuStatut = (
        <Menu
            onClick={(value) => {

                if ((props.item.date_debut !== null & props.item.echeance !== null) & (props.item.duree !== null || info_user.privilege_duree === true)) {
                    dispatch(
                        projectActions.updateProjectTask({
                            id_tache: parseInt(props.item.id),
                            libelle: props.item.libelle_tache,
                            // id_statut: parseInt(value.key),
                            id_projet: props.item.id_projet,
                            id_jalon: parseInt(value.key),
                        })
                    );
                } else {
                    dispatch(alertActions.error("Veuillez renseignez les dates et la durée."));
                }
                setConf(false)
            }}
        >
            {jalons.map((p) => (
                <Menu.Item key={p.id_jalon}>
                    <CheckCircleTwoTone
                        style={{
                            marginRight: 8,
                            cursor: "pointer",
                            zIndex: 1,
                        }}
                        twoToneColor={p.color}
                    />{" "}
                    {p.libelle}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuSuppression = (
        <Menu
        // onClick={(value) =>
        //     dispatch(
        //         projectActions.delete_project_task(parseInt(props.item.id))
        //     )
        // }
        >
            <Menu.Item key="supprimer" danger>
                <Popconfirm
                    placement="top"
                    title="Voulez-vous supprimer cette tâche ?"
                    onConfirm={() => {
                        if (props.item.ordre_jalons === jalon_initial && props.item.id_demande === null) {
                            dispatch(
                                projectActions.delete_project_task(
                                    parseInt(props.item.id)
                                )
                            )
                        } else if (props.item.id_demande !== null) {
                            dispatch(alertActions.error('Suppression échouée, la tâche vient d\'un ticket transformé en cotation'));
                        }
                        else {
                            dispatch(alertActions.error('La tâche doit être dans le premier jalon pour être supprimée'));
                        }
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined /> Supprimer la tâche
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    // update de la durée d'une tâche
    const updateDuree = (id, libelle_tache, duree, id_projet, id_jalon, poids) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                duree: duree,
                id_projet: id_projet,
                id_jalon: id_jalon,
                poids: poids,
            })
        );
    };

    // function de convertion de date 
    function format_date(date) {
        let tab = date.split('/');
        let data = tab[2] + '-' + tab[1] + "-" + tab[0];
        return data;
    }

    // quand on filtre par date 
    function onChange(date, dateString) {

        if (!isEmpty(dateString[0])) {
            let tab = [];
            tab.push(format_date(dateString[0]));
            tab.push(format_date(dateString[1]));

            dispatch(
                projectActions.updateProjectTask({
                    id_tache: props.item.id,
                    libelle: props.item.libelle_tache,
                    tab: tab,
                    id_projet: props.item.id_projet,
                    id_jalon: props.item.id_jalon,
                })
            );

        }
        else {
            alert('pas de date');
        }
    }

    const content = () => {
        return (
            <div>
                <RangePicker
                    value={date}
                    format="DD/MM/YYYY"
                    onChange={onChange}
                />
            </div>
        )
    }


    const titleToolTopCotation = (id) => {

        return demande_cotation.filter((p) => p.id_demande === id)[0].libelle_demande;

    }

    const title = () => {

        return (
            <>
                <FieldTimeOutlined style={{ color: '#3f37c9', fontSize: 18, cursor: 'pointer' }} />
                <InputNumber
                    min="0"
                    placeholder="Durée"
                    size="small"
                    value={duree}
                    onChange={(values) => setDuree(values)}
                    style={{ marginLeft: 10, width: '90px' }}
                    onBlur={() => {
                        if (duree >= -1) {
                            updateDuree(
                                parseInt(props.item.id),
                                props.item.libelle_tache,
                                duree === "" ? "" : duree,
                                props.item.id_projet,
                                props.item.id_jalon,
                                props.item.poids
                            );
                        } else {
                            dispatch(alertActions.error('La valeur ne doit pas être négative.'));
                            setDuree(props.item.duree)
                        }
                    }}
                />
                <span style={{ marginLeft: 10 }}>heures</span>
                <Popconfirm
                    placement="top"
                    title="Voulez-vous supprimer les dates ?"
                    onConfirm={() => {
                        var ctrl = jalons_items.filter((p) => p.id_jalon === props.item.id_jalon)[0];
                        if (ctrl.terminate === 0 & ctrl.execute === 0) {
                            dispatch(
                                projectActions.updateProjectTask({
                                    id_tache: props.item.id,
                                    libelle: props.item.libelle_tache,
                                    tab: 1,
                                    id_projet: props.item.id_projet,
                                    id_jalon: props.item.id_jalon,
                                })
                            );
                        } else {
                            dispatch(alertActions.error('Echec de l\'opération'));
                        }
                    }}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Tooltip placement="topLeft" title="Effacer la date">
                        <Button
                            type="primary" danger
                            icon={<DeleteOutlined />}
                            size="small"
                            style={{ float: 'right' }}
                        />
                    </Tooltip>
                </Popconfirm>
            </>
        )
    };

    return (
        <Dropdown overlay={menuSuppression} trigger={["contextMenu"]}>
            <Card
                style={{
                    marginBottom: 3,
                    width: "100%",
                    borderRadius: 4,
                    borderColor: props.item.etat === etat_bloque
                        ? 'red'
                        : ''
                }}
                bodyStyle={{
                    padding: "10px 10px",
                }}
                onClick={caldrier === 0 ? () => taskOperation() : null}
                onMouseEnter={() => setTrashHover(parseInt(props.item.id))}
                onMouseLeave={() => setTrashHover(0)}
            >
                <h3
                    style={{
                        width: "100%",
                    }}
                    className="i"
                >
                    {/* {props.item.end === 1 ? (
                        <Dropdown overlay={menuStatut} trigger={["click"]}>
                            <CheckCircleTwoTone
                                style={{
                                    marginRight: 8,
                                    cursor: "pointer",
                                    zIndex: 1,
                                }}
                                twoToneColor={props.item.statut_color}
                                onClick={(e) => {
                                    setConf(true);
                                    e.stopPropagation();
                                }}
                            />
                        </Dropdown>
                    ) : ( */}
                    {/* <Dropdown overlay={menuStatut} trigger={["click"]}>
                        <CheckCircleOutlined
                            style={{
                                marginRight: 8,
                                cursor: "pointer",
                                zIndex: 1,
                                // color: `${props.item.statut_color}`,
                            }}
                            onClick={(e) => {
                                setConf(true);
                                e.stopPropagation();
                            }}
                        />
                    </Dropdown> */}
                    {/* )} */}

                    <Text
                        // copyable
                        style={{ fontSize: 13 }}
                    >
                        {props.item.libelle_tache}
                    </Text>
                </h3>
                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    {/* Avatar de la tache */}
                    {props.item.first_name !== null ? (
                        <Avatar
                            size="small"
                            style={{
                                float: "left",
                                border: "1px solid #515151",
                                backgroundColor: "#f8f8ff",
                                color: "#515151",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 10,
                            }}
                        >
                            {props.item.first_name !== null
                                ? makeAvatar(props.item.first_name)
                                : null}
                        </Avatar>
                    ) : null}

                    {/* icone cotation */}
                    {props.item.id_demande !== null ? (
                        <div
                            style={{
                                float: "right",
                                padding: 2,
                                textAlign: "center",
                                cursor: 'pointer',
                                marginLeft: 2,
                                // marginTop: 2,
                                fontSize: 15
                            }}
                        >
                            <Tooltip placement="topLeft" title={titleToolTopCotation(props.item.id_demande)}>
                                <BulbTwoTone />
                            </Tooltip>
                        </div>
                    ) : null}

                    {/* icone calandrier  */}
                    {(props.item.echeance !== null & props.item.date_debut !== null & props.item.duree !== null) ? (
                        <>
                            <div
                                style={{
                                    float: "right",
                                    padding: 2,
                                    textAlign: "center",
                                    fontSize: 15,
                                    cursor: 'pointer',
                                    // border: caldrier !== 0 ? '1px dotted  #54fc50 ' : '2px dotted  #54fc50 ',
                                    // borderRadius: '50%',
                                    width: 30,
                                    // backgroundColor: 'rgba(166, 255, 164, 0.36)'
                                }}
                                onMouseEnter={() => setCaldrier(parseInt(props.item.id))}
                                onMouseLeave={() => setCaldrier(0)}
                            >
                                <Tooltip placement="top" title="Date de la tâche">
                                    <Popover placement="topRight" title={title(props.item)} content={content(props.item)} trigger="click">
                                        <CalendarOutlined style={{ color: 'green' }} />
                                    </Popover>
                                </Tooltip>
                            </div>
                        </>
                    ) : (props.item.echeance === null || props.item.date_debut === null || props.item.duree === null) ? (
                        <>
                            <div
                                style={{
                                    float: "right",
                                    padding: 2,
                                    textAlign: "center",
                                    fontSize: 15,
                                    cursor: 'pointer',
                                    width: 30,
                                    // border: caldrier !== 0 ? '1px dotted #cacaca' : '2px dotted #cacaca',
                                    // borderRadius: '50%',
                                    // backgroundColor: null
                                }}
                                onMouseEnter={() => setCaldrier(parseInt(props.item.id))}
                                onMouseLeave={() => setCaldrier(0)}
                            >
                                <Tooltip placement="top" title="Date de la tâche">
                                    <Popover placement="topRight" title={title(props.item)} content={content(props.item)} trigger="click">
                                        <CalendarOutlined style={{ color: '#cacaca' }} />
                                    </Popover>
                                </Tooltip>
                            </div>
                        </>
                    ) : null}

                    {/* le nombre de sous tache de la tache */}
                    {props.item.under_task > 0 ? (
                        <div
                            style={{
                                float: "right",
                                padding: 2,
                                marginTop: 1,
                                textAlign: "center",
                                marginRight: 10,
                                cursor: 'help'
                            }}
                        >
                            <Tooltip placement="topLeft" title="Sous tâches">
                                <span
                                    style={{
                                        color: "#e69696"
                                    }}
                                >
                                    <SwitcherOutlined style={{ fontSize: 12, }} />
                                    <span
                                        style={{ fontFamily: "math", fontSize: 12, marginLeft: 4 }}
                                    >
                                        {props.item.data_under_task.filter((p) => p.id_statut === 1).length}/{props.item.data_under_task.length}
                                    </span>
                                </span>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                {/* modal */}
                <Modal
                    title={<Text>Confirmation</Text>}
                    visible={modal}
                    onOk={() => {
                        dispatch(
                            projectActions.delete_project_task(trashHover)
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }}
                    onCancel={() => setModal(false)}
                    okText="Oui"
                    cancelText="Non"
                    maskClosable={false}
                    width={300}
                >
                    <Text>Voulez-vous supprimer cette tache ?</Text>
                </Modal>
            </Card>
        </Dropdown>
    );
};

export default TaskCard;
