import React, { useEffect, useState } from "react";
import "./Tache.css";
import {
  Typography,
  Divider,
  Select,
  Breadcrumb,
  Skeleton,
  Card,
  Row,
  Col,
  Empty,
  Statistic,
  Button,
  DatePicker,
  Tooltip as TooltipAnt,
} from "antd";
import {
  taskActions,
  filtreActions,
  team_menberActions,
  alertActions
} from "../../../redux/_actions";
import Container from "../../components/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import HeaderApp from "../../parchalse/HeaderApp";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment/min/moment-with-locales";
// import defineAbilityFor from "../../../config/defineAbility";
// import { Can } from "../../../config/Can";
import UserTask from "../../components/Card/UserTask";
import DetailTache from "../../components/Drawer/DetailTache";
import { makeAvatar } from "../../helpers/makeAvatar";
import {
  HomeOutlined,
  ReconciliationOutlined,
  CloseCircleOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'


moment.locale("fr");

const { Option } = Select;
const { Text } = Typography;

const { RangePicker } = DatePicker;

const Tache = () => {

  // constante id_profil de l'admin
  const admin_profil = 1;
  // constante statut project en cours
  const statut_projet_encours = 1;

  const dispatch = useDispatch();
  const items_filter = useSelector((state) => state.task.items_filter);
  const loading_filter = useSelector((state) => state.task.loading_filter);
  const user_login = useSelector((state) => state.login);

  // all infos
  const teams = useSelector((state) => state.team.items);

  // si c'est un admin ou un utilisateur lamda, en fonction du profil on choisis
  // le state a charger dans la variable projects
  // const projects = useSelector((state) => user_login.item.id_profil === admin_profil
  //   ? state.project.items
  //   : state.team_project.team_member_project).filter((p) => parseInt(p.id_statut_projet) === statut_projet_encours)
  const projects = useSelector((state) => state.project.items).filter((p) => parseInt(p.id_statut_projet) === statut_projet_encours)
  const team_project = useSelector((state) => state.team_project.team_member_project);
  const users = useSelector((state) => state.user.items).filter((p) => p.libelle !== 'Client' & p.statut_activation === 1);
  const jalons = useSelector((state) => state.jalon.items);

  // choose info
  const filtre_equipe = useSelector((state) => state.filtre.choix_equipe);
  const filtre_projet = useSelector((state) => state.filtre.choix_projet);
  const filtre_membre = useSelector((state) => state.filtre.choix_membre);
  const filtre_jalon = useSelector((state) => state.filtre.choix_jalon);
  const filtre_date = useSelector((state) => state.filtre.choix_date);

  const [group_team, setGroupTeam] = useState(teams);
  const [group_project, setGroupProject] = useState(projects);
  const [group_membre, setGroupMembre] = useState(users);
  const [group_jalon, setGroupJalon] = useState(jalons);
  // const [dateFilter, setDateFilter] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // verification si user_login est un chef de projet
  const if_chef = teams.filter((p) => p.id_user === parseInt(user_login.item.id_user));

  const fileType = 'application/vnd.opensmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (excelData, fileName) => {

    let tab_print = [];
    excelData.forEach(element => {
      let newItem = {
        "Projet": element.libelle_projet,
        "Tache": element.libelle_tache,
        "Etat": element.libelle_jalon,
        "Nb sous tache": element.nb_sousTache,
        "Date création": element.date_creation
      };
      tab_print.push(newItem);
    });

    if (tab_print.length > 0) {
      const ws = XLSX.utils.json_to_sheet(tab_print);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      dispatch(alertActions.error('Désolé ! pas de tache pour la recherche.'))
    }

  }

  // useEffect exécuté dès le chargement du composant
  useEffect(() => {
    if (user_login.item.id_profil === admin_profil) {
      dispatch(filtreActions.reset_equipe());
      dispatch(filtreActions.reset_membre());
    } else {
      if (if_chef.length > 0) {
        dispatch(filtreActions.choose_equipe(user_login.item.id_equipe));
      } else {
        dispatch(filtreActions.choose_equipe(user_login.item.id_equipe));
        dispatch(filtreActions.choose_membre(parseInt(user_login.item.id_user)));
      }
    }
    dispatch(filtreActions.reset_projet());
    dispatch(filtreActions.reset_jalon());
    dispatch(filtreActions.reset_date());
    dispatch(taskActions.reset_filtere());
  }, []);

  // useEffect qui s'exécute dans le cas ou l'un de ces paramétre change 
  useEffect(() => {

    if (filtre_equipe != null) {
      // data Select Projects
      setGroupProject(
        user_login.item.id_profil === admin_profil
          ? projects.filter((p) => p.id_equipe === filtre_equipe)
          : if_chef.length > 0
            ? projects.filter((p) => p.id_equipe === filtre_equipe)
            : team_project
      );

      // data Select Member
      setGroupMembre(
        user_login.item.id_profil === admin_profil // 
          ? users.filter((p) => p.id_equipe === filtre_equipe)
          : if_chef.length > 0
            ? users.filter((p) => p.id_equipe === filtre_equipe)
            : users.filter((p) => (
              p.id_user === parseInt(user_login.item.id_user)
                ? dispatch(filtreActions.choose_membre(parseInt(user_login.item.id_user)))
                : []
            ))
      );
    }

    if (filtre_projet != null) {
      var pro = projects.filter((p) => parseInt(p.id_projet) === parseInt(filtre_projet))[0];
      if (pro !== undefined) {
        setGroupMembre(pro.Membre_projet);
      } else {
        setGroupMembre(group_membre);
      }
    }

    if (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null || filtre_date != null) {
      dispatch(taskActions.getTaskFiltred({
        filtre_equipe: filtre_equipe,
        filtre_projet: filtre_projet,
        filtre_membre: filtre_membre,
        filtre_jalon: filtre_jalon,
        filtre_date: filtre_date
      }));
    } else {
      dispatch(taskActions.reset_filtere());
      setGroupTeam(teams);
      setGroupProject(projects);
      setGroupMembre(users);
      setGroupJalon(jalons);
    }

  }, [filtre_equipe, filtre_projet, filtre_membre, filtre_jalon, filtre_date])

  // change Equipe
  const handleEquipe = (id_equipe) => { dispatch(filtreActions.choose_equipe(id_equipe)); }
  // change Projet
  const handleProjet = (id_projet) => { dispatch(filtreActions.choose_projet(id_projet)); }
  // change Membre
  const handleMembre = (id) => { dispatch(filtreActions.choose_membre(parseInt(id))); }
  // change Equipe
  const handleJalon = (id) => { dispatch(filtreActions.choose_jalon(id)); }

  // function de convertion de date 
  function format_date(date) {
    let tab = date.split('/');
    let data = tab[2] + '-' + tab[1] + "-" + tab[0];
    return data;
  }

  // quand on filtre par date 
  function onFiltreDate(dates, dateString) {

    setSelectedDateRange(dates);

    if (!isEmpty(dateString[0])) {
      let tab = [];
      tab.push(format_date(dateString[0]));
      tab.push(format_date(dateString[1]));

      dispatch(filtreActions.choose_date(tab));
    }
  }

  //  bouton vider le filtre
  function function_del() {
    return (
      <>
        <Button
          type="primary"
          danger
          style={{ float: 'left', marginLeft: -5 }}
          onClick={() => {
            setSelectedDateRange(null);
            if (user_login.item.id_profil === admin_profil & (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null)) {
              dispatch(filtreActions.reset_equipe());
              dispatch(filtreActions.reset_projet());
              dispatch(filtreActions.reset_membre());
              dispatch(filtreActions.reset_jalon());
              dispatch(filtreActions.reset_date());
              dispatch(taskActions.reset_filtere());
            } else if (if_chef.length > 0 & (filtre_projet != null || filtre_membre != null || filtre_jalon != null)) {
              dispatch(filtreActions.reset_projet());
              dispatch(filtreActions.reset_membre());
              dispatch(filtreActions.reset_jalon());
              dispatch(filtreActions.reset_date());
              dispatch(taskActions.reset_filtere());
            } else {
              dispatch(filtreActions.reset_projet());
              dispatch(filtreActions.reset_jalon());
              dispatch(filtreActions.reset_date());
              dispatch(taskActions.reset_filtere());
            }
          }}
        >
          <CloseCircleOutlined style={{ fontSize: 15 }} />
        </Button>
        <Button
          type="primary"
          style={{ float: 'left', marginLeft: 5 }}
          onClick={() => { exportToExcel(items_filter, "Excel Export") }}
        >
          <PrinterOutlined style={{ fontSize: 15 }} />
        </Button>
      </>
    )
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: 50,
        }}
      >
        <HeaderApp />
      </div>
      <div
        style={{
          padding: "9px 18px",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Tâches</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div
        style={{
          padding: 18,
          paddingTop: 0,
          flex: 1,
          display: "flex",
          msFlexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: "white",
            padding: 10,
          }}
        >
          {/* Header body */}
          <Card style={{ marginTop: 10 }} >
            <Row gutter={16}>
              {/* TITRE INFOS */}
              <Col className="gutter-row" span={4}>
                <Text style={{ textTransform: 'uppercase' }}>Filtre des informations</Text>
              </Col>
              {/* LES EQUIPES */}
              <Col className="gutter-row" span={3}>
                <div>
                  <Select
                    disabled={user_login.item.id_profil !== admin_profil ? true : false}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Équipes"
                    style={{ width: '100%' }}
                    onChange={handleEquipe}
                    value={filtre_equipe}
                  >
                    {group_team.map((p) => (
                      <Option value={p.id_equipe}>{p.libelle}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {/* LES PROJETS */}
              <Col className="gutter-row" span={3}>
                <div>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Projets"
                    style={{ width: '100%' }}
                    onChange={handleProjet}
                    value={filtre_projet}
                  >
                    {group_project.map((p) => (
                      <Option value={p.id_projet}>{p.libelle}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {/* LES MEMBRES */}
              <Col className="gutter-row" span={3}>
                <div>
                  <Select
                    disabled={user_login.item.id_profil === admin_profil || if_chef.length > 0 ? false : true}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Membres"
                    style={{ width: '100%' }}
                    onChange={handleMembre}
                    value={filtre_membre}
                  >
                    {group_membre.map((p) => (
                      <Option value={p.id_user}>{p.first_name}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {/* LES JALONS */}
              <Col className="gutter-row" span={3}>
                <div>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Jalons"
                    style={{ width: '100%' }}
                    onChange={handleJalon}
                    value={filtre_jalon}
                  >
                    {group_jalon.map((p) => (
                      <Option value={p.id_jalon}>{p.libelle}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {/* LES DATES INTERVALE */}
              <Col className="gutter-row" span={5}>
                <div>
                  <RangePicker
                    value={selectedDateRange}
                    format="DD/MM/YYYY"
                    onChange={onFiltreDate}
                  />
                </div>
              </Col>
              <Col className="gutter-row" span={3}>
                {
                  (user_login.item.id_profil === admin_profil & (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null || filtre_date != null))
                    ? function_del()
                    : (if_chef.length > 0 & (filtre_projet != null || filtre_membre != null || filtre_jalon != null || filtre_date != null))
                      ? function_del()
                      : (filtre_projet != null || filtre_jalon != null || filtre_date != null)
                        ? function_del()
                        : null
                }
              </Col>
            </Row>
          </Card>
          {/* Fin Header */}
          {/* body body */}
          <Divider
            style={{
              margin: "10px 0",
            }}
          />
          <div
            style={{
              height: "80%",
              display: 'flex',
              flex: 1,
              msFlexDirection: 'row'
            }}
          >
            {/* Section statistique */}
            <section style={{ flex: 2 }}>
              <Card style={{ height: "100%", width: "100%" }}>
                <Card hoverable style={{ marginBottom: 10, padding: '-19px', height: 100 }} loading={loading_filter}>
                  <Statistic
                    title="Tâches"
                    value={items_filter !== undefined & items_filter.length > 0 &
                      (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null)
                      ? items_filter.length : 0}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ReconciliationOutlined />}
                    suffix=" Éléments"
                  />
                </Card>
                <Card hoverable style={{ marginBottom: 10, padding: '-19px', height: 100 }} loading={loading_filter}>
                  <Statistic
                    title="Tâches in progress"
                    value={
                      items_filter !== undefined & items_filter.length > 0 &
                        (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null)
                        ? items_filter.filter((p) => p.id_jalon === 3).length
                        : 0
                    }
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ReconciliationOutlined />}
                    suffix=" Éléments"
                  />
                </Card>
                <Card hoverable style={{ marginBottom: 10, padding: '-19px', height: 100 }} loading={loading_filter}>
                  <Statistic
                    title="Tâches to validate"
                    value={
                      items_filter !== undefined & items_filter.length > 0 &
                        (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null)
                        ? items_filter.filter((p) => p.execute === 1).length
                        : 0
                    }
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ReconciliationOutlined />}
                    suffix="Éléments"
                  />
                </Card>
                {/* <Card hoverable style={{ marginBottom: 10, padding: '-19px', height: 100 }} loading={loading_filter}>
                  <Statistic
                    title="Tâches Done"
                    value={
                      items_filter.length > 0 &
                        (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null)
                        ? items_filter.filter((p) => p.terminate === 1).length
                        : 0
                    }
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ReconciliationOutlined />}
                    suffix="Éléments"
                  />
                </Card> */}
              </Card>
            </section>
            {/* fin statistique */}
            {/* Liste des taches */}
            <section style={{ flex: 8, paddingLeft: 10 }}>
              <Scrollbars
                style={{
                  height: "100%",
                }}
              >
                <Card style={{ height: "100%", width: "100%", }}>
                  <Skeleton
                    active={loading_filter}
                    loading={loading_filter}
                  >
                    {items_filter.length > 0 &
                      (filtre_equipe != null || filtre_projet != null || filtre_membre != null || filtre_jalon != null || filtre_date != null) ? (
                      items_filter.map((p) => (
                        <UserTask item={p} />
                      ))
                    ) : (
                      <Empty
                        description={
                          <Text>Aucune tache en instance</Text>
                        }
                      />
                    )}
                  </Skeleton>
                </Card>
              </Scrollbars>
            </section>
            {/* fin liste des taches */}
          </div>
          {/* Fin Body */}
        </div>
      </div>
      <DetailTache />
    </div>
  );
};

export default Tache;
