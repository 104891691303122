import { demande_cotationConstants } from "../_constants";
import { demande_cotationServices } from "../_services";
import { alertActions, ticketActions } from "../_actions";

export const demande_cotationActions = {
    getAll,
    getOne,
    add,
    update,
    validate,
    getLineDemande,
    add_cotation_temp,
    save_cotation_temp,
    validation_team_chef,
    delete_cotation_temp,
    validate_send_customer,
    validation_dt,
    filtrer_demande_of_quotation,
    filtered_demande_of_quotation,
    not_filtered_demande_of_quotation,
    reset_filtered_demande_of_quotation,
    delete_demande_of_quotation,
    select_demande_of_quotation,
    add_cotation_ticket,
    refus_customer_quoting,
    select_demande_of_quotation_choose,
    create_tache_cotation,
    archiver_demande_of_quotation,
    terminate_demande,
    faturer_cotation
};

function faturer_cotation(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .faturer_cotation(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("La demande à été facturée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de l'operation"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_FAILURE,
            error,
        };
    }
}

function terminate_demande(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .terminate_demande(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("La demande a été terminée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_FAILURE,
            error,
        };
    }
}

function archiver_demande_of_quotation(demande_cotation, value) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .archiver_demande_of_quotation(demande_cotation, value)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(getAll());
                dispatch(alertActions.success("Archivation effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_FAILURE,
            error,
        };
    }
}

function create_tache_cotation(data) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .create_tache_cotation(data)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(demande_cotationActions.getAll());
                dispatch(alertActions.success("Céation effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.data.message));
                dispatch(demande_cotationActions.getAll());
                dispatch(alertActions.error(err.data.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function getOne(id_profil) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .getOne(id_profil)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.GETONE_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.GETONE_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.GETONE_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function getLineDemande(id_demande) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .getLineDemande(id_demande)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.GET_LINE_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation_line) {
        return {
            type: demande_cotationConstants.GET_LINE_DEMANDE_COTATION_SUCCESS,
            demande_cotation_line,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.GET_LINE_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.GETALL_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.GETALL_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.GETALL_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function add(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .add(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(ticketActions.getAll());
                dispatch(alertActions.success("Ajout effectué avec succès !"));
            })
            .catch((err) => {
                if (err !== undefined) {
                    dispatch(failure(err.message));
                }
                dispatch(alertActions.error("Échec de l'opération !"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.ADD_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.ADD_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.ADD_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function update(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .update(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.UPDATE_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.UPDATE_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.UPDATE_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function validate(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .validate(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Opération effectuée avec succès."));
            })
            .catch((err) => {
                dispatch(failure(err.data.message));
                dispatch(alertActions.error(err.data.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATE_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATE_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATE_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function validate_send_customer(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .validate_send_customer(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("La fiche de cotation a été envoyée au client !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Échec de l'opération"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function refus_customer_quoting(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .refus_customer_quoting(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("La fiche n'a pas été validée par client !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Échec de l'opération"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_FAILURE,
            error,
        };
    }
}

function validation_team_chef(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .validation_team_chef(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Validation effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de validation"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATION_TEAM_CHEF_FAILURE,
            error,
        };
    }
}

function validation_dt(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .validation_dt(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Validation effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de validation"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.VALIDATION_DT_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.VALIDATION_DT_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.VALIDATION_DT_FAILURE,
            error,
        };
    }
}

function delete_demande_of_quotation(demande_cotation) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .delete_demande_of_quotation(demande_cotation)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppression effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de suppression"));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_REQUEST,
        };
    }
    function success(demande_cotation) {
        return {
            type: demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_SUCCESS,
            demande_cotation,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_FAILURE,
            error,
        };
    }
}

function add_cotation_temp(id_demande, cotation, count) {
    return {
        type: demande_cotationConstants.ADD_COTATION_TEMP,
        id_demande,
        cotation,
        count,
    };
}

function save_cotation_temp(id_demande, row) {
    return {
        type: demande_cotationConstants.SAVE_COTATION_TEMP,
        id_demande,
        row,
    };
}

function delete_cotation_temp(id_demande, key) {
    return {
        type: demande_cotationConstants.DELETE_COTATION_TEMP,
        id_demande,
        key,
    };
}

function filtrer_demande_of_quotation(demande_cotation) {
    return {
        type: demande_cotationConstants.FILTRER_DEMANDE_OF_QUOTATION,
        demande_cotation,
    };
}

function filtered_demande_of_quotation() {
    return {
        type: demande_cotationConstants.FILTERED_DEMANDE_OF_QUOTATION,
    };
}

function not_filtered_demande_of_quotation() {
    return {
        type: demande_cotationConstants.NOT_FILTERED_DEMANDE_OF_QUOTATION,
    };
}

function reset_filtered_demande_of_quotation() {
    return {
        type: demande_cotationConstants.RESETE_FILTERED_DEMANDE_OF_QUOTATION,
    };
}

function select_demande_of_quotation(demande) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .select_demande_of_quotation(demande)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_REQUEST,
        };
    }
    function success(demande) {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_SUCCESS,
            demande,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_FAILURE,
            error,
        };
    }
}

function select_demande_of_quotation_choose(idP, idD) {
    return function (dispatch) {
        dispatch(request());
        demande_cotationServices
            .select_demande_of_quotation_choose(idP, idD)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_REQUEST,
        };
    }
    function success(demande) {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_SUCCESS,
            demande,
        };
    }
    function failure(error) {
        return {
            type: demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_FAILURE,
            error,
        };
    }
}

function add_cotation_ticket(ticket, id) {
    return {
        type: demande_cotationConstants.ADD_COTATION_TICKET,
        ticket,
        id,
    };
}
