import React, { useEffect } from "react";
import "./HeaderApp.css";
import logo from "../../assets/logo.png";
import {
    Row,
    Col,
    PageHeader,
    Avatar,
    Dropdown,
    Menu,
    Badge,
    Tooltip,
    Typography,
} from "antd";
import ModalProfilUser from "../components/Modal/ModalProfilUser";
import {
    LogoutOutlined,
    UserOutlined,
    LineChartOutlined,
    CloseOutlined,
    SettingOutlined,
    BellOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import defineAbilityFor from "../../config/defineAbility";
import { Can } from "../../config/Can";
import { useSelector } from "react-redux";
import {
    userActions,
    loginActions,
    projectActions,
    team_projectActions,
    modalActions,
} from "../../redux/_actions";
import { makeAvatar } from "../../components/helpers/makeAvatar";
import { rouge } from "../../components/helpers/myVar";
import { encryptWithAES } from "../helpers/makeAvatar";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];
const { Text, Title } = Typography;

const HeaderApp = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.login.item);
    const user = useSelector((state) => state.login);
    const notifications = useSelector((state) => state.user.notifications);
    const history = useHistory();

    const ability = defineAbilityFor(user);

    const team_projects = useSelector(
        (state) => state.team_project.team_projects
    );

    const team_member_project = useSelector(
        (state) => state.team_project.team_member_project
    );

    const user_login = useSelector(
        (state) => state.login.item
    );

    const user_is_Admin = user_login.id_profil;

    const location = useLocation();

    useEffect(() => {

        if (location.pathname.split('/').at(-1) === '') {
            dispatch(loginActions.logout());
        }
        // si non 
        else {
            // console.log('valLog');
        }

    }, []);

    const notification = (
        <div
            style={{
                overflow: 'auto',
                height: '80vh',
            }}
        >
            {notifications.map((p) => (
                // <Link>
                <Text>
                    <div
                        className="notif"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div
                            onClick={() => {
                                if (
                                    p.id_projet !== undefined && p.id_projet !== null
                                ) {
                                    dispatch(
                                        userActions.userOpenNotification(
                                            p.id_notification
                                        )
                                    );
                                    dispatch(
                                        projectActions.getAllProjectTask({
                                            id_project: parseInt(p.id_projet),
                                        })
                                    );
                                    dispatch(
                                        userActions.getAllMemberProject(
                                            parseInt(p.id_projet)
                                        )
                                    );
                                    dispatch(
                                        team_projectActions.selectedProject(
                                            team_projects.find(
                                                (pp) =>
                                                    parseInt(pp.id_projet) ===
                                                    parseInt(p.id_projet)
                                            ) ||
                                            team_member_project.find(
                                                (pp) =>
                                                    parseInt(
                                                        pp.id_projet
                                                    ) ===
                                                    parseInt(p.id_projet)
                                            )
                                        )
                                    );
                                    setTimeout(() => {
                                        history.push(
                                            `/mes-projets/${encryptWithAES(
                                                p.id_projet
                                            )}`
                                        );
                                    }, 1500);
                                } else if (user_is_Admin === 1) {
                                    dispatch(
                                        userActions.userOpenNotification(
                                            p.id_notification
                                        )
                                    );
                                    history.push(
                                        `/fiche-de-cotation`
                                    );
                                }
                            }}
                        >
                            {p.libelle} <strong>{p.libelle_projet}</strong>
                        </div>
                        <div>
                            <CloseOutlined
                                size="small"
                                onClick={(e) => {
                                    dispatch(
                                        userActions.userOpenNotification(
                                            p.id_notification
                                        )
                                    );
                                    e.stopPropagation();
                                }}
                                style={{
                                    position: "absolute",
                                    top: 2,
                                    right: 2,
                                    fontSize: 10,
                                    color: "red",
                                }}
                            />
                        </div>
                    </div>
                </Text>
                // </Link>
            ))}
        </div>
    );

    const profile_menu = (
        <Menu>
            <Menu.Item
                key="0"
                onClick={() => {
                    dispatch(modalActions.show_user_profil());
                    dispatch(userActions.resetExist());
                }}
            >
                <UserOutlined /> Profile
            </Menu.Item>
            {/* <Menu.Item key="1">
                    <SettingOutlined /> Configuration
            </Menu.Item> */}
            <Menu.Divider />
            <Menu.Item
                key="2"
                onClick={() =>
                    dispatch(
                        loginActions.logout(function () {
                            history.push("/");
                        })
                    )
                }
            >
                <LogoutOutlined /> Déconnexion
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <PageHeader
                        // avatar={{ src: './logo.png' }}
                        className="site-page-header navbar"
                        title={
                            <Link to="/accueil">
                                {/* 2iProject */}
                                {/* <Image height={50} width={150} src={logo} /> */}
                                <Title level={2} style={{ marginLeft: 20 }}>
                                    <span style={{ color: "red" }}>2i</span>
                                    <span style={{ color: "blue" }}>
                                        Project
                                    </span>
                                </Title>
                            </Link>
                        }
                        extra={[
                            <>
                                {/* le menu de l'application  */}
                                <div className="menu">
                                    <Can
                                        I="LireMenuProjet"
                                        a="Mes Projets"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/mes-projets"
                                            className={
                                                location.pathname ===
                                                    "/mes-projets"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            Projets
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireMenuProjets"
                                        a="Projets"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/projets"
                                            className={
                                                location.pathname === "/projets"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            Equipes (P)
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireMenuTache"
                                        a="Taches"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/taches"
                                            className={
                                                location.pathname === "/taches"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            Tâches
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireMenuFicheDeCotation"
                                        a="Fiche de cotation"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/fiche-de-cotation"
                                            className={
                                                location.pathname ===
                                                    "/fiche-de-cotation"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            Cotations
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireTickets"
                                        a="Tickets"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/tickets"
                                            className={
                                                location.pathname ===
                                                    "/tickets"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            Tickets
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireAnalyse"
                                        a="Analyses"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/analyses"
                                            className={
                                                location.pathname ===
                                                    "/analyses"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            {/* Analyse (Rapports) */}
                                            <Tooltip title="Analyses (Rapports)">
                                                <LineChartOutlined
                                                    style={{
                                                        fontSize: 22,
                                                        color: "#40a9ff",
                                                        position: "relative",
                                                        top: 5
                                                    }}
                                                />
                                            </Tooltip>
                                        </Link>
                                    </Can>
                                    <Can
                                        I="GestionClients"
                                        a="Gestion des clients"
                                        ability={ability}
                                    >
                                        <Link
                                            to="/gestion-des-clients"
                                            className={
                                                location.pathname ===
                                                    "/gestion-des-clients"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            {/* Gestion des clients */}
                                            <Tooltip title="Gestion des clients">
                                                <SolutionOutlined
                                                    style={{
                                                        fontSize: 22,
                                                        color: "#40a9ff",
                                                        position: "relative",
                                                        top: 5
                                                    }}
                                                />
                                            </Tooltip>
                                        </Link>
                                    </Can>
                                    <Can
                                        I="LireMenuConfiguration"
                                        a="Configurations"
                                        ability={ability}
                                    >

                                        <Link
                                            to="/utilisateurs"
                                            className={
                                                location.pathname ===
                                                    "/utilisateurs" ||
                                                    location.pathname ===
                                                    "/profiles" ||
                                                    location.pathname ===
                                                    "/equipes" ||
                                                    location.pathname ===
                                                    "/parametres"
                                                    ? "lienselected"
                                                    : "lien"
                                            }
                                        >
                                            <Tooltip title="Configurations">
                                                <SettingOutlined
                                                    style={{
                                                        fontSize: 22,
                                                        color: "#40a9ff",
                                                        position: "relative",
                                                        top: 5
                                                    }}
                                                />
                                            </Tooltip>
                                        </Link>
                                    </Can>
                                </div>

                                {/* parametre de logout ou update password */}
                                <div
                                    className="avatar"
                                >
                                    {notifications.length !== 0 ? (
                                        <Tooltip
                                            title={notification}
                                            color={"#fff"}
                                            placement="bottomRight"
                                        >
                                            <Badge
                                                count={notifications.length}
                                                showZero={true}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 0,
                                                    // backgroundColor:
                                                    //     notifications.length === 0
                                                    //         ? "#40a9ff"
                                                    //         : null,
                                                    position: "absolute",
                                                    right: 10,
                                                    top: -12,
                                                }}
                                            >
                                                <BellOutlined
                                                    style={{
                                                        marginRight: 10,
                                                        cursor: "pointer",
                                                        fontSize: 25,
                                                        padding: 0,
                                                        color: "white",
                                                        position: "absolute",
                                                        bottom: -7,
                                                        right: 0,
                                                    }}
                                                />
                                            </Badge>
                                        </Tooltip>
                                    ) : (
                                        <Badge
                                            count={notifications.length}
                                            showZero={true}
                                            style={{
                                                cursor: "pointer",
                                                padding: 0,
                                                position: "absolute",
                                                right: 10,
                                                top: -15,
                                            }}
                                        >
                                            <BellOutlined
                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer",
                                                    fontSize: 25,
                                                    padding: 0,
                                                    color: "white",
                                                    position: "absolute",
                                                    bottom: -7,
                                                    right: 0,
                                                }}
                                            />
                                        </Badge>
                                    )}

                                    <Dropdown
                                        overlay={profile_menu}
                                        trigger={["click"]}
                                    >
                                        <Avatar
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                            size={35}
                                            style={{
                                                cursor: "pointer",
                                                border: "2px solid #252526",
                                                backgroundColor: "#f8f8ff",
                                                color: "#252526",
                                                display: "inline-flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginLeft: 15,
                                            }}
                                        >
                                            {makeAvatar(auth.first_name)}
                                        </Avatar>
                                    </Dropdown>
                                </div>
                            </>,
                        ]}
                        style={{
                            backgroundColor: `${rouge}`,
                            padding: "0 24px",
                            // width: "100%",
                            height: 50,
                            boxShadow: "6px 6px 6px 1px rgba(0, 0, 0, 0.2)",
                        }}
                    />
                </Col>
            </Row>
            <ModalProfilUser />
        </>
    );
};

export default HeaderApp;
