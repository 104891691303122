import React, { useEffect, useState } from "react";
import "./App.css";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    HashRouter as Router,
    useHistory
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Accueil from "./pages/Accueil/Accueil";
import Projet from "./pages/Project/Project";
import Tache from "./pages/Tache/Tache";
import Analyse from "./pages/Analyse/Analyse";
import Detail from "./pages/Project/Detail";
import Utilisateurs from "./pages/ConfigApp/Utilisateurs/Utilisateurs";
import Profiles from "./pages/ConfigApp/Profiles/Profiles";
import Equipes from "./pages/ConfigApp/Equipes/Equipes";
import Parametres from "./pages/ConfigApp/Parametres/Parametres";
import NotFound from "./pages/NotFound/NotFound";
import AllProjects from "./pages/AllProjects/AllProjects";
import FicheDeCotation from "./pages/FicheDeCotation/FicheDeCotation";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, modalActions, userActions } from "../redux/_actions";
import { message, Modal, Button, notification, Spin } from "antd";
import { loginActions } from "../redux/_actions";
import ModalAutoLogout from "./components/Modal/ModalAutoLogout";
import 'react-schedule-job/dist/index.css';
import Tickets from "./pages/Tickets/Tickets";
import GestionClients from "./pages/GestionClients/GestionClients"

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];
var id;
// var idw;

function App() {
    const [network, setNetwork] = useState(false);

    const alert = useSelector((state) => state.alert);
    const closable_network = useSelector(
        (state) => state.modal.closable_network
    );

    const dispatch = useDispatch();

    // const sendRemeberMail = () => {
    //     dispatch(userActions.sendRememberMail())
    // }

    // const jobs = [
    //     {
    //         fn: sendRemeberMail,
    //         id: '1',
    //         schedule: '33 10 * * 5',
    //         // Execute every minutes
    //         name: 'Send Remember Mail'
    //     }
    // ]

    // function doesConnectionExist() {
    //     var xhr = new XMLHttpRequest();
    //     var file = "https://i.imgur.com/7ofBNix.png";
    //     var randomNum = Math.round(Math.random() * 10000);

    //     xhr.open("HEAD", file + "?rand=" + randomNum, true);
    //     xhr.send();

    //     xhr.addEventListener("readystatechange", processRequest, false);

    //     function processRequest(e) {
    //         if (xhr.readyState == 4) {
    //             if (xhr.status >= 200 && xhr.status < 304) {
    //                 // console.log("connection exists!");
    //                 dispatch(modalActions.close_network());
    //             } else {
    //                 // console.log("connection doesn't exist!");
    //                 dispatch(modalActions.show_network());
    //             }
    //         }
    //     }
    // }

    useEffect(() => {
        if (alert.message) {
            const msg = alert.message;
            if (alert.type === "success") {
                message.success(msg);
            } else {
                message.error(msg);
            }
            dispatch(alertActions.clear());
        }

        if (window.location.href !== "http://2iprojects.com/") {
            for (var i in events) {
                window.addEventListener(events[i], () => resetTimeout());
            }
        }

        setInterval(function () {
            let online = window.navigator.onLine;
            if (online) {
                setNetwork(false);
            } else {
                setNetwork(true);
            }
            // doesConnectionExist();
        }, 3000);

        // Connexion internet
        // doesConnectionExist()
    });

    useEffect(() => {
        if (window.location.href !== "http://2iprojects.com/") {
            id = setTimeout(() => logout(), 1000 * 60 * 60 * 8);
            // idw = setTimeout(() => warn(), 780 * 1000);
        }

        // if(token.checkToken === false) {
        //     dispatch(loginActions.logout());
        // }

    }, []);

    // setInterval(function () {
    //     let online = window.navigator.onLine;
    //     if (online) {
    //         setNetwork(false);
    //     } else {
    //         setNetwork(true);
    //     }
    //     // doesConnectionExist();
    // }, 3000);

    const resetTimeout = () => {
        clearTimeout(id);
        // clearTimeout(idw);
        id = setTimeout(() => logout(), 1000 * 60 * 60 * 8);
        // idw = setTimeout(() => warn(), 780 * 1000);
    };

    // const warn = () => {
    //     // dispatch(modalActions.show_auto_logout());
    //     if (window.location.href !== "http://localhost:3000/2iProject2iSoft/") {
    //         // warning();
    //     }
    //     // console.log("You will be logged out automatically in 1 minute.");
    // };

    const logout = () => {
        // Send a logout request to the API
        // console.log("Sending a logout request to the API...");
        dispatch(loginActions.logout());
        destroy(); // Cleanup
    };

    const destroy = () => {
        clearTimeout();

        for (var i in events) {
            window.removeEventListener(events[i], () => resetTimeout());
        }
    };

    return (
        <div className="App">
            {/* <Schedule
                jobs={jobs}
                timeZone='Africa/Niamey'
                // "UTC", "local" or "YOUR PREFERRED TIMEZONE",
                dashboard={{
                    hidden: true
                    // if true, dashboard is hidden
                }}
            /> */}
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Login />
                    </Route>
                    <PrivateRoute exact path="/accueil" component={Accueil} />
                    <PrivateRoute
                        exact
                        path="/mes-projets"
                        component={Projet}
                    />
                    <PrivateRoute
                        exact
                        path="/projets"
                        component={AllProjects}
                    />
                    <PrivateRoute exact path="/taches" component={Tache} />
                    <PrivateRoute exact path="/analyses" component={Analyse} />
                    <PrivateRoute
                        exact
                        path="/mes-projets/:id_projet"
                        component={Detail}
                    />
                    <PrivateRoute
                        exact
                        path="/utilisateurs"
                        component={Utilisateurs}
                    />
                    <PrivateRoute exact path="/profiles" component={Profiles} />
                    <PrivateRoute exact path="/equipes" component={Equipes} />
                    <PrivateRoute exact path="/fiche-de-cotation" component={FicheDeCotation} />
                    <PrivateRoute exact path="/tickets" component={Tickets} />
                    <PrivateRoute exact path="/gestion-des-clients" component={GestionClients} />
                    <PrivateRoute
                        exact
                        path="/parametres"
                        component={Parametres}
                    />
                    <Route exact component={NotFound} />
                </Switch>
            </Router>
            <ModalAutoLogout />
            <Modal
                title="Veuillez vérifier votre connexion internet."
                visible={network}
                headers={null}
                footer={null}
                width={350}
                closeIcon={true}
                maskStyle={{ backgroundColor: "#dcdcdc", opacity: 0.4 }}
            >
                <Spin style={{ width: "100%" }}></Spin>
            </Modal>
        </div>
    );
}

// function warning() {
//     Modal.warning({
//         title: "Alerte",
//         content: "Vous serez déconnecté automatiquement dans 2 minute.",
//     });
// }

function PrivateRoute({ component: Component, ...rest }) {
    const location = useLocation();
    // const history = useHistory();

    const info = useSelector((state) => state.login);
    const actions = useSelector((state) => state.login.actions);
    const authAction = actions.filter(
        (p) => p.check === true && p.url === location.pathname
    );
    let tab = location.pathname.split("/");


    // useEffect(() => {
    //     return history.listen((location) => {
    //         console.log(`You changed the page to: ${location.pathname}`)
    //     })
    // }, [history])


    return (
        <Route
            {...rest}
            render={(props) =>
                (Object.keys(info.token).length !== 0 &&
                    Object.keys(authAction).length !== 0) ||
                    (Object.keys(info.token).length !== 0 && tab.length === 3) ||
                    (Object.keys(info.token).length !== 0 &&
                        location.pathname === "/accueil") ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
}

export default App;
