import { loginConstants } from "../_constants";
const initialState = {
    loading: false,
    isAuthenticate: false,
    token: {},
    item: {},
    actions: [],
    checkToken: false,
    error: "",
};
export function login(state = initialState, action) {
    switch (action.type) {
        case loginConstants.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case loginConstants.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.login,
                loading: false,
            };
        case loginConstants.LOGIN_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case loginConstants.AUTH_REQUEST:
            return { ...state, loading: true };
        case loginConstants.AUTH_SUCCESS:
            return {
                ...state,
                item: action.auth,
                loading: false,
                isAuthenticate: true,
            };
        case loginConstants.AUTH_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                isAuthenticate: false,
            };
        // case loginConstants.PASSWORD_CHANGED_REQUEST:
        //     return { ...state, loading: true };
        // case loginConstants.PASSWORD_CHANGED_SUCCESS:
        //     return { ...state, loading: false };
        // case loginConstants.PASSWORD_CHANGED_FAILURE:
        //     return { ...state, error: action.error, loading: false };
        case loginConstants.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case loginConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                item: {},
                token: {},
                isAuthenticate: false,
                checkToken: false
            };
        case loginConstants.LOGOUT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case loginConstants.USER_ROLE_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case loginConstants.USER_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                actions: action.profils,
            };

        case loginConstants.USER_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case loginConstants.UPDATE_USER_PROFIL_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case loginConstants.UPDATE_USER_PROFIL_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.login,
            };

        case loginConstants.UPDATE_USER_PROFIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case loginConstants.CHECK_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case loginConstants.CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                checkToken: true,
            };

        case loginConstants.CHECK_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}
