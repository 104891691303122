import React from "react";
import { Card, Badge, Space, Switch, Typography, Dropdown, Menu, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    modalActions,
    customerActions
} from "../../../redux/_actions";
import "./GestionClientNew.css";
import {
    EditOutlined,
    FileProtectOutlined,
    AlignLeftOutlined
} from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

const GestionClientNew = (props) => {

    const dispatch = useDispatch();

    const demandes_customer = useSelector((state) => state.demande_cotation.items);
    const projets_customer = useSelector((state) => state.project.items);

    const customerChoose = useSelector((state) => state.customer.item_choose);

    const nb_demande = demandes_customer.length > 0 ?
        demandes_customer.filter((p) => p.id_client === props.p.id_client ? p : 0) : 0;

    const nb_project = projets_customer.length > 0 ?
        projets_customer.filter((p) => p.id_client === props.p.id_client ? p : 0) : 0;


    const menu = (
        <Menu
            onClick={(value) => {
                if (value.key === "1") {
                    dispatch(modalActions.show_add_customer());
                }
            }}
        >
            <Menu.Item key="1">
                <EditOutlined /> Modifier
            </Menu.Item>
        </Menu>
    );

    const rightClique = (
        adresse_client,
        created_at,
        created_by,
        id_client,
        modified_at,
        modified_by,
        nom_client
    ) => {
        props.setSelectedCustomer({
            adresse_client: adresse_client,
            created_at: created_at,
            created_by: created_by,
            id_client: id_client,
            modified_at: modified_at,
            modified_by: modified_by,
            nom_client: nom_client
        });
    };


    return (
        <>
            <Dropdown overlay={menu} trigger={["contextMenu"]}>
                <Card
                    onContextMenu={(e) =>
                        e.type === "contextmenu"
                            ? rightClique(
                                props.p.adresse_client,
                                props.p.created_at,
                                props.p.created_by,
                                props.p.id_client,
                                props.p.modified_at,
                                props.p.modified_by,
                                props.p.nom_client
                            )
                            : null
                    }
                    style={{
                        // flex: 1,
                        backgroundColor: "#f8f8ff",
                        border: "1px solid #dedede",
                        width: "94%",
                        textAlign: "center",
                        height: 100,
                        // margin: 9,
                        padding: 5,
                        paddingTop: 20,
                        borderRadius: 3,
                        cursor: "pointer",
                        display: "inline-block",
                        margin: 4,
                        borderLeft:
                            parseInt(customerChoose.id_client) ===
                                parseInt(props.p.id_client)
                                ? "5px solid #1890ff"
                                : null,
                    }}
                    bodyStyle={{ padding: 0, height: 79 }}
                    hoverable={true}
                    onClick={() => {
                        dispatch(customerActions.checkCustomer(props.p));
                    }}
                >

                    <Meta
                        title={<Text strong={true}>{props.p.nom_client}</Text>}
                    />
                    <div
                        style={{ marginTop: 20, color: 'red' }}
                    >
                        <Space>
                            {/* <Switch checked={show} onChange={() => setShow(!show)} /> */}
                            <Badge style={{ width: 40, marginRight: 30 }} count={nb_project.length > 0 ? (
                                <div style={{
                                    backgroundColor: '#f5222d',
                                    width: 40,
                                    height: 20,
                                    textAlign: "center",
                                    paddingTop: 2,
                                    borderRadius: 8,
                                    color: "white",
                                }}>
                                    <FileProtectOutlined /> {nb_project.length}
                                </div>
                            ) : (
                                <div style={{
                                    backgroundColor: '#f5222d', width: 40, height: 20,
                                    textAlign: "center", paddingTop: 2,
                                    borderRadius: 8,
                                    color: "white",
                                }}>
                                    <FileProtectOutlined /> {nb_project.length}
                                </div>
                            )} />
                            {/* <Badge count={<ClockCircleOutlined style={{ color: '#f5222d' }} />} /> */}
                            <Badge
                                className="site-badge-count-109"
                                count={nb_demande.length > 0 ? (
                                    <div style={{
                                        backgroundColor: '#52c41a',
                                        width: 40,
                                        height: 20,
                                        textAlign: "center",
                                        paddingTop: 2,
                                        borderRadius: 8,
                                        color: "white",
                                    }}>
                                        <AlignLeftOutlined /> {nb_demande.length}
                                    </div>
                                ) : (
                                    <div style={{
                                        backgroundColor: '#52c41a', width: 40, height: 20,
                                        textAlign: "center", paddingTop: 2,
                                        borderRadius: 8,
                                        color: "white"
                                    }}>
                                        <AlignLeftOutlined /> {nb_demande.length}
                                    </div>
                                )}
                                style={{ backgroundColor: '#52c41a', width: 40, marginRight: -10 }}
                            />
                        </Space>
                    </div>
                    <div
                        style={{
                            width: 0,
                            height: 0,
                            borderTop: "50px solid transparent",
                            borderRight: `50px solid #1890ff`,
                            borderBottom: "0px solid transparent",
                            position: "absolute",
                            right: 0,
                            bottom: 0
                        }}
                    ></div>
                </Card>
            </Dropdown>
        </>
    );
};

export default GestionClientNew;
