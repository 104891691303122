import axios from "../../axios/index";

export const ticket_stateServices = {
    getAll,
    add,
    update,
    remove,
};

async function getAll() {
    return await axios.get(`ticket_state`);
}

async function add(ticket_state) {
    return await axios.post(`ticket_state`, ticket_state);
}

async function update(ticket_state) {
    return await axios.put(
        `ticket_state/${ticket_state.id_state}`,
        ticket_state
    );
}

async function remove(ticket_state) {
    return await axios.get(`ticket_state/delete/${ticket_state}`);
}
