export const team_menberConstants = {
    ADD_REQUEST: "ADD_TEAM_MENBER_REQUEST",
    ADD_SUCCESS: "ADD_TEAM_MENBER_SUCCESS",
    ADD_FAILURE: "ADD_TEAM_MENBER_FAILURE",

    GETALL_REQUEST: "TEAM_MENBER_GETALL_REQUEST",
    GETALL_SUCCESS: "TEAM_MENBER_GETALL_SUCCESS",
    GETALL_FAILURE: "TEAM_MENBER_GETALL_FAILURE",

    GETALL_TEAM_MENBER_REQUEST: "GETALL_TEAM_MENBER_REQUEST",
    GETALL_TEAM_MENBER_SUCCESS: "GETALL_TEAM_MENBER_SUCCESS",
    GETALL_TEAM_MENBER_FAILURE: "GETALL_TEAM_MENBER_FAILURE",

    GETALL_MENBER_REQUEST: "GETALL_TASK_MENBER_REQUEST",
    GETALL_MENBER_SUCCESS: "GETALL_TASK_MENBER_SUCCESS",
    GETALL_MENBER_FAILURE: "GETALL_TASK_MENBER_FAILURE",

    DELETE_REQUEST: "DELETE_TEAM_MENBER_REQUEST",
    DELETE_SUCCESS: "DELETE_TEAM_MENBER_SUCCESS",
    DELETE_FAILURE: "DELETE_TEAM_MENBER_FAILURE",

    SELECT_TEAM_MEMBER_REQUEST: "SELECT_TEAM_MEMBER_REQUEST",
    SELECT_TEAM_MEMBER_SUCCESS: "SELECT_TEAM_MEMBER_SUCCESS",
    SELECT_TEAM_MEMBER_FAILURE: "SELECT_TEAM_MEMBER_FAILURE",

    SELECTED_TEAM_MEMBRE: "SELECTED_TEAM_MEMBRE",

    FILETR_USER_TASK: "FILETR_USER_TASK",

    RESET_USER_TASK: "RESET_USER_TASK",
    RESET_MEMBERS: "RESET_MEMBERS",

    GETALL_TASK_MEMBER_MENU_REQUEST: "GETALL_TASK_MEMBER_MENU_REQUEST",
    GETALL_TASK_MEMBER_MENU_SUCCESS: "GETALL_TASK_MEMBER_MENU_success",
    GETALL_TASK_MEMBER_MENU_FAILURE: "GETALL_TASK_MEMBER_MENU_FAILURE",
};
