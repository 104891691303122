import { loginConstants } from "../_constants";
import { loginService } from "../_services";
import { alertActions } from "./";
import * as store from "../_helpers/store";
import instance from "../../axios/index";

export const loginActions = {
    login,
    auth,
    logout,
    // passwordChanged,
    getUserRole,
    update,
    checkToken,
};

function checkToken(token) {
    return function (dispatch) {
        dispatch(request());
        loginService
            .checkToken(token)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: loginConstants.CHECK_TOKEN_REQUEST,
        };
    }
    function success(token) {
        return {
            type: loginConstants.CHECK_TOKEN_SUCCESS,
            token,
        };
    }
    function failure(error) {
        return {
            type: loginConstants.CHECK_TOKEN_FAILURE,
            error,
        };
    }
}

function update(login) {
    return function (dispatch) {
        dispatch(request());
        loginService
            .update(login)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification."));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: loginConstants.UPDATE_USER_PROFIL_REQUEST,
        };
    }
    function success(login) {
        return {
            type: loginConstants.UPDATE_USER_PROFIL_SUCCESS,
            login,
        };
    }
    function failure(error) {
        return {
            type: loginConstants.UPDATE_USER_PROFIL_FAILURE,
            error,
        };
    }
}

// LOGIN
function login(loginPassword, cb) {
    return (dispatch) => {
        dispatch(request());

        loginService.login(loginPassword).then(
            (res) => {
                dispatch(success(res.data.message.Authorization));
                window.sessionStorage.setItem(
                    "_token",
                    res.data.message.Authorization
                );
                dispatch(auth(loginPassword, cb));
                // dispatch(checkToken({token: 'Bearer ' + res.data.message.Authorization}))
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(
                    alertActions.error("Identifiant ou mot de passe incorrect")
                );
            }
        );
    };

    function request() {
        return { type: loginConstants.LOGIN_REQUEST };
    }
    function success(login) {
        return { type: loginConstants.LOGIN_SUCCESS, login };
    }
    function failure(error) {
        return { type: loginConstants.LOGIN_FAILURE, error };
    }
}

// // CHANGE PASSWORD
// function passwordChanged(info) {
//     return (dispatch) => {
//         dispatch(request());

//         loginService.changePassword(info).then(
//             (res) => {
//                 dispatch(success(res.data.message));
//                 dispatch(alertActions.success("Mot de passe modifié !"));
//             },
//             (error) => {
//                 dispatch(failure(error.message));
//                 dispatch(alertActions.error(error.message));
//             }
//         );
//     };

//     function request() {
//         return { type: loginConstants.PASSWORD_CHANGED_REQUEST };
//     }
//     function success(user) {
//         return { type: loginConstants.PASSWORD_CHANGED_SUCCESS, user };
//     }
//     function failure(error) {
//         return { type: loginConstants.PASSWORD_CHANGED_FAILURE, error };
//     }
// }

// AUTH
function auth(loginPassword, cb) {
    return (dispatch) => {
        dispatch(request());
        loginService.auth(loginPassword).then(
            (res) => {
                dispatch(success(res.data.message));
                // window.localStorage.setItem(
                //     "id_user",
                //     res.data.message.id_user
                // );
                // window.localStorage.setItem(
                //     "id_profil",
                //     res.data.message.id_profil
                // );
                setTimeout(() => {
                    cb();
                }, 100);
                
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                if(error.response !== undefined && error.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            }
        );
    };

    function request() {
        return { type: loginConstants.AUTH_REQUEST };
    }
    function success(auth) {
        return { type: loginConstants.AUTH_SUCCESS, auth };
    }
    function failure(error) {
        return { type: loginConstants.AUTH_FAILURE, error };
    }
}

// LOGOUT
function logout(callback) {
    return (dispatch) => {
        dispatch(request());
        try {
            window.sessionStorage.clear();
            window.sessionStorage.clear("");
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.localStorage.clear();
            // store.persistor.purge();
            window.localStorage.removeItem('persist:root');
            callback();
            dispatch(success());
            // dispatch({ type: storeConstants.RESET_STORE });
        } catch (ex) {
            dispatch(failure("Quelque chose ne marche pas !"));
        }
    };

    function request() {
        return { type: loginConstants.LOGOUT_REQUEST };
    }
    function success() {
        return { type: loginConstants.LOGOUT_SUCCESS };
    }
    function failure(error) {
        return { type: loginConstants.LOGOUT_SUCCESS, error };
    }
}

function getUserRole(id_profil) {
    return function (dispatch) {
        dispatch(request());
        loginService
            .getUserRole(id_profil)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: loginConstants.USER_ROLE_REQUEST,
        };
    }
    function success(profils) {
        return {
            type: loginConstants.USER_ROLE_SUCCESS,
            profils,
        };
    }
    function failure(error) {
        return {
            type: loginConstants.USER_ROLE_FAILURE,
            error,
        };
    }
}
