import { directionConstants } from "../_constants";

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export const direction = (state = initialState, action) => {
    switch (action.type) {
        case directionConstants.ADD_DIRECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case directionConstants.ADD_DIRECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.direction],
            };

        case directionConstants.ADD_DIRECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case directionConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case directionConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.direction,
            };

        case directionConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case directionConstants.UPDATE_DIRECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case directionConstants.UPDATE_DIRECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_direction === action.direction.id_direction) {
                            Object.assign(p, action.direction);
                        }
                        return p;
                    }),
                ],
            };

        case directionConstants.UPDATE_DIRECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case directionConstants.DELETE_DIRECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case directionConstants.DELETE_DIRECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter(
                        (p) => p.id_direction !== action.direction
                    ),
                ],
            };

        case directionConstants.DELETE_DIRECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.direction,
            };

        default:
            return state;
    }
};
