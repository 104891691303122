import { ticketConstants } from "../_constants";
import { ticketServices } from "../_services";

export const ticketActions = {
    getAll,
    filtrer_ticket,
    reset_filtrer_ticket,
    filtrer_ticket_by_project,
    filter_from_task,
    filter_ticket_by_project_from_getAll,
    filterTickets,
    filtrer_ticket_date,
};

function filterTickets(id_state) {
    return {
        type: ticketConstants.FILTER_TICKETS,
        id_state

    };
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        ticketServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: ticketConstants.GETALL_TICKET_REQUEST,
        };
    }
    function success(ticket) {
        return {
            type: ticketConstants.GETALL_TICKET_SUCCESS,
            ticket,
        };
    }
    function failure(error) {
        return {
            type: ticketConstants.GETALL_TICKET_FAILURE,
            error,
        };
    }
}

function filtrer_ticket(ticket) {
    return {
        type: ticketConstants.FILTRER_TICKET,
        ticket,
    };
}

function reset_filtrer_ticket() {
    return {
        type: ticketConstants.RESET_FILTRER_TICKET,
    };
}

function filtrer_ticket_by_project(id_projet) {

    if (id_projet === 0) {
        return {
            type: ticketConstants.RESET_FILTRER_TICKET_FROM_GETALL,
        };
    } else {
        return {
            type: ticketConstants.FILTRER_TICKET_BY_PROJECT,
            id_projet,
        };
    }
}

function filter_from_task(tickets) {
    return {
        type: ticketConstants.FILTER_FROM_TASK,
        tickets,
    };
}

function filter_ticket_by_project_from_getAll(projet) {

    if (projet === 0) {
        return {
            type: ticketConstants.RESET_FILTRER_TICKET_FROM_GETALL,
        };

    } else {

        return function (dispatch) {
            dispatch(request());
            ticketServices
                .getAll()
                .then((res) => {
                    dispatch(
                        success(
                            res.data.message.filter(
                                (p) => p.id_projet === projet && p.id_statut === 1
                            )
                        )
                    );
                })
                .catch((err) => {
                    dispatch(failure(err.message));
                });
        };
        function request() {
            return {
                type: ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_REQUEST,
            };
        }
        function success(ticket) {
            return {
                type: ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_SUCCESS,
                ticket,
            };
        }
        function failure(error) {
            return {
                type: ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_FAILURE,
                error,
            };
        }

    }

}

function filtrer_ticket_date(filter) {

    return function (dispatch) {
        dispatch(request());
        ticketServices
            .filtrer_ticket_date(filter)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: ticketConstants.FILTER_TICKET_DATE_REQUEST,
        };
    }
    function success(filter) {
        return {
            type: ticketConstants.FILTER_TICKET_DATE_SUCCESS,
            filter,
        };
    }
    function failure(error) {
        return {
            type: ticketConstants.FILTER_TICKET_DATE_FAILURE,
            error,
        };
    }

}
