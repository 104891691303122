export const demande_cotationConstants = {
    GETALL_DEMANDE_COTATION_REQUEST: "GETALL_DEMANDE_COTATION_REQUEST",
    GETALL_DEMANDE_COTATION_SUCCESS: "GETALL_DEMANDE_COTATION_SUCCESS",
    GETALL_DEMANDE_COTATION_FAILURE: "GETALL_DEMANDE_COTATION_FAILURE",

    GETONE_DEMANDE_COTATION_REQUEST: "GETONE_DEMANDE_COTATION_REQUEST",
    GETONE_DEMANDE_COTATION_SUCCESS: "GETONE_DEMANDE_COTATION_SUCCESS",
    GETONE_DEMANDE_COTATION_FAILURE: "GETONE_DEMANDE_COTATION_FAILURE",

    GET_LINE_DEMANDE_COTATION_REQUEST: "GET_LINE_DEMANDE_COTATION_REQUEST",
    GET_LINE_DEMANDE_COTATION_SUCCESS: "GET_LINE_DEMANDE_COTATION_SUCCESS",
    GET_LINE_DEMANDE_COTATION_FAILURE: "GET_LINE_DEMANDE_COTATION_FAILURE",

    ADD_DEMANDE_COTATION_REQUEST: "ADD_DEMANDE_COTATION_REQUEST",
    ADD_DEMANDE_COTATION_SUCCESS: "ADD_DEMANDE_COTATION_SUCCESS",
    ADD_DEMANDE_COTATION_FAILURE: "ADD_DEMANDE_COTATION_FAILURE",

    ADD_TASK_DEMANDE_COTATION_REQUEST: "ADD_TASK_DEMANDE_COTATION_REQUEST",
    ADD_TASK_DEMANDE_COTATION_SUCCESS: "ADD_TASK_DEMANDE_COTATION_SUCCESS",
    ADD_TASK_DEMANDE_COTATION_FAILURE: "ADD_TASK_DEMANDE_COTATION_FAILURE",

    UPDATE_DEMANDE_COTATION_REQUEST: "UPDATE_DEMANDE_COTATION_REQUEST",
    UPDATE_DEMANDE_COTATION_SUCCESS: "UPDATE_DEMANDE_COTATION_SUCCESS",
    UPDATE_DEMANDE_COTATION_FAILURE: "UPDATE_DEMANDE_COTATION_FAILURE",

    VALIDATE_DEMANDE_COTATION_REQUEST: "VALIDATE_DEMANDE_COTATION_REQUEST",
    VALIDATE_DEMANDE_COTATION_SUCCESS: "VALIDATE_DEMANDE_COTATION_SUCCESS",
    VALIDATE_DEMANDE_COTATION_FAILURE: "VALIDATE_DEMANDE_COTATION_FAILURE",

    VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_REQUEST: "VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_REQUEST",
    VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_SUCCESS: "VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_SUCCESS",
    VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_FAILURE: "VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_FAILURE",

    REFUS_CUSTOMER_DEMANDE_COTATION_REQUEST: "REFUS_CUSTOMER_DEMANDE_COTATION_REQUEST",
    REFUS_CUSTOMER_DEMANDE_COTATION_SUCCESS: "REFUS_CUSTOMER_DEMANDE_COTATION_SUCCESS",
    REFUS_CUSTOMER_DEMANDE_COTATION_FAILURE: "REFUS_CUSTOMER_DEMANDE_COTATION_FAILURE",

    DELETE_COTATION_TEMP: "DELETE_COTATION_TEMP",
    ADD_COTATION_TEMP: "ADD_COTATION_TEMP",
    SAVE_COTATION_TEMP: "SAVE_COTATION_TEMP",

    // TERMINATE_COTATION_REQUEST: "TERMINATE_COTATION_REQUEST",
    // TERMINATE_COTATION_SUCCESS: "TERMINATE_COTATION_SUCCESS",
    // TERMINATE_COTATION_FAILURE: "TERMINATE_COTATION_FAILURE",

    VALIDATION_TEAM_CHEF_REQUEST: "VALIDATION_TEAM_CHEF_REQUEST",
    VALIDATION_TEAM_CHEF_SUCCESS: "VALIDATION_TEAM_CHEF_SUCCESS",
    VALIDATION_TEAM_CHEF_FAILURE: "VALIDATION_TEAM_CHEF_FAILURE",

    VALIDATION_DT_REQUEST: "VALIDATION_DT_REQUEST",
    VALIDATION_DT_SUCCESS: "VALIDATION_DT_SUCCESS",
    VALIDATION_DT_FAILURE: "VALIDATION_DT_FAILURE",

    FILTRER_ALL_DEMANDE_OF_QUOTATION_FROM_PROJECT: "FILTRER_ALL_DEMANDE_OF_QUOTATION_FROM_PROJECT",

    FILTRER_DEMANDE_OF_QUOTATION: "FILTRER_DEMANDE_OF_QUOTATION",
    FILTERED_DEMANDE_OF_QUOTATION: "FILTERED_DEMANDE_OF_QUOTATION",
    NOT_FILTERED_DEMANDE_OF_QUOTATION: "NOT_FILTERED_DEMANDE_OF_QUOTATION",
    RESETE_FILTERED_DEMANDE_OF_QUOTATION:
        "RESET_FILTERED_DEMANDE_OF_QUOTATION",

    DELETE_DEMANDE_OF_QUOTATION_REQUEST: "DELETE_DEMANDE_OF_QUOTATION_REQUEST",
    DELETE_DEMANDE_OF_QUOTATION_SUCCESS: "DELETE_DEMANDE_OF_QUOTATION_SUCCESS",
    DELETE_DEMANDE_OF_QUOTATION_FAILURE: "DELETE_DEMANDE_OF_QUOTATION_FAILURE",

    ARCHIVER_DEMANDE_OF_QUOTATION_REQUEST: "ARCHIVER_DEMANDE_OF_QUOTATION_REQUEST",
    ARCHIVER_DEMANDE_OF_QUOTATION_SUCCESS: "ARCHIVER_DEMANDE_OF_QUOTATION_SUCCESS",
    ARCHIVER_DEMANDE_OF_QUOTATION_FAILURE: "ARCHIVER_DEMANDE_OF_QUOTATION_FAILURE",

    SELECT_DEMANDE_OF_QUOTATION_REQUEST: "SELECT_DEMANDE_OF_QUOTATION_REQUEST",
    SELECT_DEMANDE_OF_QUOTATION_SUCCESS: "SELECT_DEMANDE_OF_QUOTATION_SUCCESS",
    SELECT_DEMANDE_OF_QUOTATION_FAILURE: "SELECT_DEMANDE_OF_QUOTATION_FAILURE",

    SELECT_DEMANDE_OF_QUOTATION_CHOOSE_REQUEST: "SELECT_DEMANDE_OF_QUOTATION_CHOOSE_REQUEST",
    SELECT_DEMANDE_OF_QUOTATION_CHOOSE_SUCCESS: "SELECT_DEMANDE_OF_QUOTATION_CHOOSE_SUCCESS",
    SELECT_DEMANDE_OF_QUOTATION_CHOOSE_FAILURE: "SELECT_DEMANDE_OF_QUOTATION_CHOOSE_FAILURE",

    ADD_COTATION_TICKET: "ADD_COTATION_TICKET"
};
