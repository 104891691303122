import axios from "../../axios/index";

export const teamServices = {
    add,
    getAll,
    update,
}

async function getAll() {
    return await axios.get(`equipes`);
}

async function add(team) {
    return await axios.post(`equipes`, team);
}

async function update (team) {
    return await axios.put(`equipes/${team.id_equipe}`, team)
}