import { demande_cotationConstants } from "../_constants";
import { actionServices, demande_cotationServices } from "../_services";

const initialState = {
    loading: false,
    item: {},
    items: [],
    items_user: [],
    item_choose: {},
    filtrerDemande: [],
    fileredData: {},
    isFiltered: false,
    error: "",
};

export const demande_cotation = (state = initialState, action) => {
    switch (action.type) {
        case demande_cotationConstants.GETALL_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.GETALL_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.demande_cotation,
            };

        case demande_cotationConstants.GETALL_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.GETONE_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.GETONE_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items_user: action.demande_cotation,
            };

        case demande_cotationConstants.GETONE_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.GET_LINE_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.GET_LINE_DEMANDE_COTATION_SUCCESS:
            if (action.demande_cotation_line.length > 0) {
                // let tab_items = state.items.filter(
                //     (e) => e.id_demande !== action.demande_cotation_line[0].id_demande ? e : null);
                let item_current = state.items.filter(
                    (e) => e.id_demande === action.demande_cotation_line[0].id_demande ? e : null);
                item_current[0].cotations = action.demande_cotation_line;

                let indice = state.items.findIndex(objet => objet.id_demande === action.demande_cotation_line[0].id_demande);

                // let nouveauTableau = state.items.map((objet, index) => {
                //     if (index === indice) {
                //         return { ...objet, cotations: action.demande_cotation_line }; // Crée un nouvel objet avec la propriété modifiée
                //     } else {
                //         return objet; // Conserve les autres objets inchangés
                //     }
                // });

                const new_items = state.items.map((objet, index) => {
                    if (index === indice) {
                        return item_current[0]; // Remplace l'objet à l'indice spécifié
                    } else {
                        return objet; // Conserve les autres objets inchangés
                    }
                });

                // let new_items = [...tab_items, item_current[0]];
                // console.log('valLog', state.items.length, new_items.length)
                // let new_items = state.items.map(
                //     (e) => e.id_demande === action.demande_cotation_line[0].id_demande
                //         ? e.cotations = action.demande_cotation_line : null)

                return {
                    ...state,
                    loading: false,
                    items: new_items,
                };
            }
            else {
                return {
                    ...state,
                    loading: false,
                    items: state.items,
                };
            }

        case demande_cotationConstants.GET_LINE_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.ADD_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case demande_cotationConstants.ADD_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [action.demande_cotation, ...state.items],
                error: 0,
            };

        case demande_cotationConstants.ADD_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: "failed operation",
            };

        case demande_cotationConstants.UPDATE_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.UPDATE_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            p.id_demande === action.demande_cotation.id_demande
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.UPDATE_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.ADD_COTATION_TEMP:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_demande == action.id_demande) {
                            action.cotation.key = action.count;
                            p.cotations = [...p.cotations, action.cotation];
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.SAVE_COTATION_TEMP:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_demande == action.id_demande) {
                            let newData = [...p.cotations];
                            const index = newData.findIndex(
                                (item) => action.row.key == item.key
                            );
                            const item = newData[index];
                            newData.splice(index, 1, {
                                ...item,
                                ...action.row,
                            });
                            p.cotations = newData;

                            // p.cotations.splice(
                            //     p.cotations.findIndex(
                            //         (item) => action.row.key === item.key
                            //     ),
                            //     1,
                            //     { ...p.cotations, ...action.row }
                            // );
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.DELETE_COTATION_TEMP:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_demande == action.id_demande) {
                            p.cotations = [
                                ...p.cotations.filter(
                                    (pp) => pp.key != action.key
                                ),
                            ];
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.VALIDATE_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.VALIDATE_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            action.demande_cotation.id_demande
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            action.demande_cotation.id_demande
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.VALIDATE_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.VALIDATION_TEAM_CHEF_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.VALIDATION_TEAM_CHEF_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.VALIDATION_TEAM_CHEF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.ADD_TASK_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.VALIDATE_SEND_CUSTOMER_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            parseInt(action.demande_cotation.id_demande)
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.REFUS_CUSTOMER_DEMANDE_COTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.VALIDATION_DT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.VALIDATION_DT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            action.demande_cotation.id_demande
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.map((p) => {
                        if (
                            parseInt(p.id_demande) ===
                            action.demande_cotation.id_demande
                        ) {
                            Object.assign(p, action.demande_cotation);
                        }
                        return p;
                    }),
                ],
            };

        case demande_cotationConstants.VALIDATION_DT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.FILTRER_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                loading: false,
                fileredData: action.demande_cotation,
                filtrerDemande: [
                    ...state.items
                        .filter((p) =>
                            action.demande_cotation.id_projet !== null
                                ? parseInt(p.id_projet) ===
                                parseInt(action.demande_cotation.id_projet)
                                : p
                        )
                        .filter((pp) =>
                            action.demande_cotation.id_equipe !== null
                                ? parseInt(pp.id_equipe) ===
                                parseInt(action.demande_cotation.id_equipe)
                                : pp
                        )
                        .filter((ppp) =>
                            action.demande_cotation.id_statut !== null
                                ? parseInt(ppp.id_statut) ===
                                parseInt(action.demande_cotation.id_statut)
                                : ppp
                        )
                        .filter((pppp) =>
                            action.demande_cotation.id_client !== null
                                ? parseInt(pppp.id_client) ===
                                parseInt(action.demande_cotation.id_client)
                                : pppp
                        ),
                ],
            };

        case demande_cotationConstants.FILTERED_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                isFiltered: true,
            };

        case demande_cotationConstants.NOT_FILTERED_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                isFiltered: false,
                fileredData: {},
                // filtrerDemande: [],
            };

        case demande_cotationConstants.RESETE_FILTERED_DEMANDE_OF_QUOTATION:
            return {
                ...state,
                isFiltered: false,
                filtrerDemande: [],
            };

        case demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter(
                        (p) =>
                            parseInt(p.id_demande) !==
                            parseInt(action.demande_cotation)
                    ),
                ],
                filtrerDemande: [
                    ...state.filtrerDemande.filter(
                        (p) =>
                            parseInt(p.id_demande) !==
                            parseInt(action.demande_cotation)
                    ),
                ],
            };

        case demande_cotationConstants.DELETE_DEMANDE_OF_QUOTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                filtrerDemande: state.filtrerDemande.length > 0 ? [
                    ...state.filtrerDemande.filter((p) => p.id_demande !== action.demande_cotation.key),
                ] : [],
            };

        case demande_cotationConstants.ARCHIVER_DEMANDE_OF_QUOTATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.demande,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_FAILURE:
            return {
                ...state,
                loading: false,
                item: action.error,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.demande,
            };

        case demande_cotationConstants.SELECT_DEMANDE_OF_QUOTATION_CHOOSE_FAILURE:
            return {
                ...state,
                loading: false,
                item: action.error,
            };


        case demande_cotationConstants.ADD_COTATION_TICKET:
            const ok = state.items.filter((p) => p.id_demande === action.id ? p : null);
            var object = ok[0];

            // ajout de la cotation dans la liste des cotation du item
            state.item.cotations = [...object.cotations, ...action.ticket];
            // mise a jour des information du vrai item 
            state.item.adresse_client = object.adresse_client;
            state.item.created_by = object.created_by;
            state.item.date_edition = object.date_edition;
            state.item.first_name = object.first_name;
            state.item.id_client = object.id_client;
            state.item.id_color_projet = object.id_color_projet;
            state.item.id_color_statut_demande = object.id_color_statut_demande;
            state.item.id_demande = object.id_demande;
            state.item.id_direction = object.id_direction;
            state.item.id_equipe = object.id_equipe;
            state.item.id_projet = object.id_projet;
            state.item.id_responsable_demande = object.id_responsable_demande;
            state.item.id_statut = object.id_statut;
            state.item.id_type = object.id_type;
            state.item.is_validate = object.is_validate;
            state.item.key = object.key;
            state.item.libelle_demande = object.libelle_demande;
            state.item.libelle_equipe = object.libelle_equipe;
            state.item.libelle_projet = object.libelle_projet;
            state.item.libelle_statut_demande = object.libelle_statut_demande;
            state.item.libelle_type = object.libelle_type;
            state.item.nom_client = object.nom_client;
            state.item.projet_description = object.projet_description;

            for (let i = 0; i < state.item.cotations.length; i++) {
                state.item.cotations[i].key = i;
            }

            return {
                ...state,
                loading: false,
                item: state.item,
            };

        default:
            return state;
    }
};
