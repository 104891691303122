export const teamConstants = {
    ADD_REQUEST: 'ADD_TEAM_REQUEST',
    ADD_SUCCESS: 'ADD_TEAM_SUCCESS',
    ADD_FAILURE: 'ADD_TEAM_FAILURE',

    GETALL_REQUEST: 'GETALL_TEAM_REQUEST',
    GETALL_SUCCESS: 'GETALL_TEAM_SUCCESS',
    GETALL_FAILURE: 'GETALL_TEAM_FAILURE',

    UPDATE_REQUEST: 'UPDATE_TEAM_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_TEAM_FAILURE',

    SELECTED_TEAM_PROJETS: "SELECTED_TEAM_PROJETS"
}