import { profileConstants } from "../_constants";
import { profileService } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const profileActions = {
    add,
    getAll,
    update,
    // getOne,
    // remove,
};

function add(profiles) {
    return function (dispatch) {
        dispatch(request());

        profileService
            .add(profiles)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de l'ajout."));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return { type: profileConstants.ADD_REQUEST };
    }
    function success(profile) {
        return { type: profileConstants.ADD_SUCCESS, profile };
    }
    function failure(error) {
        return { type: profileConstants.ADD_FAILURE, error };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());

        profileService
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return { type: profileConstants.GETALL_REQUEST };
    }
    function success(profile) {
        return { type: profileConstants.GETALL_SUCCESS, profile };
    }
    function failure(error) {
        return { type: profileConstants.GETALL_FAILURE, error };
    }
}

function update(profile) {
    return function (dispatch) {
        dispatch(request());
        profileService
            .update(profile)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification."));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return { type: profileConstants.UPDATE_REQUEST };
    }
    function success(profile) {
        return { type: profileConstants.UPDATE_SUCCESS, profile };
    }
    function failure(error) {
        return { type: profileConstants.UPDATE_FAILURE, error };
    }
}