import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Select, Tabs, Form, Button, Input, Typography } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { teamActions, modalActions } from "../../../redux/_actions";

const { TabPane } = Tabs;
const { Option } = Select;
const { Text, Title } = Typography;

const ModalParametreEquipe = (props) => {
    const closable_setting_team = useSelector(
        (state) => state.modal.closable_setting_team
    );
    const users = useSelector((state) => state.user.user_without_team_and_current_team);
    // const team_members = useSelector((state) => state.team_menber.menbers);
    const directions = useSelector((state) => state.direction.items);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(teamActions.getAll());
    }, []);


    const teams = useSelector((state) => state.team.items);


    useEffect(() => {

        if (props.selectedTeamState === null) {
            form.setFieldsValue({
                libelle: props.updatedEquipe.libelle,
                id_user: props.updatedEquipe.id_user,
                id_direction: props.updatedEquipe.id_direction,
                color_equipe: props.updatedEquipe.color_equipe,
                type: props.updatedEquipe.type,
                color: props.updatedEquipe.color_equipe,
            });
        } else {
            let tabs = teams.filter((p) => p.id_equipe === props.selectedTeamState);
            form.setFieldsValue({
                libelle: tabs[0].libelle,
                id_user: tabs[0].id_user,
                id_direction: tabs[0].id_direction,
                color_equipe: tabs[0].color_equipe,
                type: tabs[0].type,
                color: tabs[0].color_equipe,
            });
        }
    });

    const modifierEquipe = (values) => {
        let data = {
            id_equipe: props.updatedEquipe.id_equipe,
            libelle: values.libelle,
            id_user: values.id_user,
            id_direction: values.id_direction,
            type: values.type,
            color: values.color,
        }
        dispatch(teamActions.update(data))
        dispatch(modalActions.close_setting_team())
    }

    return (
        <Modal
            title={<Title level={5} >Paramètre de l’équipe</Title>}
            visible={closable_setting_team}
            footer={null}
            onCancel={() => {
                dispatch(modalActions.close_setting_team());
            }}
            bodyStyle={{
                paddingTop: 0,
            }}
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Tabs defaultActiveKey="1" /*onChange={callback}*/>
                <TabPane tab="Équipes " key="1">
                    <Form
                        className="login-form"
                        onFinish={modifierEquipe}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="libelle"
                            label={<Text strong={true}>Libellé</Text>}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Veuillez saisir le libellé de l'équipe !",
                                },
                            ]}
                        >
                            <Input type="text" size="large" />
                        </Form.Item>
                        <Form.Item name="id_user"
                            label={<Text strong={true}>Chef d'équipe</Text>}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Veuillez choisir un chef de projet !",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                            >
                                {users.map((p) => (
                                    <Option value={p.id_user}>
                                        {p.first_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="id_direction" label={<Text strong={true}>Direction</Text>}>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                            >
                                {directions.map((p) => (
                                    <Option value={p.id_direction}>
                                        {p.libelle}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item
                                name="type"
                                label={<Text strong={true}>Type d'Equipe</Text>}
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginRight: 2,
                                    marginBottom: 0,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Veuillez renseigner le type !",
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    size="large"
                                    placeholder="Sélectionner un type"
                                    defaultValue={props.updatedEquipe.type}
                                >
                                    <Option value={0}>Secondaire</Option>
                                    <Option value={1}>Principale</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="color"
                                label={<Text strong={true}>Couleur</Text>}
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginRight: 2,
                                    marginBottom: 0,
                                }}
                            >
                                <Input size="large" type="color" placeholder="Choisir une couleur !" />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button size="large" type="primary" htmlType="submit">
                                <SaveOutlined /> Enregistrer
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                {/* <TabPane tab="Tab 2" key="2">
                    Content of Tab Pane 2
                </TabPane>
                <TabPane tab="Tab 3" key="3">
                    Content of Tab Pane 3
                </TabPane> */}
            </Tabs>
        </Modal>
    );
};

export default ModalParametreEquipe;
