import React, { useState, useEffect } from "react";
import "./DrawerDemandeDeCotation.css";
import {
    Drawer,
    Button,
    Form,
    Input,
    // Popconfirm,
    // Table,
    Select,
    DatePicker,
    InputNumber,
    // Alert,
    // Row,
    // Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    modalActions,
    team_menberActions,
    demande_cotationActions,
    userActions,
    ticketActions,
    projectActions,
} from "../../../redux/_actions";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
// import { atanh, json, parse } from "mathjs";
import { isEmpty, isNumber, isObject, parseInt, values } from "lodash";

Moment.globalMoment = moment;

Moment.globalLocale = "fr";

const { Option } = Select;

var i = 0;

const DrawerDemandeDeCotationTicket = (props) => {
    // console.log('valLog 0', props.demandeSelected)
    const [inputList, setInputList] = useState([]);
    const [formGlobal] = Form.useForm();
    const [clientExist, setClientExist] = useState('');

    const dispatch = useDispatch();

    const closable_add_demande_of_quotation = useSelector(
        (state) => state.modal.closable_add_demande_of_quotation
    );
    const project_member = useSelector((state) => state.user.project_member);
    const team_members = useSelector((state) => state.team_menber.menbers);
    const type_projets = useSelector((state) => state.type_project.items);
    const projects = useSelector((state) => state.project.items);
    const teams = useSelector((state) => state.team.items);
    const customers = useSelector((state) => state.customer.items);
    const auth = useSelector((state) => state.login.item);
    // const item_choose = useSelector((state) => state.demande_cotation.item);

    const projetsUser_auth = projects.filter((p) => p.id_equipe === auth.id_equipe);
    const check_project = projetsUser_auth.filter(
        (p) => parseInt(p.id_projet) === props.selectedProjectTicket ? p : null
    );

    const check_project_for_all = projects.find((p) => parseInt(p.id_projet) === props.selectedProjectTicket ? p : null);
    // Editable table
    useEffect(() => {
        dispatch(userActions.getAllMemberProject(props.selectedProjectTicket));
    }, [props, dispatch]);

    useEffect(() => {
        if (props.typeProjet !== 1) {
            if (Object.keys(props.demandeSelected).length !== 0 && i === 0) {
                // props.setTypeProjet(props.demandeSelected.id_type);
                setInputList(
                    props.demandeSelected.cotations !== undefined
                        ? props.demandeSelected.cotations
                        : []
                );
                formGlobal.setFieldsValue({
                    libelle_demande: props.demandeSelected.libelle_demande,
                    type_projet: props.demandeSelected.id_type,
                    projet: props.demandeSelected.id_projet,
                    equipe: props.demandeSelected.id_equipe,
                    responsable: props.demandeSelected.id_responsable_demande,
                    client: props.demandeSelected.id_client,
                    date_edition: moment(props.demandeSelected.date_edition),
                    modified_by: parseInt(auth.id_user),
                    cotation: props.demandeSelected.cotations,
                });

                props.setCount(
                    props.demandeSelected.cotations !== undefined
                        ? props.demandeSelected.cotations.length
                        : 0
                );
                i++;
            }
        }
        else {
            formGlobal.setFieldsValue({
                projet: props.selectedProjectTicket
            });

            // si c'est un chef de projet qui se connect 
            if (check_project.length > 0) {
                const info_client = customers.filter(
                    (p) => parseInt(p.id_client) === check_project[0].id_client ? p : null
                )
                if (info_client.length > 0) {
                    setClientExist(info_client[0].nom_client);
                    formGlobal.setFieldsValue({
                        client: parseInt(check_project[0].id_client)
                    });
                } else {
                    formGlobal.setFieldsValue({
                        client: null,
                    });
                }
            }
            // si c'est un l'admin ou quelqu'un qui a 
            // accès au module tickets
            else {
                if (check_project_for_all !== undefined) {
                    const info_client = customers.filter(
                        (p) => parseInt(p.id_client) === check_project_for_all.id_client ? p : null
                    )
                    if (info_client.length > 0) {
                        setClientExist(info_client[0].nom_client);
                        formGlobal.setFieldsValue({
                            client: parseInt(check_project_for_all.id_client),
                            equipe: parseInt(check_project_for_all.id_equipe)
                        });
                    } else {
                        formGlobal.setFieldsValue({
                            client: null,
                            equipe: check_project_for_all.id_equipe
                        });
                    }
                }
            }
        }
    });

    const submitDemandeDeCotation = (values) => {
        const data = {
            libelle_demande: values.libelle_demande,
            type_projet: values.type_projet,
            id_projet: parseInt(props.selectedProjectTicket),
            id_equipe: parseInt(values.equipe),
            id_user: parseInt(values.responsable),
            id_client: values.client,
            date_edition: moment(values.date_edition).format("YYYY-MM-DD"),
            created_by: parseInt(auth.id_user),
            cotation: inputList,
        };
        dispatch(demande_cotationActions.add(data));
        dispatch(
            ticketActions.filter_ticket_by_project_from_getAll(0)
        );
        dispatch(
            ticketActions.filter_ticket_by_project_from_getAll(
                parseInt(props.selectedProjectTicket)
            )
        );
        dispatch(
            demande_cotationActions.select_demande_of_quotation(
                parseInt(props.selectedProjectTicket)
            )
        );
        functionResetField(inputList.length);
        formGlobal.resetFields();
        setInputList([]);
        props.setInputTicket([]);
        props.setDemandeSelected({});
        props.setTypeProjet(1);
        setClientExist('');
        props.setChecked(false);
        props.setCount(0);
        i = 0;
        dispatch(modalActions.close_add_demande_of_quotation());
    };

    const functionResetField = (count) => {
        for (var j = 0; j < count; j++) {
            formGlobal.setFieldsValue({});
            formGlobal.resetFields();
        }
    }

    const updateDemandeDeCotation = (values) => {
        if (auth.id_profil === 3) {
            var data = {
                id_demande: props.demandeSelected.id_demande,
                libelle_demande: values.libelle_demande,
                type_projet: props.demandeSelected.id_type,
                id_projet:
                    parseInt(props.typeProjet) === 2
                        ? props.demandeSelected.id_projet
                        : props.demandeSelected.libelle_projet,
                id_equipe: props.demandeSelected.id_equipe,
                id_user: props.demandeSelected.id_responsable_demande,
                id_client: props.demandeSelected.id_client,
                date_edition: moment(props.demandeSelected.date_edition).format(
                    "YYYY-MM-DD"
                ),
                modified_by: parseInt(auth.id_user),
                cotation: inputList,
            };
        } else {
            var data = {
                id_demande: props.demandeSelected.id_demande,
                libelle_demande: values.libelle_demande,
                type_projet: values.type_projet,
                id_projet: values.projet,
                id_equipe: parseInt(values.equipe),
                id_user: parseInt(values.responsable),
                id_client: parseInt(values.client),
                date_edition: moment(values.date_edition).format("YYYY-MM-DD"),
                modified_by: parseInt(auth.id_user),
                cotation: inputList,
            };
        }
        dispatch(demande_cotationActions.update(data));
        dispatch(
            ticketActions.filter_ticket_by_project_from_getAll(0)
        );
        dispatch(
            ticketActions.filter_ticket_by_project_from_getAll(
                parseInt(props.selectedProjectTicket)
            )
        );
        dispatch(
            demande_cotationActions.select_demande_of_quotation(
                parseInt(props.selectedProjectTicket)
            )
        );
        formGlobal.resetFields();
        setInputList([]);
        props.setInputTicket([]);
        props.setDemandeSelected({});
        props.setTypeProjet(1);
        setClientExist('');
        props.setChecked(false);
        props.setCount(0);
        i = 0;
        dispatch(modalActions.close_add_demande_of_quotation());
    };

    const scr = {
        y: 335,
    };

    // handle input change
    const handleInputChange = (e, index) => {

        if (isObject(e)) {
            let { name, value } = e.target;
            const list = [...inputList];
            var n = name.split("-");
            list[index][n[0]] = value;
            setInputList(list);
        } else {
            // let { name, value } = e.target;
            const list = [...inputList];
            var name = "duree-" + index;
            var n = name.split("-");
            list[index][n[0]] = e;
            setInputList(list);
        }
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList.filter((p) => p.key !== index)];
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        const newData = {
            key: props.count,
            module: "",
            tache: "",
            description: "",
            duree: null,
            observation: "",
        };

        setInputList([...inputList, newData]);

        props.setCount(props.count + 1);
    };

    const dureeTotal = (duree) => {
        if (duree !== undefined && duree !== null) {
            let sum = 0;
            let i = 0;

            while (i < duree.length) {

                if (isNumber(duree[i]["duree"])) {
                    sum = sum + parseFloat(duree[i]["duree"]);
                }
                i++;
            }

            let result = sum / 8;
            return result.toFixed(2);
        }
    };

    return (
        <Drawer
            title={
                Object.keys(props.demandeSelected).length === 0
                    ? "Ajouter une demande de cotation"
                    : Object.keys(props.demandeSelected).length != 0
                        ? "Modifier une demande de cotation"
                        : null
            }
            placement="right"
            // closable={false}
            width={"100%"}
            mask={false}
            style={{
                marginTop: 50,
            }}
            onClose={() => {
                dispatch(
                    ticketActions.filter_ticket_by_project_from_getAll(0)
                );
                dispatch(
                    ticketActions.filter_ticket_by_project_from_getAll(
                        parseInt(props.selectedProjectTicket)
                    )
                );
                dispatch(
                    demande_cotationActions.select_demande_of_quotation(
                        parseInt(props.selectedProjectTicket)
                    )
                );
                formGlobal.resetFields();
                setInputList([]);
                props.setInputTicket([]);
                props.setDemandeSelected({});
                props.setTypeProjet(1);
                setClientExist('');
                props.setChecked(false);
                props.setCount(0);
                i = 0;
                dispatch(modalActions.close_add_demande_of_quotation());
            }}
            visible={closable_add_demande_of_quotation}
            closable={true}
            className="demande"
            afterVisibleChange={(e) => {
                if (
                    Object.keys(props.demandeSelected).length === 0 &&
                    e === true
                ) {
                    formGlobal.setFieldsValue({
                        type_projet: 2,
                    });
                    setInputList(props.inputTicket);
                }
            }}
        >
            {/* <Button onClick={() => alert(JSON.stringify(inputList, null, 2))}>
                Test
            </Button> */}
            <Form
                form={formGlobal}
                name="basic"
                onFinish={
                    Object.keys(props.demandeSelected).length === 0
                        ? submitDemandeDeCotation
                        : updateDemandeDeCotation
                }
                layout="vertical"
            >
                <Form.Item>
                    <Form.Item
                        label="Libelle"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 6,
                            marginRight: 10,
                            marginBottom: 0,
                        }}
                        name="libelle_demande"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez renseigner le libelle de la fiche de cotation",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Libelle fiche de cotation"
                            disabled={
                                auth.id_profil === 3
                                    ? true
                                    : false
                            }
                            maxLength="50"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Type projet"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginRight: 6,
                            marginBottom: 0,
                        }}
                        name="type_projet"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner le type de projet",
                            },
                        ]}
                    >
                        <Select
                            onChange={(values) => {
                                props.setTypeProjet(values);
                                if (auth.id_profil === 2) {
                                    dispatch(
                                        team_menberActions.getAllTeamMenber({
                                            id_equipe: auth.id_equipe,
                                        })
                                    );
                                    formGlobal.setFieldsValue({
                                        projet: null,
                                        responsable: null,
                                    });
                                } else {
                                    formGlobal.setFieldsValue({
                                        equipe: null,
                                        projet: null,
                                        responsable: null,
                                    });
                                }
                            }}
                            disabled={true}
                        >
                            {type_projets.map((p) => (
                                <Option value={p.id_type}>
                                    {p.libelle_type}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Projet"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 6,
                            marginRight: 6,
                            marginBottom: 0,
                        }}
                        name="projet"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez sélectionner le projet",
                            },
                        ]}
                    >
                        {
                            props.typeProjet === 2 || props.typeProjet === 1 ? (
                                <Select
                                    onChange={(values) => {
                                        dispatch(
                                            userActions.getAllMemberProject(
                                                parseInt(values)
                                            )
                                        );
                                        if (
                                            projects.find(
                                                (p) => p.id_projet == values
                                            ).id_equipe !== undefined
                                        ) {
                                            formGlobal.setFieldsValue({
                                                equipe: parseInt(
                                                    projects.find(
                                                        (p) => p.id_projet == values
                                                    ).id_equipe
                                                ),
                                            });
                                        } else {
                                            formGlobal.setFieldsValue({
                                                equipe: null,
                                                responsable: null,
                                            });
                                        }
                                    }}
                                    disabled={auth.id_profil === 3 || props.selectedProjectTicket !== null ? true : false}
                                >
                                    {auth.id_profil === 2
                                        ? projects
                                            .filter(
                                                (pp) =>
                                                    pp.id_equipe == auth.id_equipe
                                            )
                                            .map((p) => (
                                                <Option
                                                    value={parseInt(p.id_projet)}
                                                    style={{
                                                        borderLeft: `5px solid ${p.color}`,
                                                        margin: 2,
                                                    }}
                                                >
                                                    {p.libelle}
                                                </Option>
                                            ))
                                        : projects.map((p) => (
                                            <Option
                                                value={parseInt(p.id_projet)}
                                                style={{
                                                    borderLeft: `5px solid ${p.color}`,
                                                    margin: 2,
                                                }}
                                            >
                                                {p.libelle}
                                            </Option>
                                        ))}
                                </Select>
                            ) : (
                                <Input disabled={true} />
                            )}
                    </Form.Item>
                    <Form.Item
                        label="Équipe"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 4,
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                        name="equipe"
                        initialValue={
                            auth.id_profil === 2 ? auth.id_equipe : null
                        }
                    >
                        <Select
                            allowClear
                            disabled={
                                auth.id_profil === 2 ||
                                    props.typeProjet === 2 ||
                                    auth.id_profil === 3 || props.selectedProjectTicket !== 0
                                    ? true
                                    : false
                            }
                            onChange={(values) => {
                                dispatch(
                                    team_menberActions.getAllTeamMenber({
                                        id_equipe: values,
                                    })
                                );
                                formGlobal.setFieldsValue({
                                    responsable: null,
                                });
                            }}
                        >
                            {teams.map((p) => (
                                <Option
                                    value={parseInt(p.id_equipe)}
                                    style={{
                                        borderLeft: `5px solid ${p.color}`,
                                        margin: 2,
                                    }}
                                >
                                    {p.libelle}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Responsable"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 4,
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                        name="responsable"
                    >
                        <Select
                            allowClear
                            disabled={auth.id_profil === 3 ? true : false}
                        >
                            {props.typeProjet === 1 & props.selectedProjectTicket === undefined
                                ? team_members.map((p) => (
                                    <Option
                                        value={parseInt(p.id_user)}
                                        style={{
                                            margin: 2,
                                        }}
                                    >
                                        {p.first_name}
                                    </Option>
                                ))
                                : project_member.map((p) => (
                                    <Option
                                        value={parseInt(p.id_user)}
                                        style={{
                                            margin: 2,
                                        }}
                                    >
                                        {p.first_name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Client"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 6,
                            marginRight: 6,
                            marginBottom: 0,
                        }}
                        name="client"
                        // rules={parseInt(props.typeProjet) !== 1 || clientExist !== '' ? (
                        //     [
                        // {
                        //     required: true,
                        //     message:
                        //         "Ce projet existe déja, alors il doit appartenir à un client !",
                        // },
                        //     ]
                        // ) : null
                        // }
                        rules={
                            [
                                {
                                    required: true,
                                    message:
                                        "Veuillez choisir un client !",
                                },
                            ]
                        }
                    >

                        <Select
                            allowClear
                        // showSearch
                        // optionFilterProp="children"
                        // disabled={auth.id_profil === 3 || clientExist !== '' ? true : false}
                        >
                            {customers.map((p) => (
                                <Option
                                    value={p.id_client}
                                    style={{
                                        margin: 2,
                                    }}
                                >
                                    {p.nom_client}
                                </Option>
                            ))}
                        </Select>

                    </Form.Item>
                    <Form.Item
                        label="Date d'édition"
                        style={{
                            display: "inline-block",
                            width: "calc(13% - 0px)",
                            marginLeft: 6,
                            marginBottom: 0,
                        }}
                        name="date_edition"
                        initialValue={
                            Object.keys(props.demandeSelected).length === 0
                                ? moment()
                                : null
                        }
                    >
                        <DatePicker
                            allowClear={true}
                            allowEmpty={false}
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            disabled={auth.id_profil === 3 ? true : false}
                        />
                    </Form.Item>
                </Form.Item>
                {/* <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columnss}
                    size="small"
                    pagination={false}
                    scroll={scr}
                /> */}
                <p
                    style={{
                        fontSize: 14,
                        marginBottom: 5,
                        textAlign: "right",
                    }}
                >
                    Durée:{" "}
                    {isNaN(
                        dureeTotal(
                            inputList.filter(
                                (p) => p.duree !== null
                            )
                        )
                    ) !== true
                        ? dureeTotal(inputList)
                        : null}{" "}
                    jours
                </p>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#f0f0f0",
                        padding: 5,
                        color: "rgba(0, 0, 0, 0.85)",
                    }}
                >
                    <div style={{ flex: 4 }}>Module</div>
                    <div style={{ flex: 4 }}>tâche</div>
                    <div style={{ flex: 4 }}>Description</div>
                    <div style={{ flex: 4 }}>Durée</div>
                    <div style={{ flex: 4 }}>Observation</div>
                    <div style={{ flex: 1, paddingLeft: 10 }}>
                        <PlusOutlined onClick={handleAddClick} />
                    </div>
                </div>
                {inputList.map((p, i) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "5px 0px",
                            borderBottom: "1px solid #f0f0f0",
                        }}
                    >
                        <div style={{ flex: 4, paddingRight: 5 }}>
                            <Input
                                name="module"
                                value={p.module}
                                onChange={(e) => handleInputChange(e, p.key)}
                            />
                        </div>
                        <div style={{ flex: 4, paddingRight: 5 }}>
                            <Form.Item
                                // name={"tache-" + p.key}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Libelle de la tache est obligatoire",
                                    },
                                ]}
                                // initialValue={p.tache}
                                style={{ marginBottom: 0 }}
                            >
                                <Input.TextArea
                                    name={"tache-" + p.key}
                                    // defaultValue={p.tache}
                                    value={p.tache}
                                    onChange={(e) =>
                                        handleInputChange(e, p.key)
                                    }
                                    autoSize={true}
                                    required
                                />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 4, paddingRight: 5 }}>
                            {/* <Form.Item name="description"> */}
                            <Input.TextArea
                                name="description"
                                value={p.description}
                                onChange={(e) => handleInputChange(e, p.key)}
                                autoSize={true}
                            />
                            {/* </Form.Item> */}
                        </div>
                        <div style={{ flex: 4, paddingRight: 5 }}>
                            <Form.Item
                                name={"duree-" + p.key}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Durée de la tache est obligatoire",
                                    },
                                ]}
                                initialValue={p.duree}
                            >
                                <InputNumber
                                    name={"duree-" + p.key}
                                    style={{
                                        width: "100%",
                                    }}
                                    min="0"
                                    max="8"
                                    step="0.0"
                                    onChange={(e) =>
                                        handleInputChange(e, p.key)
                                    }
                                    stringMode
                                />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 4, paddingRight: 5 }}>
                            {/* <Form.Item name="observation"> */}
                            <Input
                                name="observation"
                                value={p.observation}
                                onChange={(e) => handleInputChange(e, p.key)}
                            />
                            {/* </Form.Item> */}
                        </div>
                        <div style={{ flex: 1, paddingLeft: 10 }}>
                            {inputList.length !== 0 && (
                                <MinusOutlined
                                    onClick={() => handleRemoveClick(p.key)}
                                />
                            )}
                        </div>
                    </div>
                ))}

                <Form.Item style={{ marginTop: 20, float: "right" }}>
                    <Form.Item>
                        <Button
                            type="primary"
                            style={{ marginRight: 4 }}
                            htmlType={"submit"}
                        >
                            Enregistrer
                        </Button>
                        <Button
                            style={{ marginLeft: 4 }}
                            onClick={() => {
                                dispatch(
                                    ticketActions.filter_ticket_by_project_from_getAll(0)
                                );
                                dispatch(
                                    ticketActions.filter_ticket_by_project_from_getAll(
                                        parseInt(props.selectedProjectTicket)
                                    )
                                );
                                dispatch(
                                    demande_cotationActions.select_demande_of_quotation(
                                        parseInt(props.selectedProjectTicket)
                                    )
                                );
                                formGlobal.resetFields();
                                setInputList([]);
                                props.setInputTicket([]);
                                props.setDemandeSelected({});
                                props.setTypeProjet(1);
                                setClientExist('');
                                props.setChecked(false);
                                props.setCount(0);
                                i = 0;
                                dispatch(
                                    modalActions.close_add_demande_of_quotation()
                                )
                            }}
                        >
                            Annuler
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </Drawer >
    );
};

export default DrawerDemandeDeCotationTicket;
