import React, { useState } from 'react';
import {
    Divider,
    Spin,
    Card,
    Empty,
    Typography,
    Tabs,
    Breadcrumb,
} from "antd";
import {
    HomeOutlined,
    PlusOutlined,
    FileProtectOutlined,
    AlignLeftOutlined,
    CalendarOutlined
} from "@ant-design/icons";
import { modalActions, customerActions, taskActions, demande_cotationActions } from "../../../redux/_actions";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from 'react-redux';
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import HeaderApp from "../../parchalse/HeaderApp";
import ModalAddClient from "../../components/Modal/ModalAddClient"
import GestionClientNew from "../../components/GestionClients/GestionClientNew"
import ModalAjouterClient from "../../components/Modal/ModalAjouterClient"
import ProjetNew from "../../components/Projet/ProjetNew"
import CustomerCotation from "../../components/GestionClients/CustomerCotation"
import CustomerPlanning from "../../components/GestionClients/CustomerPlanning"
import { useEffect } from 'react';


const { Text } = Typography;
const { TabPane } = Tabs;


const GestionClients = () => {

    const customers = useSelector((state) => state.customer.items);
    const loading = useSelector((state) => state.customer.loading);
    const user = useSelector((state) => state.login);
    const customerChoose = useSelector((state) => state.customer.item_choose);
    const projets = useSelector((state) => state.project.items);


    // const customerWithProject = useSelector((state) => state.customer.items);
    const customerWithProject = customers.filter(e => {
        return projets.some(item => item.id_client === e.id_client); // take the ! out and you're done
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(demande_cotationActions.getAll());
        dispatch(taskActions.dropTaskCustomer());
        // dispatch(customerActions.dropCheckCustomer());
    }, []);

    const project_customer = Object.keys(customerChoose).length > 0 ?
        projets.filter((p) => p.id_client === customerChoose.id_client)
        : [];

    const ability = defineAbilityFor(user);

    const [selectedCustomer, setSelectedCustomer] = useState({});

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Gestion des clients</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    padding: 18,
                    paddingTop: 0,
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: "85.4%",
                }}
            >
                {/* Pour voir les clients et effectuer des operations sur les clients */}
                <Can I="LireClient" a="Gestion des clients" ability={ability}>
                    <div
                        style={{
                            flex: 2,
                            backgroundColor:
                                //     user.item.id_equipe !== null
                                //         ? "white"
                                //         : "#f8f8ff",
                                "#f8f8ff",
                            padding: 10,
                            borderRight: "2px solid #f0f0f0",

                        }}
                    >
                        <Text>
                            {user.item.id_equipe !== null
                                ? user.item.libelle_equipe
                                : "Membres"}
                        </Text>
                        <Divider
                            style={{
                                margin: "10px 0",
                                backgroundColor: "#f0f0f0",
                            }}
                        />
                        <Can
                            I="OperationClients"
                            a="Gestion des clients"
                            ability={ability}
                        >
                            <Card
                                style={{
                                    margin: 5,
                                    borderRadius: 5,
                                    backgroundColor: "#dedede",
                                    border: "2px dashed black",
                                    height: 64,
                                    minWidth: 260,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    dispatch(
                                        modalActions.show_modal_add_client()
                                    );
                                }}
                            >
                                <PlusOutlined />
                            </Card>
                        </Can>
                        {user.item.id_equipe !== null ? (
                            <Scrollbars
                                autoHide
                                style={{
                                    background: "white",
                                    height: "68vh",
                                }}
                            >
                                <Spin
                                    tip="Chargement en cours..."
                                    spinning={loading}
                                >
                                    {customerWithProject.map((p) => (
                                        <GestionClientNew
                                            p={p}
                                            setSelectedCustomer={
                                                setSelectedCustomer
                                            }
                                        />
                                    ))}
                                </Spin>
                            </Scrollbars>
                        ) : null}
                    </div>
                </Can>

                {/* afficher les projets et les cotations appartenant au client  */}
                <Can I="DetailsClient" a="Gestion des clients" ability={ability}>
                    <div
                        style={{
                            flex: 8,
                            backgroundColor:
                                user.item.id_equipe !== null
                                    ? "white"
                                    : "#f8f8ff",
                            padding: 10,
                        }}
                    >
                        <Tabs
                            // onChange={(value) =>
                            //     console.log('ok')
                            // }
                            defaultActiveKey="1"
                        >
                            <TabPane tab={<Text><FileProtectOutlined /> Projets</Text>} key="1">
                                {/* <CustomerProject /> */}
                                {
                                    project_customer.length > 0 ? (
                                        project_customer.map((p) => (
                                            <ProjetNew
                                                p={p}
                                            />
                                        ))
                                    ) : (
                                        <Empty
                                            description={
                                                <Text>
                                                    Aucun
                                                    projet
                                                    trouvé.
                                                </Text>
                                            }
                                        />
                                    )
                                }
                            </TabPane>
                            <TabPane tab={<Text><AlignLeftOutlined /> Cotations</Text>} key="2">
                                <CustomerCotation />
                            </TabPane>
                            <TabPane tab={<Text><CalendarOutlined />Planning</Text>} key="3">
                                <CustomerPlanning />
                            </TabPane>
                        </Tabs>
                    </div>
                </Can>
            </div>
            <ModalAddClient />
            <ModalAjouterClient
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
            />
        </div>
    )
}

export default GestionClients;