import React, { useEffect, useState } from "react";
import { Modal, Select, Form, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    demande_cotationActions,
    modalActions,
    team_projectActions,
} from "../../../redux/_actions";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

const ModalFilterDemandeCotation = () => {

    // constante id_profil de l'admin
    const admin_profil = 1;

    const [disableTeam, setDisableTeam] = useState(false);
    // const [changeTeamSelect, setChangeTeamSelect] = useState(false);

    const closable_filter_demande_of_quotation = useSelector(
        (state) => state.modal.closable_filter_demande_of_quotation
    );
    const teams = useSelector((state) => state.team.items);
    const customers = useSelector((state) => state.customer.items);
    const statut_demandes = useSelector((state) => state.statut_demande.items);
    const projects = useSelector((state) => state.project.items);
    const fileredData = useSelector(
        (state) => state.demande_cotation.fileredData
    );
    const team_project = useSelector((state) => state.team_project.team_member_project);

    const auth = useSelector((state) => state.login.item);
    // verification si user_login est un chef de projet
    const if_chef = teams.filter((p) => p.id_user === parseInt(auth.id_user));

    const [group_projet, setGroupProjet] = useState(projects);
    const [group_team, setGroupTeam] = useState(teams);
    const [group_statut,] = useState(statut_demandes);
    const [group_costomer,] = useState(customers);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const filtrerDemande = (values) => {
        dispatch(demande_cotationActions.filtrer_demande_of_quotation(values));
        dispatch(demande_cotationActions.filtered_demande_of_quotation());
        dispatch(modalActions.close_filter_demande_of_cotation());
        form.resetFields();
        setDisableTeam(false);
    };

    useEffect(() => {

        // trie des equipes en fonction du profil qui c'est connecté
        setGroupTeam(
            auth.id_profil === admin_profil
                ? teams
                : teams.filter((p) => p.id_equipe === auth.id_equipe)
        )

        // trie des projets en fonction du profil qui c'est connecté
        setGroupProjet(
            auth.id_profil === admin_profil
                ? projects
                : if_chef.length > 0
                    ? projects.filter((p) => p.id_equipe === auth.id_equipe)
                    : team_project
        )

        // if (auth.id_profil !== admin_profil) {
        //     setDisableTeam(true);
        //     form.setFieldsValue({
        //         id_equipe: auth.id_equipe,
        //     });
        // }



    }, []);

    useEffect(() => {

        if (fileredData.id_projet !== undefined
            || fileredData.id_statut !== undefined
            || fileredData.id_client
        ) {
            form.setFieldsValue({
                id_equipe: auth.id_profil === admin_profil ? fileredData.id_equipe : auth.id_equipe,
                id_projet: fileredData.id_projet,
                id_statut: fileredData.id_statut,
                id_client: fileredData.id_client,
            });
        }

    });

    return (
        <Modal
            title="Filtrer les demandes"
            visible={closable_filter_demande_of_quotation}
            onCancel={() => {
                dispatch(modalActions.close_filter_demande_of_cotation());
                dispatch(demande_cotationActions.not_filtered_demande_of_quotation());
                form.resetFields();
                setDisableTeam(false);
                // setChangeTeamSelect(false);
            }}
            footer={null}
            bodyStyle={{
                paddingTop: 10,
            }}
        >
            {/* <Button onClick={() => alert(disableTeam)}>Test</Button> */}
            <Form
                name="basic"
                form={form}
                initialValues={{
                    id_projet: null,
                    id_equipe:
                        auth.id_profil === 2 ? parseInt(auth.id_equipe) : null,
                    id_statut: null,
                    id_client: null,
                }}
                onFinish={filtrerDemande}
            >
                <Form.Item>
                    <Form.Item
                        label="Projet"
                        name="id_projet"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            onChange={(values) => {
                                // if (values !== undefined) {
                                //     setDisableTeam(true);
                                // } else {
                                //     setDisableTeam(false);
                                // }
                                if (
                                    projects.find(
                                        (p) => parseInt(p.id_projet) === parseInt(values)
                                    ) !== undefined &&
                                    projects.find((p) => parseInt(p.id_projet) === parseInt(values))
                                        .id_equipe !== undefined
                                ) {
                                    form.setFieldsValue({
                                        id_equipe: parseInt(
                                            projects.find(
                                                (p) => parseInt(p.id_projet) === parseInt(values)
                                            ).id_equipe
                                        ),
                                    });
                                    // setDisableTeam(true);
                                } else {
                                    form.setFieldsValue({
                                        id_equipe: null,
                                    });
                                    // setDisableTeam(false);
                                }
                            }}
                        >
                            {
                                group_projet.map((p) => (
                                    <Option value={parseInt(p.id_projet)}>
                                        {p.libelle}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Equipe"
                        name="id_equipe"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginLeft: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            onChange={(values) => {
                                // if (values === undefined) {
                                //     setChangeTeamSelect(false);
                                // } else {
                                dispatch(
                                    team_projectActions.getAllTeamProject({
                                        id_equipe: values,
                                    })
                                );
                                form.setFieldsValue({
                                    id_projet: null,
                                });
                                //     setChangeTeamSelect(true);
                                // }
                            }}
                            disabled={
                                disableTeam || auth.id_profil === 2
                                    ? true
                                    : false
                            }
                        >
                            {group_team.map((p) => (
                                <Option value={parseInt(p.id_equipe)}>
                                    {p.libelle}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Form.Item
                        label="Etat"
                        name="id_statut"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            // onChange={handleChange}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                        >
                            {group_statut.map((p) => (
                                <Option value={parseInt(p.id_statut)}>
                                    {p.libelle}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Client"
                        name="id_client"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginLeft: 4,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            // onChange={handleChange}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                        >
                            {group_costomer.map((p) => (
                                <Option value={parseInt(p.id_client)}>
                                    {p.nom_client}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<FilterOutlined />}
                        style={{ marginRight: 10 }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="default"
                        onClick={() =>
                            dispatch(
                                modalActions.close_filter_demande_of_cotation()
                            )
                        }
                    >
                        Annuler
                    </Button>
                    <Button
                        type="default"
                        style={{
                            float: "right",
                            marginRight: 10,
                            borderColor: "red",
                            color: "red",
                        }}
                        onClick={() => {
                            dispatch(
                                demande_cotationActions.reset_filtered_demande_of_quotation()
                            );
                            dispatch(modalActions.close_filter_demande_of_cotation());
                            dispatch(demande_cotationActions.not_filtered_demande_of_quotation());
                            form.resetFields();
                            setDisableTeam(false);
                        }}
                    >
                        Effacer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalFilterDemandeCotation;
