import { team_projectConstants } from "../_constants";

const initialState = {
  loading: false,
  added: {},
  deleted: {},
  updated: {},
  tab: [],
  items: [],
  team_projects: [],
  filterProject: [],
  filterProjectUser: [],
  team_member_project: [],
  selectedProject: {},
  searchVeri: "",
  item: {},
  checkedAll: false,
  error: "",
};

export const team_project = (state = initialState, action) => {
  switch (action.type) {
    case team_projectConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.project,
      };

    case team_projectConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.GETALL_TEAM_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.GETALL_TEAM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_projects: action.team_project,
        filterProject: [
          ...action.team_project.filter((p) => p.id_statut_projet === 1),
        ],
      };

    case team_projectConstants.GETALL_TEAM_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.ADD_TEAM_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.ADD_TEAM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_projects: [...state.team_projects, ...action.team_project],
        filterProject: [...state.filterProject, ...action.team_project],
      };

    case team_projectConstants.ADD_TEAM_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.AFFECT_TEAM_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.AFFECT_TEAM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_projects: [...state.team_projects, ...action.team_project],
      };

    case team_projectConstants.AFFECT_TEAM_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.UPDATE_TEAM_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.UPDATE_TEAM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_projects: [
          ...state.team_projects.map((p) => {
            if (
              parseInt(p.id_projet) ===
              parseInt(action.team_project[0].id_projet)
            ) {
              Object.assign(p, action.team_project[0]);
            }
            return p;
          }),
        ],
        filterProject: [
          ...state.filterProject.map((p) => {
            if (
              parseInt(p.id_projet) ===
              parseInt(action.team_project[0].id_projet)
            ) {
              Object.assign(p, action.team_project[0]);
            }
            return p;
          }),
        ],
        items: [
          ...state.items.map((p) => {
            if (
              parseInt(p.id_projet) ===
              parseInt(action.team_project[0].id_projet)
            ) {
              Object.assign(p, action.team_project[0]);
            }
            return p;
          }),
        ],
      };

    case team_projectConstants.UPDATE_TEAM_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_member_project: [...action.team_member_project],
        filterProjectUser: [
          ...action.team_member_project.filter((p) => p.id_statut_projet === 1),
        ],
      };

    case team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.team_project,
      };

    case team_projectConstants.ADD_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case team_projectConstants.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.project],
      };

    case team_projectConstants.ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [
          ...state.items.map((p) => {
            if (
              parseInt(p.id_projet) === parseInt(action.project[0].id_projet)
            ) {
              Object.assign(p, action.project[0]);
            }
            return p;
          }),
        ],
      };

    case team_projectConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case team_projectConstants.SEARCH_PROJECT:
      return {
        ...state,
        tab:
          action.team_project !== ""
            ? [
              ...state.items.filter((p) =>
                p.libelle
                  .toLowerCase()
                  .includes(action.team_project.toLowerCase())
              ),
            ]
            : [],
        searchVeri: action.team_project !== "" ? action.team_project : "",
      };

    case team_projectConstants.SEARCH_PROJECT_CHEF:
      return {
        ...state,
        tab:
          action.team_project !== ""
            ? [
              ...state.team_projects.filter((p) =>
                p.libelle
                  .toLowerCase()
                  .includes(action.team_project.toLowerCase())
              ),
            ]
            : [],
        searchVeri: action.team_project !== "" ? action.team_project : "",
      };

    case team_projectConstants.SEARCH_PROJECT_DEV:
      return {
        ...state,
        tab:
          action.team_project !== ""
            ? [
              ...state.team_member_project.filter((p) =>
                p.libelle
                  .toLowerCase()
                  .includes(action.team_project.toLowerCase())
              ),
            ]
            : [],
        searchVeri: action.team_project !== "" ? action.team_project : "",
      };

    case team_projectConstants.RESET_TEAM_PROJECT:
      return {
        ...state,
        team_projects: [],
      };

    case team_projectConstants.FILTER_IN_PROGRESS_PROJECT:
      return {
        ...state,
        filterProject: [
          ...state.team_projects.filter((p) => p.id_statut_projet === 1),
        ],
        filterProjectUser: [
          ...state.team_member_project.filter((p) => p.id_statut_projet === 1),
        ],
      };

    case team_projectConstants.FILTER_ARCHIVED_PROJECT:
      return {
        ...state,
        filterProject: [
          ...state.team_projects.filter((p) => p.id_statut_projet === 2),
        ],
        filterProjectUser: [
          ...state.team_member_project.filter((p) => p.id_statut_projet === 2),
        ],
      };

    case team_projectConstants.FILTER_ALL_PROJECT:
      return {
        ...state,
        filterProject: [...state.team_projects],
        filterProjectUser: [...state.team_member_project],
      };

    case team_projectConstants.RESET_SEARCH:
      return {
        ...state,
        searchVeri: "",
        tab: []
      };

    case team_projectConstants.RESET_FILTER_PROJECT_USER:
      return {
        ...state,
        searchVeri: "",
        filterProjectUser: []
      };

    case team_projectConstants.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case team_projectConstants.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_projects: [
          ...state.team_projects.filter((p) => parseInt(p.id_projet) !== parseInt(action.project)),
        ],
        filterProject: [
          ...state.filterProject.filter((p) => parseInt(p.id_projet) !== parseInt(action.project)),
        ],
      };

    case team_projectConstants.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
