import React, { useEffect } from "react";
import { Modal, Button, Form, Input, Typography, Select, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    modalActions,
    statut_demandeActions,
    ticket_stateActions,
    type_ticketActions,
    priority_ticketActions,
} from "../../../redux/_actions";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const ModalAjouterPriorityTicket = (props) => {
    const dispatch = useDispatch();
    const closable_priority_ticket = useSelector(
        (state) => state.modal.closable_priority_ticket
    );
    const colors = useSelector((state) => state.project_color.items);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            libelle: props.priorityTicket.priority_name,
            id_color: props.priorityTicket.priority_color,
        });
    });

    const submitAddColor = (values) => {
        if (Object.keys(props.priorityTicket).length === 0) {
            dispatch(priority_ticketActions.add(values));
            dispatch(modalActions.close_priority_ticket());
            form.resetFields();
        } else {
            const data = {
                id_priority: props.priorityTicket.id_priority,
                libelle: values.libelle,
                id_color: values.id_color,
            };
            dispatch(priority_ticketActions.update(data));
            props.setPriorityTicket({});
            dispatch(modalActions.close_priority_ticket());
        }
    };

    return (
        <Modal
            title={
                Object.keys(props.priorityTicket).length === 0
                    ? "Ajouter priorité ticket"
                    : "Modifier priorité ticket"
            }
            visible={closable_priority_ticket}
            footer={null}
            onCancel={() => {
                dispatch(modalActions.close_priority_ticket());
                props.setTypeTicket({});
            }}
            maskClosable={false}
        >
            <Form
                className="login-form"
                onFinish={submitAddColor}
                form={form}
                layout="vertical"
            >
                <Form.Item>
                    <Form.Item
                        name="libelle"
                        style={{
                            display: "inline-block",
                            width: "calc(70% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Libelle</Text>}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez entrer le libellé du statut de la tache !",
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Entrer le libellé du statut de la tache"
                        />
                    </Form.Item>
                    <Form.Item
                        name="id_color"
                        style={{
                            display: "inline-block",
                            width: "calc(29% - 0px)",
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Couleur</Text>}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner la couleur du statut de la tache !",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner la couleur du statut de la tache "
                            // defaultValue={
                            //     props.taskStatutSelected !== {}
                            //         ? [props.taskStatutSelected.id_color]
                            //         : null
                            // }
                        >
                            {colors.map((p) => (
                                <Option value={p.id_color}>
                                    <Avatar
                                        size="small"
                                        style={{
                                            backgroundColor: p.color,
                                        }}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => {
                            dispatch(modalActions.close_priority_ticket());
                            props.setPriorityTicket({});
                        }}
                    >
                        <CloseCircleOutlined /> Annuler
                    </Button>{" "}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterPriorityTicket;
