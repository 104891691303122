import React, { useEffect, useState } from "react";
import { Card, Avatar, Typography, Dropdown, Menu, Badge } from "antd";
import { makeAvatar } from "../../helpers/makeAvatar";
import { useSelector, useDispatch } from "react-redux";
import {
    projectActions,
    modalActions,
    team_menberActions,
    userActions,
    team_projectActions,
    ticketActions,
    demande_cotationActions,
} from "../../../redux/_actions";
import { useHistory } from "react-router-dom";
import "./Projet.css";
import { EditOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
// import defineAbilityFor from "../../../config/defineAbility";
// import { Can } from "../../../config/Can";
import { encryptWithAES } from "../../helpers/makeAvatar";

const { Meta } = Card;
const { Text } = Typography;

const ProjetNew = (props) => {
    // const loading = useSelector((state) => state.project.loading);
    // const taches = useSelector((state) => state.project.project_task);
    const [, setSelectedTeam] = useState("");

    const user = useSelector((state) => state.login);

    // const ability = defineAbilityFor(user);

    const location = useLocation();

    const history = useHistory();

    const dispatch = useDispatch();


    // useEffect(() => {
    //     dispatch(
    //         ticketActions.filtrer_ticket_by_project(
    //             parseInt(props.p.id_projet)
    //         )
    //     )
    // }, []);

    const goToProject = (task) => {
        dispatch(
            projectActions.getAllProjectTask({
                id_project: parseInt(task.id_projet),
            })
        );
        dispatch(userActions.getAllMemberProject(parseInt(task.id_projet)));
        dispatch(team_projectActions.selectedProject(task));
        // setTimeout(function () {
        //     if (loading === false && taches !== []) {
        // history.push(
        //     `/projets/${parseInt(task.id_projet)}`,
        //     parseInt(task.id_projet)
        // );
        history.push(`/mes-projets/${encryptWithAES(task.id_projet)}`);
        //     }
        // }, 2000);
    };

    const menu = (
        <Menu
            onClick={(value) => {
                if (value.key === "1") {
                    // if (props.p.id_equipe !== undefined) {
                    //     dispatch(modalActions.show_add_team_project());
                    // } else {
                    //     dispatch(modalActions.show_add_allproject());
                    // }
                    dispatch(modalActions.show_add_team_project());
                }
            }}
        >
            <Menu.Item key="1">
                <EditOutlined /> Modifier
            </Menu.Item>
        </Menu>
    );

    const rightClique = (
        id_projet,
        libelle,
        echeance,
        color,
        id_equipe,
        Membre_projet,
        description,
        id_statut_projet,
        delete_project,
        id_client
    ) => {
        if (id_equipe !== undefined) {
            setSelectedTeam(id_equipe);
            props.setProjectSelected({
                id_projet: parseInt(id_projet),
                libelle: libelle,
                echeance: echeance,
                id_color: color,
                id_equipe: id_equipe,
                id_statut_projet: id_statut_projet,
                id_equipe_membre: [
                    ...Membre_projet.map((p) => parseInt(p.id_equipe_membre)),
                ],
                description: description,
                delete_project: delete_project,
                id_client: id_client,
            });
            dispatch(
                team_menberActions.selectTeamMember({
                    id_equipe: id_equipe,
                })
            );
        } else {
            props.setProjectSelected({
                id_projet: parseInt(id_projet),
                libelle: libelle,
                echeance: echeance,
                id_color: color,
                // id_equipe: id_equipe,
                // id_equipe_membre: [
                //     ...Membre_projet.map((p) => parseInt(p.id_equipe_membre)),
                // ],
                description: description,
                delete_project: delete_project,
                id_client: id_client,
            });
        }
    };

    return (
        <>
            {location.pathname === "/equipes" ||
                location.pathname === "/projets" ||
                location.pathname === "/mes-projets" ? (
                <>
                    <Dropdown overlay={menu} trigger={["contextMenu"]}>
                        <Card
                            onContextMenu={(e) =>
                                e.type === "contextmenu"
                                    ? rightClique(
                                        props.p.id_projet,
                                        props.p.libelle,
                                        props.p.echeance,
                                        props.p.id_color,
                                        props.p.id_equipe,
                                        props.p.Membre_projet,
                                        props.p.description,
                                        props.p.id_statut_projet,
                                        props.p.delete_project,
                                        props.p.id_client
                                    )
                                    : null
                            }
                            style={{
                                // flex: 1,
                                backgroundColor: "#f8f8ff",
                                border: "1px solid #dedede",
                                width: "24%",
                                textAlign: "center",
                                height: 120,
                                // margin: 9,
                                padding: 5,
                                paddingTop: 20,
                                borderRadius: 3,
                                cursor: "pointer",
                                display: "inline-block",
                                margin: 4,
                            }}
                            bodyStyle={{ padding: 0, height: 79 }}
                            hoverable={true}
                            onClick={() => goToProject(props.p)}
                        >
                            <Meta
                                title={<Text strong={true}>{props.p.libelle}</Text>}
                                description={props.p.statut_libelle}
                            />
                            {props.p.Membre_projet !== undefined ? (
                                <Avatar.Group
                                    style={{
                                        marginTop: 5,
                                    }}
                                    maxCount={2}
                                    maxStyle={{
                                        color: "#f56a00",
                                        backgroundColor: "#fde3cf",
                                    }}
                                >
                                    {props.p.Membre_projet.length > 0 ? (
                                        props.p.Membre_projet.map((pp) => (
                                            <Avatar
                                                style={{
                                                    border: "2px solid #252526",
                                                    backgroundColor: "#f8f8ff",
                                                    color: "#252526",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {makeAvatar(pp.first_name)}
                                            </Avatar>
                                        ))
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: "#f8f8ff",
                                                borderColor: "#f8f8ff",
                                            }}
                                        ></Avatar>
                                    )}
                                </Avatar.Group>
                            ) : null}

                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: "50px solid transparent",
                                    borderRight: `50px solid ${props.p.color}`,
                                    borderBottom: "0px solid transparent",
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0,
                                }}
                            ></div>
                        </Card>
                    </Dropdown>
                </>
            ) : (location.pathname === "/gestion-des-clients") ? (
                <>
                    <Card
                        style={{
                            backgroundColor: "#f8f8ff",
                            border: "1px solid #dedede",
                            width: "24%",
                            textAlign: "center",
                            height: 120,
                            padding: 5,
                            paddingTop: 20,
                            borderRadius: 3,
                            cursor: "pointer",
                            display: "inline-block",
                            margin: 4,
                        }}
                        bodyStyle={{ padding: 0, height: 79 }}
                        hoverable={true}
                        onClick={() => goToProject(props.p)}
                    >
                        <Meta
                            title={<Text strong={true}>{props.p.libelle}</Text>}
                            description={props.p.statut_libelle}
                        />
                        {props.p.Membre_projet !== undefined ? (
                            <Avatar.Group
                                style={{
                                    marginTop: 5,
                                }}
                                maxCount={2}
                                maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                }}
                            >
                                {props.p.Membre_projet.length > 0 ? (
                                    props.p.Membre_projet.map((pp) => (
                                        <Avatar
                                            style={{
                                                border: "2px solid #252526",
                                                backgroundColor: "#f8f8ff",
                                                color: "#252526",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {makeAvatar(pp.first_name)}
                                        </Avatar>
                                    ))
                                ) : (
                                    <Avatar
                                        style={{
                                            backgroundColor: "#f8f8ff",
                                            borderColor: "#f8f8ff",
                                        }}
                                    ></Avatar>
                                )}
                            </Avatar.Group>
                        ) : null}

                        <div
                            style={{
                                width: 0,
                                height: 0,
                                borderTop: "50px solid transparent",
                                borderRight: `50px solid ${props.p.color}`,
                                borderBottom: "0px solid transparent",
                                position: "absolute",
                                right: 0,
                                bottom: 0,
                            }}
                        ></div>
                    </Card>
                </>
            )
                : (
                    <Badge.Ribbon text={props.p.nb_ticket} color="rgb(249 69 77)">
                        <Card
                            style={{
                                // flex: 1,
                                backgroundColor: "#f8f8ff",
                                border: "1px solid #dedede",
                                // width: user.item.id_profil === 3 ? "24%" : '24%',
                                width: '96%',
                                textAlign: "center",
                                height: 120,
                                margin: 5,
                                // marginTop: 15,
                                padding: 5,
                                paddingTop: 20,
                                borderRadius: 3,
                                cursor: "pointer",
                                borderLeft:
                                    parseInt(props.selectedProjectTicket) ===
                                        parseInt(props.p.id_projet)
                                        ? "5px solid red"
                                        : null,
                                //display: "inline-block",
                            }}
                            bodyStyle={{ padding: 0, height: 79 }}
                            hoverable={true}
                            onClick={() =>
                                location.pathname !== "/tickets" ? (
                                    goToProject(props.p)
                                ) : (
                                    <>
                                        {props.setChecked(0)}
                                        {dispatch(ticketActions.filter_ticket_by_project_from_getAll(0))}
                                        {
                                            dispatch(
                                                ticketActions.getAll()
                                            )
                                        }
                                        {
                                            dispatch(
                                                ticketActions.filter_ticket_by_project_from_getAll(
                                                    parseInt(props.p.id_projet)
                                                )
                                            )
                                        }
                                        {dispatch(
                                            ticketActions.filtrer_ticket_by_project(
                                                parseInt(props.p.id_projet)
                                            )
                                        )}
                                        {props.setSelectProjectTicket(
                                            parseInt(props.p.id_projet)
                                        )}
                                        {dispatch(
                                            projectActions.active_existant(
                                                props.p.demande
                                            )
                                        )}
                                        {dispatch(
                                            demande_cotationActions.select_demande_of_quotation(
                                                parseInt(props.p.id_projet)
                                            )
                                        )}
                                        {props.setInputTicket([])}
                                    </>
                                )
                            }
                        >
                            <Meta
                                title={<Text strong={true}>{props.p.libelle}</Text>}
                                description={props.p.statut_libelle}
                            />
                            {props.p.Membre_projet !== undefined ? (
                                <Avatar.Group
                                    style={{
                                        marginTop: 5,
                                    }}
                                    maxCount={2}
                                    maxStyle={{
                                        color: "#f56a00",
                                        backgroundColor: "#fde3cf",
                                    }}
                                >
                                    {props.p.Membre_projet.length > 0 ? (
                                        props.p.Membre_projet.map((pp) => (
                                            <Avatar
                                                style={{
                                                    border: "2px solid #252526",
                                                    backgroundColor: "#f8f8ff",
                                                    color: "#252526",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {makeAvatar(pp.first_name)}
                                            </Avatar>
                                        ))
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: "#f8f8ff",
                                                borderColor: "#f8f8ff",
                                            }}
                                        ></Avatar>
                                    )}
                                </Avatar.Group>
                            ) : null}

                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: "50px solid transparent",
                                    borderRight: `50px solid ${props.p.color}`,
                                    borderBottom: "0px solid transparent",
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0,
                                }}
                            ></div>
                        </Card>
                    </Badge.Ribbon>
                )
            }
        </>
    );
};

export default ProjetNew;
