import { teamConstants } from "../_constants";
import { teamServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const teamActions = {
    add,
    getAll,
    update,
    selectedTeam
};

function selectedTeam(equipe) {
    return {
        type: teamConstants.SELECTED_TEAM_PROJETS,
        equipe
    }
}

function add(team) {
    return function (dispatch) {
        dispatch(request());
        teamServices
            .add(team)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: teamConstants.ADD_REQUEST,
        };
    }
    function success(team) {
        return {
            type: teamConstants.ADD_SUCCESS,
            team,
        };
    }
    function failure(error) {
        return {
            type: teamConstants.ADD_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        teamServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: teamConstants.GETALL_REQUEST,
        };
    }
    function success(team) {
        return {
            type: teamConstants.GETALL_SUCCESS,
            team,
        };
    }
    function failure(error) {
        return {
            type: teamConstants.GETALL_FAILURE,
            error,
        };
    }
}

function update(team) {
    return function (dispatch) {
        dispatch(request());
        teamServices.update(team)
            .then(res => {
                dispatch(success(res.data.message))
                dispatch(alertActions.success("Modification effectué !"));
            })
            .catch(err => {
                dispatch(failure(err.message))
                dispatch(alertActions.error("Echec de modification."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            })
    };
    function request() {
        return {
            type: teamConstants.UPDATE_REQUEST,
        };
    }
    function success(team) {
        return {
            type: teamConstants.UPDATE_SUCCESS,
            team,
        };
    }
    function failure(team) {
        return {
            type: teamConstants.UPDATE_FAILURE,
            team,
        };
    }
}
