import axios from "../../axios/index";

export const priority_ticketServices = {
    getAll,
    add,
    update,
    remove,
};

async function getAll() {
    return await axios.get(`priority_ticket`);
}

async function add(priority_ticket) {
    return await axios.post(`priority_ticket`, priority_ticket);
}

async function update(priority_ticket) {
    return await axios.put(
        `priority_ticket/${priority_ticket.id_priority}`,
        priority_ticket
    );
}

async function remove(priority_ticket) {
    return await axios.get(`priority_ticket/delete/${priority_ticket}`);
}
