import { statut_projetConstants } from "../_constants";

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export const statut_projet = (state = initialState, action) => {
    switch (action.type) {
        case statut_projetConstants.GETALL_STATUT_PROJET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statut_projetConstants.GETALL_STATUT_PROJET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.statut_projet,
            };

        case statut_projetConstants.GETALL_STATUT_PROJET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
