import { projectConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    items_have_ticket: [],
    items_search: [],
    item: {},
    activeExistant: false,
    selectedTask: {},
    project_without_team: [],
    checkedAll: false,
    project_task: [],
    allTask_member: [],
    filterAllTask_member: [],
    tab: [],
    error: "",
};

export const project = (state = initialState, action) => {
    switch (action.type) {
        case projectConstants.GETALL_PROJECT_WITHOUT_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALL_PROJECT_WITHOUT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                project_without_team: action.project,
            };

        case projectConstants.GETALL_PROJECT_WITHOUT_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: false,
            };

        case projectConstants.GETALL_PROJECT_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALL_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                project_task: action.project.sort((a, b) => b.ordre - a.ordre),
                loading: false,
            };

        case projectConstants.GETALL_PROJECT_TASK_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case projectConstants.ADD_PROJECT_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.ADD_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                project_task: [
                    ...state.project_task.sort((a, b) => a.ordre - b.ordre),
                    action.task,
                ],
            };

        case projectConstants.ADD_PROJECT_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.DRAG_IN_DROP_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.DRAG_IN_DROP_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                project_task: [
                    ...state.project_task
                        .sort((a, b) => b.ordre - a.ordre)
                        .map((p) => {
                            if (action.task !== undefined & p.id === action.task.id) {
                                p.id_jalon = action.task.id_jalon;
                                p.ordre = action.task.ordre;
                            }
                            return p;
                        }),
                ],
            };

        case projectConstants.DRAG_IN_DROP_TASK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.SELECTED_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedTask: action.task,
            };

        case projectConstants.UPDATE_TASK_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.UPDATE_TASK_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedTask: Object.assign(state.selectedTask, action.task),
                project_task: [
                    ...state.project_task
                        .map((p) => {
                            if (p.id === action.task.id) {
                                Object.assign(p, action.task);
                            }
                            return p;
                        })
                        .sort((a, b) => b.ordre - a.ordre),
                ],
                allTask_member: [
                    ...state.allTask_member
                        .map((p) => {
                            if (p.id_projet === action.task.id) {
                                Object.assign(p, action.project);
                            }
                            return p;
                        })
                        .sort((a, b) => b.ordre - a.ordre),
                ],
            };

        case projectConstants.UPDATE_TASK_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.STOP_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case projectConstants.ADD_WITHOUT_TEAM_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.ADD_WITHOUT_TEAM_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                project_without_team: [
                    ...state.project_without_team,
                    action.project,
                ],
            };

        case projectConstants.ADD_WITHOUT_TEAM_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                project_without_team: [
                    ...state.project_without_team.map((p) => {
                        if (p.id_projet === action.project.id_projet) {
                            Object.assign(p, action.project);
                        }
                        return p;
                    }),
                ],
            };

        case projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.GETALL_MENBER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALL_MENBER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                allTask_member: action.team_menber,
                filterAllTask_member: action.team_menber.filter(
                    (p) => p.id_statut === 2
                ),
            };

        case projectConstants.GETALL_MENBER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.RESET_USER_TASK:
            return {
                ...state,
                allTask_member: [],
                filterAllTask_member: [],
                selectedTask: {},
            };

        case projectConstants.FILETR_USER_TASK:
            return {
                ...state,
                tab: [
                    ...state.allTask_member.filter(
                        (p) => p.id_jalon === action.id
                    ),
                ],
            };

        case projectConstants.INCREMENT_UNDER_TASK_NUMBER:
            return {
                ...state,
                project_task: [
                    ...state.project_task.map((p) => {
                        if (parseInt(p.id) === parseInt(action.id_tache)) {
                            p.under_task = p.under_task + 1;
                        }
                        return p;
                    }),
                ],
            };

        case projectConstants.DELETE_PROJECT_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.DELETE_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                project_task: [
                    ...state.project_task.filter(
                        (p) => parseInt(p.id) !== parseInt(action.project_task)
                    ),
                ],
            };

        case projectConstants.FILTER_TASK_NOT_DONE:
            return {
                ...state,
                filterAllTask_member: [
                    ...state.allTask_member.filter((p) => p.id_statut === 2),
                ],
            };

        case projectConstants.FILTER_TASK_DONE:
            return {
                ...state,
                filterAllTask_member: [
                    ...state.allTask_member.filter((p) => p.id_statut === 1),
                ],
            };

        case projectConstants.FILTER_TASK_ALL:
            return {
                ...state,
                filterAllTask_member: [...state.allTask_member],
            };

        case projectConstants.DELETE_PROJECT_WITHOUT_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.DELETE_PROJECT_WITHOUT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                project_without_team: [
                    ...state.project_without_team.filter(
                        (p) =>
                            parseInt(p.id_projet) !== parseInt(action.project)
                    ),
                ],
            };

        case projectConstants.DELETE_PROJECT_WITHOUT_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.GETALL_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALL_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.project,
            };

        case projectConstants.GETALL_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.GETALL_PROJECT_HAVE_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALL_PROJECT_HAVE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items_have_ticket: action.project,
            };

        case projectConstants.GETALL_PROJECT_HAVE_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case projectConstants.ACTIVE_EXISTANT:
            return {
                ...state,
                activeExistant: action.demande
            }

        case projectConstants.GETALLSEARCH_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case projectConstants.GETALLSEARCH_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                items_search: action.project,
            };

        case projectConstants.GETALLSEARCH_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
