import axios from "../../axios/index";
export const projectServices = {
    getAll,
    getAllProjectWithoutTeam,
    getAllProjectTask,
    addProjectTask,
    dragInDropTask,
    updateProjectTask,
    addWithoutTeamProject,
    updateWithoutTeamProject,
    getAllTaskTeamMember,
    delete_project_task,
    delete_project_without_team,
    getAllSearch,
    getAllProjectHasTickets
};

async function getAll() {
    return await axios.get(`projets`);
}

async function getAllProjectHasTickets() {
    return await axios.get(`projets/with_ticket`);
}

async function getAllSearch(project_name) {
    return await axios.get(`projets/searchAll/${project_name}`);
}

async function delete_project_without_team(id_project) {
    return await axios.delete(`projets/${id_project}`);
}

async function delete_project_task(id_tache) {
    return await axios.delete(`projets/task/${id_tache}`);
}

async function updateWithoutTeamProject(project) {
    return await axios.put(`projets/updateProjectWithoutTeam/${project.id_projet}`, project)
}

async function addWithoutTeamProject(project) {
    return await axios.post(`projets/addProjectWithoutTeam`, project)
}

async function getAllProjectWithoutTeam() {
    return await axios.get(`projets/no_equipe/choisi`);
}

async function getAllProjectTask(project) {
    return await axios.get(`projets/taches/${project.id_project}`, project);
}

async function addProjectTask(task) {
    return await axios.post(`taches`, task);
}

async function dragInDropTask(task) {
    return await axios.put(`/taches/${task.id_tache}`, task);
}

async function updateProjectTask(task) {
    return await axios.put(`/taches/${task.id_tache}`, task);
}

async function getAllTaskTeamMember(team_menber) {
    return await axios.get(`equipe_membres/all_task_member/${team_menber.id_user}`);
}
