import { type_projectConstants } from "../_constants";
import { type_projectServices } from "../_services";

export const type_projectActions = {
    getAll,
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        type_projectServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: type_projectConstants.GETALL_TYPE_PROJECT_REQUEST,
        };
    }
    function success(type_project) {
        return {
            type: type_projectConstants.GETALL_TYPE_PROJECT_SUCCESS,
            type_project,
        };
    }
    function failure(error) {
        return {
            type: type_projectConstants.GETALL_TYPE_PROJECT_FAILURE,
            error,
        };
    }
}
