import { filtreConstants } from "../_constants";

const initialState = {
    choix_equipe: null,
    choix_projet: null,
    choix_membre: null,
    choix_jalon: null,
    choix_date: null,
}

export const filtre = (state = initialState, action) => {
    switch (action.type) {

        case filtreConstants.CHOOSE_EQUIPE:
            return {
                ...state,
                choix_equipe: action.id,
                choix_projet: null,
                choix_membre: null,
                choix_jalon: null
            };
        case filtreConstants.RESET_EQUIPE:
            return {
                ...state,
                choix_equipe: null,
            };

        case filtreConstants.CHOOSE_PROJET:
            return {
                ...state,
                choix_projet: action.id,
            };
        case filtreConstants.RESET_PROJET:
            return {
                ...state,
                choix_projet: null,
            };

        case filtreConstants.CHOOSE_MEMBRE:
            return {
                ...state,
                choix_membre: action.id,
            };
        case filtreConstants.RESET_MEMBRE:
            return {
                ...state,
                choix_membre: null,
            };

        case filtreConstants.CHOOSE_JALON:
            return {
                ...state,
                choix_jalon: action.id,
            };
        case filtreConstants.RESET_JALON:
            return {
                ...state,
                choix_jalon: null,
            };
        case filtreConstants.CHOOSE_DATE:
            return {
                ...state,
                choix_date: action.tab,
            };
        case filtreConstants.RESET_DATE:
            return {
                ...state,
                choix_date: null,
            };

        default:
            return state;
    }
}