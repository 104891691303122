import { customerConstants } from "../_constants";
import { customerServices } from "../_services";
import { alertActions } from "../_actions";

export const customerActions = {
    getAll,
    add,
    update,
    remove,
    checkCustomer,
    dropCheckCustomer
};

function checkCustomer(customer) {
    return {
        type: customerConstants.CHECK_CUSTOMER_REQUEST,
        customer
    }
}

function dropCheckCustomer() {
    return {
        type: customerConstants.DROP_CHECK_CUSTOMER_REQUEST,
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        customerServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: customerConstants.GETALL_CUSTOMER_REQUEST,
        };
    }
    function success(customer) {
        return {
            type: customerConstants.GETALL_CUSTOMER_SUCCESS,
            customer,
        };
    }
    function failure(error) {
        return {
            type: customerConstants.GETALL_CUSTOMER_FAILURE,
            error,
        };
    }
}

function add(customer) {
    return function (dispatch) {
        dispatch(request());
        customerServices
            .add(customer)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectué"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
            });
    };
    function request() {
        return {
            type: customerConstants.ADD_CUSTOMER_REQUEST,
        };
    }
    function success(customer) {
        return {
            type: customerConstants.ADD_CUSTOMER_SUCCESS,
            customer,
        };
    }
    function failure(error) {
        return {
            type: customerConstants.ADD_CUSTOMER_FAILURE,
            error,
        };
    }
}

function update(customer) {
    return function (dispatch) {
        dispatch(request());
        customerServices
            .update(customer)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.success("Echec de modification"));
            });
    };
    function request() {
        return {
            type: customerConstants.UPDATE_CUSTOMER_REQUEST,
        };
    }
    function success(customer) {
        return {
            type: customerConstants.UPDATE_CUSTOMER_SUCCESS,
            customer,
        };
    }
    function failure(error) {
        return {
            type: customerConstants.UPDATE_CUSTOMER_FAILURE,
            error,
        };
    }
}

function remove(customer) {
    return function (dispatch) {
        dispatch(request());
        customerServices
            .remove(customer)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppression effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de suppression"));
            });
    };
    function request() {
        return {
            type: customerConstants.DELETE_CUSTOMER_REQUEST,
        };
    }
    function success(customer) {
        return {
            type: customerConstants.DELETE_CUSTOMER_SUCCESS,
            customer,
        };
    }
    function failure(error) {
        return {
            type: customerConstants.DELETE_CUSTOMER_FAILURE,
            error,
        };
    }
}
