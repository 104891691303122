export const profileConstants = {
    ADD_REQUEST: "PROFILE_ADD_REQUEST",
    ADD_SUCCESS: "PROFILE_ADD_SUCCESS",
    ADD_FAILURE: "PROFILE_ADD_FAILURE",

    GETALL_REQUEST: "PROFILE_GETALL_REQUEST",
    GETALL_SUCCESS: "PROFILE_GETALL_SUCCESS",
    GETALL_FAILURE: "PROFILE_GETALL_FAILURE",

    UPDATE_REQUEST: "PROFILE_UPDATE_REQUEST",
    UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",
};
