import React, { useState, useEffect } from "react";
import "./DetailTache.css";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Avatar,
    DatePicker,
    Input,
    Drawer,
    Button,
    Skeleton,
    Card,
    Select,
    Form,
    InputNumber,
    Menu,
    Dropdown,
    Typography,
    Spin,
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import {
    PlusOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import {
    modalActions,
    taskActions,
    projectActions,
    alertActions,
} from "../../../redux/_actions";
import dateFormat from "dateformat";
// import moment from "moment";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import { makeAvatar } from "../../helpers/makeAvatar";
import { isEmpty } from "lodash";

const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;

Moment.globalMoment = moment;

Moment.globalLocale = "fr";

const DetailTache = () => {
    const task = useSelector((state) => state.project.selectedTask);

    // eslint-disable-next-line no-unused-vars
    const [log, setLog] = useState("");
    const [showAddUnderTask, setShowAddUnderTask] = useState(false);
    const [showResponsable, setShowResponsable] = useState(false);
    const [showEcheance, setShowEcheance] = useState(false);
    const [showDateDebut, setShowDateDebut] = useState(false);
    const [showDurre, setShowDurre] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const [showStatut, setShowStatut] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [showMore, setShowMore] = useState(false);
    const [duree, setDuree] = useState("");
    const [dureeEstimee, setDureeEstimee] = useState("");
    const [poids, setPoids] = useState("");
    const [editUnderTask, setEditUnderTask] = useState(0);
    const [editUnderText, setEditUnderText] = useState("");
    const [statutTask, setStatutTask] = useState({});
    const [statutHover, setStatutHover] = useState(0);
    // const [etatB,] = useState(0);
    // const [etatN,] = useState(1);

    // Nouvelle chose
    const [hoverTitle, setHoverTitle] = useState(false);
    const [hoverResponsable, setHoverResponsable] = useState(false);
    const [hoverDateDebut, setHoverDateDebut] = useState(false);
    const [hoverEcheance, setHoverEcheance] = useState(false);
    const [hoverDureeEstimee, setHoverDureeEstimee] = useState(false);
    const [hoverDuree, setHoverDuree] = useState(false);
    const [hoverPoids, setHoverPoids] = useState(false);
    const [hoverDescription, setHoverDescription] = useState(false);
    const [hoverStatut, setHoverStatut] = useState(false);
    const [hoverEtat, setHoverEtat] = useState(false);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const closable_detail_task = useSelector(
        (state) => state.modal.closable_detail_task
    );
    const task_logs = useSelector((state) => state.task.task_logs);
    const task_logs_loading = useSelector(
        (state) => state.task.task_logs_loading
    );
    const under_tasks = useSelector((state) => state.task.under_task);
    const under_loading = useSelector((state) => state.task.loading);
    const team_menbers = useSelector((state) => state.user.project_member);
    const statut = useSelector((state) => state.statut.items);
    const items_jalon = useSelector((state) => state.jalon.items);
    const auth = useSelector((state) => state.login.item);
    const info_user = useSelector((state) => state.user.items).filter((p) => p.id_user === parseInt(auth.id_user))[0];
    // const jalons = useSelector((state) => state.jalon.items).filter((p) => p.terminate === 1 || p.execute === 1);

    const jTypeEx = items_jalon.filter((p) => p.execute === 1)[0];
    const jTypeTe = items_jalon.filter((p) => p.terminate === 1)[0];
    const [jEX, setJEX] = useState('');
    const [jTE, setJTE] = useState('');

    const items_filter = useSelector((state) => state.task.items_filter);

    // choose infos sur les filtres
    // const filtre_equipe = useSelector((state) => state.filtre.choix_equipe);
    // const filtre_projet = useSelector((state) => state.filtre.choix_projet);
    // const filtre_membre = useSelector((state) => state.filtre.choix_membre);
    // const filtre_jalon = useSelector((state) => state.filtre.choix_jalon);

    const resetStates = () => {

        dispatch(
            projectActions.getAllProjectTask({
                id_project: parseInt(task.id_projet),
            })
        );

        setTitle(task.libelle_tache);
        setDescription(task.tache_description);
        setDuree(task.duree);
        setDureeEstimee(task.duree_estimative);
        setPoids(task.poids);
        setShowAddUnderTask(false);
        setShowResponsable(false);
        setShowEcheance(false);
        setShowProject(false);
        setShowStatut(false);
        setShowDescription(false);
        setShowTitle(false);
        setShowDateDebut(false);
        dispatch(modalActions.close_detail_task());
        setShowMore(false);
        dispatch(taskActions.reset_under_task());
        dispatch(taskActions.reset_task_logs());
        // dispatch(projectActions.reset_task());
    };

    const submitTacheLogs = (values) => {
        dispatch(
            taskActions.addTaskLog({
                id_tache: parseInt(task.id),
                description: values.log,
                create_by: auth.id_user,
            })
        );
        form.resetFields();
    };

    const updateDescription = (
        id,
        libelle_tache,
        description,
        id_projet,
        id_jalon
    ) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                description: description,
                id_projet: id_projet,
                id_jalon: id_jalon,
            })
        );
        setShowDescription(false);
    };

    const uppdateTitle = (id, titre, id_projet, id_jalon) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: titre,
                id_projet: id_projet,
                id_jalon: id_jalon,
            })
        );
        setShowTitle(false);
    };

    const updateDateDebut = (
        id,
        libelle_tache,
        date_debut,
        id_projet,
        id_jalon
    ) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                date_debut: date_debut,
                id_projet: id_projet,
                id_jalon: id_jalon,
            })
        );
        setShowDateDebut(false);
    };

    const updateEcheance = (
        id,
        libelle_tache,
        echeance,
        id_projet,
        id_jalon
    ) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                echeance: echeance,
                id_projet: id_projet,
                id_jalon: id_jalon,
            })
        );
        setShowEcheance(false);
    };

    const updateDuree = (id, libelle_tache, duree, id_projet, id_jalon, poids) => {
        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                duree: duree,
                id_projet: id_projet,
                id_jalon: id_jalon,
                poids: poids,
            })
        );
        setShowDurre(false);
    };
    const updateDureeEstimee = (id, libelle_tache, dureeEstimee, id_projet, id_jalon, poids) => {

        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                dureeEstimee: dureeEstimee,
                id_projet: id_projet,
                id_jalon: id_jalon,
                poids: poids,
            })
        );
        setShowDurre(false);
    };

    const updatePoids = (id, libelle_tache, poids, id_projet, id_jalon) => {

        dispatch(
            projectActions.updateProjectTask({
                id_tache: id,
                libelle: libelle_tache,
                poids: poids,
                id_projet: id_projet,
                id_jalon: id_jalon,
            })
        );
        setShowDurre(false);
    };

    useEffect(() => {
        if (jTypeEx !== undefined) {
            if (Object.keys(jTypeEx).length > 0) {
                setJEX(jTypeEx);
            }
        }
        if (jTypeTe !== undefined) {
            if (Object.keys(jTypeTe).length > 0) {
                setJTE(jTypeTe);
            }
        }
    }, [jTypeEx, jTypeTe])

    useEffect(() => {
        setTitle(task.libelle_tache);
        setDescription(task.tache_description);
        setDuree(task.duree);
        setDureeEstimee(task.duree_estimative);
        setPoids(task.poids);
    }, [task.tache_description, task.duree, task.libelle_tache, task.poids, task.duree_estimative]);

    const handleMenuClick = (value) => {
        dispatch(
            taskActions.updateUnderTask({
                id_tache: parseInt(statutTask.id_tache),
                libelle: statutTask.libelle_tache,
                id_statut: parseInt(value.key),
                id_projet: parseInt(statutTask.id_projet),
            })
        );
        dispatch(
            taskActions.getRecentlyTask(
                auth.id_user
            )
        );
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {statut !== undefined
                ? statut.map((p) => (
                    <Menu.Item
                        key={p.id_statut}
                        onMouseEnter={() => setStatutHover(p.id_statut)}
                        onMouseLeave={() => setStatutHover(0)}
                        style={{
                            borderRight:
                                statutHover === p.id_statut
                                    ? "4px solid #40a9ff"
                                    : null,
                        }}
                    >
                        <span
                            style={{
                                width: 15,
                                height: 15,
                                // background: p.end === 0 ? "black" : p.color,
                                background: p.color,
                                borderRadius: "50%",
                                display: "inline-block",
                                marginRight: 10,
                            }}
                        ></span>
                        {p.libelle}
                    </Menu.Item>
                ))
                : null}
        </Menu>
    );

    const splitFirstName = (nom) => {
        if (nom !== "" && nom !== undefined && nom !== null) {
            let tab = nom.split(" ");
            return tab[0] + " " + tab[1];
        }
    };

    return (
        <Drawer
            title={
                <h3
                    style={{ color: "white", fontSize: 24, fontWeight: "bold" }}
                >
                    {task.libelle_projet}
                </h3>
            }
            className="detailtache"
            placement="right"
            visible={closable_detail_task}
            width={1000}
            headerStyle={{
                backgroundColor: "#73a5f5",
                height: 50,
                paddingLeft: 10,
            }}
            bodyStyle={{
                padding: 10,
                paddingTop: 0,
                backgroundColor: "white",
                fontSize: 13,
            }}
            onClose={() => resetStates()}
            style={{
                marginTop: 50,
            }}
        >
            {/* <Button onClick={() => alert(task.description)}>Test</Button> */}
            <div
                style={{
                    height: "92%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 24,
                    }}
                >
                    <TextArea
                        style={{
                            fontSize: 20,
                            border: hoverTitle
                                ? `1px solid rgb(115, 165, 245)`
                                : `1px solid white`,
                            paddingLeft: 0,
                            width: 980,
                            // height: "auto",
                            // maxHeight: 100
                        }}
                        autoSize={true}
                        onMouseEnter={() => setHoverTitle(true)}
                        onMouseLeave={() => setHoverTitle(false)}
                        value={title}
                        autoFocus
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                        onBlur={() =>
                            uppdateTitle(
                                parseInt(task.id),
                                title,
                                task.id_projet,
                                task.id_jalon
                            )
                        }
                    />
                </div>
                <div style={{ flex: 9, display: "flex", flexDirection: "row" }}>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                height: 220,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {/* responsable de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div
                                    style={{
                                        flex: 3,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text style={{ fontSize: 14 }}>
                                        Responsable{" "}
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        flex: 7,
                                        display: "flex",
                                        alignItems: "center",
                                        paddingRight: 5,
                                    }}
                                >
                                    <Select
                                        style={{
                                            cursor: "pointer",
                                            width: "80%",
                                            border: hoverResponsable
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverResponsable(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverResponsable(false)
                                        }
                                        placeholder="Sélectionner le responsable"
                                        // defaultValue={[]}
                                        value={
                                            task.id_user !== null &&
                                                task.id_user !== undefined &&
                                                task.id_user !== isNaN
                                                ? parseInt(task.id_user)
                                                : null
                                        }
                                        onChange={(value) =>
                                            dispatch(
                                                projectActions.updateProjectTask(
                                                    {
                                                        id_tache: parseInt(
                                                            task.id
                                                        ),
                                                        libelle:
                                                            task.libelle_tache,
                                                        id_user:
                                                            value === undefined
                                                                ? ""
                                                                : value,
                                                        id_projet:
                                                            task.id_projet,
                                                        id_jalon: task.id_jalon,
                                                        id_auth: parseInt(auth.id_user)
                                                    }
                                                )
                                            )
                                        }
                                        onSelect={() => {
                                            setShowResponsable(false);
                                        }}
                                        onBlur={() => {
                                            setShowResponsable(false);
                                        }}
                                        allowClear
                                        onClear={() =>
                                            dispatch(
                                                projectActions.updateProjectTask(
                                                    {
                                                        id_tache: parseInt(
                                                            task.id
                                                        ),
                                                        libelle:
                                                            task.libelle_tache,
                                                        id_user: null,
                                                        id_projet:
                                                            task.id_projet,
                                                        id_jalon: task.id_jalon,
                                                    }
                                                )
                                            )
                                        }
                                    >
                                        {team_menbers.map((p) => (
                                            <Option value={parseInt(p.id_user)}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        style={{
                                                            border: "1px solid #252526",
                                                            backgroundColor:
                                                                "#f8f8ff",
                                                            color: "#252526",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            marginRight: 10,
                                                            width: 26,
                                                            height: 26,
                                                        }}
                                                    >
                                                        {makeAvatar(
                                                            p.first_name
                                                        )}
                                                    </Avatar>{" "}
                                                    {splitFirstName(
                                                        p.first_name
                                                    )}
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            {/* date debut de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3, alignItems: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        Date début
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        flex: 7,
                                        alignItems: "center",
                                        paddingRight: 5,
                                    }}
                                >
                                    <DatePicker
                                        style={{
                                            width: "80%",
                                            color: "darkred",
                                            border: hoverDateDebut
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverDateDebut(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverDateDebut(false)
                                        }
                                        allowClear={true}
                                        format="DD/MM/YYYY"
                                        value={
                                            task.date_debut !== null
                                                ? moment(
                                                    task.date_debut,
                                                    "YYYY/MM/DD"
                                                )
                                                : null
                                        }
                                        // showTime={{
                                        //     format: "HH:mm",
                                        // }}
                                        onChange={(date, dateString) => {
                                            let d;
                                            if (dateString !== "") {
                                                var mydate = moment(
                                                    dateString,
                                                    "DD/MM/YYYY"
                                                );
                                                d =
                                                    moment(mydate).format(
                                                        "YYYY/MM/DD"
                                                    );
                                            } else {
                                                d = "";
                                            }
                                            d !== undefined
                                                ? task.echeance === null ||
                                                    d === ""
                                                    ? updateDateDebut(
                                                        parseInt(task.id),
                                                        task.libelle_tache,
                                                        d === "" ? "" : d,
                                                        task.id_projet,
                                                        task.id_jalon
                                                    )
                                                    : Date.parse(d) <=
                                                        Date.parse(task.echeance)
                                                        ? updateDateDebut(
                                                            parseInt(task.id),
                                                            task.libelle_tache,
                                                            d,
                                                            task.id_projet,
                                                            task.id_jalon
                                                        )
                                                        : dispatch(
                                                            alertActions.error(
                                                                "Date début doit être inferieur a l'échéance !"
                                                            )
                                                        )
                                                : setShowDateDebut(false);
                                        }}
                                        onBlur={() => setShowDateDebut(false)}
                                    />
                                </div>
                            </div>
                            {/* date d'echeance de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>
                                        Échéance
                                    </Text>
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <DatePicker
                                        style={{
                                            width: "80%",
                                            border: hoverEcheance
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverEcheance(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverEcheance(false)
                                        }
                                        allowClear={true}
                                        allowEmpty={false}
                                        format="DD/MM/YYYY"
                                        value={
                                            task.echeance !== null
                                                ? moment(
                                                    task.echeance,
                                                    "YYYY/MM/DD"
                                                )
                                                : null
                                        }
                                        // showTime={{
                                        //     format: "HH:mm",
                                        // }}
                                        onChange={(date, dateString) => {
                                            let d;
                                            if (dateString !== "") {
                                                var mydate = moment(
                                                    dateString,
                                                    "DD/MM/YYYY"
                                                );
                                                d =
                                                    moment(mydate).format(
                                                        "YYYY/MM/DD"
                                                    );
                                            } else {
                                                d = "";
                                            }

                                            d !== undefined
                                                ? task.date_debut === null
                                                    ? updateEcheance(
                                                        parseInt(task.id),
                                                        task.libelle_tache,
                                                        d,
                                                        task.id_projet,
                                                        task.id_jalon
                                                    )
                                                    : Date.parse(
                                                        task.date_debut
                                                    ) <= Date.parse(d)
                                                        ? updateEcheance(
                                                            parseInt(task.id),
                                                            task.libelle_tache,
                                                            d === "" ? "" : d,
                                                            task.id_projet,
                                                            task.id_jalon
                                                        )
                                                        : dispatch(
                                                            alertActions.error(
                                                                "Date début doit être inferieur a l'échéance !"
                                                            )
                                                        )
                                                : setShowEcheance(false);
                                        }}
                                        onBlur={() => setShowEcheance(false)}
                                    />
                                </div>
                            </div>
                            {/* durée estimative de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>Durée estimée</Text>{" "}
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <InputNumber
                                        min="0"
                                        readOnly={!isNaN(parseInt(task.id_demande)) ? true : false}
                                        style={{
                                            width: "25%",
                                            marginRight: 2,
                                            cursor: "pointer",
                                            border: hoverDureeEstimee
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() => setHoverDureeEstimee(true)}
                                        onMouseLeave={() =>
                                            setHoverDureeEstimee(false)
                                        }
                                        placeholder="Durée" //  de la tache
                                        value={dureeEstimee}
                                        onChange={(values) => setDureeEstimee(values)}
                                        onBlur={() => {
                                            if (dureeEstimee >= -1) {
                                                updateDureeEstimee(
                                                    parseInt(task.id),
                                                    task.libelle_tache,
                                                    dureeEstimee === "" ? 0 : dureeEstimee,
                                                    task.id_projet,
                                                    task.id_jalon,
                                                    poids === "" ? "" : poids
                                                );
                                            } else {
                                                dispatch(alertActions.error('La valeur ne doit pas être négative.'));
                                                setDuree(task.duree)
                                            }
                                        }}
                                    />
                                    {task.duree_estimative !== null
                                        ? task.duree_estimative > 1
                                            ? "heures"
                                            : "heure"
                                        : null}
                                </div>
                            </div>
                            {/* durée de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>Durée</Text>{" "}
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <InputNumber
                                        min="0"
                                        style={{
                                            width: "25%",
                                            marginRight: 2,
                                            cursor: "pointer",
                                            border: hoverDuree
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() => setHoverDuree(true)}
                                        onMouseLeave={() =>
                                            setHoverDuree(false)
                                        }
                                        placeholder="Durée" //  de la tache
                                        value={duree}
                                        onChange={(values) => setDuree(values)}
                                        onBlur={() => {
                                            if (duree >= -1) {
                                                updateDuree(
                                                    parseInt(task.id),
                                                    task.libelle_tache,
                                                    duree === "" ? "" : duree,
                                                    task.id_projet,
                                                    task.id_jalon,
                                                    poids === "" ? "" : poids
                                                );
                                            } else {
                                                dispatch(alertActions.error('La valeur ne doit pas être négative.'));
                                                setDuree(task.duree)
                                            }
                                        }}
                                    />
                                    {task.duree !== null
                                        ? task.duree > 1
                                            ? "heures"
                                            : "heure"
                                        : null}
                                </div>
                            </div>
                            {/* poids de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>Poids</Text>{" "}
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <InputNumber
                                        max="24"
                                        min="1"
                                        style={{
                                            width: "25%",
                                            marginRight: 2,
                                            cursor: "pointer",
                                            border: hoverPoids
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() => setHoverPoids(true)}
                                        onMouseLeave={() =>
                                            setHoverPoids(false)
                                        }
                                        placeholder="Poids" //  de la tache
                                        value={poids}
                                        onChange={(values) => setPoids(values)}
                                        onBlur={() => {
                                            updatePoids(
                                                parseInt(task.id),
                                                task.libelle_tache,
                                                poids === "" ? "" : poids,
                                                task.id_projet,
                                                task.id_jalon,
                                            );
                                        }}
                                    />
                                    {task.poids !== null
                                        ? task.poids > 1
                                            ? "poids"
                                            : "poids"
                                        : null}
                                </div>
                            </div>
                            {/* statut de la tache qui est representer par le jalon ou il se trouve */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>Statut </Text>{" "}
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <Select
                                        style={{
                                            width: 120,
                                            cursor: "pointer",
                                            border: hoverStatut
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverStatut(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverStatut(false)
                                        }
                                        placeholder="Selectionner le statut"
                                        onChange={(value) => {
                                            if ((task.date_debut !== null & task.echeance !== null) & (task.duree !== null || info_user.privilege_duree === true) || (value !== jEX.id_jalon & value !== jTE.id_jalon)) {
                                                dispatch(
                                                    projectActions.updateProjectTask(
                                                        {
                                                            id_tache: parseInt(
                                                                task.id
                                                            ),
                                                            libelle: task.libelle_tache,
                                                            // id_statut: value,
                                                            etat: task.etat,
                                                            id_projet: task.id_projet,
                                                            id_jalon: value,
                                                        }
                                                    )
                                                )
                                                if (jTypeTe.id_jalon === value) {
                                                    dispatch(taskActions.updateTaskFiltred(parseInt(task.id)))
                                                }
                                            } else {
                                                dispatch(alertActions.error("Veuillez renseignez les dates et la durée."));
                                            }
                                        }}
                                        onSelect={() => {
                                            setShowStatut(false);
                                        }}
                                        onBlur={() => {
                                            setShowStatut(false);
                                        }}
                                        value={task.id_jalon}
                                    >
                                        {
                                            items_jalon.length > 0 ?
                                                items_jalon.map((i) => (
                                                    <Option value={i.id_jalon}>{i.libelle}</Option>
                                                )) : null

                                        }
                                    </Select>
                                </div>
                            </div>
                            {/* Etat de la tache */}
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <Text style={{ fontSize: 14 }}>Etat </Text>{" "}
                                </div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <Select
                                        style={{
                                            width: 120,
                                            cursor: "pointer",
                                            border: hoverEtat
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverEtat(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverEtat(false)
                                        }
                                        placeholder="Selectionner le statut"
                                        onChange={(value) => {
                                            dispatch(
                                                projectActions.updateProjectTask(
                                                    {
                                                        id_tache: parseInt(
                                                            task.id
                                                        ),
                                                        libelle: task.libelle_tache,
                                                        // id_statut: value,
                                                        etat: value,
                                                        id_projet: task.id_projet,
                                                        id_jalon: task.id_jalon,
                                                    }
                                                )
                                            )
                                        }}
                                        onSelect={() => {
                                            setShowStatut(false);
                                        }}
                                        onBlur={() => {
                                            setShowStatut(false);
                                        }}
                                        value={task.etat}
                                    >
                                        <Option value={0}>---</Option>
                                        <Option value={1}>Bloquée</Option>
                                    </Select>
                                </div>
                            </div>
                            {/* Description de la tache */}
                            <div
                                style={{
                                    flex: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 2,
                                }}
                            >
                                <div style={{ flex: 3 }}>Description</div>
                                <div style={{ flex: 7, paddingRight: 5 }}>
                                    <TextArea
                                        style={{
                                            paddingLeft: 15,
                                            marginBottom: 5,
                                            width: "100%",
                                            border: hoverDescription
                                                ? `1px solid rgb(115, 165, 245)`
                                                : `1px solid white`,
                                        }}
                                        onMouseEnter={() =>
                                            setHoverDescription(true)
                                        }
                                        onMouseLeave={() =>
                                            setHoverDescription(false)
                                        }
                                        placeholder="Ajouter plus d'information a cette tâche."
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 2,
                                        }}
                                        value={description}
                                        onChange={(e) =>
                                            task.tache_description !==
                                                e.target.value
                                                ? setDescription(e.target.value)
                                                : null
                                        }
                                        onBlur={(e) =>
                                            e.target.value !== ""
                                                ? updateDescription(
                                                    parseInt(task.id),
                                                    task.libelle_tache,
                                                    description,
                                                    task.id_projet,
                                                    task.id_jalon
                                                )
                                                : updateDescription(
                                                    parseInt(task.id),
                                                    task.libelle_tache,
                                                    "",
                                                    task.id_projet,
                                                    task.id_jalon
                                                )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {/* ajout des sous tache */}
                        <div
                            style={{
                                marginTop: 128,
                                flex: 1,
                                backgroundColor: "#f8f8ff",
                                marginRight: 5,
                                marginBottom: 5,
                            }}
                        >
                            <div
                                className="sous-tache"
                                onClick={() =>
                                    setShowAddUnderTask(!showAddUnderTask)
                                }
                            >
                                <Button type="default" shape="circle">
                                    <PlusOutlined />
                                </Button>
                                <Text
                                    strong={true}
                                    className="btn"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                    }}
                                >
                                    Ajouter une sous-tâche
                                </Text>
                            </div>
                            {/* <Spin
                            style={{ position: "relative", top: 40}}
                                tip="Chargement en cours..."
                                size="large"
                                spinning={under_loading}
                            > */}
                            <Scrollbars
                                style={{ height: "80%" }}
                                onScroll={() => setShowAddUnderTask(false)}
                            >
                                {showAddUnderTask ? (
                                    <Card
                                        style={{
                                            width: "100%",
                                            marginBottom: 3,
                                            // border: '2px solid red'
                                        }}
                                        bodyStyle={{ padding: 8 }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckCircleOutlined
                                                style={{
                                                    marginRight: 8,
                                                    cursor: "pointer",
                                                    zIndex: 1,
                                                }}
                                            />
                                            <TextArea
                                                rows={2}
                                                placeholder="Saisir libellé de la tâche"
                                                size="small"
                                                onBlur={(e) => {
                                                    if (e.target.value !== "") {
                                                        dispatch(
                                                            taskActions.addUnderTask(
                                                                {
                                                                    id_user: parseInt(task.id_user),
                                                                    libelle: e.target.value,
                                                                    id_projet: parseInt(task.id_projet),
                                                                    ordre: 1,
                                                                    id_statut: 2,
                                                                    id_parent: parseInt(task.id),
                                                                    id_jalon: parseInt(task.id_jalon),
                                                                }
                                                            )
                                                        );
                                                        setShowAddUnderTask(
                                                            false
                                                        );
                                                    } else {
                                                        setShowAddUnderTask(
                                                            false
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    marginLeft: 5,
                                                    width: 410,
                                                    // border: '2px solid red'
                                                }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                                {/* <Spin
                                    style={{ width: "100%" }}
                                    tip="Chargement en cours..."
                                    size="large"
                                    spinning={under_loading}
                                /> */}
                                <Skeleton
                                    active={under_loading}
                                    loading={under_loading}
                                />
                                {under_tasks.map((p) => (
                                    <Card
                                        style={{
                                            width: "99%",
                                            marginBottom: 3,
                                        }}
                                        bodyStyle={{ padding: 8 }}
                                    >
                                        <Dropdown
                                            overlay={menu}
                                            trigger={["click"]}
                                            onClick={() => setStatutTask(p)}
                                        >
                                            <CheckCircleOutlined
                                                style={{
                                                    marginRight: 8,
                                                    cursor: "pointer",
                                                    zIndex: 1,
                                                    color: p.statut_color
                                                }}
                                            />
                                        </Dropdown>

                                        {parseInt(editUnderTask) ===
                                            parseInt(p.id_tache) &&
                                            p.end == 0 ? (
                                            <TextArea
                                                rows={2}
                                                placeholder="Saisir libellé de la tâche"
                                                size="small"
                                                defaultValue={editUnderText}
                                                onChange={(e) => {
                                                    if (e.target.value !== "") {
                                                        setEditUnderText(
                                                            e.target.value
                                                        );
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (editUnderText !== "") {
                                                        dispatch(
                                                            taskActions.updateUnderTask(
                                                                {
                                                                    id_tache:
                                                                        parseInt(
                                                                            editUnderTask
                                                                        ),
                                                                    libelle:
                                                                        editUnderText,
                                                                    id_projet:
                                                                        parseInt(
                                                                            task.id_projet
                                                                        ),
                                                                }
                                                            )
                                                        );
                                                        setEditUnderTask(0);
                                                    } else {
                                                        setEditUnderTask(0);
                                                    }
                                                }}
                                                style={{ marginLeft: 25, width: "87%", marginTop: -20 }}
                                            />
                                        ) : (
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 5,
                                                    // border: "1px dashed #dcdcdc",
                                                    // paddingTop: 30,
                                                }}
                                                onClick={() => {
                                                    setEditUnderTask(
                                                        parseInt(p.id_tache)
                                                    );
                                                    setEditUnderText(
                                                        p.libelle_tache
                                                    );
                                                }}
                                            >
                                                {p.libelle_tache}
                                            </span>
                                        )}
                                        {p.end == 0 ? (
                                            <DeleteOutlined
                                                style={{
                                                    position: "absolute",
                                                    right: 10,
                                                    bottom: 10,
                                                }}
                                                onClick={() =>
                                                    dispatch(
                                                        taskActions.deleteUnderTask(
                                                            parseInt(p.id_tache)
                                                        )
                                                    )
                                                }
                                            />
                                        ) : null}
                                    </Card>
                                ))}
                            </Scrollbars>
                            {/* </Spin> */}
                        </div>
                    </div>
                    {/* commentaire de la tache */}
                    <div style={{ flex: 1, marginLeft: 5 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "3px 5px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                alignContent: "center",
                                backgroundColor: "#f8f8ff",
                                borderBottom: "1px solid #dcdcdc",
                            }}
                        >
                            <Skeleton
                                active={task_logs_loading}
                                loading={task_logs_loading}
                            />
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "3px 5px",
                                }}
                            >
                                <Avatar
                                    size="large"
                                    style={{
                                        border: "2px solid #252526",
                                        backgroundColor: "#f8f8ff",
                                        color: "#252526",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {task_logs[0] !== [] &&
                                        task_logs[0] !== undefined
                                        ? makeAvatar(task_logs[0].first_name)
                                        : null}
                                </Avatar>
                            </div>
                            <div
                                style={{
                                    flex: 12,
                                    display: "flex",
                                    flexDirection: "column",
                                    color: "#808080",
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            marginRight: 10,
                                            marginBottom: 5,
                                        }}
                                    >
                                        {task_logs[0] !== [] &&
                                            task_logs[0] !== undefined
                                            ? task_logs[0].first_name
                                            : null}
                                    </p>
                                    <span>
                                        il y'a{" "}
                                        <Moment
                                            fromNow
                                            ago
                                            date={
                                                task_logs[0] !== [] &&
                                                    task_logs[0] !== undefined
                                                    ? task_logs[0].create_at
                                                    : null
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        {task_logs[0] !== [] &&
                                            task_logs[0] !== undefined
                                            ? task_logs[0].description_log
                                            : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Scrollbars
                            style={{
                                height: "73%",
                            }}
                        >
                            {task_logs.map((p, index) =>
                                index > 0 ? (
                                    <div
                                        style={{
                                            borderBottom:
                                                index === 0
                                                    ? "1px solid #dcdcdc"
                                                    : null,
                                            marginRight: 10,
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "3px 5px",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                alignContent: "center",
                                                backgroundColor: "#f8f8ff",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "3px 5px",
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    flex: 12,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "#808080",
                                                    borderBottom:
                                                        index !== 0
                                                            ? "1px solid #dcdcdc"
                                                            : null,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            marginRight: 10,
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        {p.first_name}
                                                    </p>
                                                    <span>
                                                        il y'a{" "}
                                                        <Moment
                                                            fromNow
                                                            ago
                                                            date={p.create_at}
                                                        />
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        {p.description_log}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            )}
                            {/* </Spin> */}
                        </Scrollbars>
                        <Form
                            className="login-form"
                            onFinish={submitTacheLogs}
                            form={form}
                            style={{
                                marginBottom: 10,
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Form.Item
                                name="log"
                                style={{ flex: 25, margin: 0, padding: 0 }}
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    placeholder="Veuillez saisir un commentaire..."
                                    size="middle"
                                    onChange={(e) => setLog(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    flex: 6,
                                    margin: 0,
                                    padding: 0,
                                    marginLeft: 10,
                                }}
                            >
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: "100%" }}
                                >
                                    Commenter
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default DetailTache;
