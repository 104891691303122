import axios from "../../axios/index";
export const groupServices = {
    //add,
    getAll,
    // getById,
    //update,
    // remove,
};

async function getAll() {
    return await axios.get(`groups`);
}