import React, { useEffect } from "react";
import { Modal, Button, Form, Input, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { modalActions, project_colorActions, jalonActions, statutActions } from "../../../redux/_actions";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ModalAjouterCouleur = (props) => {
    const dispatch = useDispatch();
    const closable_add_color = useSelector(
        (state) => state.modal.closable_add_color
    );
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            color: props.colorSelected.color,
        });
    });

    const submitAddColor = (values) => {
        if (Object.keys(props.colorSelected).length === 0) {
            dispatch(project_colorActions.add(values));
            dispatch(modalActions.close_add_color());
        } else {
            const data = {
                id_color: props.colorSelected.id_color,
                color: values.color,
            };
            dispatch(project_colorActions.update(data));
            setTimeout(() => {
                dispatch(jalonActions.getAll());
                dispatch(statutActions.getAll());
            }, 100)
            props.setColorSelected({});
            dispatch(modalActions.close_add_color());
        }
    };

    return (
        <Modal
            title={
                Object.keys(props.colorSelected).length === 0
                    ? "Ajouter une couleur"
                    : "Modifier une couleur"
            }
            visible={closable_add_color}
            footer={null}
            onCancel={() => {
                dispatch(modalActions.close_add_color());
                props.setColorSelected({});
            }}
            maskClosable={false}
        >
            <Form
                className="login-form"
                onFinish={submitAddColor}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="color"
                    label={<Text strong={true}>Couleur</Text>}
                    rules={[
                        {
                            required: true,
                            message: "Veuillez choisir une couleur !",
                        },
                    ]}
                >
                    <Input size="large" type="color" placeholder="Choisir une couleur !" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => {
                            dispatch(modalActions.close_add_color());
                            props.setColorSelected({});
                        }}
                    >
                        <CloseCircleOutlined /> Annuler
                    </Button>{" "}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterCouleur;
