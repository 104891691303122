import { groupConstants } from "../_constants";
import { groupServices } from "../_services";
import { loginActions } from "../_actions";

export const groupActions = {
    //add,
    getAll,
    //update,
    // getOne,
    // remove,
    // getModuleActionSuccess,
    // getModuleActionRequest,
    // getModuleAction
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        groupServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };

    function request() {
        return {
            type: groupConstants.GETALL_REQUEST,
        };
    }
    function success(group) {
        return {
            type: groupConstants.GETALL_SUCCESS,
            group,
        };
    }
    function failure(error) {
        return {
            type: groupConstants.GETALL_FAILURE,
            error,
        };
    }
}

// function request () {
//     return {
//         type: groupConstants.GET_GROUP_ACTION_REQUEST,
//     }
// }
// function success (group) {
//     return {
//         type: groupConstants.GET_GROUP_ACTION_SUCCESS,
//         group
//     }
// }
// function failure (error) {
//     return {
//         type: groupConstants.GET_GROUP_ACTION_FAILURE,
//         error
//     }
// }
