import { createStore, applyMiddleware } from "redux";
import rootReducers from "../_reducers";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import logger from 'redux-logger';

import {
    createStateSyncMiddleware,
    initStateWithPrevTab,
} from "redux-state-sync";

const config = {
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    initiateWithState: true
};

export const store = createStore(
    rootReducers,
    applyMiddleware(
        thunk,
        // logger,
        createStateSyncMiddleware(config)
        //routerMiddleware(history),
    )
);

initStateWithPrevTab(store);

export const persistor = persistStore(store);
