import axios from "../../axios/index";

export const demande_cotationServices = {
    getAll,
    getOne,
    add,
    update,
    validate,
    validation_dt,
    getLineDemande,
    validation_team_chef,
    validate_send_customer,
    delete_demande_of_quotation,
    select_demande_of_quotation,
    refus_customer_quoting,
    select_demande_of_quotation_choose,
    create_tache_cotation,
    archiver_demande_of_quotation,
    terminate_demande,
    faturer_cotation
};

async function getAll() {
    return await axios.get(`demande_cotation`);
}

async function getOne(id_demande) {
    return await axios.get(`demande_cotation/get_one/${id_demande}`);
}

async function getLineDemande(id_demande) {
    return await axios.get(`demande_cotation/getLineDemande/${id_demande}`);
}

async function create_tache_cotation(demande_cotation) {
    return await axios.post(`demande_cotation/create_tache`, demande_cotation);
}

async function add(demande_cotation) {
    return await axios.post(`demande_cotation`, demande_cotation);
}

async function update(demande_cotation) {
    return await axios.put(
        `demande_cotation/${demande_cotation.id_demande}`,
        demande_cotation
    );
}

async function faturer_cotation(demande_cotation) {
    return await axios.post(`demande_cotation/faturer_cotation`, demande_cotation);
}

async function validate(demande_cotation) {
    return await axios.post(`demande_cotation/validate`, demande_cotation);
}

async function validation_team_chef(demande_cotation) {
    return await axios.get(
        `demande_cotation/validation_team_chef/${demande_cotation}`
    );
}

async function terminate_demande(demande_cotation) {
    return await axios.get(
        `demande_cotation/terminate_demande/${demande_cotation}`
    );
}

async function validate_send_customer(demande_cotation) {
    return await axios.get(
        `demande_cotation/validate_send_customer/${demande_cotation}`
    );
}

async function refus_customer_quoting(demande_cotation) {
    return await axios.get(
        `demande_cotation/refus_customer_quoting/${demande_cotation}`
    );
}

async function validation_dt(demande_cotation) {
    return await axios.get(
        `demande_cotation/validation_dt/${demande_cotation}`
    );
}

async function delete_demande_of_quotation(demande_cotation) {
    return await axios.get(`demande_cotation/delete/${demande_cotation}`);
}

async function archiver_demande_of_quotation(demande_cotation, value) {
    return await axios.get(`demande_cotation/archiver/${demande_cotation}/${value}`);
}

async function select_demande_of_quotation(demande_cotation) {
    return await axios.get(`demande_cotation/select/${demande_cotation}`);
}

async function select_demande_of_quotation_choose(idP, idD) {
    return await axios.get(`demande_cotation/select_cotation/${idP}/${idD}`);
}
