export const statutConstants = {
    GETALL_STATUT_REQUEST: "GETALL_STATUT_REQUEST",
    GETALL_STATUT_SUCCESS: "GETALL_STATUT_SUCCESS",
    GETALL_STATUT_FAILURE: "GETALL_STATUT_FAILURE",

    ADD_STATUT_REQUEST: "ADD_STATUT_REQUEST",
    ADD_STATUT_SUCCESS: "ADD_STATUT_SUCCESS",
    ADD_STATUT_FAILURE: "ADD_STATUT_FAILURE",

    UPDATE_STATUT_REQUEST: "UPDATE_STATUT_REQUEST",
    UPDATE_STATUT_SUCCESS: "UPDATE_STATUT_SUCCESS",
    UPDATE_STATUT_FAILURE: "UPDATE_STATUT_FAILURE",
};
