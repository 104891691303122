import React, { Children, useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Divider,
    Spin,
    Space,
    Dropdown,
    Table,
    Tooltip,
    Popconfirm,
    Menu,
    Tag,
    Popover,
    Form,
    Input,
    Modal,
    DatePicker,
} from "antd";
import {
    TagsOutlined,
    HomeOutlined,
    UserOutlined,
    CheckOutlined,
    EditOutlined,
    PrinterOutlined,
    EyeOutlined,
    FilterOutlined,
    ClearOutlined,
    DeleteOutlined,
    IssuesCloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseSquareOutlined,
    RedoOutlined,
    RetweetOutlined,
    PlusSquareOutlined,
    CheckSquareOutlined,
    CarryOutOutlined,
    AuditOutlined,
    SaveOutlined,
    CloseOutlined,
    CopyOutlined
} from "@ant-design/icons";
import {
    customerActions,
    modalActions,
    demande_cotationActions,
    team_menberActions,
    projectActions,
    userActions,
    team_projectActions,
    alertActions,
} from "../../../redux/_actions";
import { useSelector, useDispatch } from "react-redux";
import ClientsDrawer from "../../components/Drawer/ClientsDrawer";
import DrawerDemandeDeCotation from "../../components/Drawer/DrawerDemandeDeCotation";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import ModalFilterDemandeCotation from "../../components/Modal/ModalFilterDemandeCotation";
import ModalValiderDemandeCotation from "../../components/Modal/ModalValiderDemandeCotation";
import "./FicheDeCotation.css";
import HeaderApp from "../../parchalse/HeaderApp";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { now, parseInt } from "lodash";
import moment from "moment-timezone";


const FicheDeCotation = () => {

    const [formGlobal] = Form.useForm();

    // creation des constante
    // les differentes profils  
    const profil_admin = 1;
    const profil_chef_equipe_dev = 2;
    const profil_user_dev = 3;
    const profil_chef_equipe_com = 4;
    const profil_user_com = 5;

    // les differente statut pour une demande de cotation;
    const statut_initial = 1;
    const valider_chef_equipe = 2;
    const approuver_dt = 3;
    const statut_accepter_client = 4;
    const statut_envoyer_client = 5;
    const statut_refuser_client = 6;
    const statut_terminer_dmnde = 7;
    const statut_facturer_dmnde = 8;
    const statut_archiver_dmnde = 9;

    // les variable useState du composant 
    const [demandeSelected, setDemandeSelected] = useState({});
    const [demandeDetail, setDemandeDetail] = useState({});
    const [typeProjet, setTypeProjet] = useState(1);
    const [loadingCotation, setLoadingCotation] = useState(true);
    const [selectedDemande, setSelectedDemande] = useState(0);
    const [duplicateDemande, setduplicateDemande] = useState(0);
    const auth = useSelector((state) => state.login.item);

    const dispatch = useDispatch();

    // la recuperation des valeur a partir du state globale
    const demande_cotations = useSelector(
        (state) => state.demande_cotation.items
    );
    const loading = useSelector((state) => state.demande_cotation.loading);

    const filtrerDemande = useSelector(
        (state) => state.demande_cotation.filtrerDemande
    );
    const isFiltered = useSelector(
        (state) => state.demande_cotation.isFiltered
    );
    const fileredData = useSelector(
        (state) => state.demande_cotation.fileredData
    );
    const statut_demande = useSelector((state) => state.statut_demande.items);

    const user = useSelector((state) => state.login);
    const ability = defineAbilityFor(user);

    // console.log('valLog', demande_cotations)

    // le useEffect qui est executer dès l'affichage du composant courant
    useEffect(() => {
        dispatch(customerActions.getAll());
        // dispatch(demande_cotationActions.getOne(auth.id_profil));
        dispatch(modalActions.close_add_demande_of_quotation());
        dispatch(demande_cotationActions.not_filtered_demande_of_quotation());
        dispatch(demande_cotationActions.reset_filtered_demande_of_quotation());
        dispatch(demande_cotationActions.getAll());
        dispatch(projectActions.getAll());
    }, []);

    // useEffect(() => {
    //     setSelectedDemande(selectedDemande);
    // }, [selectedDemande]);


    // fucntion pour le formatage des date du composant
    // const myFormatDate = (inputDate) => {

    //     const tab_date = inputDate.split('-');
    //     const date_return = tab_date[2] + "/" + tab_date[1] + "/" + tab_date[0];

    //     return date_return;

    //     // var date = new Date(inputDate);
    //     // if (!isNaN(date.getTime())) {
    //     //     // Months use 0 index.
    //     //     return (
    //     //         date.getDate() +
    //     //         "/" +
    //     //         (parseInt(date.getMonth()) + 1) +
    //     //         "/" +
    //     //         date.getFullYear()
    //     //     );
    //     // }
    // };

    // function pour le renvoi de la cotation vers le client
    function handleMenuRenvoi(e) {

        if (selectedDemande !== 0) {

            // c'est le renvoi de la fiche de cotation au client  
            if (parseInt(e.key) === 1) {
                dispatch(demande_cotationActions.validate_send_customer(selectedDemande));
            }

            // on vide la demande selectionnée
            // setSelectedDemande(0)
        }

    }

    // function de recuperation de la reponse du client pour traitement
    function handleMenuReponse(e) {

        if (selectedDemande !== 0) {
            // si c'est validé par le client 
            if (parseInt(e.key) === 1) {
                dispatch(modalActions.show_validate_cotation());
            }

            // refus par le client de la demande de cotation
            else if (parseInt(e.key) === 2) {
                dispatch(demande_cotationActions.refus_customer_quoting(selectedDemande));
            }

            // on vide la demande selectionnée
            // setSelectedDemande(0)
        }

    }


    // le menu du drawdrop pour la reponse du client 
    const menu = (
        <Menu onClick={(values) => { handleMenuReponse(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Valider par le client'}>
                    <CheckCircleOutlined style={{ color: "#82dd8c", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: '#82dd8c', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Valider
                    </Button>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="2">
                <Tooltip placement="rightBottom" title={'Refuser par le client'}>
                    <CloseCircleOutlined style={{ color: "red", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Rejeter
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    // function de recuperation de la reponse du client pour traitement
    function handleMenuTache(e) {

        // c'est la validation du client pour la demande de cotation 
        if (parseInt(e.key) === 1) {
            dispatch(demande_cotationActions.create_tache_cotation({
                id: selectedDemande,
                etat: 1
            }));
        }

        // refus par le client de la demande de cotation
        else if (parseInt(e.key) === 2) {
            dispatch(demande_cotationActions.create_tache_cotation({
                id: selectedDemande,
                etat: 0
            }));
        }

    }

    // le menu du drawdrop pour la reponse du client 
    const menuTache = (
        <Menu onClick={(values) => { handleMenuTache(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Valider par le client'}>
                    <CheckCircleOutlined style={{ color: "#82dd8c", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: '#82dd8c', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Créer
                    </Button>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="2">
                <Tooltip placement="rightBottom" title={'Refuser par le client'}>
                    <CloseCircleOutlined style={{ color: "red", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Déjà créer
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    // menu pour le bouton refus par le client
    const menuRefus = (
        <Menu onClick={(values) => { handleMenuRenvoi(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Renvoyer la cotation au client'}>
                    <RedoOutlined style={{ color: "orange", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'black', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Renvoyer
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    const onFinish = (values) => {
        dispatch(demande_cotationActions.faturer_cotation({
            'ref': values.ref,
            'id_demande': cotation.id_demande
        }));
    };

    const [cotation, setCotation] = useState({});

    const handleTitle = () => {
        return (
            <>
                <span>Référence de la facture</span>
                {/* <CloseOutlined style={{ cursor: 'pointer', float: 'right', marginTop: 5 }} /> */}
            </>
        );
    }

    const content = () => {

        if (Object.keys(cotation).length > 0) {

            let demande = demande_cotations.filter((p) => p.id_demande === cotation.id_demande)[0];

            if (demande.ref_facture !== null) {
                formGlobal.setFieldsValue({
                    ref: demande.ref_facture,
                });
            } else {
                formGlobal.resetFields();
            }
        }

        return (
            <div>
                <Form
                    form={formGlobal}
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="ref" rules={[{ required: true, message: 'la référence est obligatoire!' }]}>
                        <Input style={{ width: 140 }} placeholder="la référence... " />
                    </Form.Item>
                    {/* <Form.Item name="id" rules={[{ required: true, message: 'la référence est obligatoire!' }]}>
                        <Input style={{ width: 140 }} placeholder="la référence... " />
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    // les columns du tableau d'affichage des informations pour les demandes de cotation
    const columns = [

        {
            title: "#",
            dataIndex: "key",
            key: "ke",
            width: '40px',
            // render(text, record) {
            //     return {
            //         props: {
            //             style: {
            //                 background: record.color_statut,
            //                 padding: '0px'
            //             }
            //         },
            //     };
            // }
        },
        {
            title: "Libellé",
            dataIndex: "libelle_demande",
            key: "libelle_demande",
            render: (record) => (record === null ? "..." : record.length > 30 ? (
                <>
                    <Tooltip placement="top" title={record}>
                        {record.substr(0, 30) + '...'}
                    </Tooltip>
                </>
            ) : record),
            width: 300,
        },
        {
            title: "Projet",
            dataIndex: "libelle_projet",
            key: "libelle_projet",
            render: (record) => (record === null ? "..." : record.length > 20 ? (
                <>
                    <Tooltip placement="top" title={record}>
                        {record.substr(0, 20) + '...'}
                    </Tooltip>
                </>
            ) : record),
            width: 150,
        },
        {
            title: "Équipe",
            dataIndex: "libelle_equipe",
            key: "libelle_equipe",
            render: (record) => (record === null ? "---" : record),
            width: 150,
        },
        {
            title: "Client",
            dataIndex: "nom_client",
            key: "nom_client",
            width: 150,
            render: (record) => (record === null ? "---" : record),
        },
        {
            title: "État",
            dataIndex: "libelle_statut_demande",
            key: "libelle_statut_demande",
            width: 150,
            render: (text, record) => (
                <>
                    <Tag
                        color={statut_demande !== undefined ? statut_demande.filter((i) => i.libelle == text)[0].color : null}
                        style={{ width: 90, textAlign: "center", fontWeight: "bold" }}
                    >
                        {text.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: "Progression",
            dataIndex: "etat_demande",
            key: "etat_demande",
            width: 150,
            render: (text, record) => (
                <>
                    <Tag
                        color={record.etat_demande > 0 ? "green" : 'orange'}
                        style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        {record.etat_demande} %
                    </Tag>
                </>
            ),
        },
        // {
        //     title: "Responsable",
        //     dataIndex: "first_name",
        //     key: "first_name",
        //     render: (record) => (record === null ? "---" : record),
        //     width: 150,
        // },
        // {
        //     title: "Date d'édition",
        //     dataIndex: "date_edition",
        //     key: "date_edition",
        //     render: (record) => myFormatDate(record),
        //     width: 110,
        // },
        {
            title: "Action",
            key: "operation",
            width: 150,
            render: (record) => (
                <>
                    <Space>
                        {/* voir le detail de la cotation */}
                        <Tooltip placement="top" title={'Détail de la demande'}>
                            <Button
                                title="Détail"
                                type="default"
                                style={{ backgroundColor: "#fafafa" }}
                                icon={<EyeOutlined />}
                                size="small"
                                onClick={() => {
                                    setSelectedDemande(record.key)
                                    setDemandeDetail(record);
                                    setTypeProjet(record.id_type);
                                    dispatch(
                                        modalActions.show_add_demande_of_quotation()
                                    );
                                    if (record.id_user !== null) {
                                        dispatch(
                                            userActions.getAllMemberProject(
                                                parseInt(record.id_projet)
                                            )
                                        );
                                    }
                                }}
                            />
                        </Tooltip>
                        <Can
                            I="DupliquerDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            <Tooltip placement="top" title={'Dupliquer la demande'}>
                                <Button
                                    title="Duplication"
                                    type="default"
                                    style={{ backgroundColor: "#dbe4ee" }}
                                    icon={<CopyOutlined />}
                                    size="small"
                                    onClick={() => {
                                        setDemandeSelected(record);
                                        setduplicateDemande(1)
                                        setTypeProjet(record.id_type);
                                        if (record.id_equipe !== null) {
                                            dispatch(
                                                team_menberActions.getAllTeamMenber(
                                                    {
                                                        id_equipe: parseInt(
                                                            record.id_equipe
                                                        ),
                                                    }
                                                )
                                            );
                                        }
                                        if (record.id_user !== null) {
                                            dispatch(
                                                userActions.getAllMemberProject(
                                                    parseInt(record.id_projet)
                                                )
                                            );
                                        }
                                        dispatch(
                                            modalActions.show_add_demande_of_quotation()
                                        );
                                    }}
                                />
                            </Tooltip>
                        </Can>
                        {/* modifier un demande */}
                        {record.id_statut !== statut_accepter_client & record.id_statut !== statut_envoyer_client & record.id_statut !== statut_terminer_dmnde & record.id_statut !== statut_facturer_dmnde & record.id_statut !== statut_archiver_dmnde ? (
                            <Can
                                I="ModifierDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'Modifier la demande'}>
                                    <Button
                                        // title="Modifier"
                                        type="default"
                                        style={{ backgroundColor: "#f0e68c" }}
                                        icon={<EditOutlined />}
                                        size="small"
                                        onClick={() => {
                                            setDemandeSelected(record);
                                            setTypeProjet(record.id_type);
                                            if (record.id_equipe !== null) {
                                                dispatch(
                                                    team_menberActions.getAllTeamMenber(
                                                        {
                                                            id_equipe: parseInt(
                                                                record.id_equipe
                                                            ),
                                                        }
                                                    )
                                                );
                                            }
                                            if (record.id_user !== null) {
                                                dispatch(
                                                    userActions.getAllMemberProject(
                                                        parseInt(record.id_projet)
                                                    )
                                                );
                                            }
                                            dispatch(
                                                modalActions.show_add_demande_of_quotation()
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </Can>
                        ) : null}
                        {/* les differents type de validation d'une cotation */}
                        {record.id_statut === statut_initial ? (
                            < Can
                                I="ValiderDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'valider la demande'}>
                                    <Popconfirm
                                        title="Êtes-vous sûr de vouloir valider cette demande de cotation ?"
                                        onConfirm={() =>
                                            dispatch(
                                                demande_cotationActions.validation_team_chef(
                                                    record.id_demande
                                                )
                                            )

                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                        placement="top"
                                    >
                                        <Button
                                            // title="Valider"
                                            type="default"
                                            style={{ backgroundColor: "#495057", color: '#fff' }}
                                            // icon={<CheckOutlined />}
                                            size="small"
                                        >V</Button>
                                    </Popconfirm>
                                </Tooltip>
                            </Can>
                        ) : record.id_statut === valider_chef_equipe ? (
                            <Can
                                I="ApprouverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'Approuver la demande'}>
                                    <Popconfirm
                                        title="Êtes-vous sûr de vouloir approver cette demande de cotation ?"
                                        onConfirm={() =>
                                            dispatch(
                                                demande_cotationActions.validation_dt(
                                                    record.id_demande
                                                )
                                            )
                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                        placement="top"
                                    >
                                        <Button
                                            title="Approuver"
                                            type="default"
                                            style={{ backgroundColor: "#82dd8c", color: "#000" }}
                                            // icon={<CheckSquareOutlined style={{ color: '#000' }} />}
                                            size="small"
                                        >A</Button>
                                    </Popconfirm>
                                </Tooltip>
                            </Can>
                        ) : record.id_statut === approuver_dt || record.id_statut === statut_envoyer_client || record.id_statut === statut_refuser_client ? (
                            <Can
                                I="RetourDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                {(parseInt(record.id_statut) === approuver_dt) ?
                                    (
                                        <>
                                            <Tooltip placement="top" title={'Cotation à envoyer au client'}>
                                                <Popconfirm
                                                    title="Vous confirmez vouloir envoyé cette demande de cotation au client ?"
                                                    onConfirm={() =>
                                                        dispatch(
                                                            demande_cotationActions.validate_send_customer(
                                                                record.id_demande
                                                            )
                                                        )
                                                    }
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    placement="top"
                                                >
                                                    <Button
                                                        title="Terminer"
                                                        type="default"
                                                        style={{ backgroundColor: "#f0e68c", color: '#000' }}
                                                        // icon={<RetweetOutlined />}
                                                        size="small"
                                                    >E</Button>
                                                </Popconfirm>
                                            </Tooltip>
                                        </>
                                    ) :
                                    //  si c'est envoyer au client, alors on deux choix (refuser ou accepter)
                                    (parseInt(record.id_statut) === statut_envoyer_client) ?
                                        (
                                            <>
                                                <Tooltip placement="rightBottom" title={'Réponse du client ?'}>
                                                    <Dropdown
                                                        overlay={menu}
                                                        placement="bottomRight"
                                                        trigger="click"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedDemande(record.id_demande)
                                                            }}
                                                            type="default"
                                                            style={{ backgroundColor: "orange" }}
                                                            icon={<IssuesCloseOutlined />}
                                                            size="small"
                                                        >
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                            </>
                                        ) :
                                        //  si c'est refuser par le client 
                                        (parseInt(record.id_statut) === statut_refuser_client) ?
                                            (
                                                <>
                                                    <Tooltip placement="top" title={'Refuser par le client'}>
                                                        <Dropdown
                                                            overlay={menuRefus}
                                                            placement="bottomRight"
                                                            trigger="click"
                                                        >
                                                            <Button
                                                                // title="Renvoyer"
                                                                onClick={() => {
                                                                    setSelectedDemande(record.id_demande)
                                                                }}
                                                                type="default"
                                                                style={{ backgroundColor: "#ff6347" }}
                                                                icon={<CloseSquareOutlined style={{ color: 'white' }} />}
                                                                size="small"
                                                            >
                                                            </Button>
                                                        </Dropdown>
                                                    </Tooltip>
                                                </>
                                            )
                                            // si c'est accepter par le client
                                            : (parseInt(record.id_statut) === statut_accepter_client) ?
                                                (
                                                    <>
                                                        <Tooltip placement="top" title={'Cette cotation à été valider'}>
                                                            <Button
                                                                type="default"
                                                                style={{ backgroundColor: "#82dd8c" }}
                                                                icon={<CheckOutlined />}
                                                                size="small"
                                                                disabled={true}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                ) : null
                                }
                            </Can>
                        ) : record.id_statut === statut_accepter_client ? (
                            < Can
                                I="TerminerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'Terminer la demande'}>
                                    <Popconfirm
                                        title="Êtes-vous sûr d'avoir terminer cette demande de cotation ?"
                                        onConfirm={() =>
                                            dispatch(
                                                demande_cotationActions.terminate_demande(
                                                    record.id_demande
                                                )
                                            )
                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                        placement="top"
                                    >
                                        <Button
                                            // title="Terminer"
                                            type="default"
                                            style={{ backgroundColor: "#118ab2" }}
                                            icon={<CarryOutOutlined style={{ color: 'white' }} />}
                                            size="small"
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            </Can>
                        ) : record.id_statut === statut_terminer_dmnde ? (
                            < Can
                                I="FacturerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="bottom" title={'Facturer la demande'}>
                                    <Popover
                                        content={content}
                                        title={handleTitle}
                                        trigger="click"
                                        width="130"
                                    >
                                        <Button
                                            // title="Facturer"
                                            type="default"
                                            style={{ backgroundColor: "#caf0f8" }}
                                            icon={<AuditOutlined style={{ color: '#000' }} />}
                                            size="small"
                                            onClick={() => {
                                                setCotation(record);
                                            }}
                                        />
                                    </Popover>
                                </Tooltip>
                            </Can>
                        ) : null}
                        {/* permet de la creation des taches de la demande*/}
                        <Can
                            I="create_tache"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            {parseInt(record.is_create) === 1 ? (
                                <Tooltip placement="topLeft" title={'Création des tâches ?'}>
                                    <Dropdown
                                        overlay={menuTache}
                                        placement="bottomRight"
                                        trigger="click"
                                    >
                                        <Button
                                            // title="Création des taches"
                                            onClick={() => {
                                                setSelectedDemande(record.id_demande)
                                            }}
                                            type="default"
                                            style={{ backgroundColor: "orange" }}
                                            icon={<PlusSquareOutlined />}
                                            size="small"
                                        >
                                        </Button>
                                    </Dropdown>
                                </Tooltip>
                            ) : null}
                        </Can>
                        {/* Suppression de la demande */}
                        <Can
                            I="SupprimerDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            {parseInt(record.id_statut) === 1 ? (
                                <>
                                    <Tooltip placement="top" title={'Suppimer la demande'}>
                                        <Popconfirm
                                            title="Êtes-vous sûr de vouloir supprimer cette demande de cotation ?"
                                            onConfirm={() =>
                                                dispatch(
                                                    demande_cotationActions.delete_demande_of_quotation(
                                                        record.id_demande
                                                    )
                                                )
                                            }
                                            okText="Oui"
                                            cancelText="Non"
                                        >
                                            <Button
                                                // title="Supprimer"
                                                style={{
                                                    backgroundColor: "#ff6347",
                                                    color: "white",
                                                }}
                                                icon={<DeleteOutlined />}
                                                size="small"
                                            />
                                        </Popconfirm>
                                    </Tooltip>
                                </>
                            ) : null}
                        </Can>
                        {/* Archiver une cotation */}
                        {record.id_statut === statut_facturer_dmnde || record.id_statut === statut_refuser_client ? (
                            <Can
                                I="ArchiverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'Archiver la demande'}>
                                    <Popconfirm
                                        title="Êtes-vous sûr de vouloir archiver cette demande de cotation ?"
                                        onConfirm={() =>
                                            dispatch(
                                                demande_cotationActions.archiver_demande_of_quotation(
                                                    record.id_demande, 1
                                                )
                                            )
                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                    >
                                        <Button
                                            // title="Archiver"
                                            style={{
                                                backgroundColor: "#10002b",
                                                color: "white",
                                            }}
                                            icon={<TagsOutlined />}
                                            size="small"
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            </Can>
                        ) : record.id_statut === statut_archiver_dmnde ? (
                            <Can
                                I="ArchiverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Tooltip placement="top" title={'Désarchiver la demande'}>
                                    <Popconfirm
                                        title="Êtes-vous sûr de vouloir Désarchiver cette demande de cotation ?"
                                        onConfirm={() =>
                                            dispatch(
                                                demande_cotationActions.archiver_demande_of_quotation(
                                                    record.id_demande, 0
                                                )
                                            )
                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: "#1bb02b",
                                                color: "white",
                                            }}
                                            icon={<TagsOutlined />}
                                            size="small"
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            </Can>
                        ) : null}
                        {/* imprimer une demande */}
                        {/* <Can
                            I="ImprimerDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            <Tooltip placement="top" title={'Imprimer la demande'}>
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir imprimer cette demande de cotation ?"
                                    onConfirm={() =>
                                        ImprimerDemandeCotation(record)
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        // title="Imprimer"
                                        type="primary"
                                        icon={<PrinterOutlined />}
                                        size="small"
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </Can> */}
                    </Space>
                </>
            ),
        },
    ];

    // function pour le calcule de la duree d'une demande de cotation
    const dureeTotal = (durre) => {
        let sum = 0;
        let i = 0;

        while (i < durre.length) {
            sum = sum + durre[i]["duree"];
            i++;
        }
        let result = parseFloat(sum) / 8;
        return result.toFixed(2);
    };

    // function pour l'impression d'une demande de cotation
    const ImprimerDemandeCotation = (demande) => {

        let cotations = demande.cotations;
        let tab = [];
        cotations.map((p) =>
            tab.push([p.module, p.tache, p.description, p.duree, p.observation])
        );

        const doc = new jsPDF();

        var num;
        if (parseInt(demande.id_demande) < 10) {
            num = `000${demande.id_demande}`;
        } else if (
            parseInt(demande.id_demande) >= 10 &&
            parseInt(demande.id_demande) < 100
        ) {
            num = `00${demande.id_demande}`;
        } else if (parseInt(demande.id_demande) >= 100) {
            num = `0${demande.id_demande}`;
        } else {
            num = `${demande.id_demande}`;
        }

        doc.setFontSize(14);
        doc.text(`FICHE DE COTATION  N° ${num}`, 70, 15);

        doc.setFontSize(10);
        doc.text(`Projet : ${demande.libelle_projet}`, 10, 23);

        var equipe;
        if (demande.libelle_equipe !== null) {
            equipe = demande.libelle_equipe;
        } else {
            equipe = "___";
        }
        doc.setFontSize(10);
        doc.text(`Équipe : ${equipe}`, 10, 31);

        var client;
        if (demande.nom_client !== null) {
            client = demande.nom_client;
        } else {
            client = "___";
        }

        doc.setFontSize(10);
        doc.text(`Client : ${client}`, 10, 39);

        var respon_non;
        if (demande.first_name !== null && demande.first_name !== undefined) {
            respon_non = demande.first_name;
        } else {
            respon_non = "___";
        }

        doc.setFontSize(10);
        doc.text(`Responsable : ${respon_non}`, 138, 23);

        doc.setFontSize(10);
        doc.text(
            `Date d'édition : ${moment(demande.date_edition).format('DD/MM/YYYY')}`,
            138,
            31
        );

        doc.setFontSize(10);
        doc.text(
            `Durée total : ${dureeTotal(demande.cotations)} ${parseInt(dureeTotal(demande.cotations)) <= 1 ? "jour" : "jours"
            }`,
            138,
            39
        );

        doc.autoTable({ startY: 38 });

        doc.autoTable({
            style: {
                padding: 10,
                marginTop: 100,
                columnWidth: "wrap",
            },
            margin: { horizontal: 10 },
            bodyStyles: { fontSize: 8, textColor: "black" },
            columnStyles: {
                0: { cellWidth: 38 },
                1: { cellWidth: 80 },
                2: { cellWidth: 57 },
                3: { cellWidth: 15 },
                // 4: {cellWidth: 30 },
            },
            head: [
                ["Module", "tâche", "Description", "Durée" /*, "Observation"*/],
            ],
            body: tab,
        });

        doc.save("demande_cotation_.pdf");
    };


    // le rendu du composant courant
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Projets</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    margin: 18,
                    marginTop: 0,
                    backgroundColor: "white",
                    padding: 18,
                    paddingTop: 10,
                    paddingBottom: 0,
                    marginBottom: 0,
                    height: "85.4%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    {/* <div
                        style={{
                            flex: 1,
                        }}
                    >

                    </div> */}

                    {/* {
                        statut_demande.length > 0 ?
                            statut_demande.map((p) => (
                                <>
                                    <div style={{
                                        marginTop: '5px',
                                        backgroundColor: p.color,
                                        width: '40px',
                                        height: '27px',
                                        borderRadius: '3px solid black',
                                        borderTopLeftRadius: '3px',
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                        marginLeft: '30px'
                                    }}>
                                    </div>
                                    <label style={{ marginTop: 8, marginLeft: 5, color: 'rgb(211, 207, 207)' }}>{p.libelle}</label>
                                </>
                            )) : null
                    } */}
                    <div
                        style={{
                            flex: 1,
                            textAlign: "right",
                        }}
                    >

                        <Can
                            I="LireClient"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            <Button
                                type="default"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                    dispatch(modalActions.show_add_customers())
                                }
                            >
                                <UserOutlined /> Clients
                            </Button>
                        </Can>

                        {isFiltered ? (
                            <Button
                                type="dashed"
                                style={{
                                    marginRight: 10,
                                    borderColor: "red",
                                    color: "red",
                                }}
                                onClick={() => {
                                    dispatch(
                                        modalActions.show_filter_demande_of_cotation()
                                    );
                                    if (auth.id_profil === 2) {
                                        dispatch(
                                            team_projectActions.getAllTeamProject(
                                                {
                                                    id_equipe: auth.id_equipe,
                                                }
                                            )
                                        );
                                    }
                                }}
                            >
                                <ClearOutlined /> Filtrer
                            </Button>
                        ) : (
                            <Button
                                type="dashed"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    dispatch(
                                        modalActions.show_filter_demande_of_cotation()
                                    );
                                    if (auth.id_profil === 2) {
                                        dispatch(
                                            team_projectActions.getAllTeamProject(
                                                {
                                                    id_equipe: auth.id_equipe,
                                                }
                                            )
                                        );
                                    }
                                }}
                            >
                                <FilterOutlined /> Filtrer
                            </Button>
                        )}

                        <Can
                            I="AjouterDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            <Button
                                type="primary"
                                onClick={() => {
                                    dispatch(
                                        modalActions.show_add_demande_of_quotation()
                                    );
                                    setDemandeSelected({});
                                    if (isFiltered) {
                                        dispatch(
                                            demande_cotationActions.not_filtered_demande_of_quotation()
                                        );
                                    }
                                }}
                            >
                                Ajouter une demande
                            </Button>
                        </Can>
                    </div>
                </div>

                <Divider style={{ margin: "20px 0px" }} />
                <Spin
                    tip="Chargement en cours..."
                    size="large"
                    spinning={loading}
                >
                    <Table
                        style={{
                            height: '75vh',
                        }}
                        className="table-striped-rows"
                        // rowClassName={(record, index) => "etatDemande"}
                        // rowClassName={(record, index) => (parseInt(record.id_statut) === statut_envoyer_client ?
                        //     "sendClient1" : parseInt(record.id_statut) === statut_refuser_client ?
                        //         "refusClient1" : parseInt(record.id_statut) === statut_accepter_client ?
                        //             'validateClient1' : 'etatDemande')}
                        columns={columns}
                        pagination={{ defaultPageSize: 12 }}
                        scroll={{ y: 440 }}
                        dataSource={
                            auth.id_profil === profil_chef_equipe_dev
                                ? isFiltered
                                    ? filtrerDemande
                                        .filter(
                                            (p) =>
                                                p.id_equipe === auth.id_equipe
                                        )
                                        .filter((p) =>
                                            fileredData.id_projet !== null
                                                ? parseInt(p.id_projet) ===
                                                parseInt(
                                                    fileredData.id_projet
                                                )
                                                : p
                                        )
                                        .filter((pp) =>
                                            fileredData.id_equipe !== null
                                                ? parseInt(pp.id_equipe) ===
                                                parseInt(
                                                    fileredData.id_equipe
                                                )
                                                : pp
                                        )
                                        .filter((ppp) =>
                                            fileredData.id_statut !== null
                                                ? parseInt(ppp.id_statut) ===
                                                parseInt(
                                                    fileredData.id_statut
                                                )
                                                : parseInt(ppp.id_statut) !== statut_archiver_dmnde
                                        )
                                        .filter((pppp) =>
                                            fileredData.id_client !== null
                                                ? parseInt(pppp.id_client) ===
                                                parseInt(
                                                    fileredData.id_client
                                                )
                                                : pppp
                                        )
                                    : demande_cotations.filter(
                                        (p) =>
                                            p.id_equipe === auth.id_equipe &&
                                            (parseInt(p.id_statut) === statut_initial ||
                                                parseInt(p.id_statut) === valider_chef_equipe ||
                                                parseInt(p.id_statut) === approuver_dt ||
                                                parseInt(p.id_statut) === statut_envoyer_client ||
                                                parseInt(p.id_statut) === statut_refuser_client ||
                                                parseInt(p.id_statut) === statut_accepter_client ||
                                                parseInt(p.id_statut) === statut_terminer_dmnde ||
                                                parseInt(p.id_statut) === statut_facturer_dmnde)
                                    )
                                : auth.id_profil === profil_user_com
                                    ? isFiltered
                                        ? filtrerDemande
                                            .filter((p) =>
                                                fileredData.id_projet !== null
                                                    ? parseInt(p.id_projet) ===
                                                    parseInt(
                                                        fileredData.id_projet
                                                    )
                                                    : p
                                            )
                                            .filter((pp) =>
                                                fileredData.id_equipe !== null
                                                    ? parseInt(pp.id_equipe) ===
                                                    parseInt(
                                                        fileredData.id_equipe
                                                    )
                                                    : pp
                                            )
                                            .filter((ppp) =>
                                                fileredData.id_statut !== null
                                                    ? parseInt(ppp.id_statut) ===
                                                    parseInt(
                                                        fileredData.id_statut
                                                    )
                                                    : parseInt(ppp.id_statut) !== statut_archiver_dmnde
                                            )
                                            .filter((pppp) =>
                                                fileredData.id_client !== null
                                                    ? parseInt(pppp.id_client) ===
                                                    parseInt(
                                                        fileredData.id_client
                                                    )
                                                    : pppp
                                            )
                                        : demande_cotations.filter(
                                            (p) => (parseInt(p.id_statut) === statut_initial ||
                                                parseInt(p.id_statut) === valider_chef_equipe ||
                                                parseInt(p.id_statut) === approuver_dt ||
                                                parseInt(p.id_statut) === statut_envoyer_client ||
                                                parseInt(p.id_statut) === statut_refuser_client ||
                                                parseInt(p.id_statut) === statut_accepter_client ||
                                                parseInt(p.id_statut) === statut_terminer_dmnde ||
                                                parseInt(p.id_statut) === statut_facturer_dmnde
                                            )
                                        )
                                    : auth.id_profil === profil_admin
                                        ? isFiltered
                                            ? filtrerDemande
                                                .filter((p) =>
                                                    fileredData.id_projet !== null
                                                        ? parseInt(p.id_projet) ===
                                                        parseInt(
                                                            fileredData.id_projet
                                                        )
                                                        : p
                                                )
                                                .filter((pp) =>
                                                    fileredData.id_equipe !== null
                                                        ? parseInt(pp.id_equipe) ===
                                                        parseInt(
                                                            fileredData.id_equipe
                                                        )
                                                        : pp
                                                )
                                                .filter((ppp) =>
                                                    fileredData.id_statut !== null
                                                        ? parseInt(ppp.id_statut) ===
                                                        parseInt(
                                                            fileredData.id_statut
                                                        )
                                                        : parseInt(ppp.id_statut) !== statut_archiver_dmnde
                                                )
                                                .filter((pppp) =>
                                                    fileredData.id_client !== null
                                                        ? parseInt(pppp.id_client) ===
                                                        parseInt(
                                                            fileredData.id_client
                                                        )
                                                        : pppp
                                                )
                                                .filter((ppppp) =>
                                                    fileredData.id_client === null ||
                                                        fileredData.id_equipe === null ||
                                                        fileredData.id_projet === null ||
                                                        fileredData.id_statut === null ?
                                                        (parseInt(ppppp.id_statut) === statut_initial &&
                                                            parseInt(ppppp.created_by) === parseInt(auth.id_user)) ||
                                                        (parseInt(ppppp.id_statut) === valider_chef_equipe ||
                                                            parseInt(ppppp.id_statut) === approuver_dt ||
                                                            parseInt(ppppp.id_statut) === statut_envoyer_client ||
                                                            parseInt(ppppp.id_statut) === statut_refuser_client ||
                                                            parseInt(ppppp.id_statut) === statut_accepter_client ||
                                                            parseInt(ppppp.id_statut) === statut_archiver_dmnde ||
                                                            parseInt(ppppp.id_statut) === statut_terminer_dmnde ||
                                                            parseInt(ppppp.id_statut) === statut_facturer_dmnde
                                                        ) : null
                                                )
                                            : demande_cotations.filter(
                                                (p) => (parseInt(p.id_statut) === statut_initial &&
                                                    parseInt(p.created_by) === parseInt(auth.id_user)) ||
                                                    (parseInt(p.id_statut) === valider_chef_equipe ||
                                                        parseInt(p.id_statut) === approuver_dt ||
                                                        parseInt(p.id_statut) === statut_envoyer_client ||
                                                        parseInt(p.id_statut) === statut_refuser_client ||
                                                        parseInt(p.id_statut) === statut_accepter_client ||
                                                        parseInt(p.id_statut) === statut_terminer_dmnde ||
                                                        parseInt(p.id_statut) === statut_facturer_dmnde
                                                    )
                                            )
                                        : auth.id_profil === profil_chef_equipe_com
                                            ? isFiltered
                                                ? filtrerDemande
                                                    .filter(
                                                        (p) =>
                                                            ((parseInt(p.id_statut) ===
                                                                statut_initial ||
                                                                parseInt(p.id_statut) ===
                                                                valider_chef_equipe) &&
                                                                parseInt(p.created_by) ===
                                                                parseInt(
                                                                    auth.id_user
                                                                )) ||
                                                            parseInt(p.id_statut) === approuver_dt ||
                                                            parseInt(p.id_statut) === statut_refuser_client ||
                                                            parseInt(p.id_statut) === statut_envoyer_client ||
                                                            parseInt(p.id_statut) === statut_accepter_client ||
                                                            parseInt(p.id_statut) === statut_archiver_dmnde ||
                                                            parseInt(p.id_statut) === statut_terminer_dmnde ||
                                                            parseInt(p.id_statut) === statut_facturer_dmnde
                                                        // || parseInt(p.id_statut) === 5
                                                    )
                                                    .filter((p) =>
                                                        fileredData.id_projet !== null
                                                            ? parseInt(p.id_projet) ===
                                                            parseInt(
                                                                fileredData.id_projet
                                                            )
                                                            : p
                                                    )
                                                    .filter((pp) =>
                                                        fileredData.id_equipe !== null
                                                            ? parseInt(pp.id_equipe) ===
                                                            parseInt(
                                                                fileredData.id_equipe
                                                            )
                                                            : pp
                                                    )
                                                    .filter((ppp) =>
                                                        fileredData.id_statut !== null
                                                            ? parseInt(ppp.id_statut) ===
                                                            parseInt(
                                                                fileredData.id_statut
                                                            )
                                                            : parseInt(ppp.id_statut) !== statut_archiver_dmnde
                                                    )
                                                    .filter((pppp) =>
                                                        fileredData.id_client !== null
                                                            ? parseInt(pppp.id_client) ===
                                                            parseInt(
                                                                fileredData.id_client
                                                            )
                                                            : pppp
                                                    )
                                                    .filter((ppppp) =>
                                                        fileredData.id_client === null ||
                                                            fileredData.id_equipe === null ||
                                                            fileredData.id_projet === null ||
                                                            fileredData.id_statut === null ?
                                                            parseInt(ppppp.id_statut) === approuver_dt ||
                                                            parseInt(ppppp.id_statut) === statut_envoyer_client ||
                                                            parseInt(ppppp.id_statut) === statut_refuser_client ||
                                                            parseInt(ppppp.id_statut) === statut_accepter_client ||
                                                            parseInt(ppppp.id_statut) === statut_archiver_dmnde ||
                                                            parseInt(ppppp.id_statut) === statut_terminer_dmnde ||
                                                            parseInt(ppppp.id_statut) === statut_facturer_dmnde : null

                                                    )
                                                : demande_cotations.filter(
                                                    (p) => parseInt(p.id_statut) === approuver_dt ||
                                                        parseInt(p.id_statut) === statut_envoyer_client ||
                                                        parseInt(p.id_statut) === statut_refuser_client ||
                                                        parseInt(p.id_statut) === statut_accepter_client ||
                                                        parseInt(p.id_statut) === statut_terminer_dmnde ||
                                                        parseInt(p.id_statut) === statut_facturer_dmnde
                                                )
                                            : auth.id_profil === profil_user_dev
                                                ? isFiltered
                                                    ? filtrerDemande
                                                        .filter(
                                                            (p) =>
                                                                parseInt(
                                                                    p.id_responsable_demande
                                                                ) === parseInt(auth.id_user)
                                                        )
                                                        .filter((p) =>
                                                            fileredData.id_projet !== null
                                                                ? parseInt(p.id_projet) ===
                                                                parseInt(
                                                                    fileredData.id_projet
                                                                )
                                                                : p
                                                        )
                                                        .filter((pp) =>
                                                            fileredData.id_equipe !== null
                                                                ? parseInt(pp.id_equipe) ===
                                                                parseInt(
                                                                    fileredData.id_equipe
                                                                )
                                                                : pp
                                                        )
                                                        .filter((ppp) =>
                                                            fileredData.id_statut !== null
                                                                ? parseInt(ppp.id_statut) ===
                                                                parseInt(
                                                                    fileredData.id_statut
                                                                ) && (parseInt(ppp.id_statut) === statut_initial ||
                                                                    parseInt(ppp.id_statut) === statut_accepter_client ||
                                                                    parseInt(ppp.id_statut) === statut_terminer_dmnde ||
                                                                    parseInt(ppp.id_statut) === statut_archiver_dmnde)
                                                                : (parseInt(ppp.id_statut) === statut_initial ||
                                                                    parseInt(ppp.id_statut) === statut_accepter_client ||
                                                                    parseInt(ppp.id_statut) === statut_terminer_dmnde)
                                                        )
                                                        .filter((pppp) =>
                                                            fileredData.id_client !== null
                                                                ? parseInt(pppp.id_client) ===
                                                                parseInt(
                                                                    fileredData.id_client
                                                                )
                                                                : pppp
                                                        )
                                                    : demande_cotations.filter(
                                                        (p) =>
                                                        (parseInt(p.id_responsable_demande) === (parseInt(auth.id_user)) &&
                                                            (parseInt(p.id_statut) === statut_initial ||
                                                                parseInt(p.id_statut) === statut_accepter_client ||
                                                                parseInt(p.id_statut) === statut_terminer_dmnde))
                                                    )
                                                : null
                        }
                        // scroll={scr}
                        size="small"
                    />
                </Spin>
                {/* <NestedTable /> */}
            </div>
            <ClientsDrawer />
            <DrawerDemandeDeCotation
                setduplicateDemande={setduplicateDemande}
                duplicateDemande={duplicateDemande}
                setDemandeSelected={setDemandeSelected}
                demandeSelected={demandeSelected}
                setDemandeDetail={setDemandeDetail}
                demandeDetail={demandeDetail}
                setTypeProjet={setTypeProjet}
                typeProjet={typeProjet}
                setLoadingCotation={setLoadingCotation}
                loadingCotation={loadingCotation}
                selectedDemande={selectedDemande}
                setSelectedDemande={setSelectedDemande}
            />
            <ModalFilterDemandeCotation />
            <ModalValiderDemandeCotation
                selectedDemande={selectedDemande}
                setSelectedDemande={setSelectedDemande}
            />
        </div>
    );
};

export default FicheDeCotation;
