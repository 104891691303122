import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  projectActions,
  modalActions,
  taskActions,
  alertActions,
} from "../../../redux/_actions";
import TaskCard from "../../components/Card/TaskCard";
import { Card, Typography, Button, Tooltip } from "antd";
import AddTask from "../../components/Card/AddTask";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { isNull } from "lodash";

const { Text } = Typography;
const Tableau = (props) => {
  const [dragTask, setDragTask] = useState("");
  const [dragInDropTitle, setDragInDropTitle] = useState("");
  const [, setShowProjectDetail] = useState(false);
  const [columns, setColumns] = useState([]);
  const [hoverAddTask, setHoverAddTask] = useState(false);
  const [isDuree, setIsDuree] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateEcheance, setEcheance] = useState("");
  const [show, setShow] = useState(0);

  const dispatch = useDispatch();

  const allTask = useSelector((state) => state.task.items);
  const taches = useSelector((state) => state.project.project_task);
  const jalons = useSelector((state) => state.jalon.items);
  const auth = useSelector((state) => state.login.item);
  const info_user = useSelector((state) => state.user.items).filter((p) => p.id_user === parseInt(auth.id_user))[0];
  const closable_add_task = useSelector(
    (state) => state.modal.closable_add_task
  );
  const jTypeEx = useSelector((state) => state.jalon.items).filter((p) => p.execute === 1)[0];
  const jTypeTe = useSelector((state) => state.jalon.items).filter((p) => p.terminate === 1)[0];
  const [jEX, setJEX] = useState(null);
  const [jTE, setJTE] = useState(null);

  useEffect(() => {

    if (jTypeEx !== undefined) {
      if (Object.keys(jTypeEx).length > 0) {
        setJEX(jTypeEx);
      }
    }

    if (jTypeTe !== undefined) {
      if (Object.keys(jTypeTe).length > 0) {
        setJTE(jTypeTe);
      }
    }

  }, [jTypeEx, jTypeTe]);

  useEffect(() => {
    const columnGenerate = () => {
      var t = [];
      jalons.map((p) =>
        t.push({
          name: p.libelle,
          color: p.color,
          items: taches
            .filter(
              (pp) =>
                // eslint-disable-next-line eqeqeq
                pp.id_projet == props.id_projet &&
                // eslint-disable-next-line eqeqeq
                pp.id_jalon == p.id_jalon
            )
            .sort((a, b) => b.ordre - a.ordre),
        })
      );
      setColumns(t);
    };
    columnGenerate();
    dispatch(modalActions.close_add_task());
  }, [dispatch, jalons, props.id_projet, taches]);

  useEffect(() => {
    return () => {
      dispatch(modalActions.close_add_task());
    };
  }, [dispatch]);

  // const team_menbers = useSelector((state) => state.team_menber.menbers);
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    let jalon_destination = parseInt(destination.droppableId) + 1;
    var ctrl = jalons.filter((p) => p.id_jalon === jalon_destination)[0];

    // console.log('valLog', ctrl)
    // console.log('valLog dateDebut ', isNull(dateDebut))
    // console.log('valLog dateFin ', isNull(dateEcheance))
    // console.log('valLog duree ', isNull(isDuree))
    // console.log('valLog terminate, execute , privilege', ctrl.terminate, ctrl.execute, info_user.privilege_duree)

    // la duree n'est pas estimer et qu'on esseye de l'amener a validate ou a done
    // if (((isNull(dateEcheance) || isNull(dateDebut)) & (ctrl.execute === 1 || ctrl.terminate === 1) & (isNull(isDuree) & info_user.privilege_duree === false))) {
    if (((isNull(dateEcheance) || isNull(dateDebut) || isNull(isDuree)) & (ctrl.execute === 1 || ctrl.terminate === 1)) & info_user.privilege_duree === false) {
      dispatch(alertActions.error("Veuillez renseignez les dates et la durée."));
    }
    else if (info_user.privilege_duree === true & ctrl.libelle || ((isNull(dateEcheance) || isNull(dateDebut)) & (ctrl.execute === 1 || ctrl.terminate === 1))) {
      dispatch(alertActions.error("Veuillez renseignez les dates."));
    }
    // si la duree a ete renseigner
    else {
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
        // Confirmation des changement sur la tache niveau base de donnee.
        dispatch(
          projectActions.dragInDropTask({
            id_tache: parseInt(dragTask),
            id_jalon: parseInt(destination.droppableId) + 1,
            libelle: dragInDropTitle,
            // description: "Haaaaaaaaaaaaaaaa",
            ordre: parseInt(destination.index) + 1,
            id_projet: parseInt(props.id_projet),
          })
        );

        var quitter = columns[parseInt(source.droppableId)];
        var venir = columns[parseInt(destination.droppableId)];

        // console.log("Quitter name : ", quitter.name)
        // console.log("Venir name : ", venir.name)
        // console.log("Comlumns : ", tab)

        dispatch(
          taskActions.addTaskLog({
            id_tache: parseInt(dragTask),
            // description: `a déplacé la tache de ${quitter.name} à ${venir.name}`,
            description: `A déplacé la tâche de ${quitter.name} à ${venir.name}`,
            create_by: parseInt(auth.id_user),
          })
        );
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];

        /*let tab_source = [
          ...copiedItems.map((p, index) => {
            return {
              id_tache: parseInt(p.id),
              libelle: p.libelle_tache,
              ordre: index + 1,
            };
          }),
        ];
     
        let obj = tab_source.find((p) => p.id_tache == parseInt(dragTask));
     
        let newObj = {
          id_tache: obj.id_tache,
          libelle: obj.libelle,
          ordre: destination.index,
        };
     
        let oldIndex = source.index;
     
        let ordre_tab = [];
     
        if (oldIndex > newObj.ordre) {
          if (tab_source[0].ordre == newObj.ordre) {
            for (var i = 0; i < oldIndex; i++) {
              ordre_tab.push({
                id_tache: tab_source[i].id_tache,
                libelle: tab_source[i].libelle,
                ordre:
                  tab_source[i].id_tache == newObj.id_tache
                    ? newObj.ordre
                    : tab_source[i].ordre,
              });
            }
          } else {
              for(var i = 0; i < tab_source.length; i++) {
                  if(i == newObj.ordre) {
                      ordre_tab.push({
                          id_tache: tab_source[i].id_tache,
                          libelle: tab_source[i].libelle,
                          ordre:
                            tab_source[i].id_tache == newObj.id_tache
                              ? newObj.ordre
                              : tab_source[i].ordre,
                        });
                  } else {
                      ordre_tab.push({
                          id_tache: tab_source[i].id_tache,
                          libelle: tab_source[i].libelle,
                          ordre:
                            tab_source[i].id_tache == newObj.id_tache
                              ? newObj.ordre
                              : tab_source[i].ordre,
                        });
                  }
              }
          }
        } else {
          // console.log("Nonnnnnnnnnnn");
        }
     
        // console.log("Tableau trie : ", ordre_tab);*/

        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        });
        // Confirmation des changement sur la tache niveau base de donnee.
        dispatch(
          projectActions.dragInDropTask({
            id_tache: parseInt(dragTask),
            // id_jalon: parseInt(destination.droppableId) + 1,
            libelle: dragInDropTitle,
            // description: "Haaaaaaaaaaaaaaaa",
            ordre: parseInt(destination.index) + 1,
            id_projet: parseInt(props.id_projet),
          })
        );
        // setTimeout(() => {
        //     dispatch(
        //         projectActions.getAllProjectTask({
        //             id_project: parseInt(props.id_projet),
        //         })
        //     );
        // }, 100);
      }
    }

  };


  // La fonction pour initialise les donnees de la tache a deplace dans nos use state.
  const taskData = (id, libelle, duree, id_jalon, date_debut, echeance) => {

    setDragTask(id);
    setDragInDropTitle(libelle);
    setIsDuree(duree);
    setDateDebut(date_debut);
    setEcheance(echeance);

  };


  return (
    <div
      style={{
        marginTop: 10,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([id, column], index) => {
          // let jal = jalons.filter((p) => p.libelle === column.name & (p.terminate === 1 || p.execute === 1));
          return (
            <div
              // sm={4}
              // md={4}
              // xl={4}
              style={{
                flex: 1,
                height: "80%",
              }}
              key={id}
            >
              <Card
                key={id}
                bodyStyle={{
                  padding: 10,
                }}
                style={{
                  marginRight: 10,
                  borderLeft: `5px solid ${column.color}`,
                }}
                onMouseEnter={() => (
                  <>
                    {setHoverAddTask(true)}
                    {setShow(parseInt(id) + 1)}
                  </>
                )}
                onMouseLeave={() => (
                  <>
                    {setHoverAddTask(false)}
                    {setShow(0)}
                  </>
                )}
              >
                <Text style={{ fontSize: 13 }}>{column.name}</Text>
                {show === parseInt(id) + 1 & (parseInt(id) + 1 !== jEX.id_jalon) & (parseInt(id) + 1 !== jTE.id_jalon) ?
                  <Tooltip title="Ajouter une tâche">
                    <PlusOutlined
                      style={{
                        float: "right",
                        position: "relative",
                        top: 3,
                      }}
                      onClick={() => {
                        props.setShowTakAdd(parseInt(id) + 1);
                        dispatch(modalActions.show_add_task())
                      }}
                    />
                  </Tooltip>
                  : null}
              </Card>
              <Scrollbars
                autoHide
                style={{
                  minHeight: "53vh",
                  marginTop: 10,
                  width: "97%",
                  backgroundColor: "#f8f8ff",
                  // marginBottom: 20,
                  // borderRadius: 4,
                  border: "1px solid #dcdcdc",
                  // padding: 5,
                }}
              >
                {props.showTaskAdd === parseInt(id) + 1 && closable_add_task === true ? (
                  <AddTask
                    id_jalon={props.showTaskAdd}
                    id_projet={props.id_projet}
                    ordre={
                      Object.keys(taches.filter((p) => p.id_jalon === id + 1))
                        .length + 1
                    }
                    setColumns={setColumns}
                  />
                ) : null}
                <Droppable droppableId={id} key={id}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        style={{
                          height: "50vh",
                          // marginTop: 10,
                          width: "100%",
                          backgroundColor: "#f8f8ff",
                          marginBottom: 20,
                          borderRadius: 4,
                          // border: "1px solid #dcdcdc",
                          padding: 3,
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {column.items
                          .sort(function (a, b) {
                            return a.ordre - b.ordre;
                          })
                          .map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        onMouseDown={() =>
                                          taskData(
                                            item.id,
                                            item.libelle_tache,
                                            item.duree,
                                            item.id_jalon,
                                            item.date_debut,
                                            item.echeance
                                          )
                                        }
                                      >
                                        <TaskCard
                                          item={item}
                                          setShowProjectDetail={
                                            setShowProjectDetail
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Scrollbars>
            </div>
          );
        })}
      </DragDropContext >
    </div >
  );
};

export default Tableau;
