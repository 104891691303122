import { ticketActions } from "../_actions";
import { ticketConstants } from "../_constants";

const initialState = {
    loading: false,
    item: {},
    items: [],
    filtrerTicketsByProjet: [],
    filtrerTickets: [],
    isFiltered: false,
    error: "",
};

export const ticket = (state = initialState, action) => {
    switch (action.type) {
        case ticketConstants.GETALL_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ticketConstants.GETALL_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [action.ticket],
            };

        case ticketConstants.GETALL_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ticketConstants.FILTER_TICKET_DATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ticketConstants.FILTER_TICKET_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                filtrerTickets: action.filter,
                isFiltered: true,
            };

        case ticketConstants.FILTER_TICKET_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ticketConstants.FILTRER_TICKET:
            return {
                ...state,
                filtrerTickets: [
                    ...state.filtrerTicketsByProjet
                        .filter((p) =>
                            action.ticket.id_state !== null
                                ? parseInt(p.id_state) ===
                                parseInt(action.ticket.id_state)
                                : p
                        )
                        .filter((p) =>
                            action.ticket.id_client !== null
                                ? parseInt(p.id_client) ===
                                parseInt(action.ticket.id_client)
                                : p
                        )
                        .filter((p) =>
                            action.ticket.id_type_demande !== null
                                ? parseInt(p.id) ===
                                parseInt(action.ticket.id_type_demande)
                                : p
                        )
                        .filter((p) =>
                            action.ticket.id_priority !== null
                                ? parseInt(p.id_priority) ===
                                parseInt(action.ticket.id_priority)
                                : p
                        ),
                ],
                isFiltered: true,
            };

        case ticketConstants.RESET_FILTRER_TICKET:
            return {
                ...state,
                isFiltered: false,
                filtrerTickets: [],
            };

        case ticketConstants.RESET_FILTRER_TICKET_FROM_GETALL:
            return {
                ...state,
                items: [],
                filtrerTicketsByProjet: [
                    ...state.items.filter(
                        (p) =>
                            parseInt(p.id_state) === 10
                    ),
                ],
                // filtrerTickets: [],
            };

        case ticketConstants.FILTER_TICKETS:
            return {
                ...state,
                items: [],
                isFiltered: true,
                filtrerTickets: [
                    ...state.filtrerTicketsByProjet.filter(
                        (p) =>
                            parseInt(p.id_state) === action.id_state
                    ),
                ],
            };

        case ticketConstants.FILTRER_TICKET_BY_PROJECT:
            return {
                ...state,
                filtrerTicketsByProjet: [
                    ...state.items.filter(
                        (p) =>
                            parseInt(p.id_projet) ===
                            parseInt(action.id_projet) &&
                            parseInt(p.id_state) === 1
                    ),
                ],
                isFiltered: false,
                filtrerTickets: [],
            };

        case ticketConstants.FILTER_FROM_TASK:
            return {
                ...state,
                items: [
                    ...state.items.map((p) => {
                        action.tickets.map((pp) =>
                            p.id_tickets === pp.id_tickets
                                ? Object.assign(p, pp)
                                : p
                        );
                        return p;
                    }),
                ],
                filtrerTicketsByProjet: [
                    ...state.filtrerTicketsByProjet.map((p) => {
                        action.tickets.map((pp) =>
                            p.id_tickets === pp.id_tickets
                                ? Object.assign(p, pp)
                                : p
                        );
                        return p;
                    }),
                ],
            };

        case ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                filtrerTicketsByProjet: [...action.ticket],
            };

        case ticketConstants.FILTRER_TICKET_BY_PROJECT_FROM_GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
