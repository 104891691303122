import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Button, Typography} from "antd";
import { SaveOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { team_menberActions, modalActions, userActions } from '../../../redux/_actions';

const { Option } = Select;
const { Text } = Typography;

const ModalMenbreEquipeChefProjet = (props) => {
    const closable_add_team_menber = useSelector(
        (state) => state.modal.closable_add_team_menber
    );
    const teams = useSelector((state) => state.team.items);
    const user_without_team = useSelector((state) => state.user.user_without_team);
    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const auth = useSelector((state) => state.login.item);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            id_equipe: auth.id_equipe,
        });
    });

    const submitTeamMenber = (values) => {
        let data = {
            id_user: parseInt(values.id_user),
            id_equipe: parseInt(values.id_equipe),
            create_by: 1,
        };
        dispatch(team_menberActions.add(data));
        dispatch(userActions.getUserWithoutTeam());
        dispatch(modalActions.close_add_team_menber());
    };

    return (
        <Modal
            title={<Text strong={true}>Ajouter un membre</Text>}
            visible={closable_add_team_menber}
            footer={null}
            onCancel={() => dispatch(modalActions.close_add_team_menber())}
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Form
                className="login-form"
                onFinish={submitTeamMenber}
                form={form}
                layout="vertical"
            >
                <Form.Item 
                    name="id_equipe" 
                    label={<Text strong={true}>Équipe</Text>}
                    rules={[
                        {
                            required: true,
                            message:
                                "Veuillez sélectionner une équipe",
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        disabled
                    >
                        {teams.map((p) => (
                            <Option value={p.id_equipe}>{p.libelle}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item 
                    name="id_user" 
                    label={<Text strong={true}>Membre</Text>}
                    rules={[
                        {
                            required: true,
                            message:
                                "Veuillez sélectionner les membres !",
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        placeholder="Sélectionner les membres"
                    >
                        {user_without_team.map((p) => (
                            <Option value={p.id_user} disabled={team_menbers.find( pp => p.id_user === pp.id_user) !== undefined ? (true) : (false)}>
                                {p.first_name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" type="default" htmlType="submit" onClick={() => dispatch(modalActions.close_add_team_menber())}>
                        <CloseCircleOutlined /> Annuler
                    </Button>{' '}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalMenbreEquipeChefProjet;
