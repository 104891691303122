export const loginConstants = {
    LOGIN_REQUEST: "LOGIN_LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_LOGIN_FAILURE",

    LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "USERS_LOGOUT_FAILURE",

    AUTH_REQUEST: "LOGIN_AUTH_REQUEST",
    AUTH_SUCCESS: "LOGIN_AUTH_SUCCESS",
    AUTH_FAILURE: "LOGIN_AUTH_FAILURE",

    PASSWORD_CHANGED_REQUEST: "PASSWORD_CHANGED_REQUEST",
    PASSWORD_CHANGED_SUCCESS: "PASSWORD_CHANGED_SUCCESS",
    PASSWORD_CHANGED_FAILURE: "PASSWORD_CHANGED_FAILURE",

    USER_ROLE_REQUEST: "USER_ROLE_REQUEST",
    USER_ROLE_SUCCESS: "USER_ROLE_SUCCESS",
    USER_ROLE_FAILURE: "USER_ROLE_FAILURE",

    UPDATE_USER_PROFIL_REQUEST: "UPDATE_USER_PROFIL_REQUEST",
    UPDATE_USER_PROFIL_SUCCESS: "UPDATE_USER_PROFIL_SUCCESS",
    UPDATE_USER_PROFIL_FAILURE: "UPDATE_USER_PROFIL_FAILURE",

    CHECK_TOKEN_REQUEST: "CHECK_TOKEN_REQUEST",
    CHECK_TOKEN_SUCCESS: "CHECK_TOKEN_SUCCESS",
    CHECK_TOKEN_FAILURE: "CHECK_TOKEN_FAILURE",
};
