import React, { Children, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Button,
    Form,
    Modal,
    DatePicker,
} from "antd";
import {
    demande_cotationActions,
    alertActions,
    modalActions,
} from "../../../redux/_actions";
import "jspdf-autotable";
import { isEmpty, now, parseInt } from "lodash";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";

Moment.globalMoment = moment;
Moment.globalLocale = "fr";


const ModalValiderDemandeCotation = ({ setSelectedDemande, selectedDemande }) => {

    const [FormModal] = Form.useForm();

    const dispatch = useDispatch();
    const isModalVisible = useSelector((state) => state.modal.closable_validate_cotation);
    const demande_cotation = useSelector((state) => state.demande_cotation.items).filter((i) => i.key === selectedDemande)[0];

    const handleCancel = () => {
        dispatch(modalActions.close_validate_cotation());
        FormModal.resetFields();
        // setSelectedDemande(0);
    };

    if (isModalVisible === false) {
        FormModal.resetFields();
    }

    const handleValider = (values) => {
        let date_livraison = moment(values.date_livraison).format("YYYY-MM-DD");
        let date = now();
        date = moment(date).format("YYYY-MM-DD");
        if (Date.parse(date) <= Date.parse(date_livraison)) {
            dispatch(demande_cotationActions.validate({
                id_demande: selectedDemande,
                date_livraison: date_livraison
            }));
            dispatch(modalActions.close_validate_cotation());
            dispatch(demande_cotationActions.getAll());
            // setSelectedDemande(0);
        }
        else {
            dispatch(alertActions.error('La date est déjà dépassée !'))
        }

    };

    const onFinishFailed = (errorInfo) => {
        // console.log('valLog:', errorInfo);
    };

    useEffect(() => {
        if (demande_cotation !== undefined && demande_cotation.date_livraison !== null) {
            FormModal.setFieldsValue({
                date_livraison: moment(demande_cotation.date_livraison),
            });
        }
    });

    return (
        <>
            <Modal
                title="Date de livraison"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer=""
                width={390}
            >
                <Form
                    form={FormModal}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleValider}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="inline"
                >
                    <Form.Item
                        label="Date"
                        name="date_livraison"
                        rules={[{ required: true, message: 'Obligatoire !' }]}
                    >
                        <DatePicker
                            allowClear={true}
                            allowEmpty={false}
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ModalValiderDemandeCotation;
