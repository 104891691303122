export const ticket_stateConstants = {
    GETALL_TICKET_STATE_REQUEST: "GETALL_TICKET_STATE_REQUEST",
    GETALL_TICKET_STATE_SUCCESS: "GETALL_TICKET_STATE_SUCCESS",
    GETALL_TICKET_STATE_FAILURE: "GETALL_TICKET_STATE_FAILURE",

    ADD_TICKET_STATE_REQUEST: "ADD_TICKET_STATE_REQUEST",
    ADD_TICKET_STATE_SUCCESS: "ADD_TICKET_STATE_SUCCESS",
    ADD_TICKET_STATE_FAILURE: "ADD_TICKET_STATE_FAILURE",

    UPDATE_TICKET_STATE_REQUEST: "UPDATE_TICKET_STATE_REQUEST",
    UPDATE_TICKET_STATE_SUCCESS: "UPDATE_TICKET_STATE_SUCCESS",
    UPDATE_TICKET_STATE_FAILURE: "UPDATE_TICKET_STATE_FAILURE",

    DELETE_TICKET_STATE_REQUEST: "DELETE_TICKET_STATE_REQUEST",
    DELETE_TICKET_STATE_SUCCESS: "DELETE_TICKET_STATE_SUCCESS",
    DELETE_TICKET_STATE_FAILURE: "DELETE_TICKET_STATE_FAILURE",
};
