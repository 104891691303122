import { ticket_stateConstants } from "../_constants";
import { ticket_stateServices } from "../_services";
import { alertActions } from "../_actions";

export const ticket_stateActions = {
    getAll,
    add,
    update,
    remove,
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        ticket_stateServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: ticket_stateConstants.GETALL_TICKET_STATE_REQUEST,
        };
    }
    function success(ticket_state) {
        return {
            type: ticket_stateConstants.GETALL_TICKET_STATE_SUCCESS,
            ticket_state,
        };
    }
    function failure(error) {
        return {
            type: ticket_stateConstants.GETALL_TICKET_STATE_FAILURE,
            error,
        };
    }
}

function add(ticket_state) {
    return function (dispatch) {
        dispatch(request());
        ticket_stateServices
            .add(ticket_state)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec d'ajout."));
            });
    };
    function request() {
        return {
            type: ticket_stateConstants.ADD_TICKET_STATE_REQUEST,
        };
    }
    function success(ticket_state) {
        return {
            type: ticket_stateConstants.ADD_TICKET_STATE_SUCCESS,
            ticket_state,
        };
    }
    function failure(error) {
        return {
            type: ticket_stateConstants.ADD_TICKET_STATE_FAILURE,
            error,
        };
    }
}

function update(ticket_state) {
    return function (dispatch) {
        dispatch(request());
        ticket_stateServices
            .update(ticket_state)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification."));
            });
    };
    function request() {
        return {
            type: ticket_stateConstants.UPDATE_TICKET_STATE_REQUEST,
        };
    }
    function success(ticket_state) {
        return {
            type: ticket_stateConstants.UPDATE_TICKET_STATE_SUCCESS,
            ticket_state,
        };
    }
    function failure(error) {
        return {
            type: ticket_stateConstants.UPDATE_TICKET_STATE_FAILURE,
            error,
        };
    }
}

function remove(ticket_state) {
    return function (dispatch) {
        dispatch(request());
        ticket_stateServices
            .remove(ticket_state)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: ticket_stateConstants.DELETE_TICKET_STATE_REQUEST,
        };
    }
    function success(ticket_state) {
        return {
            type: ticket_stateConstants.DELETE_TICKET_STATE_SUCCESS,
            ticket_state,
        };
    }
    function failure(error) {
        return {
            type: ticket_stateConstants.DELETE_TICKET_STATE_FAILURE,
            error,
        };
    }
}
