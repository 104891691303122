import axios from "../../axios/index";

export const team_projectServices = {
  addTeamProject,
  getAllTeamProject,
  affectTeamProject,
  updateTeamProject,
  getAllTeamMemberProject,
  getAll,
  add,
  update,
  delete_project,
};

async function delete_project(id_project) {
  return await axios.delete(`projets/${id_project}`);
}

async function update(project) {
  return await axios.put(`projets/${project.id_projet}`, project);
}

async function add(project) {
  return await axios.post(`projets`, project);
}

async function getAll() {
  return await axios.get(`projets`);
}

async function getAllTeamMemberProject(id_equipe_membre) {
  return await axios.get(`projets/getAllTeamMemberProject/${id_equipe_membre}`);
}

async function addTeamProject(team_project) {
  return await axios.post(`projets/attributedEquipe`, team_project);
}

async function affectTeamProject(team_project) {
  return await axios.post(`projets/attributed`, team_project);
}

async function getAllTeamProject(team_project) {
  return await axios.get(
    `equipes/projetEquipe/${team_project.id_equipe}`,
    team_project
  );
}

async function updateTeamProject(team_project) {
  return await axios.put(`projets/${team_project.id_projet}`, team_project);
}
