import axios from "../../axios/index";
export const jalonServices = {
    add,
    getAll,
    // getById,
    update,
    remove,
};

async function update(jalon) {
    return await axios.put(`/jalons/${jalon.id_jalon}`, jalon);
}

async function getAll() {
    return await axios.get(`/jalons`);
}

async function add(jalon) {
    return await axios.post(`/jalons`, jalon);
}

async function remove(id_jalon) {
    return await axios.delete(`/jalons/remove/${id_jalon}`);
}