import { type_ticketConstants } from "../_constants";

const initialState = {
    loading: false,
    item: {},
    items: [],
    error: "",
};

export const type_ticket = (state = initialState, action) => {
    switch (action.type) {
        case type_ticketConstants.GETALL_TYPE_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type_ticketConstants.GETALL_TYPE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.type_ticket,
            };

        case type_ticketConstants.GETALL_TYPE_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case type_ticketConstants.ADD_TYPE_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type_ticketConstants.ADD_TYPE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.type_ticket],
            };

        case type_ticketConstants.ADD_TYPE_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case type_ticketConstants.UPDATE_TYPE_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type_ticketConstants.UPDATE_TYPE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id) === parseInt(action.type_ticket.id)
                        ) {
                            Object.assign(p, action.type_ticket);
                        }
                        return p;
                    }),
                ],
            };

        case type_ticketConstants.UPDATE_TYPE_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.type_ticket,
            };

        case type_ticketConstants.DELETE_TYPE_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type_ticketConstants.DELETE_TYPE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter(
                        (p) => parseInt(p.id) !== parseInt(action.type_ticket)
                    ),
                ],
            };

        case type_ticketConstants.DELETE_TYPE_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
