import React, { useState } from "react";
import { Drawer, Table, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { customerActions, modalActions } from "../../../redux/_actions";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ModalAjouterClient from "../Modal/ModalAjouterClient";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";

const ClientsDrawer = () => {
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const dispatch = useDispatch();

    const closable_add_customers = useSelector(
        (state) => state.modal.closable_add_customers
    );
    const clients = useSelector((state) => state.customer.items);
    const user = useSelector((state) => state.login);
    const ability = defineAbilityFor(user);

    const columns = [
        {
            title: "Nom client",
            dataIndex: "nom_client",
            key: "nom_client",
        },
        {
            title: "Adresse",
            dataIndex: "adresse_client",
            key: "adresse_client",
        },
        {
            title: (
                <Can I="AjouterClient" a="Fiche de cotation" ability={ability}>
                    <PlusOutlined
                        onClick={() =>
                            dispatch(modalActions.show_add_customer())
                        }
                    />
                </Can>
            ),
            key: "action",
            render: (text, record) => (
                <Space size="small">
                    <Can
                        I="ModifierClient"
                        a="Fiche de cotation"
                        ability={ability}
                    >
                        <EditOutlined
                            onClick={() => {
                                setSelectedCustomer(record);
                                dispatch(modalActions.show_add_customer());
                            }}
                        />
                    </Can>
                    <Can
                        I="RetirerClient"
                        a="Fiche de cotation"
                        ability={ability}
                    >
                        <Popconfirm
                            title="Voulez-vous supprimer ce client ?"
                            onConfirm={() =>
                                dispatch(
                                    customerActions.remove(
                                        parseInt(record.id_client)
                                    )
                                )
                            }
                            okText="Oui"
                            cancelText="Non"
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </Can>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            nom_client: "Hachimou Abdou",
            adresse_client: "Cite chinoise",
        },
        {
            key: "2",
            nom_client: "Maliki Harouna",
            adresse_client: "Lazaret",
        },
        {
            key: "1",
            nom_client: "Omar kadri",
            adresse_client: "Koira kano",
        },
    ];

    return (
        <>
            <Drawer
                title="Clients"
                placement="right"
                closable={true}
                maskClosable={false}
                style={{ marginTop: 50 }}
                width={500}
                onClose={() => dispatch(modalActions.close_add_customers())}
                visible={closable_add_customers}
            >
                <Table columns={columns} dataSource={clients} size="small" />
            </Drawer>
            <ModalAjouterClient
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
            />
        </>
    );
};

export default ClientsDrawer;
