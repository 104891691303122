import React, { useState } from "react";
import { Card, Avatar, Menu, Dropdown, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    projectActions,
    modalActions,
    team_menberActions,
    userActions,
    team_projectActions,
} from "../../../redux/_actions";
import { useHistory } from "react-router-dom";
import "./Projet.css";
import { EditOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { makeAvatar, encryptWithAES } from "../../helpers/makeAvatar";

const { Meta } = Card;
const { Text } = Typography;

const Projet = (props) => {
    const loading = useSelector((state) => state.project.loading);
    const taches = useSelector((state) => state.project.project_task);
    const [, setSelectedTeam] = useState("");

    const location = useLocation();

    const history = useHistory();

    const dispatch = useDispatch();

    const goToProject = (task) => {
        dispatch(
            projectActions.getAllProjectTask({
                id_project: parseInt(task.id_projet),
            })
        );
        dispatch(userActions.getAllMemberProject(parseInt(task.id_projet)));
        dispatch(team_projectActions.selectedProject(task));
        // setTimeout(function () {
        // if (loading === false && taches !== []) {
        history.push(
            `/mes-projets/${encryptWithAES(task.id_projet)}`
        );
        // history.push(
        //     `/projets/${task.id_projet}`
        // );
        // }
        // }, 1500);
    };

    const menu = (
        <Menu
            onClick={(value) => {
                if (value.key === "1") {
                    if (parseInt(props.p.id_equipe)) {
                        dispatch(modalActions.show_add_team_project());
                    } else {
                        dispatch(modalActions.show_add_allproject());
                    }
                }
            }}
        >
            <Menu.Item key="1">
                <EditOutlined /> Modifier
            </Menu.Item>
        </Menu>
    );

    const rightClique = (
        id_projet,
        libelle,
        echeance,
        color,
        id_equipe,
        Membre_projet,
        description,
        id_statut_projet,
        delete_project,
        id_client
    ) => {
        if (id_equipe !== undefined) {
            setSelectedTeam(id_equipe);
            props.setProjectSelected({
                id_projet: parseInt(id_projet),
                libelle: libelle,
                echeance: echeance,
                id_color: color,
                id_equipe: id_equipe,
                id_equipe_membre: [
                    ...Membre_projet.map((p) => parseInt(p.id_equipe_membre)),
                ],
                description: description,
                id_statut_projet: id_statut_projet,
                delete_project: delete_project,
                id_client: id_client
            });
            dispatch(
                team_menberActions.selectTeamMember({
                    id_equipe: id_equipe,
                })
            );
        } else {
            props.setProjectSelected({
                id_projet: parseInt(id_projet),
                libelle: libelle,
                echeance: echeance,
                id_color: color,
                // id_equipe: id_equipe,
                // id_equipe_membre: [
                //     ...Membre_projet.map((p) => parseInt(p.id_equipe_membre)),
                // ],
                description: description,
                id_statut_projet: id_statut_projet,
                delete_project: delete_project,
                id_client: id_client
            });
        }
    };

    return (
        <>
            {location.pathname === "/equipes" ||
                location.pathname === "/projets" ||
                location.pathname === "/mes-projets" ? (
                <Dropdown overlay={menu} trigger={["contextMenu"]}>
                    <Card
                        onContextMenu={(e) =>
                            e.type === "contextmenu"
                                ? rightClique(
                                    props.p.id_projet,
                                    props.p.libelle,
                                    props.p.echeance,
                                    props.p.id_color,
                                    props.p.id_equipe,
                                    props.p.Membre_projet,
                                    props.p.description,
                                    props.p.id_statut_projet,
                                    props.p.delete_project,
                                    props.p.id_client
                                )
                                : null
                        }
                        style={{
                            backgroundColor: "#f8f8ff",
                            border: "1px solid #dedede",
                        }}
                        bodyStyle={{ padding: 0, height: 79 }}
                        className="project-grid"
                        hoverable={true}
                        onClick={() => goToProject(props.p)}
                    >
                        <Meta
                            title={<Text strong={true}>{props.p.libelle}</Text>}
                            description={props.p.statut_libelle}
                        />
                        {props.p.Membre_projet !== undefined ? (
                            <Avatar.Group
                                style={{
                                    marginTop: 5,
                                }}
                                maxCount={2}
                                maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                }}
                            >
                                {props.p.Membre_projet.length > 0 ? (
                                    props.p.Membre_projet.map((pp) => (
                                        <Avatar
                                            style={{
                                                border: "2px solid #252526",
                                                backgroundColor: "#f8f8ff",
                                                color: "#252526",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {makeAvatar(pp.first_name)}
                                        </Avatar>
                                    ))
                                ) : (
                                    <Avatar
                                        style={{
                                            backgroundColor: "#f8f8ff",
                                            borderColor: "#f8f8ff",
                                        }}
                                    ></Avatar>
                                )}
                            </Avatar.Group>
                        ) : null}

                        <div
                            style={{
                                width: 0,
                                height: 0,
                                borderTop: "50px solid transparent",
                                borderRight: `50px solid ${props.p.color}`,
                                borderBottom: "0px solid transparent",
                                position: "absolute",
                                right: 0,
                                bottom: 0,
                            }}
                        ></div>
                    </Card>
                </Dropdown>
            ) : (
                <Card
                    style={{
                        backgroundColor: "#f8f8ff",
                        border: "1px solid #dedede",
                    }}
                    bodyStyle={{ padding: 0, height: 79 }}
                    className="project-grid"
                    hoverable={true}
                    onClick={() => goToProject(props.p)}
                >
                    <Meta
                        title={<Text strong={true}>{props.p.libelle}</Text>}
                        description={props.p.statut_libelle}
                    />
                    {props.p.Membre_projet !== undefined ? (
                        <Avatar.Group
                            style={{
                                marginTop: 5,
                            }}
                            maxCount={2}
                            maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                            }}
                        >
                            {props.p.Membre_projet.length > 0 ? (
                                props.p.Membre_projet.map((pp) => (
                                    <Avatar
                                        style={{
                                            border: "2px solid #252526",
                                            backgroundColor: "#f8f8ff",
                                            color: "#252526",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {makeAvatar(pp.first_name)}
                                    </Avatar>
                                ))
                            ) : (
                                <Avatar
                                    style={{
                                        backgroundColor: "#f8f8ff",
                                        borderColor: "#f8f8ff",
                                    }}
                                ></Avatar>
                            )}
                        </Avatar.Group>
                    ) : null}

                    <div
                        style={{
                            width: 0,
                            height: 0,
                            borderTop: "50px solid transparent",
                            borderRight: `50px solid ${props.p.color}`,
                            borderBottom: "0px solid transparent",
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                        }}
                    ></div>
                </Card>
            )}
        </>
    );
};

export default Projet;
