import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions, loginActions } from "./";

export const userActions = {
  //login,
  //logout,
  register,
  getAll,
  //getOne,
  remove,
  update,
  getUserWithoutTeam,
  userNotification,
  userOpenNotification,
  getAllChiefWithoutTeam,
  getAllChiefWithoutTeamAndCurrentTeam,
  getAllMemberProject,
  checkLogin,
  checkLoginUpdate,
  checkMail,
  checkMailUpdate,
  resetExist,
  changeUserPassword,
  sendRememberMail,
};

function sendRememberMail() {
  return function (dispatch) {
    dispatch(request());
    userService
      .sendRememberMail()
      .then((res) => {
        // console.log("Donnee : ", res)
        // dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
      });
  };
  function request() {
    return {
      type: userConstants.SEND_REMEMBER_MAIL_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.SEND_REMEMBER_MAIL_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.SEND_REMEMBER_MAIL_SUCCESS,
      error,
    };
  }
}

function remove(user) {
  return function (dispatch) {
    dispatch(request());
    userService
      .remove(user)
      .then((res) => {
        dispatch(success(user));
        dispatch(alertActions.success("La suppression effectuée."));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Echec de suppression."));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request(user) {
    return {
      type: userConstants.DELETE_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.DELETE_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.DELETE_FAILURE,
      error,
    };
  }
}

function changeUserPassword(user) {
  return function (dispatch) {
    dispatch(request());
    userService
      .changeUserPassword(user)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("Changement effectué."));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Echec Changement."));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.CHANGE_USER_PASSWORD_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.CHANGE_USER_PASSWORD_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHANGE_USER_PASSWORD_FAILURE,
      error,
    };
  }
}

function resetExist() {
  return {
    type: userConstants.RESET_EXIST,
  };
}

function checkMailUpdate(mail) {
  return function (dispatch) {
    dispatch(request());
    userService
      .checkMailUpdate(mail)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.CHECK_MAIL_UPDATE_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.CHECK_MAIL_UPDATE_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_MAIL_UPDATE_FAILURE,
      error,
    };
  }
}

function checkMail(mail) {
  return function (dispatch) {
    dispatch(request());
    userService
      .checkMail(mail)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.CHECK_MAIL_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.CHECK_MAIL_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_MAIL_FAILURE,
      error,
    };
  }
}

function checkLogin(login) {
  return function (dispatch) {
    dispatch(request());
    userService
      .checkLogin(login)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.CHECK_LOGIN_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.CHECK_LOGIN_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_LOGIN_FAILURE,
      error,
    };
  }
}

function checkLoginUpdate(login) {
  return function (dispatch) {
    dispatch(request());
    userService
      .checkLoginUpdate(login)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.CHECK_LOGIN_UPDATE_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.CHECK_LOGIN_UPDATE_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_LOGIN_UPDATE_FAILURE,
      error,
    };
  }
}

function getAllMemberProject(id_project) {
  return function (dispatch) {
    dispatch(request());
    userService
      .getAllMemberProject(id_project)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.GETALL_PROJECT_MEMBER_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.GETALL_PROJECT_MEMBER_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GETALL_PROJECT_MEMBER_FAILURE,
      error,
    };
  }
}

function getAllChiefWithoutTeamAndCurrentTeam(id_equipe) {
  return function (dispatch) {
    dispatch(request());
    userService
      .getAllChiefWithoutTeamAndCurrentTeam(id_equipe)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });

    function request() {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_REQUEST,
      };
    }
    function success(user) {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_SUCCESS,
        user,
      };
    }
    function failure(error) {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_AND_CURRENT_FAILURE,
        error,
      };
    }
  };
}
function getAllChiefWithoutTeam() {
  return function (dispatch) {
    dispatch(request());
    userService
      .getAllChiefWithoutTeam()
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_REQUEST,
      };
    }
    function success(user) {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_SUCCESS,
        user,
      };
    }
    function failure(error) {
      return {
        type: userConstants.GETALL_CHIEF_WITHOUT_TEAM_FAILURE,
        error,
      };
    }
  };
}

function userOpenNotification(id_notification) {
  return function (dispatch) {
    dispatch(request());
    userService
      .userOpenNotification(id_notification)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: userConstants.USER_OPEN_NOTIFICATION_REQUEST,
      };
    }
    function success(notification) {
      return {
        type: userConstants.USER_OPEN_NOTIFICATION_SUCCESS,
        notification,
      };
    }
    function failure(error) {
      return {
        type: userConstants.USER_OPEN_NOTIFICATION_FAILURE,
        error,
      };
    }
  };
}

function userNotification(id_user) {
  return function (dispatch) {
    dispatch(request());
    userService
      .userNotification(id_user)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: userConstants.USER_NOTIFICATION_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.USER_NOTIFICATION_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.USER_NOTIFICATION_FAILURE,
      error,
    };
  }
}

function getUserWithoutTeam() {
  return function (dispatch) {
    dispatch(request());
    userService
      .getUserWithoutTeam()
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: userConstants.USER_WITHOUT_TEAM_REQUEST,
      };
    }
    function success(user) {
      return {
        type: userConstants.USER_WITHOUT_TEAM_SUCCESS,
        user,
      };
    }
    function failure(error) {
      return {
        type: userConstants.USER_WITHOUT_TEAM_FAILURE,
        error,
      };
    }
  };
}

function register(user) {
  return function (dispatch) {
    dispatch(request());
    userService
      .register(user)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("L'ajout effectué !"));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(
          alertActions.error("L'adresse mail où le login existe déjà !")
        );
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };

  function request() {
    return {
      type: userConstants.ADD_REQUEST,
    };
  }
  function success(user) {
    return {
      type: userConstants.ADD_SUCCESS,
      user,
    };
  }
  function failure(error) {
    return {
      type: userConstants.ADD_FAILURE,
      error,
    };
  }
}

function getAll() {
  return function (dispatch) {
    dispatch(request());

    userService
      .getAll()
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };

  function request() {
    return {
      type: userConstants.GETALL_REQUEST,
    };
  }

  function success(user) {
    return {
      type: userConstants.GETALL_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: userConstants.GETALL_FAILURE,
      error,
    };
  }
}

function update(user) {
  return function (dispatch) {
    dispatch(request());
    userService
      .update(user)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("Modification effectuée !"));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(
          alertActions.error("L'adresse mail où le login existe déjà !")
        );
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: userConstants.UPDATE_REQUEST,
      };
    }

    function success(user) {
      return {
        type: userConstants.UPDATE_SUCCESS,
        user,
      };
    }
    function failure(error) {
      return {
        type: userConstants.UPDATE_FAILURE,
        error,
      };
    }
  };
}
