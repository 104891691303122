import { droitConstants } from "../_constants";
import { droitServices } from "../_services";
import { loginActions } from "../_actions";

export const droitActions = {
    add,
    getAll,
    delete_droit,
    checkAllNew,
};

function checkAllNew(droit) {
    return function (dispatch) {
        dispatch(request());
        droitServices
            .checkAllNew(droit)
            .then((res) => {
                dispatch(res.data.message);
            })
            .catch((err) => {
                dispatch(err.message);
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: droitConstants.CHECkALL_NEW_REQUEST,
        };
    }
    function success(droit) {
        return {
            type: droitConstants.CHECkALL_NEW_SUCCESS,
            droit,
        };
    }
    function failure(error) {
        return {
            type: droitConstants.CHECkALL_NEW_FAILURE,
            error,
        };
    }
}

function add(droit) {
    return function (dispatch) {
        dispatch(request());
        droitServices
            .add(droit)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return {
            type: droitConstants.ADD_REQUEST,
        };
    }
    function success(droit) {
        return {
            type: droitConstants.ADD_SUCCESS,
            droit,
        };
    }
    function failure(error) {
        return {
            type: droitConstants.ADD_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        droitServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };

    function request() {
        return {
            type: droitConstants.GETALL_REQUEST,
        };
    }
    function success(droit) {
        return {
            type: droitConstants.GETALL_SUCCESS,
            droit,
        };
    }
    function failure(error) {
        return {
            type: droitConstants.GETALL_FAILURE,
            error,
        };
    }
}

function delete_droit(droit) {
    return function (dispatch) {
        dispatch(request());
        droitServices
            .delete_droit(droit)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: droitConstants.DELETE_REQUEST,
        };
    }
    function success(droit) {
        return {
            type: droitConstants.DELETE_SUCCESS,
            droit,
        };
    }
    function failure(error) {
        return {
            type: droitConstants.DELETE_FAILURE,
            error,
        };
    }
}
