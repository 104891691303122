import axios from "../../axios/index";

export const directionServices = {
    getAll,
    add,
    update,
    remove,
};

async function remove(direction) {
    return await axios.delete(`directions/${direction}`);
}

async function update(directions) {
    return await axios.put(`directions/${directions.id_direction}`, directions);
}

async function add(direction) {
    return await axios.post(`directions`, direction);
}

async function getAll() {
    return await axios.get(`directions`);
}
