import React, { useEffect } from "react";
import { Button, Modal, Input, Form } from "antd";
import {
    SaveOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "../../../../redux/_actions";
import { modalActions } from "../../../../redux/_actions";

const ModalProfile = (props) => {
    const closable_add_profil = useSelector(
        (state) => state.modal.closable_add_profil
    );
    const closable_update_profil = useSelector(
        (state) => state.modal.closable_update_profil
    );
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // Soumission des donnees ajouter profil
    const submitProfile = (values) => {
        if (props.updatedProfile.id_profil === undefined) {
            dispatch(profileActions.add(values));
            form.resetFields();
            dispatch(modalActions.close_add_profile());
        } else {
            dispatch(
                profileActions.update({
                    id_profil: props.updatedProfile.id_profil,
                    libelle: values.libelle,
                })
            );
            props.setUpdatedProfile({
                id_profil: '',
                libelle: ''
            })
            dispatch(modalActions.close_update_profile());
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            libelle: props.updatedProfile.libelle,
        });
    });

    return (
        <Modal
            title={
                props.updatedProfile.id_profil === undefined
                    ? "Ajouter profil"
                    : "Modifier profil"
            }
            visible={
                props.updatedProfile.id_profil === undefined
                    ? closable_add_profil
                    : closable_update_profil
            }
            footer={null}
            onCancel={() => {
                props.updatedProfile.id_profil === undefined
                    ? dispatch(modalActions.close_add_profile())
                    : dispatch(modalActions.close_update_profile());
            }}
        >
            <Form className="login-form" onFinish={submitProfile} form={form} layout="vertical">
                <Form.Item
                    name="libelle"
                    label="Libelle"
                    rules={[
                        {
                            required: true,
                            message: "Veuillez saisir votre nom de profil!",
                        },
                    ]}
                >
                    <Input size="large" placeholder="Entrer un nom de profil" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalProfile;
