import { AbilityBuilder, Ability } from "@casl/ability";

export default function defineAbilityFor(user) {
    const { can, build } = new AbilityBuilder(Ability);

    // if (user.libelle_profil === "Admin") {
    //     can("add_user", "user");
    //     can("view_user", "user");
    //     can("delete_user", "user");
    //     can("update_user", "user");
    // }

    // if (user.role === "agent") {
    //     can("add", "post");
    //     can("update", "post");
    //     can("view", "post");
    // }

    // if (user.role === "client") {
    //     can("view", "post");
    // }

    return build();
}
