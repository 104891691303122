import axios from "../../axios/index";

export const ticketServices = {
    getAll,
    filtrer_ticket_date,
}

async function getAll() {
    return await axios.get(`ticket`);
}

async function filtrer_ticket_date(filtre) {
    return await axios.post(`ticket/filtre`, filtre);
}