import { projectConstants, project_colorConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    item: {},
    error: "",
};

export const project_color = (state = initialState, action) => {
    switch (action.type) {
        case project_colorConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case project_colorConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.project_color,
            };

        case project_colorConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case project_colorConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case project_colorConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.color],
            };

        case project_colorConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case project_colorConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case project_colorConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items.map(p => {
                    if(parseInt(p.id_color) === parseInt(action.color.id_color)) {
                        p.color = action.color.color
                    }
                    return p;
                })]
            }

        default:
            return state;
    }
};
