import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions, customerActions } from "../../../redux/_actions";
import { Modal, Button, Form, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";

const ModalAddClient = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // recuperation des information de l'etat du modal (true or false)
    const closable_modal_add_client = useSelector(
        (state) => state.modal.closable_modal_add_client
    );

    // recuperation des informations de l'utilisateur connecté
    const user = useSelector((state) => state.login.item);

    // traitement apres avoir soumis le formulaire pour ajout d'un client 
    const submitAddCustomers = (values) => {
        const data = {
            nom_client: values.nom_client,
            adresse_client: values.adresse_client,
            create_by: parseInt(user.id_user),
        };

        dispatch(customerActions.add(data));
        form.resetFields();
        dispatch(modalActions.close_modal_add_client());
    };

    return (
        <>
            <Modal
                title={
                    "Ajouter un client"
                }
                visible={closable_modal_add_client}
                onCancel={() => {
                    dispatch(modalActions.close_modal_add_client());
                    form.resetFields();
                }}
                footer={null}
                bodyStyle={{
                    paddingBottom: 1,
                }}
            >
                <Form
                    onFinish={
                        submitAddCustomers
                    }
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        label="Nom client"
                        name="nom_client"
                        rules={[
                            {
                                required: true,
                                message: "Vueillez entrer le nom de la société (client) ...",
                            },
                        ]}
                    >
                        <Input placeholder="Entrer le nom de la société (Client) ..." />
                    </Form.Item>

                    <Form.Item label="Adresse" name="adresse_client">
                        <Input placeholder="Entrer l'adresse de la société (Client) ..." />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined /> Enregistrer
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )

}

export default ModalAddClient