import { Card } from "antd";
import { droitConstants } from "../_constants";

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export function droit(state = initialState, action) {
    switch (action.type) {
        case droitConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case droitConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.droit]
            };

        case droitConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case droitConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case droitConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.droit,
            };

        case droitConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        // case droitConstants.DELETE_REQUEST:
        //     return {
        //         ...state,
        //         loading: false
        //     };

        // case droitConstants.DELETE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         items: state.items.map(p => (
        //             p === action.droit ? (null) : (p)
        //         ))
        //     };

        // case droitConstants.DELETE_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.error
        //     };

        default:
            return state;
    }
}
