import React from "react";
import {  Modal, Typography } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../../redux/_actions";

const { Text } = Typography;

const ModalAutoLogout = () => {
    const closable_auto_logout = useSelector(
        (state) => state.modal.closable_auto_logout
    );

    const dispatch = useDispatch();

    return (
        <Modal
            title={<Text strong={true}>Ajouter une équipe</Text>}
            visible={closable_auto_logout}
            footer={null}
            onCancel={() => dispatch(modalActions.close_auto_logout())}
            maskClosable={false}
        >
            <h1>Auto logout</h1>
        </Modal>
    );
};

export default ModalAutoLogout;
