export const modalConstants = {
    SHOW_MODAL_ADD_CLIENT: "SHOW_MODAL_ADD_CLIENT",
    CLOSE_MODAL_ADD_CLIENT: "CLOSE_MODAL_ADD_CLIENT",

    SHOW_ADD_PROFILE: "SHOW_ADD_PROFILE",
    CLOSE_ADD_PROFILE: "CLOSE_ADD_PROFILE",

    SHOW_VALIDATE_COTATION: "SHOW_VALIDATE_COTATION",
    CLOSE_VALIDATE_COTATION: "CLOSE_VALIDATE_COTATION",

    SHOW_ADD_PROJECT: "SHOW_ADD_PROJECT",
    CLOSE_ADD_PROJECT: "CLOSE_ADD_PROJECT",

    SHOW_UPDATE_PROFILE: "SHOW_UPDATE_PROFILE",
    CLOSE_UPDATE_PROFILE: "CLOSE_UPDATE_PROFILE",

    SHOW_ADD_TEAM: "SHOW_ADD_TEAM",
    CLOSE_ADD_TEAM: "CLOSE_ADD_TEAM",

    SHOW_MORE_TEAM: "SHOW_MORE_TEAM",
    CLOSE_MORE_TEAM: "CLOSE_MORE_TEAM",

    SHOW_SETTING_TEAM: "SHOW_SETTING_TEAM",
    CLOSE_SETTING_TEAM: "CLOSE_SETTING_TEAM",

    SHOW_ADD_TEAM_MENBER: "SHOW_ADD_TEAM_MENBER",
    CLOSE_ADD_TEAM_MENBER: "CLOSE_ADD_TEAM_MENBER",

    SHOW_ADD_TEAM_PROJECT: "SHOW_ADD_TEAM_PROJECT",
    CLOSE_ADD_TEAM_PROJECT: "CLOSE_ADD_TEAM_PROJECT",

    SHOW_AFFECT_TEAM_PROJECT: "SHOW_AFFECT_TEAM_PROJECT",
    CLOSE_AFFECT_TEAM_PROJECT: "CLOSE_AFFECT_TEAM_PROJECT",

    SHOW_DETAIL_TASK: "SHOW_DETAIL_TASK",
    CLOSE_DETAIL_TASK: "CLOSE_DETAIL_TASK",

    SHOW_ADD_TASK: "SHOW_ADD_TASK",
    CLOSE_ADD_TASK: "CLOSE_ADD_TASK",

    SHOW_MORE_PROJECT: "SHOW_MORE_PROJECT",
    CLOSE_MORE_PROJECT: "CLOSE_MORE_PROJECT",

    SHOW_ADD_ALLPROJECT: "SHOW_ADD_ALLPROJECT",
    CLOSE_ADD_ALLPROJECT: "CLOSE_ADD_ALLPROJECT",

    SHOW_ADD_COLOR: "SHOW_ADD_COLOR",
    CLOSE_ADD_COLOR: "CLOSE_ADD_COLOR",

    SHOW_ADD_TASK_STATUT: "SHOW_ADD_TASK_STATUT",
    CLOSE_ADD_TASK_STATUT: "CLOSE_ADD_TASK_STATUT",

    SHOW_ADD_JALON: "SHOW_ADD_JALON",
    CLOSE_ADD_JALON: "CLOSE_ADD_JALON",

    SHOW_USER_PROFIL: "SHOW_USER_PROFIL",
    CLOSE_USER_PROFIL: "CLOSE_USER_PROFIL",

    SHOW_AUTO_LOGOUT: "SHOW_AUTO_LOGOUT",
    CLOSE_AUTO_LOGOUT: "CLOSE_AUTO_LOGOUT",

    SHOW_ADD_DIRECTION: "SHOW_ADD_DIRECTION",
    CLOSE_ADD_DIRECTION: "CLOSE_ADD_DIRECTION",

    SHOW_NETWORK: "SHOW_NETWORK",
    CLOSE_NETWORK: "CLOSE_NETWORK",

    SHOW_ADD_CUSTOMERS: "SHOW_ADD_CUSTOMERS",
    CLOSE_ADD_CUSTOMERS: "CLOSE_ADD_CUSTOMERS",

    SHOW_ADD_CUSTOMER: "SHOW_ADD_CUSTOMER",
    CLOSE_ADD_CUSTOMER: "CLOSE_ADD_CUSTOMER",

    SHOW_ADD_STATUT_DEMANDE: "SHOW_ADD_STATUT_DEMANDE",
    CLOSE_ADD_STATUT_DEMANDE: "CLOSE_ADD_STATUT_DEMANDE",

    SHOW_ADD_DEMANE_OF_QUOTATION: "SHOW_ADD_DEMANE_OF_QUOTATION",
    CLOSE_ADD_DEMANE_OF_QUOTATION: "CLOSE_ADD_DEMANE_OF_QUOTATION",

    SHOW_FILTER_DEMANDE_OF_QUOTATION: "SHOW_FILTER_DEMANDE_OF_QUOTATION",
    CLOSE_FILTER_DEMANDE_OF_QUOTATION: "CLOSE_FILTER_DEMANDE_OF_QUOTATION",

    SHOW_TYPE_TICKET: "SHOW_TYPE_TICKET",
    CLOSE_TYPE_TICKET: "CLOSE_TYPE_TICKET",

    SHOW_TICKET_STATE: "SHOW_TICKET_STATE",
    CLOSE_TICKET_STATE: "CLOSE_TICKET_STATE",

    SHOW_PRIORITY_TICKET: "SHOW_PRIORITY_TICKET",
    CLOSE_PRIORITY_TICKET: "CLOSE_PRIORITY_TICKET",

    SHOW_FILTRER_TICKET: "SHOW_FILTRER_TICKET",
    CLOSE_FILTRER_TICKET: "CLOSE_FILTRER_TICKET",
};
