import { priority_ticketConstants } from "../_constants";

const initialState = {
    loading: false,
    item: {},
    items: [],
    error: "",
};

export const priority_ticket = (state = initialState, action) => {
    switch (action.type) {
        case priority_ticketConstants.GETALL_PRIORITY_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case priority_ticketConstants.GETALL_PRIORITY_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.priority_ticket,
            };

        case priority_ticketConstants.GETALL_PRIORITY_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case priority_ticketConstants.ADD_PRIORITY_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case priority_ticketConstants.ADD_PRIORITY_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.priority_ticket],
            };

        case priority_ticketConstants.ADD_PRIORITY_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case priority_ticketConstants.UPDATE_PRIORITY_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case priority_ticketConstants.UPDATE_PRIORITY_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_priority) ===
                            parseInt(action.priority_ticket.id_priority)
                        ) {
                            Object.assign(p, action.priority_ticket);
                        }
                        return p;
                    }),
                ],
            };

        case priority_ticketConstants.DELETE_PRIORITY_TICKET_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case priority_ticketConstants.DELETE_PRIORITY_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter(
                        (p) =>
                            parseInt(p.id_priority) !==
                            parseInt(action.priority_ticket)
                    ),
                ],
            };

        case priority_ticketConstants.DELETE_PRIORITY_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
