import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    useEffect(() => {
        document.title = "2iProject-404";
    }, []);
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <div
                style={{
                    padding: 50,
                    backgroundColor: "#001529",
                    width: "30%",
                }}
            >
                <h1 style={{ color: "white" }}>404</h1>
                <h3 style={{ color: "white" }}>
                    <strong>Oups !!!</strong> Page introuvable !
                </h3>
                <h3 style={{ color: "white" }}>
                    Retouner a l'<Link to="/accueil">accueil</Link>
                </h3>
            </div>
        </div>
    );
};

export default NotFound;
