import axios from "axios";

let instance = axios.create({
    // baseURL: "http://localhost:8080/api/v1/",
    // baseURL: "http://localhost/backend/public/index.php/api/v1/",
    // baseURL: "http://localhost/api/public/index.php/api/v1/",
    // baseURL: "http://178.238.233.82/2iProject2iSoft/api/public/index.php/api/v1/",
    baseURL: "http://2iprojects.com/api/public/index.php/api/v1/",
    // timeout: 15000,
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
    const token = window.sessionStorage.getItem("_token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

instance.interceptors.response.use(response => {
    // Edit response config
    return response;
}, error => {
    return Promise.reject(error.response);
});


export default instance;
