import React, { useState, useEffect } from "react";
import "./ConfigApp.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Breadcrumb, Card, Typography, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUsers,
    faIdCard,
    faCog,
    faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import HeaderApp from "../../parchalse/HeaderApp";
import { useSelector } from "react-redux";
import { projectActions } from "../../../redux/_actions";
import { rouge, bleuclair } from "../../helpers/myVar";
import { modalActions, directionActions } from "../../../redux/_actions";

const { Text } = Typography;

const ConfigApp = () => {
    const location = useLocation();

    const [section, setSection] = useState("Utilisateurs");

    const dispatch = useDispatch();

    const user = useSelector((state) => state.login);

    const ability = defineAbilityFor(user);

    useEffect(() => {
        dispatch(
            projectActions.getAllProjectWithoutTeam(user.id_equipe_membre)
        );
        dispatch(modalActions.close_more_team());
        dispatch(modalActions.close_more_project())
        dispatch(directionActions.getAll());
    }, [dispatch, user.id_equipe_membre]);

    const navigation = (section) => {
        setSection(section);
    };

    return (
        <>
            <div
                style={{
                    padding: "9px 18px"
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Configurations</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Text>{location.pathname.split("/")}</Text>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        marginLeft: 18,
                        marginRight: 9,
                    }}
                >
                    <Can
                        I="LireSousMenuUtilisateurs"
                        a="Utilisateurs"
                        ability={ability}
                    >
                        <Link to="/utilisateurs">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/utilisateurs"
                                        ? {
                                            textAlign: "center",
                                            backgroundColor: `${bleuclair}`,
                                            color: "white",
                                        }
                                        : {
                                            textAlign: "center",
                                        }
                                }
                                onClick={() => navigation("Utilisateurs")}
                            >
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/utilisateurs"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Utilisateurs
                                </Text>
                            </Card>
                        </Link>
                    </Can>
                </div>
                <div
                    style={{
                        flex: 1,
                        marginLeft: 9,
                        marginRight: 9,
                        backgroundColor: "green",
                    }}
                >
                    <Can I="LireSousMenuProfils" a="Profils" ability={ability}>
                        <Link to="/profiles">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/profiles"
                                        ? {
                                            textAlign: "center",
                                            backgroundColor: `${bleuclair}`,
                                            color: "white",
                                        }
                                        : {
                                            textAlign: "center",
                                        }
                                }
                                onClick={() => navigation("Profiles")}
                            >
                                <FontAwesomeIcon
                                    icon={faIdCard}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/profiles"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Profils
                                </Text>
                            </Card>
                        </Link>
                    </Can>
                </div>
                <div
                    style={{
                        flex: 1,
                        marginLeft: 9,
                        marginRight: 9,
                        backgroundColor: "yellow",
                    }}
                >
                    <Can I="LireSousMenuEquipes" a="Equipes" ability={ability}>
                        <Link to="/equipes">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/equipes"
                                        ? {
                                            textAlign: "center",
                                            backgroundColor: `${bleuclair}`,
                                            color: "white",
                                        }
                                        : {
                                            textAlign: "center",
                                        }
                                }
                                onClick={() => navigation("Equipes")}
                            >
                                <FontAwesomeIcon
                                    icon={faUserFriends}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/equipes"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Équipes
                                </Text>
                            </Card>
                        </Link>
                    </Can>
                </div>
                <div
                    style={{
                        flex: 1,
                        marginLeft: 9,
                        marginRight: 18,
                        backgroundColor: "purple",
                    }}
                >
                    <Can
                        I="LireSousMenuParametres"
                        a="Parametres"
                        ability={ability}
                    >
                        <Link to="/parametres">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/parametres"
                                        ? {
                                            textAlign: "center",
                                            backgroundColor: `${bleuclair}`,
                                            color: "white",
                                        }
                                        : {
                                            textAlign: "center",
                                        }
                                }
                                onClick={() => navigation("Parametres")}
                            >
                                <FontAwesomeIcon
                                    icon={faCog}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/parametres"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Paramètres
                                </Text>
                            </Card>
                        </Link>
                    </Can>
                </div>
            </div>
            {/* <Row
                gutter={18}
                style={{
                    padding: 18,
                    paddingTop: 0,
                }}
            >
                <Can
                    I="LireSousMenuUtilisateurs"
                    a="Utilisateurs"
                    ability={ability}
                >
                    <Col span={6}>
                        <Link to="/utilisateurs">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/utilisateurs"
                                        ? {
                                              textAlign: "center",
                                              backgroundColor: `${bleuclair}`,
                                              color: "white",
                                          }
                                        : {
                                              textAlign: "center",
                                          }
                                }
                                onClick={() => navigation("Utilisateurs")}
                            >
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/utilisateurs"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Utilisateurs
                                </Text>
                            </Card>
                        </Link>
                    </Col>
                </Can>
                <Can I="LireSousMenuProfils" a="Profils" ability={ability}>
                    <Col span={6}>
                        <Link to="/profiles">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/profiles"
                                        ? {
                                              textAlign: "center",
                                              backgroundColor: `${bleuclair}`,
                                              color: "white",
                                          }
                                        : {
                                              textAlign: "center",
                                          }
                                }
                                onClick={() => navigation("Profiles")}
                            >
                                <FontAwesomeIcon
                                    icon={faIdCard}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/profiles"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Profils
                                </Text>
                            </Card>
                        </Link>
                    </Col>
                </Can>
                <Can I="LireSousMenuEquipes" a="Equipes" ability={ability}>
                    <Col span={6}>
                        <Link to="/equipes">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/equipes"
                                        ? {
                                              textAlign: "center",
                                              backgroundColor: `${bleuclair}`,
                                              color: "white",
                                          }
                                        : {
                                              textAlign: "center",
                                          }
                                }
                                onClick={() => navigation("Equipes")}
                            >
                                <FontAwesomeIcon
                                    icon={faUserFriends}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/equipes"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Équipes
                                </Text>
                            </Card>
                        </Link>
                    </Col>
                </Can>
                <Can
                    I="LireSousMenuParametres"
                    a="Parametres"
                    ability={ability}
                >
                    <Col span={6}>
                        <Link to="/parametres">
                            <Card
                                bordered={false}
                                className="tab"
                                style={
                                    location.pathname === "/parametres"
                                        ? {
                                              textAlign: "center",
                                              backgroundColor: `${bleuclair}`,
                                              color: "white",
                                          }
                                        : {
                                              textAlign: "center",
                                          }
                                }
                                onClick={() => navigation("Parametres")}
                            >
                                <FontAwesomeIcon
                                    icon={faCog}
                                    style={{
                                        fontSize: 20,
                                        marginRight: 10,
                                    }}
                                />{" "}
                                <Text
                                    className="tab1"
                                    style={
                                        location.pathname === "/parametres"
                                            ? { color: "white" }
                                            : null
                                    }
                                >
                                    Paramètres
                                </Text>
                            </Card>
                        </Link>
                    </Col>
                </Can>
            </Row> */}
            {/* <HeaderApp />
            <div className="container">
                <Row
                    style={{
                        marginBottom: 9,
                    }}
                >
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item href="">
                                <HomeOutlined />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="">
                                <span>Configurations</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="">
                                <Text>{location.pathname.split('/')}</Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row
                    gutter={18}
                    style={{
                        marginBottom: 9,
                    }}
                >
                    <Can I="LireSousMenuUtilisateurs" a="Utilisateurs" ability={ability}>
                        <Col span={6}>
                            <Link to="/utilisateurs">
                                <Card
                                    bordered={false}
                                    className="tab"
                                    style={
                                        location.pathname === "/utilisateurs"
                                            ? {
                                                  textAlign: "center",
                                                  backgroundColor: `${bleuclair}`,
                                                  color: "white",
                                              }
                                            : {
                                                  textAlign: "center",
                                              }
                                    }
                                    onClick={() => navigation("Utilisateurs")}
                                >
                                    <FontAwesomeIcon
                                        icon={faUsers}
                                        style={{
                                            fontSize: 20,
                                            marginRight: 10,
                                        }}
                                    />{" "}
                                    <Text
                                        className="tab1"
                                        style={
                                            location.pathname === "/utilisateurs"
                                                ? { color: "white" }
                                                : null
                                        }
                                    >
                                        Utilisateurs
                                    </Text>
                                </Card>
                            </Link>
                        </Col>
                    </Can>
                    <Can I="LireSousMenuProfils" a="Profils" ability={ability}>
                        <Col span={6}>
                            <Link to="/profiles">
                                <Card
                                    bordered={false}
                                    className="tab"
                                    style={
                                        location.pathname === "/profiles"
                                            ? {
                                                  textAlign: "center",
                                                  backgroundColor: `${bleuclair}`,
                                                  color: "white",
                                              }
                                            : {
                                                  textAlign: "center",
                                              }
                                    }
                                    onClick={() => navigation("Profiles")}
                                >
                                    <FontAwesomeIcon
                                        icon={faIdCard}
                                        style={{
                                            fontSize: 20,
                                            marginRight: 10,
                                        }}
                                    />{" "}
                                    <Text
                                        className="tab1"
                                        style={
                                            location.pathname === "/profiles"
                                                ? { color: "white" }
                                                : null
                                        }
                                    >
                                        Profils
                                    </Text>
                                </Card>
                            </Link>
                        </Col>
                    </Can>
                    <Can I="LireSousMenuEquipes" a="Equipes" ability={ability}>
                        <Col span={6}>
                            <Link to="/equipes">
                                <Card
                                    bordered={false}
                                    className="tab"
                                    style={
                                        location.pathname === "/equipes"
                                            ? {
                                                  textAlign: "center",
                                                  backgroundColor: `${bleuclair}`,
                                                  color: "white",
                                              }
                                            : {
                                                  textAlign: "center",
                                              }
                                    }
                                    onClick={() => navigation("Equipes")}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserFriends}
                                        style={{
                                            fontSize: 20,
                                            marginRight: 10,
                                        }}
                                    />{" "}
                                    <Text
                                        className="tab1"
                                        style={
                                            location.pathname === "/equipes"
                                                ? { color: "white" }
                                                : null
                                        }
                                    >
                                        Équipes
                                    </Text>
                                </Card>
                            </Link>
                        </Col>
                    </Can>
                    <Can I="LireSousMenuParametres" a="Parametres" ability={ability}>
                        <Col span={6}>
                            <Link to="/parametres">
                                <Card
                                    bordered={false}
                                    className="tab"
                                    style={
                                        location.pathname === "/parametres"
                                            ? {
                                                  textAlign: "center",
                                                  backgroundColor: `${bleuclair}`,
                                                  color: "white",
                                              }
                                            : {
                                                  textAlign: "center",
                                              }
                                    }
                                    onClick={() => navigation("Parametres")}
                                >
                                    <FontAwesomeIcon
                                        icon={faCog}
                                        style={{
                                            fontSize: 20,
                                            marginRight: 10,
                                        }}
                                    />{" "}
                                    <Text
                                        className="tab1"
                                        style={
                                            location.pathname === "/parametres"
                                                ? { color: "white" }
                                                : null
                                        }
                                    >
                                        Paramètres
                                    </Text>
                                </Card>
                            </Link>
                        </Col>
                    </Can>
                </Row>
            </div> */}
        </>
    );
};

export default ConfigApp;
