import { type_projectConstants } from "../_constants";

const initialState = {
    loading: false,
    item: {},
    items: [],
    error: "",
};

export const type_project = (state = initialState, action) => {
    switch (action.type) {
        case type_projectConstants.GETALL_TYPE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type_projectConstants.GETALL_TYPE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.type_project,
            };

        case type_projectConstants.GETALL_TYPE_PROJECT_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
