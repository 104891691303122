import { project_statusConstants } from "../_constants";
import { project_statusServices } from "../_services";
import { loginActions } from "../_actions";

export const project_statusActions = {
    getAll,
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        project_statusServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: project_statusConstants.GETALL_REQUEST,
        };
    }
    function success(project_status) {
        return {
            type: project_statusConstants.GETALL_SUCCESS,
            project_status,
        };
    }
    function failure(error) {
        return {
            type: project_statusConstants.GETALL_FAILURE,
            error,
        };
    }
}
