import axios from "../../axios/index";

export const taskServices = {
    add,
    getAll,
    update,
    getAllTaskLogs,
    addTaskLog,
    getAllUnderTask,
    updateUnderTask,
    getAllUserTask,
    deleteUnderTask,
    getAllPlaningTaskOfTeam,
    getAllPlaningTaskOfUser,
    getAllPlaningTaskOfProject,
    generateTaskFromTicket,
    getAllTaskForCustomer,
    getDataChart,
    getDataChartDetail,
    getDataChartEquipe,
    getDataChartUsers,
    getDataChartAllTeam,
    getDataChartEATeam,
    getDataChartCHTeam,
    getChartEAProjets,
    getChartCHUsers,
    getRecentlyTask,
    getTaskFiltred
};

async function getTaskFiltred(data) {
    return await axios.post(`/taches/filtered`, data)
}

async function getChartEAProjets(data) {
    if (data !== undefined) {
        return await axios.put(`/taches/getChartEAProjets/${data.id_equipe}`, data);
    } else {
        return [];
    }
}

async function getChartCHUsers(data) {
    if (data !== undefined) {
        return await axios.put(`/taches/getChartCHUsers/${data.id_equipe}`, data);
    } else {
        return [];
    }
}

async function getDataChartEquipe(data) {
    if (data !== undefined) {
        return await axios.put(`/taches/getDataChartEquipe/${data.id_equipe}`, data);
    } else {
        return [];
    }
}

async function getDataChartUsers(data) {
    if (data !== undefined) {
        return await axios.put(`/taches/getDataChartUsers/${data.id_equipe}`, data);
    } else {
        return [];
    }
}

async function getAllTaskForCustomer(id_client) {
    return await axios.get(`/taches/getAllTaskForCustomer/${id_client}`);
}

async function getRecentlyTask(id_user) {
    return await axios.get(`/taches/getRecentlyTask/${id_user}`);
}

async function getAllPlaningTaskOfProject(id_projet) {
    return await axios.get(`/taches/getAllPlaningTaskOfProject/${id_projet}`);
}

async function getAllPlaningTaskOfUser(id_equipe_membre) {
    return await axios.get(`/taches/getAllPlaningTaskOfUser/${id_equipe_membre}`);
}

async function getAllPlaningTaskOfTeam(id_equipe) {
    return await axios.get(`/taches/getAllPlaningTaskOfTeam/${id_equipe}`);
}

async function deleteUnderTask(under_task) {
    return await axios.delete(`/taches/${under_task}`);
}

async function add(task) {
    return await axios.post(`/taches`, task);
}

async function generateTaskFromTicket(ticket) {
    return await axios.post(`/taches/ticket`, ticket);
}

async function getAll() {
    return await axios.get(`/taches`);
}

async function getDataChart(filter_chart) {
    if (filter_chart !== undefined) {
        return await axios.post(`/taches/getDataChart`, filter_chart);
    } else {
        return [];
    }
}

async function getDataChartDetail(filter_chart) {
    if (filter_chart !== undefined) {
        return await axios.post(`/taches/getDataChartDetail`, filter_chart);
    } else {
        return [];
    }
}

async function getDataChartAllTeam(filter_chart) {
    if (filter_chart !== undefined) {
        return await axios.post(`/taches/getDataChartAllTeam`, filter_chart);
    } else {
        return [];
    }
}

async function getDataChartEATeam(filter_chart) {
    if (filter_chart !== undefined) {
        return await axios.post(`/taches/getDataChartEATeam`, filter_chart);
    } else {
        return [];
    }
}

async function getDataChartCHTeam(filter_chart) {
    if (filter_chart !== undefined) {
        return await axios.post(`/taches/getDataChartCHTeam`, filter_chart);
    } else {
        return [];
    }
}

async function update(task) {
    return await axios.put(`/taches/${task.id_tache}`, task);
}

async function getAllTaskLogs(id_task) {
    return await axios.get(`/taches/tache_logs/${id_task}`);
}

async function addTaskLog(task_log) {
    return await axios.post(`/tache_logs`, task_log);
}

async function getAllUnderTask(id_task) {
    return await axios.get(`/taches/sousTache/${id_task}`);
}

async function updateUnderTask(task) {
    return await axios.put(`/taches/sousTache/${task.id_tache}`, task);
}

async function getAllUserTask(id_user) {
    return await axios.get(`/taches/user_tache/${id_user}`);
}
