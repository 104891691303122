import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions, customerActions } from "../../../redux/_actions";
import { Modal, Button, Form, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";

const ModalAjouterClient = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const closable_add_customer = useSelector(
    (state) => state.modal.closable_add_customer
  );
  const user = useSelector((state) => state.login.item);

  useEffect(() => {
    if (Object.keys(props.selectedCustomer).length !== 0) {
      form.setFieldsValue({
        nom_client: props.selectedCustomer.nom_client,
        adresse_client: props.selectedCustomer.adresse_client,
      });
    }
  });

  const submitAddCustomers = (values) => {
    const data = {
      nom_client: values.nom_client,
      adresse_client: values.adresse_client,
      create_by: parseInt(user.id_user),
    };
    dispatch(customerActions.add(data));
    form.resetFields();
    props.setSelectedCustomer({})
    dispatch(modalActions.close_add_customer());
  };

  const updateCustomers = (values) => {
    const data = {
      id_client: props.selectedCustomer.id_client,
      nom_client: values.nom_client,
      adresse_client: values.adresse_client,
      modified_by: parseInt(user.id_user)
    };
    dispatch(customerActions.update(data));
    form.resetFields();
    props.setSelectedCustomer({})
    dispatch(modalActions.close_add_customer());
  };

  return (
    <Modal
      title={
        Object.keys(props.selectedCustomer).length === 0
          ? "Ajouter client"
          : "Modifier client"
      }
      visible={closable_add_customer}
      onCancel={() => {
        dispatch(modalActions.close_add_customer());
        props.setSelectedCustomer({});
        form.resetFields();
      }}
      footer={null}
      bodyStyle={{
        paddingBottom: 1,
      }}
    >
      <Form
        onFinish={
          Object.keys(props.selectedCustomer).length === 0
            ? submitAddCustomers
            : updateCustomers
        }
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Nom client"
          name="nom_client"
          rules={[
            {
              required: true,
              message: "Vueillez entrer le nom et prénom du client",
            },
          ]}
        >
          <Input defaultValue={props.selectedCustomer.nom_client} />
        </Form.Item>

        <Form.Item label="Adresse" name="adresse_client">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <SaveOutlined /> Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAjouterClient;
