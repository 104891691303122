import React from "react";
import { Card, Typography, Tooltip, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { WechatOutlined } from "@ant-design/icons";
import {
    modalActions,
    taskActions,
    projectActions,
    userActions,
} from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import moment from "moment/min/moment-with-locales";
import { makeAvatar } from "../../helpers/makeAvatar";
import './UserTask.css';

moment.locale("fr");
const { Text } = Typography;

const UserTask = (props) => {
    const dispatch = useDispatch();

    const taskOperation = () => {
        dispatch(projectActions.selectedProjectTask(props.item));
        dispatch(taskActions.getAllTaskLogs(parseInt(props.item.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(props.item.id_tache)));
        dispatch(modalActions.show_detail_task());
        dispatch(
            userActions.getAllMemberProject(parseInt(props.item.id_projet))
        );
    };

    return (
        <Card
            className="task"
            bodyStyle={{ padding: 5, display: "flex", borderRadius: 10 }}
            style={{ marginBottom: 10, cursor: 'pointer' }}
            onClick={() => taskOperation()}
        >
            <div style={{ flex: 6 }}>
                <Avatar
                    style={{
                        width: 36,
                        height: 36,
                        marginRight: 10,
                        border: "1px solid #515151",
                        backgroundColor: "#f8f8ff",
                        color: "#515151",
                        fontSize: 10,
                    }}
                >
                    {props.item.first_name !== null
                        ? makeAvatar(props.item.first_name)
                        : '.'}
                </Avatar>{" "}
                {props.item.libelle_tache.length > 50
                    ? (
                        <Tooltip placement="top" title={props.item.libelle_tache}>
                            {props.item.libelle_tache.substr(0, 50) + '...'}
                        </Tooltip>
                    ) : props.item.libelle_tache}
            </div>
            <div style={{ flex: 2 }}>
                <div
                    className="projet_name"
                    style={{
                        display: "inline-block",
                        padding: "2px 5px",
                        borderRadius: 5,
                        backgroundColor: '#eaeaea',
                        // backgroundColor: 'rgb(0, 21, 41)',
                        marginLeft: 10,
                        position: "relative",
                        color: "black",
                        bottom: 3,
                        top: 3,
                    }}
                >
                    {props.item.libelle_projet.length > 11
                        ? (
                            <Tooltip placement="top" title={props.item.libelle_projet}>
                                {props.item.libelle_projet.substr(0, 11) + '...'}
                            </Tooltip>
                        ) : props.item.libelle_projet}
                </div>
            </div>
            <div style={{ flex: 2 }}>
                <div
                    style={{
                        display: "inline-block",
                        padding: "2px 5px",
                        borderRadius: 5,
                        backgroundColor: `${props.item.statut_color}`,
                        marginLeft: 10,
                        position: "relative",
                        color: "white",
                        bottom: 3,
                        top: 3,
                    }}
                >
                    <Text>
                        {props.item.libelle_jalon}
                    </Text>
                </div>
            </div>
            <div style={{ flex: 2 }}>
                <Tag color="green" key={props.item.id_tache}>
                    {props.item.nb_sousTache} Sous tâches
                </Tag>
            </div>
            <div style={{ flex: 2 }}>
                <Text color="green">{moment(props.item.date_create).format('ll')}</Text>
            </div>
        </Card>
    );
};

export default UserTask;
