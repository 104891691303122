import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Line } from "react-chartjs-2";
import {
    Tabs,
    Typography as Text,
    Button,
    Breadcrumb,
    DatePicker,
    Tooltip as TooltipAnt,
    Descriptions
} from 'antd';
import {
    CloseCircleOutlined,
    AreaChartOutlined,
} from "@ant-design/icons";
import {
    taskActions
} from "../../../redux/_actions";
import ChartEquipes from './Charts/ChartEquipes';
import ChartGlobale from './Charts/ChartGlobale';
import ChartTaches from './Charts/ChartTaches';
import ChartEATeam from './Charts/ChartEATeam';
import ChartEACHDetail from './Charts/ChartEACHDetail';
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import { isEmpty } from 'lodash';
Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

Moment.globalMoment = moment;
Moment.globalLocale = "fr";
const { RangePicker } = DatePicker;

const ChartAnalyse = () => {
    // const profil_admin = 1;
    const profil_chef_projet = 2;
    const profil_chef_commercial = 4;


    const dispatch = useDispatch();

    const { TabPane } = Tabs;

    // Parametre du composant "ChartGlobale"
    const [data, setData] = useState({});
    const [tada, setTada] = useState({});
    // si on choisi une equipe a partir de l'onglet "Equipes"
    const [chooseTeam, setChooseTeam] = useState({});
    // parametre du composant "ChartEquipes"
    const [chartTeam, setChartTeam] = useState({});
    const [chartUser, setChartUser] = useState({});
    // parametre du composant "ChartTaches"
    const [chartTaskTem, setChartTaskTeam] = useState({});
    // parametre du composant ChartEATeam
    const [chartEA, setChartEA] = useState({});
    const [chartCH, setChartCH] = useState({});
    // parametre du composant ChartEATeam
    const [CHuser, setCHusers] = useState({});

    // Récupération des informations sur les states du module "Analyse"
    const filter_chart = useSelector((state) => state.task.filter_chart);
    const data_chart = useSelector((state) => state.task.items_chart);
    const items_chartCH_users = useSelector((state) => state.task.items_chartCH_users);
    const items_chart_user = useSelector((state) => state.task.items_chart_user);
    const items_chart_detail = useSelector((state) => state.task.items_chart_detail);
    const items_chart_equipe = useSelector((state) => state.task.items_chart_equipe);
    const teams = useSelector((state) => state.team.items).map((p) => p.libelle);
    const project_color = useSelector((state) => state.project_color.items);
    const users = useSelector((state) => state.user.items);
    const loadingTask_allTeam = useSelector((state) => state.task.loadingTask_allTeam);
    const items_chartTask_team = useSelector((state) => state.task.items_chartTask_team);
    const items_chartEA_team = useSelector((state) => state.task.items_chartEA_team);
    const items_chartCH_team = useSelector((state) => state.task.items_chartCH_team);
    const current_month = items_chartCH_team.map((i) => i.mois)[0];
    const user_login = useSelector((state) => state.login);
    const user_login_profil = useSelector((state) => state.login.item.id_profil);

    // les dispatch a executé lors du chargement de la page
    useEffect(() => {

        setData({
            labels: data_chart.map((p) => p.libelle_equipe),
            datasets: [
                {
                    label: 'L\'ensemble des Tâches par équipes',
                    data: data_chart.map((p) => p.nb_tache),
                    backgroundColor: data_chart.map((p) => p.color),
                }
            ],
        });

        setTada({
            labels: items_chart_detail.data_equipe.map((p) => p.libelle),
            datasets: items_chart_detail.tab_jalons,
        });

        setChartCH({
            labels: items_chartCH_team.map((p) => p.label),
            datasets: [
                {
                    label: "Charge horaire des équipes en heurs période  " + current_month,
                    data: items_chartCH_team.map((p) => p.data),
                    backgroundColor: items_chartCH_team.map((p) => p.color),
                }
            ],
        });

        setChartEA({
            labels: items_chartEA_team.map((p) => p.label),
            datasets: [
                {
                    label: "État d'avancement de tous les projets par équipes en (%)",
                    data: items_chartEA_team.map((p) => p.data),
                    backgroundColor: items_chartEA_team.map((p) => p.color),
                }
            ],
        });

        setChartTaskTeam({
            labels: ["janvier", "Février", "Mars", "Avril", "Mai", "Juin", "juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            datasets: items_chartTask_team,
        });

        // reset filtre
        dispatch(taskActions.resetFilter_chart());

        // pour annuler le choix fait sur une equipe
        dispatch(taskActions.delDataChartTeam());
        setChooseTeam({});

    }, []);

    // l'ensemble des taches par equipes 0
    useEffect(() => {
        if (data_chart !== undefined) {
            setData({
                labels: data_chart.map((p) => p.libelle_equipe),
                datasets: [
                    {
                        label: 'L\'ensemble des Tâches par équipes',
                        data: data_chart.map((p) => p.nb_tache),
                        backgroundColor: data_chart.map((p) => p.color),
                        // backgroundColor: project_color.map((p) => p.color),
                    }
                ],
            });
        }
    }, [data_chart]);

    // le detail des taches des equipes par jalons 1
    useEffect(() => {
        if (items_chart_detail.tab_jalons !== undefined) {
            setTada({
                labels: items_chart_detail.data_equipe.map((p) => p.libelle),
                datasets: items_chart_detail.tab_jalons,
            });
        }
    }, [items_chart_detail]);

    useEffect(() => {
        if (items_chartEA_team !== undefined) {
            setChartEA({
                labels: items_chartEA_team.map((p) => p.label),
                datasets: [
                    {
                        label: "État d'avancement de tous les projets par équipes en (%)",
                        data: items_chartEA_team.map((p) => p.data),
                        backgroundColor: items_chartEA_team.map((p) => p.color),
                    }
                ],
            });
        }
    }, [items_chartEA_team]);

    // Charge horaire des equipes 
    useEffect(() => {
        if (items_chartCH_team !== undefined) {
            setChartCH({
                labels: items_chartCH_team.map((p) => p.label),
                datasets: [
                    {
                        label: "Charge horaire des équipes en heurs du mois de " + current_month,
                        data: items_chartCH_team.map((p) => p.data),
                        backgroundColor: items_chartCH_team.map((p) => p.color),
                    }
                ],
            });
        }
    }, [items_chartCH_team]);

    // auto_load evolution de creation de tache pour les equipes par moi
    useEffect(() => {
        if (items_chartTask_team !== undefined) {
            setChartTaskTeam({
                labels: ["janvier", "Février", "Mars", "Avril", "Mai", "Juin", "juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                datasets: items_chartTask_team,
            });
        }
    }, [items_chartTask_team])

    // AUTO_LOAD FILTRE PAR DATE 
    useEffect(() => {
        // les dispatch concernant le module "Analyse"
        dispatch(taskActions.getDataChart({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));
        // Details de tache par jalon pour toutes les equipes
        dispatch(taskActions.getDataChartDetail({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));
        // Evancement de creation de tache pour les equipes par moi
        dispatch(taskActions.getDataChartAllTeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));
        // EA : Etat d'Avancement
        dispatch(taskActions.getDataChartEATeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));
        // CH : Charge Horaire
        dispatch(taskActions.getDataChartCHTeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));

        if (Object.keys(chooseTeam).length > 0) {
            // Tabs Projets 
            dispatch(taskActions.getChartEAProjets({
                id_equipe: chooseTeam.id_equipe,
                date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            }));
            dispatch(taskActions.getChartCHUsers({
                id_equipe: chooseTeam.id_equipe,
                date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            }));
            // Tabs Equipes/Usres
            dispatch(taskActions.getDataChartEquipe({
                id_equipe: chooseTeam.id_equipe,
                date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            }));
            dispatch(taskActions.getDataChartUsers({
                id_equipe: chooseTeam.id_equipe,
                date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
                date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            }));
        }

    }, [filter_chart])

    // le dispatch a executé lorsqu'on click sur l'onglet "Tâches"
    useEffect(() => {
        setChartTaskTeam({
            labels: ["janvier", "Février", "Mars", "Avril", "Mai", "Juin", "juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            datasets: items_chartTask_team,
        });
    }, [items_chartTask_team]);

    // les dispach a executé lorsqu'on clique sur une equipe pour voir les details 
    useEffect(() => {
        if (Object.keys(chooseTeam).length > 0) {
            let team = users.filter((p) => p.id_equipe === chooseTeam.id_equipe & p.statut_activation === 1)
            setChartTeam({
                labels: team.map((p) => p.first_name),
                datasets: items_chart_equipe,
            })

            setCHusers({
                labels: team.map((p) => p.first_name),
                datasets: [
                    {
                        label: 'Charge Horaire des utilisateur de l\'équipe en heurs (176h) période ' + current_month,
                        data: items_chartCH_users.map((p) => p.data),
                        backgroundColor: project_color.map((p) => p.color),
                    }
                ],
            });

            setChartUser({
                labels: items_chart_user.map((p) => p.first_name),
                datasets: [
                    {
                        label: 'Durée des tâches exécutée par utilisateurs',
                        data: items_chart_user.map((p) => p.nb_tache),
                        backgroundColor: project_color.map((p) => p.color),
                    }
                ],
            });
        }
    }, [items_chart_equipe, items_chart_user, items_chartCH_users])

    // pour vider les valeurs des données d'une equipe 
    const handleClose = () => {
        setChooseTeam({});
        setChartTeam({});
        dispatch(taskActions.delDataChartTeam());
    }

    // quand on filtre par date 
    function onChange(date, dateString) {
        if (!isEmpty(dateString[0])) {
            dispatch(taskActions.filter_chart(dateString));
        } else {
            dispatch(taskActions.resetFilter_chart());
        }
    }

    return (
        <div
            style={{
                marginTop: 10,
                marginLeft: 20,
                marginRight: 20
            }}
        >
            <Breadcrumb>
                <Breadcrumb.Item>
                    <AreaChartOutlined />
                </Breadcrumb.Item>
                {
                    Object.keys(chooseTeam).length === 0 ? (
                        <Breadcrumb.Item>
                            Les différents types de graphes
                        </Breadcrumb.Item>
                    ) : (
                        <>
                            <Breadcrumb.Item>
                                Détail sur l'équipe <b>{chooseTeam.libelle}</b>
                            </Breadcrumb.Item>
                            {user_login_profil !== profil_chef_projet ? (
                                <TooltipAnt placement="topLeft" title="Reset choose">
                                    <Button type="primary" danger style={{ float: 'right', marginLeft: 10 }} onClick={handleClose}>
                                        <CloseCircleOutlined style={{ fontSize: 15 }} />
                                    </Button>
                                </TooltipAnt>
                            ) : null}
                        </>
                    )
                }
                <div
                    style={{
                        float: "right",
                    }}
                >
                    <RangePicker onChange={onChange} format="DD/MM/YYYY" />
                    {
                        filter_chart.length !== 0 ? (
                            <TooltipAnt placement="topLeft" title="Reset Filter">
                                <Button type="dashed" danger style={{ float: 'right', marginLeft: 10 }} onClick={() => {
                                    dispatch(taskActions.resetFilter_chart());
                                }}>
                                    <CloseCircleOutlined style={{ fontSize: 15 }} />
                                </Button>
                            </TooltipAnt>
                        ) : null
                    }
                    {
                        Object.keys(chooseTeam).length > 0 & filter_chart.length !== 0 ? (
                            <Text strong={true} style={{ float: 'left', marginLeft: 40, marginTop: 5 }}>
                                <b>Du {filter_chart[0]} au {filter_chart[1]} </b>
                            </Text>
                        ) : null
                    }
                </div>
            </Breadcrumb>
            <Tabs tabPosition="left" style={{ marginTop: 20 }}>
                <TabPane
                    tab={
                        <Text strong={true}>
                            <b>ÉQUIPES</b>
                        </Text>
                    } key="1"
                >
                    {
                        Object.keys(chooseTeam).length === 0 ? (
                            <ChartGlobale setChooseTeam={setChooseTeam} data={data} tada={tada} />
                        ) : (
                            <ChartEquipes chartUser={chartUser} chartTeam={chartTeam} />
                        )
                    }
                </TabPane>
                <TabPane
                    tab={
                        <Text strong={true}>
                            <b>PROJETS</b>
                        </Text>
                    } key="2"
                >
                    {
                        Object.keys(chooseTeam).length === 0 ? (
                            <ChartEATeam setChooseTeam={setChooseTeam} chartEA={chartEA} chartCH={chartCH} />
                        ) : (
                            <ChartEACHDetail CHuser={CHuser} chooseTeam={chooseTeam} />
                        )
                    }
                </TabPane>
                <TabPane
                    tab={
                        <Text strong={true} style={{}}>
                            <b>TÂCHES</b>
                        </Text>
                    } key="3"
                >
                    <ChartTaches loadingTask_allTeam={loadingTask_allTeam} chartTaskTem={chartTaskTem} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ChartAnalyse