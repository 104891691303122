import { directionConstants } from "../_constants";
import { directionServices } from "../_services";
import { loginActions, alertActions } from "../_actions";

export const directionActions = {
    getAll,
    add,
    update,
    remove,
};

function remove(directions) {
    return function (dispatch) {
        dispatch(request());
        directionServices
            .remove(directions)
            .then((res) => {
                dispatch(success(directions));
                dispatch(alertActions.success("Suppression effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec suppression !"));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: directionConstants.DELETE_DIRECTION_REQUEST,
        };
    }
    function success(direction) {
        return {
            type: directionConstants.DELETE_DIRECTION_SUCCESS,
            direction,
        };
    }
    function failure(error) {
        return {
            type: directionConstants.DELETE_DIRECTION_FAILURE,
            error,
        };
    }
}

function update(direction) {
    return function (dispatch) {
        dispatch(request());
        directionServices
            .update(direction)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec modification !"));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: directionConstants.UPDATE_DIRECTION_REQUEST,
        };
    }
    function success(direction) {
        return {
            type: directionConstants.UPDATE_DIRECTION_SUCCESS,
            direction,
        };
    }
    function failure(error) {
        return {
            type: directionConstants.UPDATE_DIRECTION_FAILURE,
            error,
        };
    }
}

function add(direction) {
    return function (dispatch) {
        dispatch(request());
        directionServices
            .add(direction)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec d'ajout !"));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: directionConstants.ADD_DIRECTION_REQUEST,
        };
    }
    function success(direction) {
        return {
            type: directionConstants.ADD_DIRECTION_SUCCESS,
            direction,
        };
    }
    function failure(error) {
        return {
            type: directionConstants.ADD_DIRECTION_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        directionServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if(err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout())
                }
            });
    };
    function request() {
        return {
            type: directionConstants.GETALL_REQUEST,
        };
    }
    function success(direction) {
        return {
            type: directionConstants.GETALL_SUCCESS,
            direction,
        };
    }
    function failure(error) {
        return {
            type: directionConstants.GETALL_FAILURE,
            error,
        };
    }
}
