import React from 'react';
import {
    Row,
    Col,
    Spin,
    Empty,
} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { Bar, Line as LineChart } from "react-chartjs-2";



const ChartEquipes = ({ chartTeam, chartUser }) => {

    const loading_chart_equipe = useSelector((state) => state.task.loading_chart_equipe);
    const loading_chart_user = useSelector((state) => state.task.loading_chart_user);




    const optionsGrouped = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    const optionsStacked = {
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
    }

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart_equipe}
                    >
                        <h3 style={{ textAlign: 'center' }}>L'ENSEMBLE DES TACHES DES UTILISATEURS DE L'EQUIPE</h3>
                        <div style={{ marginLeft: 5, marginRight: 15 }}>
                            {Object.keys(chartTeam).length > 0 ? (
                                <Bar
                                    height={380}
                                    data={chartTeam}
                                    options={optionsStacked}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            ) : (
                                <>
                                    <Empty description={false} />
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart_user}
                    >
                        <h3 style={{ textAlign: 'center' }}>LA DURÉE DES TACHES EXÉCUTÉE PAR UTILISATEURS DE L'EQUIPE</h3>
                        <div style={{ marginLeft: 5, marginRight: 15 }}>
                            {Object.keys(chartUser).length > 0 ? (
                                <Bar
                                    height={380}
                                    data={chartUser}
                                    options={optionsGrouped}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            ) : (
                                <>
                                    <Empty description={false} />
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>
        </div>
    )
}

export default ChartEquipes;