import React from "react";
import { Button, Modal, Input, Form, Select, Typography } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { modalActions, teamActions } from "../../../redux/_actions";

const { Option } = Select;
const { Text } = Typography;

const ModalEquipes = (props) => {
    const closable_add_equipe = useSelector(
        (state) => state.modal.closable_add_equipe
    );
    const users = useSelector((state) => state.user.chiefs);
    const directions = useSelector((state) => state.direction.items);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // Soumission des donnees ajouter equipe
    const submitProfile = (values) => {

        const data = {
            libelle: values.libelle,
            id_user: parseInt(values.id_user),
            id_direction: parseInt(values.id_direction),
            type: parseInt(values.type),
            color: values.color
        }
        dispatch(teamActions.add(data));
        form.resetFields();
        dispatch(modalActions.close_add_team());
    };

    return (
        <Modal
            title={<Text strong={true}>Ajouter une équipe</Text>}
            visible={closable_add_equipe}
            footer={null}
            onCancel={() => dispatch(modalActions.close_add_team())}
            bodyStyle={
                {
                    // paddingBottom: 0,
                }
            }
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Form onFinish={submitProfile} form={form} layout="vertical">
                <Form.Item
                    name="libelle"
                    label={<Text strong={true}>Libellé</Text>}
                    rules={[
                        {
                            required: true,
                            message: "Veuillez saisir le libellé du l'équipe !",
                        },
                    ]}
                >
                    <Input type="text" size="large" placeholder="Libellé du l'équipe" />
                </Form.Item>
                <Form.Item
                    name="id_user"
                    label={<Text strong={true}>Chef d'équipe</Text>}
                    rules={[
                        {
                            required: true,
                            message: "Veuillez choisir un chef de projet !",
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        placeholder="Sélectionner un chef de projet"
                    >
                        {users.map((p) => (
                            <Option value={p.id_user}>{p.first_name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="id_direction" label={<Text strong={true}>Direction</Text>}>
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        placeholder="Sélectionner la direction de l'équipe"
                    >
                        {directions.map((p) => (
                            <Option value={p.id_direction}>{p.libelle}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="type"
                        label={<Text strong={true}>Type d'Equipe</Text>}
                        rules={[
                            {
                                required: true,
                                message: "Veuillez renseigner le type !",
                            },
                        ]}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner un type"
                        >
                            <Option value={0}>Secondaire</Option>
                            <Option value={1}>Principale</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="color"
                        label={<Text strong={true}>Couleur</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                    >
                        <Input size="large" type="color" placeholder="Choisir une couleur !" />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" type="default" onClick={() => dispatch(modalActions.close_add_team())}>
                        <CloseCircleOutlined /> Annuler
                    </Button>{' '}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalEquipes;
