export const filtreConstants = {
    CHOOSE_EQUIPE: "CHOOSE_EQUIPE",
    RESET_EQUIPE: "RESET_EQUIPE",

    CHOOSE_PROJET: "CHOOSE_PROJET",
    RESET_PROJET: "RESET_PROJET",

    CHOOSE_MEMBRE: "CHOOSE_MEMBRE",
    RESET_MEMBRE: "RESET_MEMBRE",

    CHOOSE_JALON: "CHOOSE_JALON",
    RESET_JALON: "RESET_JALON",

    CHOOSE_DATE: "CHOOSE_DATE",
    RESET_DATE: "RESET_DATE",
}