import { statut_demandeConstants } from "../_constants";
import { statut_demandeServices } from "../_services";

const initialeState = {
    loading: false,
    item: {},
    items: [],
    error: "",
};

export const statut_demande = (state = initialeState, action) => {
    switch (action.type) {
        case statut_demandeConstants.GETALL_STATUT_DEMANDE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statut_demandeConstants.GETALL_STATUT_DEMANDE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.statut_demande,
            };

        case statut_demandeConstants.GETALL_STATUT_DEMANDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case statut_demandeConstants.ADD_STATUT_DEMANDE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statut_demandeConstants.ADD_STATUT_DEMANDE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.statut_demande],
            };

        case statut_demandeConstants.ADD_STATUT_DEMANDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case statut_demandeConstants.UPDATE_STATUT_DEMANDE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statut_demandeConstants.UPDATE_STATUT_DEMANDE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (
                            parseInt(p.id_statut) ===
                            parseInt(action.statut_demande.id_statut)
                        ) {
                            Object.assign(p, action.statut_demande);
                        }
                        return p;
                    }),
                ],
            };

        case statut_demandeConstants.UPDATE_STATUT_DEMANDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case statut_demandeConstants.DELETE_STATUT_DEMANDE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statut_demandeConstants.DELETE_STATUT_DEMANDE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.filter(
                        (p) =>
                            parseInt(p.id_statut) !==
                            parseInt(action.statut_demande)
                    ),
                ],
            };

        case statut_demandeConstants.DELETE_STATUT_DEMANDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
