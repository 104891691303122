import { profileConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    item: {},
    error: "",
};

export const profile = (state = initialState, action) => {
    switch (action.type) {
        case profileConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case profileConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                added: action.profile,
                items: [...state.items, action.profile],
            };

        case profileConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case profileConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case profileConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.profile,
            };

        case profileConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case profileConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case profileConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                //items: action.profile,
                item:
                    state.item.id_profil === action.profile.id_profil
                        ? action.profile
                        : state.item,
                items: [
                    ...state.items.map(
                        (u) => {
                            if(u.id_profil === action.profile.id_profil) {
                                u.libelle = action.profile.libelle
                            }
                            return u;
                        }
                    )
                ],
            };

        case profileConstants.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                items: action.error,
            };

        default:
            return state;
    }
};
