import { teamConstants } from "../_constants";

const initialState = {
    loading: false,
    added: {},
    deleted: {},
    updated: {},
    items: [],
    item: {},
    selectedTeam: null,
    checkedAll: false,
    error: "",
};

export const team = (state = initialState, action) => {
    switch (action.type) {
        case teamConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case teamConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.team,
            };

        case teamConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case teamConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case teamConstants.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [action.team, ...state.items],
            };

        case teamConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case teamConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case teamConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (p.id_equipe === action.team.id_equipe) {
                            p.libelle = action.team.libelle;
                            p.id_user = action.team.id_user;
                            p.id_direction = action.team.id_direction;
                            p.first_name = action.team.first_name;
                            p.type = action.team.type;
                            p.color_equipe = action.team.color_equipe;
                        }
                        return p;
                    }),
                ],
            };

        case teamConstants.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case teamConstants.SELECTED_TEAM_PROJETS:
            return {
                ...state,
                selectedTeam: action.equipe,
            };

        default:
            return state;
    }
};
