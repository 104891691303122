import React from "react";
import { modalActions } from "../../../redux/_actions";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Drawer, Input, Typography } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import EquipeCard from "../Equipe/EquipeCard";

const { Search } = Input;
const { Text} = Typography;

const VoirToutesLesEquipes = (props) => {

    const dispatch = useDispatch();

    const teams = useSelector((state) => state.team.items);
    const closable_more_team = useSelector(
        (state) => state.modal.closable_more_team
    );

    const onSearch = (value) => {
        props.setSearch(value);
    };

    return (
        <Drawer
            title={<Text strong={true}>Équipes</Text>}
            // placement="right"
            closable={true}
            placement="left"
            onClose={() => dispatch(modalActions.close_more_team())}
            visible={closable_more_team}
            maskClosable={false}
            mask={false}
            width={280}
            style={{
                marginTop: 50,
            }}
            bodyStyle={{
                paddingTop: 10,
            }}
        >
            <Search
                placeholder="input search text"
                onSearch={onSearch}
                style={{ width: "100%" }}
                allowClear={true}
            />
            <Divider
                style={{
                    margin: "10px 0px",
                }}
            />
            <Scrollbars
                style={{
                    height: 520,
                }}
            >
                {teams
                    .filter((p) =>
                        p.libelle
                            .toLowerCase()
                            .includes(props.search.toLowerCase())
                    )
                    .map((p) => (
                        <EquipeCard
                            p={p}
                            teamSelected={props.teamSelected}
                            setTeamSelected={props.setTeamSelected}
                            selectedTeam={props.selectedTeam}
                            setUpdatedEquipe={props.setUpdatedEquipe}
                        />
                    ))}
            </Scrollbars>
        </Drawer>
    );
};

export default VoirToutesLesEquipes;
