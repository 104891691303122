import React, { useEffect, useState } from "react";
import { Modal, Tabs, Typography, Form, Button, Input, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    modalActions,
    loginActions,
    userActions,
} from "../../../redux/_actions";
import {
    UserOutlined,
    MailOutlined,
    LoginOutlined,
    KeyOutlined,
    SaveOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const { TabPane } = Tabs;

const ModalProfilUser = () => {
    const [tabBan, setTabBan] = useState("1");

    const closable_user_profil = useSelector(
        (state) => state.modal.closable_user_profil
    );
    const profiles = useSelector((state) => state.profile.items);
    const user = useSelector((state) => state.login.item);
    const login_exist = useSelector((state) => state.user.login_exist);
    const email_exist = useSelector((state) => state.user.email_exist);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formPassword] = Form.useForm();

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 80,
                }}
                defaultValue={
                    user.phone !== undefined && user.phone !== null
                        ? user.phone.slice(0, 4)
                        : null
                }
            >
                <Option value="+227">+227</Option>
                <Option value="+234">+234</Option>
            </Select>
        </Form.Item>
    );

    useEffect(() => {
        form.setFieldsValue({
            first_name: user.first_name,
            // login: user.login,
            prefix: user.phone !== undefined && user.phone !== null ? user.phone.slice(0, 4) : null,
            email: user.email,
            phone: user.phone !== undefined && user.phone !== null ? user.phone.slice(4, 30) : null,
            id_profil: user.id_profil,
        });
        formPassword.setFieldsValue({
            old_password: "",
            new_password: "",
            conf_password: "",
        });
    });

    const submitUserInfo = (values) => {
        const data = {
            id_user: parseInt(user.id_user),
            first_name: values.first_name,
            phone:
                values.prefix === null
                    ? "+227" + values.phone
                    : values.prefix + "" + values.phone,
            email: values.email,
            password: values.password === undefined ? null : values.password,
            // id_profil: values.id_profil,
            login: user.login,
            statut_activation: true,
        };

        dispatch(loginActions.update(data));
    };

    const chnagePassword = (values) => {
        const data = {
            old_password: values.old_password,
            new_password: values.new_password,
            id_user: parseInt(user.id_user),
        };
        dispatch(userActions.changeUserPassword(data));
        formPassword.resetFields();
    };

    const callback = (key) => {
        setTabBan(key);
    };

    return (
        <Modal
            title={<Text strong={true}>Paramètres du profil</Text>}
            visible={closable_user_profil}
            width={700}
            footer={null}
            bodyStyle={{ paddingTop: 0 }}
            onCancel={() => {
                dispatch(modalActions.close_user_profil());
                setTabBan("1");
                form.resetFields();
            }}
            maskClosable={false}
        >
            <Tabs activeKey={tabBan} onChange={callback}>
                <TabPane tab={<Text strong={true}>Profil</Text>} key="1">
                    <Form
                        form={form}
                        onFinish={submitUserInfo}
                        // onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Form.Item
                            name="first_name"
                            label="Nom & prénom"
                            rules={[
                                {
                                    required: true,
                                    message: "Champ obligatoire.",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                defaultValue={user.first_name}
                                prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                }
                                placeholder="Nom & Prenom"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item
                                label="Téléphone"
                                name="phone"
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginRight: 2,
                                    marginBottom: 0,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Input
                                    defaultValue={
                                        user.phone !== undefined &&
                                            user.phone !== null
                                            ? user.phone.slice(4, 30)
                                            : null
                                    }
                                    size="large"
                                    addonBefore={prefixSelector}
                                    placeholder="Telephone"
                                />
                            </Form.Item>
                            <Form.Item
                                label="E-mail"
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginBottom: 5,
                                }}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Input
                                    defaultValue={user.email}
                                    size="large"
                                    style={{
                                        border: email_exist
                                            ? "2px solid #ff4d4f"
                                            : null,
                                    }}
                                    prefix={<MailOutlined />}
                                    placeholder="E-mail"
                                    onBlur={(e) => {
                                        dispatch(
                                            userActions.checkMailUpdate({
                                                email: e.target.value,
                                                id_user: parseInt(user.id_user),
                                            })
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item
                                name="id_profil"
                                label="Profil"
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginRight: 2,
                                    marginBottom: 0,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Select
                                    size="large"
                                    placeholder="Selectionner profil"
                                    style={{
                                        width: "100%",
                                    }}
                                    disabled={true}
                                    defaultValue={[parseInt(user.id_profil)]}
                                >
                                    {profiles.map((p) => (
                                        <Option value={p.id_profil}>
                                            {p.libelle}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="login"
                                label="Login"
                                style={{
                                    display: "inline-block",
                                    width: "calc(49% - 0px)",
                                    marginBottom: 0,
                                }}
                            >
                                <Input
                                    size="large"
                                    defaultValue={user.login}
                                    style={{
                                        border: login_exist
                                            ? "2px solid #ff4d4f"
                                            : null,
                                    }}
                                    disabled={true}
                                    prefix={
                                        <LoginOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Login"
                                    onBlur={(e) =>
                                        dispatch(
                                            userActions.checkLoginUpdate({
                                                login: e.target.value,
                                                id_user: parseInt(user.id_user),
                                            })
                                        )
                                    }
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    email_exist === true || login_exist === true
                                        ? true
                                        : false
                                }
                            >
                                <SaveOutlined /> Enregistrer
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                {/* <TabPane tab={<Text strong={true}>Affichage</Text>} key="2">
                    Content of Tab Pane 2
                </TabPane>
                <TabPane tab={<Text strong={true}>Notification</Text>} key="3">
                    Content of Tab Pane 3
                </TabPane> */}
                <TabPane
                    tab={<Text strong={true}>Confidentialité</Text>}
                    key="4"
                >
                    <Form
                        form={formPassword}
                        onFinish={chnagePassword}
                        // onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Form.Item
                            name="old_password"
                            label="Mot de passe actuel"
                            rules={[
                                {
                                    required: true,
                                    message: "Champ obligatoire.",
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                type="password"
                                prefix={<KeyOutlined />}
                                placeholder="Mot de passe"
                            />
                        </Form.Item>
                        <Form.Item
                            name="new_password"
                            label="Nouveau mot de passe"
                            rules={[
                                {
                                    required: true,
                                    message: "Champ obligatoire.",
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                type="password"
                                prefix={<KeyOutlined />}
                                placeholder="Mot de passe"
                            />
                        </Form.Item>
                        <Form.Item
                            name="conf_password"
                            label="Confirmer le nouveau mot de passe"
                            rules={[
                                {
                                    required: true,
                                    message: "Champ obligatoire.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue("new_password") ===
                                            value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            "Les mots de passe doivent être identiques."
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size="large"
                                type="password"
                                prefix={<KeyOutlined />}
                                placeholder="Mot de passe"
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                            >
                                <SaveOutlined /> Enregistrer
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default ModalProfilUser;
