import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Detail.css";
import {
    Row,
    Button,
    Card,
    Breadcrumb,
    Avatar,
    Spin,
    Typography,
    Tooltip,
} from "antd";
import { HomeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import DetailTache from "../../components/Drawer/DetailTache";
import { useSelector, useDispatch } from "react-redux";
import Tableau from "./Tableau";
import Liste from "../../pages/Project/Liste";
import HeaderApp from "../../parchalse/HeaderApp";
import { makeAvatar, decryptWithAES } from "../../helpers/makeAvatar";
import {
    projectActions,
    team_projectActions,
    userActions,
    modalActions,
    taskActions,
} from "../../../redux/_actions";
import { useHistory, useLocation } from "react-router-dom";

import Planning from "./Planning";

const { Text, Title } = Typography;
const tabList = [
    {
        key: "tab1",
        tab: <Text style={{ fontSize: 13 }}>Tableau</Text>,
    },
    {
        key: "tab2",
        tab: <Text style={{ fontSize: 13 }}>Liste</Text>,
    },
    {
        key: "tab3",
        tab: <Text style={{ fontSize: 13 }}>Planning</Text>,
    },
];

const Detail = () => {
    var { id_projet } = useParams();
    id_projet = decryptWithAES(id_projet);

    const history = useHistory();

    const loading = useSelector((state) => state.project.loading);

    const [tabKey, setTabKey] = useState("tab1");
    const location = useLocation();
    const [showTaskAdd, setShowTakAdd] = useState(0);


    // const [projectSelected] = useState(team_projects.find((p) => p.id_projet === id_projet));

    const projectSelected = useSelector(
        (state) => state.team_project.selectedProject
    );

    const contentList = {
        tab1: <Tableau id_projet={id_projet} setShowTakAdd={setShowTakAdd} showTaskAdd={showTaskAdd} />,
        tab2: <Liste id_projet={id_projet} />,
        tab3: <Planning />,
    };

    const dev_projects = useSelector(
        (state) => state.team_project.team_member_project
    );
    const team_projects = useSelector(
        (state) => state.team_project.team_projects
    );
    const project_without_team = useSelector(
        (state) => state.project.project_without_team
    );

    const user = useSelector((state) => state.login.item);
    const dispatch = useDispatch();
    const teams = useSelector((state) => state.team.items);
    const customerChoose = useSelector((state) => state.customer.item_choose);

    useEffect(() => {
        document.title = "2iProject-Détails";
        if (user.id_equipe === null || user.id_profil === 1) {
            let veri_tp = team_projects.find((p) => p.id_projet == id_projet);
            let veri_pwt = project_without_team.find(
                (p) => p.id_projet == id_projet
            );
            if (veri_tp === undefined && veri_pwt === undefined && Object.keys(customerChoose).length === 0) {
                history.push("/404");
            } else {
                dispatch(
                    projectActions.getAllProjectTask({
                        id_project: parseInt(id_projet),
                    })
                );
                dispatch(userActions.getAllMemberProject(parseInt(id_projet)));
                if (veri_tp !== undefined) {
                    dispatch(team_projectActions.selectedProject(veri_tp));
                } else if (veri_pwt !== undefined) {
                    dispatch(team_projectActions.selectedProject(veri_pwt));
                }
                dispatch(
                    taskActions.getAllPlaningTaskOfProject(parseInt(id_projet))
                );
            }
        } else {
            let veri_tp = team_projects.find((p) => p.id_projet == id_projet);
            let veri_dp = dev_projects.find((p) => p.id_projet == id_projet);
            if (user.id_equipe !== null) {
                let equipe = teams.find((p) => p.id_equipe == user.id_equipe);

                if (equipe.id_user == user.id_user || user.id_profil === 1) {
                    if (veri_tp === undefined && Object.keys(customerChoose).length === 0) {
                        history.push("/404");
                    } else {
                        dispatch(
                            projectActions.getAllProjectTask({
                                id_project: parseInt(id_projet),
                            })
                        );
                        dispatch(
                            userActions.getAllMemberProject(parseInt(id_projet))
                        );
                        dispatch(team_projectActions.selectedProject(veri_tp));
                        dispatch(
                            taskActions.getAllPlaningTaskOfProject(
                                parseInt(id_projet)
                            )
                        );
                    }
                } else {
                    if (veri_dp === undefined && Object.keys(customerChoose).length === 0) {
                        history.push("/404");
                    } else {
                        dispatch(
                            projectActions.getAllProjectTask({
                                id_project: parseInt(id_projet),
                            })
                        );
                        dispatch(
                            userActions.getAllMemberProject(parseInt(id_projet))
                        );
                        dispatch(team_projectActions.selectedProject(veri_dp));
                        dispatch(
                            taskActions.getAllPlaningTaskOfProject(
                                parseInt(id_projet)
                            )
                        );
                    }
                }
            }
        }
        dispatch(modalActions.close_detail_task());
        dispatch(userActions.userNotification(user.id_user));
    }, [
        dev_projects,
        dispatch,
        history,
        id_projet,
        project_without_team,
        team_projects,
        teams,
        user.id_equipe,
        user.id_profil,
        user.id_user,
    ]);

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Projet</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Text strong>Détail</Text>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* <Button
                onClick={() => {
                    var acoder =
                        "U2FsdGVkX19TLfPpjj+KIMxwOZ9sFj0IW5A/+IBZmrPg=";
                    let tab = acoder.split("");
                    tab.map((p) =>
                        p === "/"
                            ? (acoder =
                                  "U2FsdGVkX19TLfPpjj+KIMxwOZ9sFj0IW5Ak+IBZmrPg=")
                            : acoder
                    );
                    alert(acoder);
                }}
            >
                Test
            </Button> */}
            <Spin tip="Chargement en cours..." size="large" spinning={loading}>
                <div
                    style={{
                        padding: 18,
                        paddingTop: 0,
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "85.4%",
                        // border: '2px solid red'
                    }}
                >
                    <Card
                        bodyStyle={{
                            padding: "0px 10px",
                            height: "77%",
                        }}
                        defaultActiveTabKey={tabKey}
                        style={{ width: "100%" }}
                        title={
                            <>
                                <Title level={4}>
                                    <ArrowLeftOutlined
                                        onClick={() => window.history.back()}
                                    />{" "}
                                    {projectSelected.libelle}
                                </Title>
                            </>
                        }
                        extra={
                            <>
                                {projectSelected.Membre_projet !== undefined ? (
                                    <Avatar.Group>
                                        {projectSelected.Membre_projet.map(
                                            (p) => (
                                                <Tooltip title={p.first_name}>
                                                    <Avatar
                                                        style={{
                                                            marginRight: 5,
                                                            marginTop: 10,
                                                            border:
                                                                "2px solid #252526",
                                                            backgroundColor:
                                                                "#f8f8ff",
                                                            color: "#252526",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {makeAvatar(
                                                            p.first_name
                                                        )}
                                                    </Avatar>
                                                </Tooltip>
                                            )
                                        )}
                                    </Avatar.Group>
                                ) : null}
                            </>
                        }
                        tabList={tabList}
                        activeTabKey={tabKey}
                        onTabChange={(key) => {
                            setTabKey(key);
                        }}
                    >
                        {contentList[tabKey]}
                    </Card>
                </div>
            </Spin>
            <DetailTache />
            {/* <HeaderApp />
            <Spin tip="Loading..." size="large" spinning={loading}>
                <div
                    style={{
                        position: "relative",
                        padding: "7px  18px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingBottom: 9,
                            paddingTop: 9,
                        }}
                    >
                        <Breadcrumb>
                            <Breadcrumb.Item href="">
                                <HomeOutlined />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="">
                                <span>Projet</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="">
                                <Text strong>Détail</Text>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <Card
                            bodyStyle={{
                                padding: "0px 10px",
                            }}
                            defaultActiveTabKey={tabKey}
                            style={{ width: "100%" }}
                            title={
                                <>
                                    <Title level={4}>
                                        <ArrowLeftOutlined
                                            onClick={() =>
                                                window.history.back()
                                            }
                                        />{" "}
                                        {projectSelected.libelle}
                                    </Title>
                                </>
                            }
                            extra={
                                <>
                                    {projectSelected.Membre_projet !==
                                    undefined ? (
                                        <Avatar.Group>
                                            {projectSelected.Membre_projet.map(
                                                (p) => (
                                                    <Avatar
                                                        style={{
                                                            marginRight: 5,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        {makeAvatar(
                                                            p.first_name
                                                        )}
                                                    </Avatar>
                                                )
                                            )}
                                        </Avatar.Group>
                                    ) : null}
                                </>
                            }
                            tabList={tabList}
                            activeTabKey={tabKey}
                            onTabChange={(key) => {
                                setTabKey(key);
                            }}
                        >
                            {contentList[tabKey]}
                        </Card>
                    </div>
                    <DetailTache />
                </div>
            </Spin> */}
        </div>
    );
};

export default Detail;
