import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Divider,
    Table,
    Popconfirm,
    Checkbox,
    Typography,
    Menu,
    Dropdown,
    Spin,
    Input,
    Modal,
    Form,
    Empty,
    Tag,
    DatePicker,
    Tooltip as TooltipAnt,
} from "antd";
import {
    HomeOutlined,
    AppstoreOutlined,
    SearchOutlined,
    EyeOutlined,
    FilterOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import HeaderApp from "../../parchalse/HeaderApp";
import { useDispatch, useSelector } from "react-redux";
import {
    modalActions,
    ticketActions,
    type_ticketActions,
    priority_ticketActions,
    customerActions,
    ticket_stateActions,
    taskActions,
    demande_cotationActions,
    userActions,
    projectActions,
} from "../../../redux/_actions";
import ModalFiltrerTicket from "../../components/Modal/ModalFiltrerTicket";
import ProjetNew from "../../components/Projet/ProjetNew";
import { Scrollbars } from "react-custom-scrollbars";
import DrawerDemandeDeCotationTicket from "../../components/Drawer/DrawerDemandeDeCotationTicket";
import { isEmpty } from "lodash";

const { Text } = Typography;
const { RangePicker } = DatePicker;


const Tickets = () => {
    const [selectedProjectTicket, setSelectProjectTicket] = useState(0);
    const [inputTicket, setInputTicket] = useState([]);
    const [demandeSelected, setDemandeSelected] = useState({});
    // const [demandeDetail, setDemandeDetail] = useState({});
    const [typeProjet, setTypeProjet] = useState(1);
    const [count, setCount] = useState(0);
    // const [activeExistant, setActiveExistant] = useState(false);
    const [checked, setChecked] = useState(0);
    const [filterTickets, setFilterTickets] = useState("Tous les tickets");

    const [dateFilter, setDateFilter] = useState({});
    const [filterStatut, setFilterStatut] = useState('');

    let filtrerTicketsByProjet = useSelector(
        (state) => state.ticket.filtrerTicketsByProjet
    );
    const filtrerTickets = useSelector((state) => state.ticket.filtrerTickets);
    const loading = useSelector((state) => state.ticket.loading);
    const isFiltered = useSelector((state) => state.ticket.isFiltered);
    const projets = useSelector((state) => state.project.items_have_ticket);
    // const projectWithTicket = useSelector((state) => state.project.items_have_ticket);
    // const ticketsAll = useSelector((state) => state.ticket.items);
    const projets_search = useSelector((state) => state.project.items_search);
    const activeExistant = useSelector((state) => state.project.activeExistant);
    const item = useSelector((state) => state.demande_cotation.item);
    const items = useSelector((state) => state.demande_cotation.items);
    const auth = useSelector((state) => state.login.item);
    const projetsUser_auth = projets.filter((p) => p.id_equipe === auth.id_equipe);
    const [searchFiltre, setSearchFiltre] = useState('');

    const ticket_state = useSelector((state) => state.ticket_state.items);

    const dispatch = useDispatch();
    const { SubMenu } = Menu;
    const [listDemande, setListDemande] = useState([]);
    // variable de visibilité du modal detail d'un ticket
    const [ticketDetail, setTicketDetail] = useState(false);
    const [formDetailTicket] = Form.useForm();

    useEffect(() => {
        if (selectedProjectTicket !== 0) {

            setListDemande(
                items.filter(
                    (p) => p.id_projet === selectedProjectTicket && p.id_statut === 1 ? p : null
                )
            )

        }
    }, [selectedProjectTicket, items]);

    useEffect(() => {
        dispatch(ticketActions.getAll());
        dispatch(projectActions.getAll());
        dispatch(customerActions.getAll());
        dispatch(type_ticketActions.getAll());
        dispatch(ticket_stateActions.getAll());
        dispatch(priority_ticketActions.getAll());
        dispatch(demande_cotationActions.getAll());
        dispatch(projectActions.getAllProjectHasTickets());
        dispatch(modalActions.close_add_demande_of_quotation());
        dispatch(ticketActions.reset_filtrer_ticket());

        if (projetsUser_auth.length > 0 && projetsUser_auth[0] !== undefined) {
            setSelectProjectTicket(parseInt(projetsUser_auth[0].id_projet));
            dispatch(
                ticketActions.filtrer_ticket_by_project(
                    parseInt(projetsUser_auth[0].id_projet)
                )
            );
            // dispatch(
            //     demande_cotationActions.select_demande_of_quotation(
            //         parseInt(projetsUser_auth[0].id_projet)
            //     )
            // );
            dispatch(projectActions.active_existant(projetsUser_auth[0].demande));

        }
        else {
            dispatch(
                ticketActions.filtrer_ticket_by_project(0)
            );
            dispatch(
                demande_cotationActions.select_demande_of_quotation(0)
            );
            dispatch(projectActions.active_existant(0));
        }
        setInputTicket([]);

    }, []);

    useEffect(() => {

        if (inputTicket.length === 0) {
            dispatch(projectActions.getAllProjectHasTickets())
        }

    }, [dispatch, inputTicket]);


    /**
     * 
     * @param {*} Souvenir code Abdou Abdou [Chef de projet] 
     */
    // useEffect(() => {
    //     let allticketChecked = document.querySelectorAll(".ant-checkbox-checked");
    //     let allinput = document.querySelectorAll(".ant-checkbox-input");
    //     let allspan = document.querySelectorAll(".ant-checkbox");

    //     for (let i = 0; i < allticketChecked.length; i++) {
    //         allticketChecked[i].classList.remove("ant-checkbox-checked");
    //         allinput[i].remove();

    //         let fils = document.createElement('input');
    //         fils.className = 'ant-checkbox-input';
    //         fils.setAttribute("type", "checkbox");

    //         allspan[i].prepend(fils);
    //     }

    //     let allInputticketChecked = document.querySelectorAll("checkboox-ticket");

    //     for (let i = 0; i < allInputticketChecked.length; i++) {
    //         allInputticketChecked[i].checked = false;
    //     }

    // }, [filtrerTicketsByProjet])

    const addTicketSelected = (ticket) => {
        let tab = [...inputTicket];

        tab.push(ticket);
        setChecked(checked + 1);
        setInputTicket(tab);
    };

    const RemoveTicketSelected = (ticket) => {
        setChecked(checked - 1);
        let tab = [
            ...inputTicket.filter(
                (p) => parseInt(p.id_tickets) !== parseInt(ticket.id_tickets)
            ),
        ];

        setInputTicket(tab);
    };

    const columns = [
        {
            title: "Libelle",
            dataIndex: "ticket_title",
            key: "ticket_title",
            width: 200,
            ellipsis: {
                showTitle: true,
            },
        },
        {
            title: "Projet",
            dataIndex: "libelle_projet",
            key: "libelle_projet",
            width: 120,
        },
        {
            title: "Crée le ",
            dataIndex: "create_at_ticket",
            key: "create_at_ticket",
            width: 120,
        },
        {
            title: "Priorité ",
            dataIndex: "priority_name",
            key: "priority_name",
            width: 120,
        },
        {
            title: "État",
            dataIndex: "state_name",
            key: "state_name",
            width: 150,
            render: (text, record) => (
                <>
                    <Tag color={record.state_color_hex} style={{ width: 90, textAlign: "center", fontWeight: "bold" }}>
                        {text.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: "Type demande ",
            dataIndex: "type_name",
            key: "type_name",
            width: 120,
        },
        {
            title: "Action ",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (text, record) => (
                <>
                    {record.id_state === 1 ? (
                        <>
                            <Checkbox
                                className="checkboox-ticket"
                                onChange={(e) => {
                                    e.target.checked
                                        ? addTicketSelected(record)
                                        : RemoveTicketSelected(record)
                                }}
                                disabled={record.id_state !== 1 ? true : false}
                            ></Checkbox>
                        </>
                    ) : (
                        <Checkbox
                            className="checkboox-ticket"
                            checked={true}
                            disabled={true}
                        ></Checkbox>
                    )}
                    <Button
                        type="primary"
                        size="small"
                        icon={<EyeOutlined style={{ color: "black" }} />}
                        style={{ marginLeft: 20, backgroundColor: "#f0e68c" }}
                        onClick={() => handleDetailTicket(record)}
                    />
                </>
            ),
        },
    ];

    const transformAsCotation = (tab, id) => {
        let t = [];

        // dispatch(demande_cotationActions.select_demande_of_quotation_choose(selectedProjectTicket, id));
        let i = item.cotations.length;

        tab.map((p, e) => {
            return t.push({
                key: i + e,
                id_demande: item.id_demande,
                module: "",
                tache: p.ticket_title,
                description: p.ticket_description,
                duree: null,
                observation: "",
                id_tickets: p.id_tickets,
            });
        });
        dispatch(demande_cotationActions.add_cotation_ticket(t, id));
    };

    const menu = (
        <Menu
            onClick={(value) => {

                let id_demande = parseInt(value.key);
                if (parseInt(value.key) > 1) {
                    value.key = "2";
                }

                if (value.key === "1") {
                    let t = [];
                    inputTicket.map((p, i) => {
                        return t.push({
                            key: i,
                            module: "",
                            tache: p.ticket_title,
                            description: p.ticket_description,
                            duree: null,
                            observation: "",
                            id_tickets: p.id_tickets,
                        });
                    });
                    setCount(inputTicket.length);
                    setInputTicket(t);
                    setTypeProjet(1);
                    dispatch(modalActions.show_add_demande_of_quotation());
                } else if (value.key === "2") {
                    dispatch(
                        userActions.getAllMemberProject(
                            parseInt(selectedProjectTicket)
                        )
                    );
                    // dispatch(demande_cotationActions.select_demande_of_quotation_choose(selectedProjectTicket, id_demande));
                    setTypeProjet(2);
                    setInputTicket([]);
                    transformAsCotation(inputTicket, id_demande);
                    setDemandeSelected(item)
                    setTimeout(() => {
                        dispatch(modalActions.show_add_demande_of_quotation());
                    }, 100);
                }
            }}
        >
            <Menu.Item key={1}>Nouveau</Menu.Item>
            {/* <Menu.Item key={2} disabled={activeExistant}>
                Existant
            </Menu.Item> */}
            <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Existant" disabled={activeExistant}>
                {
                    listDemande.length > 0 ?
                        listDemande.map((p) => (
                            !isEmpty(p.libelle_demande) === true ? (
                                <Menu.Item key={p.id_demande}>{p.libelle_demande}</Menu.Item>
                            ) : (
                                <Menu.Item disabled>La demande n'as pas de libellé</Menu.Item>
                            )
                        )) : null
                }
            </SubMenu>
        </Menu>
    );

    const onSearch = (value) => {
        if (value !== '') {
            setSearchFiltre(value);
            dispatch(projectActions.getAllSearch(value));
        } else {
            setSearchFiltre('');
            dispatch(
                ticketActions.filtrer_ticket_by_project(0)
            );
            dispatch(
                demande_cotationActions.select_demande_of_quotation(0)
            );
            dispatch(projectActions.active_existant(0));
        }
    }

    const suffix = (
        <SearchOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );

    const handleDetailTicket = (values) => {
        formDetailTicket.setFieldsValue({
            ticket_title: values.ticket_title,
            ticket_description: values.ticket_description,
            priority_name: values.priority_name,
            type_name: values.type_name,
        });
        setTicketDetail(true)
    }

    const handleCancel = () => {
        formDetailTicket.resetFields();
        setTicketDetail(false);
    };

    const menuEtat = (
        <Menu
            onClick={(value) => {

                if (value.key === "Tous les tickets") {

                    // si un filtre existe deja
                    if (Object.keys(dateFilter).length > 0) {

                        dispatch(ticketActions.filtrer_ticket_date({
                            'date_debut': dateFilter.date_debut,
                            'date_fin': dateFilter.date_fin,
                            'id_projet': dateFilter.id_projet,
                            'id_statut': ''
                        }));

                        setFilterTickets(value.key);
                        setFilterStatut('');
                    }
                    // si non on reinitialise le tout
                    else {
                        dispatch(ticketActions.reset_filtrer_ticket());
                        setFilterTickets(value.key);
                        setFilterStatut('');
                    }

                } else {

                    let statut = ticket_state.filter((p) => p.id_state == value.key)[0];
                    setFilterTickets(statut.state_name);
                    setFilterStatut(statut.id_state);

                    // si un filtre existe deja
                    if (Object.keys(dateFilter).length > 0) {

                        dispatch(ticketActions.filtrer_ticket_date({
                            'date_debut': dateFilter.date_debut,
                            'date_fin': dateFilter.date_fin,
                            'id_projet': dateFilter.id_projet,
                            'id_statut': statut.id_state
                        }));

                    } else {
                        dispatch(ticketActions.filtrer_ticket_date({
                            'date_debut': '',
                            'date_fin': '',
                            'id_projet': selectedProjectTicket,
                            'id_statut': statut.id_state
                        }));
                    }


                    // dispatch(ticketActions.filterTickets(statut.id_state));

                }
            }}
        >
            {ticket_state.length > 0 ? (
                <>
                    <Menu.Item key="Tous les tickets" className="m">
                        Tous les tickets
                    </Menu.Item>
                    {ticket_state.map((p) => (
                        <Menu.Item key={p.id_state} className="m">
                            {p.state_name}
                        </Menu.Item>
                    ))}
                </>
            ) : (
                <Menu.Item className="m">
                    Pas de statut pour les Tickets
                </Menu.Item>
            )}
        </Menu>
    );

    // quand on filtre par date 
    function onChange(date, dateString) {

        let date_debut = dateString['0'];
        let date_fin = dateString['1'];
        let id_projet = selectedProjectTicket;

        setDateFilter({
            'date_debut': date_debut,
            'date_fin': date_fin,
            'id_projet': id_projet,
            'id_statut': filterStatut
        });

        if (!isEmpty(date_debut)) {
            dispatch(ticketActions.filtrer_ticket_date({
                'date_debut': date_debut,
                'date_fin': date_fin,
                'id_projet': id_projet,
                'id_statut': filterStatut
            }));
        } else {
            setDateFilter({});
            dispatch(ticketActions.reset_filtrer_ticket());
        }

    }

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Tickets</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    padding: 18,
                    paddingTop: 0,
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        flex: 2,
                        padding: 10,
                        borderRight: "2px solid #f0f0f0",
                        backgroundColor: "white",
                        height: "85.4vh",
                    }}
                >
                    <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Projets</Text>
                    <Input
                        placeholder="input search text"
                        onKeyUp={(e) => { onSearch(e.target.value) }}
                        style={{ width: 225, marginLeft: 10 }}
                        suffix={suffix}
                    />
                    <Divider
                        style={{
                            margin: "10px 0",
                            backgroundColor: "#f0f0f0",
                        }}
                    />
                    <Scrollbars
                        autoHide
                        style={{
                            background: "white",
                            height: "72vh",
                        }}
                    >
                        {
                            //  si on esseye de filtrer les projets par recharche
                            searchFiltre !== '' ?
                                // si c'est un chef de projet qui se connecte
                                auth.id_profil === 2
                                    ? projets_search
                                        .filter(
                                            (pp) =>
                                                parseInt(pp.id_equipe) ===
                                                parseInt(auth.id_equipe)
                                        )
                                        .map((p, i) => (
                                            <ProjetNew
                                                p={p}
                                                selectedProjectTicket={
                                                    selectedProjectTicket
                                                }
                                                setSelectProjectTicket={
                                                    setSelectProjectTicket
                                                }
                                                setInputTicket={setInputTicket}
                                                setChecked={setChecked}
                                            />
                                        ))
                                    :
                                    // si c'est un developpeur qui se connecte
                                    auth.id_profil === 3 ? (
                                        projets_search.filter(
                                            (pp) =>
                                                parseInt(pp.id_equipe) ===
                                                parseInt(auth.id_equipe))
                                            .map((p, i) => (
                                                p.Membre_projet.map((t) => t.id_user).includes(parseInt(auth.id_user)) ? (
                                                    <ProjetNew
                                                        p={p}
                                                        selectedProjectTicket={
                                                            selectedProjectTicket
                                                        }
                                                        setSelectProjectTicket={
                                                            setSelectProjectTicket
                                                        }
                                                        setInputTicket={setInputTicket}
                                                        setChecked={setChecked}
                                                    />
                                                ) : null
                                            ))
                                    )
                                        // si non affiche le toute
                                        : projets_search.map((p) => (
                                            <ProjetNew
                                                p={p}
                                                selectedProjectTicket={
                                                    selectedProjectTicket
                                                }
                                                setSelectProjectTicket={
                                                    setSelectProjectTicket
                                                }
                                                setInputTicket={setInputTicket}
                                                setChecked={setChecked}
                                            />
                                        ))
                                :
                                // si non pas de recherche des projet lancer
                                // si c'est c'est un chef de projet qui est connecté
                                auth.id_profil === 2
                                    ? projets.filter(
                                        (pp) =>
                                            parseInt(pp.id_equipe) ===
                                            parseInt(auth.id_equipe))
                                        .map((p, i) => (
                                            <ProjetNew
                                                p={p}
                                                selectedProjectTicket={
                                                    selectedProjectTicket
                                                }
                                                setSelectProjectTicket={
                                                    setSelectProjectTicket
                                                }
                                                setInputTicket={setInputTicket}
                                                setChecked={setChecked}
                                            />
                                        ))
                                    :
                                    // si c'est un developpeur qui se connecte
                                    auth.id_profil === 3 ? (
                                        projets.filter(
                                            (pp) =>
                                                parseInt(pp.id_equipe) ===
                                                parseInt(auth.id_equipe))
                                            .map((p, i) => (
                                                p.Membre_projet.map((t) => t.id_user).includes(parseInt(auth.id_user)) ? (
                                                    <ProjetNew
                                                        p={p}
                                                        selectedProjectTicket={
                                                            selectedProjectTicket
                                                        }
                                                        setSelectProjectTicket={
                                                            setSelectProjectTicket
                                                        }
                                                        setInputTicket={setInputTicket}
                                                        setChecked={setChecked}
                                                    />
                                                ) : null
                                            ))
                                    )
                                        // si non on affiche le tout
                                        : projets.map((p) => (
                                            <ProjetNew
                                                p={p}
                                                selectedProjectTicket={
                                                    selectedProjectTicket
                                                }
                                                setSelectProjectTicket={
                                                    setSelectProjectTicket
                                                }
                                                setInputTicket={setInputTicket}
                                                setChecked={setChecked}
                                            />
                                        ))

                        }
                    </Scrollbars>
                </div>
                <div
                    style={{
                        flex: 8,
                        padding: 10,
                        backgroundColor: "white",
                        height: "85.4vh",
                    }}
                >
                    <Text>Tickets</Text>
                    <div
                        style={{
                            float: "right",
                        }}
                    >
                        {filtrerTicketsByProjet.length > 0 ? (
                            <Dropdown
                                overlay={menuEtat}
                                trigger={["click"]}
                            >
                                <Button
                                    style={{
                                        position: "relative",
                                        // bottom: 5,
                                        right: 30,
                                        width: 130,
                                    }}
                                >
                                    <span style={{ marginRight: 20 }}>
                                        {filterTickets}
                                    </span>
                                </Button>
                            </Dropdown>
                        ) : null}
                        {filtrerTicketsByProjet.length > 0 ? (
                            <>
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    onChange={onChange}
                                    style={{ width: '40%', right: 20, }}
                                />
                                {
                                    isFiltered ? (
                                        <TooltipAnt placement="topLeft" title="Reset Filter">
                                            <Button type="dashed" danger style={{ right: 10 }} onClick={() => {
                                                setFilterStatut('');
                                                setFilterTickets("Tous les tickets");
                                                dispatch(ticketActions.reset_filtrer_ticket());
                                            }}>
                                                <CloseCircleOutlined style={{ fontSize: 15 }} />
                                            </Button>
                                        </TooltipAnt>
                                    ) : null
                                }
                            </>
                        ) : null}
                        {(inputTicket.length === 0 || checked <= 0) ? (
                            <>
                                <Dropdown
                                    overlay={menu}
                                    trigger={["click"]}
                                    disabled={
                                        (inputTicket.length === 0 || checked <= 0) ? true : false
                                    }
                                >
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 10 }}
                                    >
                                        Demande de cotation
                                    </Button>
                                </Dropdown>

                                <Button
                                    type="primary"
                                    style={{ marginRight: 10 }}
                                    disabled={
                                        (inputTicket.length === 0 && checked <= 0) ? true : false
                                    }
                                >
                                    Tache
                                </Button>
                            </>
                        ) : (
                            <>
                                <Dropdown overlay={menu} trigger={["click"]}>
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 10 }}
                                    >
                                        Demande de cotation
                                    </Button>
                                </Dropdown>
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir génère les taches à partir des tickets sélectionner ?"
                                    onConfirm={() =>
                                        <>
                                            {dispatch(
                                                taskActions.generateTaskFromTicket({
                                                    data: inputTicket,
                                                    user: parseInt(auth.id_user),
                                                })
                                            )}
                                            {dispatch(projectActions.getAllProjectHasTickets())}
                                            {dispatch(ticketActions.filter_ticket_by_project_from_getAll(0))}
                                            {dispatch(ticketActions.filter_ticket_by_project_from_getAll(inputTicket[0].id_projet))}
                                            {setInputTicket([])}
                                            {setChecked(0)}
                                        </>
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 10 }}
                                    >
                                        Tache
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                        {/* <Button
                            type="dashed"
                            style={{ marginRight: 10 }}
                            onClick={() =>
                                dispatch(modalActions.show_filtrer_ticket())
                            }
                        >
                            <FilterOutlined /> Filtrer
                        </Button> */}
                    </div>
                    <Divider
                        style={{
                            margin: "20px 0",
                            backgroundColor: "#f0f0f0",
                        }}
                    />
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading}
                    >
                        {filtrerTicketsByProjet.length > 0 ? (
                            <>
                                <Table
                                    size="small"
                                    dataSource={
                                        isFiltered
                                            ? filtrerTickets
                                            : filtrerTicketsByProjet
                                    }
                                    columns={columns}
                                    pagination={{ pageSize: 100 }}
                                    scroll={{ y: 440 }}
                                />
                            </>
                        ) : (
                            <>
                                <Empty
                                // description={
                                //     // <Text>
                                //     //     Aucun
                                //     //     projet
                                //     //     trouvé.
                                //     // </Text>
                                // }
                                />
                            </>
                        )}
                    </Spin>
                </div>
            </div>
            <ModalFiltrerTicket />
            <DrawerDemandeDeCotationTicket
                setDemandeSelected={setDemandeSelected}
                demandeSelected={demandeSelected}
                setTypeProjet={setTypeProjet}
                typeProjet={typeProjet}
                inputTicket={inputTicket}
                setCount={setCount}
                count={count}
                setChecked={setChecked}
                selectedProjectTicket={selectedProjectTicket}
                setInputTicket={setInputTicket}
            />

            <Modal
                title={<Text strong={true}>Détail Ticket</Text>}
                visible={ticketDetail}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={formDetailTicket}
                    layout="vertical"
                >
                    <Form.Item
                        name="ticket_title"
                        label={<Text strong={true}>Titre</Text>}
                        tooltip="Titre du ticket"
                    >
                        <Input placeholder="input placeholder" readOnly />
                    </Form.Item>
                    <Form form={formDetailTicket} layout="inline" >
                        <Form.Item name="priority_name"
                            label={<Text strong={true}>Priorité</Text>}
                        >
                            <Input placeholder="Priorité" style={{ width: 220 }} readOnly />
                        </Form.Item>
                        <Form.Item name="type_name"
                            label={<Text strong={true}>Type</Text>}
                        >
                            <Input placeholder="Etat du ticket" style={{ width: 220 }} readOnly />
                        </Form.Item>
                    </Form>
                    <br />
                    <Form.Item name="ticket_description"
                        label={<Text strong={true}>Description</Text>}
                        tooltip="description du ticket"
                    >
                        <Input.TextArea rows={5} readOnly />
                    </Form.Item>
                </Form>
            </Modal>
        </div >

    );
};

export default Tickets;
