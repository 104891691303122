import React from "react";
import { Card } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
    modalActions,
    taskActions,
    projectActions,
} from "../../../redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { makeAvatar } from "../../helpers/makeAvatar";

const ListeItem = (props) => {
    const colors = useSelector((state) => state.project_color.items);

    const dispatch = useDispatch();

    const taskOperation = () => {
        dispatch(projectActions.selectedProjectTask(props.item));
        dispatch(taskActions.getAllTaskLogs(parseInt(props.item.id)));
        dispatch(taskActions.getAllUnderTask(parseInt(props.item.id)));
        dispatch(modalActions.show_detail_task());
    };

    return (
        <Card bodyStyle={{ padding: 5 }}>
            <div style={{ float: "left" }}>
                <Avatar
                    style={{
                        border: "2px solid #252526",
                        backgroundColor: "#f8f8ff",
                        color: "#252526",
                    }}
                >
                    {props.item.first_name !== null
                        ? makeAvatar(props.item.first_name)
                        : null}
                </Avatar>{" "}
                {props.item.libelle_tache}
            </div>
            <div style={{ float: "right" }}>
                <span>
                    <ArrowRightOutlined
                        key="ellipsis"
                        style={{
                            float: "right",
                            marginTop: 8,
                            marginRight: 8,
                        }}
                        onClick={() => taskOperation()}
                    />
                </span>
            </div>
        </Card>
    );
};

export default ListeItem;
