import React, { useState, useEffect } from "react";
import "./Profiles.css";
import {
  Row,
  Col,
  Space,
  Table,
  Spin,
  Tooltip,
  Checkbox,
  Typography,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import ModalProfile from "./ModalProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  modalActions,
  droitActions,
  actionActions,
  userActions,
} from "../../../../redux/_actions";
import Container from "../../../components/Container/Container";
import ConfigApp from "../ConfigApp";
import defineAbilityFor from "../../../../config/defineAbility";
import { Can } from "../../../../config/Can";
import HeaderApp from "../../../parchalse/HeaderApp";

const { Text } = Typography;

const Profiles = () => {
  const [hoverProfile, setHoverProfile] = useState(0);
  const [hoverModule, setHoverModule] = useState(0);
  const [hoverAction, setHoverAction] = useState(0);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [profileSelected, setProfileSelected] = useState(0);
  const [moduleSelected, setModuleSelected] = useState(0);

  const CheckAllBox = 1;
  const UnCheckAllBox = 0;

  const dispatch = useDispatch();

  const profiles = useSelector((state) => state.profile.items);
  const profiles_loading = useSelector((state) => state.profile.loading);
  const groups = useSelector((state) => state.group.items);
  const groups_loading = useSelector((state) => state.group.loading);
  const actions = useSelector((state) => state.action.items);
  const actions_loading = useSelector((state) => state.action.loading);
  const checkedAll = useSelector((state) => state.action.checkedAll);
  const user = useSelector((state) => state.login);

  useEffect(() => {
    document.title = "2iProject-Profils";
    if (profiles[0] !== undefined && profiles[0].id_profil !== undefined) {
      setProfileSelected(profiles[0].id_profil);
    }
    if (groups[0] !== undefined && groups[0].id_module !== undefined) {
      setModuleSelected(groups[0].id_module);
    }
    dispatch(userActions.userNotification(user.item.id_user));
  }, [groups, profiles]);

  // Ouvrir le modale ajouter profile
  const openFormProfile = () => {
    setUpdatedProfile({});
    dispatch(modalActions.show_add_profile());
  };

  const ability = defineAbilityFor(user);

  // Colonne du tableau des profils
  const columnsProfile = [
    {
      title: "Profils",
      dataIndex: "libelle",
      key: "nomprenom",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: (
        <Tooltip title={<Text>Ajouter un profil</Text>}>
          <Can I="AjouterProfil" a="Profils" ability={ability}>
            <PlusOutlined onClick={openFormProfile} />
          </Can>
        </Tooltip>
      ),
      key: "action",
      fixed: "rigth",
      width: 50,
      render: (text, record) => (
        <Space size="middle">
          {record.id_profil === hoverProfile ? (
            <Can I="ModifierProfil" a="Profils" ability={ability}>
              <EditOutlined
                onClick={() => {
                  setUpdatedProfile({
                    id_profil: record.id_profil,
                    libelle: record.libelle,
                  });
                  setProfileSelected(record.id_profil);
                  dispatch(modalActions.show_update_profile());
                }}
              />
            </Can>
          ) : null}
        </Space>
      ),
      // width: 30,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  //Colone du tableau des modules
  const columns_actions = [
    {
      title: (
        <>
          <Text>Droits</Text>
          <Checkbox
            value={checkedAll}
            checked={checkedAll}
            onChange={(e) => {
              if (e.target.value === false) {
                dispatch(
                  actionActions.async_checkall({
                    id_profil: profileSelected,
                    id_module: moduleSelected,
                    choix: CheckAllBox,
                  })
                );
                dispatch(actionActions.checkall(moduleSelected));
              } else {
                dispatch(
                  actionActions.async_checkall({
                    id_profil: profileSelected,
                    id_module: moduleSelected,
                    choix: UnCheckAllBox,
                  })
                );
                dispatch(actionActions.uncheckall(moduleSelected));
              }
            }}
            style={{ float: "right" }}
          />
        </>
      ),
      dataIndex: "description",
      key: "libelle_module",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <>
          <span style={{ width: "50%", display: "inline-block" }}>{text}</span>
          <Checkbox
            style={{
              float: "right",
            }}
            value={record.check}
            checked={record.check}
            onChange={(e) => {
              if (e.target.value) {
                dispatch(
                  droitActions.delete_droit({
                    id_profil: profileSelected,
                    id_action: parseInt(record.id_action),
                  })
                );
                dispatch(actionActions.unchecked(record.id_action));
              } else {
                dispatch(
                  droitActions.add({
                    id_profil: profileSelected,
                    id_action: parseInt(record.id_action),
                  })
                );
                dispatch(actionActions.checked(record.id_action));
              }
            }}
          />
        </>
      ),
    },
  ];

  const columns_modules = [
    {
      title: <Text>Modules</Text>,
      dataIndex: "libelle_module",
      key: "nomprenom",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const scroll = {
    y: "78vh",
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: 50,
        }}
      >
        <HeaderApp />
      </div>
      <div
        style={{
          height: 129,
        }}
      >
        <ConfigApp />
      </div>
      <div
        style={{
          flex: 1,
          padding: 18,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: 9,
          }}
          className="profile"
        >
          <Spin
            tip="Chargement en cours..."
            size="large"
            spinning={profiles_loading}
          >
            <Table
              columns={columnsProfile}
              dataSource={profiles}
              size="small"
              pagination={false}
              scroll={scroll}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: (event) => {
                    setHoverProfile(record.id_profil);
                  },
                  onMouseLeave: (event) => {
                    setHoverProfile(0);
                  },
                  onClick: (event) => {
                    setProfileSelected(record.id_profil);
                    dispatch(
                      actionActions.getProfileAction({
                        id_profil: record.id_profil,
                      })
                    );
                  },
                };
              }}
              rowClassName={(record) => {
                if (hoverProfile === record.id_profil) {
                  return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                } else if (profileSelected === record.id_profil) {
                  return "profileSelected";
                }
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            flex: 1,
            marginRight: 9,
            marginLeft: 9,
          }}
          className="profile"
        >
          <Spin
            tip="Chargement en cours..."
            size="large"
            spinning={groups_loading}
          >
            <Table
              columns={columns_modules}
              dataSource={groups}
              size="small"
              pagination={false}
              scroll={scroll}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: (event) => {
                    setHoverModule(record.id_module);
                  },
                  onMouseLeave: (event) => {
                    setHoverModule(0);
                  },
                  onClick: (event) => {
                    setModuleSelected(record.id_module);
                  },
                };
              }}
              rowClassName={(record) => {
                if (hoverModule === record.id_module) {
                  return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                } else if (moduleSelected === record.id_module) {
                  return "profileSelected";
                }
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: 9,
          }}
          className="profile"
        >
          <Spin
            tip="Chargement en cours..."
            size="large"
            spinning={actions_loading}
          >
            <Table
              columns={columns_actions}
              dataSource={actions.filter((p) => p.id_module === moduleSelected)}
              size="small"
              pagination={false}
              scroll={scroll}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: (event) => setHoverAction(record.id_action),
                  onMouseLeave: (event) => setHoverAction(0),
                };
              }}
              rowClassName={(record) => {
                if (hoverAction === record.id_module) {
                  return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                } else {
                  return profileSelected;
                }
              }}
            />
          </Spin>
        </div>
      </div>
      <ModalProfile
        updatedProfile={updatedProfile}
        setUpdatedProfile={setUpdatedProfile}
      />
      {/* <ConfigApp />
            <div
                style={{
                    marginTop: 9,
                    marginLeft: 18,
                    marginRight: 18,
                    height: "82vh",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        marginRight: 9,
                    }}
                    className="profile"
                >
                    <Spin
                        tip="Loading..."
                        size="large"
                        spinning={profiles_loading}
                    >
                        <Table
                            columns={columnsProfile}
                            dataSource={profiles}
                            size="small"
                            pagination={false}
                            scroll={scroll}
                            onRow={(record, rowIndex) => {
                                return {
                                    onMouseEnter: (event) => {
                                        setHoverProfile(record.id_profil);
                                    },
                                    onMouseLeave: (event) => {
                                        setHoverProfile(0);
                                    },
                                    onClick: (event) => {
                                        setProfileSelected(record.id_profil);
                                        dispatch(
                                            actionActions.getProfileAction({
                                                id_profil: record.id_profil,
                                            })
                                        );
                                    },
                                };
                            }}
                            rowClassName={(record) => {
                                if (hoverProfile === record.id_profil) {
                                    return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                                } else if (
                                    profileSelected === record.id_profil
                                ) {
                                    return "profileSelected";
                                }
                            }}
                        />
                    </Spin>
                </div>
                <div
                    style={{
                        flex: 1,
                        marginRight: 9,
                        marginLeft: 9,
                    }}
                    className="profile"
                >
                    <Spin
                        tip="Loading..."
                        size="large"
                        spinning={groups_loading}
                    >
                        <Table
                            columns={columns_modules}
                            dataSource={groups}
                            size="small"
                            pagination={false}
                            scroll={scroll}
                            onRow={(record, rowIndex) => {
                                return {
                                    onMouseEnter: (event) => {
                                        setHoverModule(record.id_module);
                                    },
                                    onMouseLeave: (event) => {
                                        setHoverModule(0);
                                    },
                                    onClick: (event) => {
                                        setModuleSelected(record.id_module);
                                    },
                                };
                            }}
                            rowClassName={(record) => {
                                if (hoverModule === record.id_module) {
                                    return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                                } else if (
                                    moduleSelected === record.id_module
                                ) {
                                    return "profileSelected";
                                }
                            }}
                        />
                    </Spin>
                </div>
                <div
                    style={{
                        flex: 1,
                        marginLeft: 9,
                    }}
                    className="profile"
                >
                    <Spin
                        tip="Loading..."
                        size="large"
                        spinning={actions_loading}
                    >
                        <Table
                            columns={columns_actions}
                            dataSource={actions.filter(
                                (p) => p.id_module === moduleSelected
                            )}
                            size="small"
                            pagination={false}
                            scroll={scroll}
                            onRow={(record, rowIndex) => {
                                return {
                                    onMouseEnter: (event) =>
                                        setHoverAction(record.id_action),
                                    onMouseLeave: (event) => setHoverAction(0),
                                };
                            }}
                            rowClassName={(record) => {
                                if (hoverAction === record.id_module) {
                                    return ".card .ant-table-tbody > tr.ant-table-row:hover > td";
                                } else {
                                    return profileSelected;
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>
            <ModalProfile
                    updatedProfile={updatedProfile}
                    setUpdatedProfile={setUpdatedProfile}
                /> */}
    </div>
  );
};

export default Profiles;
