import React, { useState, useEffect } from "react";
import HeaderApp from "../../parchalse/HeaderApp";
import {
    Breadcrumb,
    Divider,
    Typography,
    Popover,
    Input,
    Empty,
    Spin,
    Tabs,
    Avatar,
    Dropdown,
    Button,
    Menu,
} from "antd";
import {
    HomeOutlined,
    ProjectOutlined,
    UserOutlined,
    FileOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import EquipeCardNew from "../../components/Equipe/EquipeCardNew";
import Projet from "../../components/Projet/ProjetNew";
import {
    team_projectActions,
    taskActions,
    userActions,
    teamActions,
    team_menberActions
} from "../../../redux/_actions";
import { Scrollbars } from "react-custom-scrollbars";
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { makeAvatar } from "../../helpers/makeAvatar";
import Paper from "@material-ui/core/Paper";
import dateFormat from "dateformat";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { projectActions, modalActions } from "../../../redux/_actions";
import DetailTache from "../../components/Drawer/DetailTache";
import ModalAjouterProjetEquipe from "../../components/Modal/ModalAjouterProjetEquipe";

const { Text } = Typography;
const { TabPane } = Tabs;

const AllProjects = () => {
    const [searchProjet, setSearchProjet] = useState("");
    const [filterProjectState, setFilterProjectState] = useState("En cours");

    const dispatch = useDispatch();

    const teams = useSelector((state) => state.team.items);
    const selectedTeam = useSelector((state) => state.team.selectedTeam);
    const [projectSelected, setProjectSelected] = useState({});
    const loadingTeam = useSelector((state) => state.team.loading);
    const team_projects = useSelector(
        (state) => state.team_project.filterProject
    );
    const tab = useSelector((state) => state.team_project.tab);
    const loadingTeamProjet = useSelector(
        (state) => state.team_project.loading
    );
    const searchVeri = useSelector((state) => state.team_project.searchVeri);

    const [teamSelected, setTeamSelected] = useState(0);

    useEffect(() => {
        if (teams.length > 0 && teams[0].id_equipe !== undefined) {
            if (selectedTeam === null) {
                setTeamSelected(teams[0].id_equipe);
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: parseInt(teams[0].id_equipe),
                    })
                );
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: parseInt(teams[0].id_equipe),
                    })
                );
                dispatch(
                    taskActions.getAllPlaningTaskOfTeam(teams[0].id_equipe)
                );
            } else {
                setTeamSelected(selectedTeam);
                dispatch(
                    team_projectActions.getAllTeamProject({
                        id_equipe: parseInt(selectedTeam),
                    })
                );
                dispatch(
                    team_menberActions.getAllTeamMenber({
                        id_equipe: parseInt(selectedTeam),
                    })
                );
                dispatch(taskActions.getAllPlaningTaskOfTeam(selectedTeam));
            }
        }
        dispatch(modalActions.close_detail_task());
    }, [dispatch, selectedTeam, teams]);

    useEffect(() => {
        return () => dispatch(modalActions.close_add_team_project());
    });

    // useEffect(() => {
    //     dispatch(team_projectActions.resetSearch());
    // }, [])

    const handleClick = (id) => {
        setTeamSelected(id);
        setSearchProjet("");
        dispatch(teamActions.selectedTeam(id));
        dispatch(
            team_projectActions.getAllTeamProject({
                id_equipe: parseInt(id),
            })
        );
        dispatch(
            team_menberActions.getAllTeamMenber({
                id_equipe: parseInt(id),
            })
        );
        dispatch(taskActions.getAllPlaningTaskOfTeam(id));
        dispatch(team_projectActions.resetSearch());
    };

    const handleChangeChef = (e) => {
        setSearchProjet(e.target.value);
        dispatch(team_projectActions.searchProjectChef(e.target.value));
    };

    const openPlan = (obj) => {
        dispatch(projectActions.selectedProjectTask(obj.data));
        dispatch(taskActions.getAllTaskLogs(parseInt(obj.data.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(obj.data.id_tache)));
        dispatch(userActions.getAllMemberProject(parseInt(obj.data.id_projet)));
        setTimeout(() => {
            dispatch(modalActions.show_detail_task());
        }, 100);
    };

    const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment {...restProps} onClick={openPlan}>
            <Popover
                style={{
                    borderLeft: `10px solid ${restProps.data.color}`,
                }}
                content={
                    <div>
                        <p>
                            <FileOutlined /> {restProps.data.libelle_tache}
                        </p>
                        {restProps.data.first_name !== null &&
                            restProps.data.first_name !== undefined ? (
                            <p style={{ marginBottom: 0 }}>
                                <UserOutlined /> {restProps.data.first_name}
                            </p>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <ProjectOutlined /> {restProps.data.libelle_projet}
                    </>
                }
            >
                <div
                    style={{
                        height: "100%",
                        padding: 3,
                        borderLeft: `10px solid ${restProps.data.color}`,
                    }}
                >
                    <Avatar size="small" style={{ float: "left" }}>
                        {makeAvatar(restProps.data.first_name)}
                    </Avatar>
                    {children}
                </div>
            </Popover>
        </Appointments.Appointment>
    );

    const tasks = useSelector((state) => state.task.allTeamPlaningTask);

    // console.log('valLog AllProjects --- 1', tasks);

    const menuEtat = (
        <Menu
            onClick={(value) => {
                if (value.key === "En cours") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterInProgressProject());
                } else if (value.key === "Archivée") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterArchivedProject());
                } else if (value.key === "Tous les projets") {
                    setFilterProjectState(value.key);
                    dispatch(team_projectActions.filterAllProject());
                }
            }}
        >
            <Menu.Item key="En cours" className="m">
                En cours
            </Menu.Item>
            <Menu.Item key="Archivée" className="m">
                Archivée
            </Menu.Item>
            <Menu.Item key="Tous les projets" className="m">
                Tous les projets
            </Menu.Item>
        </Menu>
    );

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    padding: "9px 18px",
                }}
            >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>Projets</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                style={{
                    padding: 18,
                    paddingTop: 0,
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        flex: 2,
                        padding: 10,
                        borderRight: "2px solid #f0f0f0",
                        backgroundColor: "white",
                        height: "85.4vh",
                    }}
                >
                    <Text>Equipes</Text>
                    <Divider
                        style={{
                            margin: "10px 0",
                            backgroundColor: "#f0f0f0",
                        }}
                    />
                    <Scrollbars
                        style={{
                            height: "73vh",
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <Spin
                            tip="Chargement en cours..."
                            spinning={loadingTeam}
                        >
                            {teams.map((p) => (
                                <EquipeCardNew
                                    p={p}
                                    handleClick={handleClick}
                                    teamSelected={teamSelected}
                                />
                            ))}
                        </Spin>
                    </Scrollbars>
                </div>
                <div
                    style={{
                        flex: 8,
                        padding: 10,
                        backgroundColor: "white",
                        height: "85.4vh",
                    }}
                >
                    <Tabs
                        onChange={(value) =>
                            value === "2"
                                ? dispatch(
                                    taskActions.getAllPlaningTaskOfTeam(
                                        teamSelected
                                    )
                                )
                                : null
                        }
                        defaultActiveKey="1"
                        tabBarExtraContent={
                            <>
                                <Dropdown
                                    overlay={menuEtat}
                                    trigger={["click"]}
                                >
                                    <Button
                                        style={{
                                            position: "relative",
                                            bottom: 5,
                                            right: 20,
                                            width: 130,
                                        }}
                                    >
                                        <span style={{ marginRight: 20 }}>
                                            {filterProjectState}
                                        </span>
                                    </Button>
                                </Dropdown>
                                {/* <AutoComplete
                                    style={{
                                        float: "right",
                                        position: "relative",
                                        right: 0,
                                        bottom: 5,
                                        width: 210,
                                    }}
                                > */}
                                <Input.Search
                                    style={{
                                        float: "right",
                                        position: "relative",
                                        right: 0,
                                        bottom: 5,
                                        width: 210,
                                    }}
                                    autoFocus={searchVeri !== "" ? true : false}
                                    value={searchVeri}
                                    placeholder="rechercher un projet"
                                    enterButton
                                    onChange={handleChangeChef}
                                />
                                {/* </AutoComplete> */}
                            </>
                        }
                    >
                        <TabPane tab={<Text>Projets</Text>} key="1">
                            <Scrollbars
                                style={{
                                    height: "70vh",
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Spin
                                    tip="Chargement en cours..."
                                    spinning={loadingTeamProjet}
                                >
                                    {team_projects.length ? (
                                        searchProjet !== "" ||
                                            searchVeri !== "" ? (
                                            tab.length > 0 ? (
                                                tab.map((p) => <Projet p={p} />)
                                            ) : (
                                                <Empty
                                                    description={
                                                        <Text>
                                                            Aucun projet trouvé.
                                                        </Text>
                                                    }
                                                />
                                            )
                                        ) : (
                                            team_projects.map((p) => (
                                                <Projet
                                                    p={p}
                                                    setProjectSelected={
                                                        setProjectSelected
                                                    }
                                                />
                                            ))
                                        )
                                    ) : (
                                        <Empty
                                            description={
                                                <Text>
                                                    Aucun projet trouvé.
                                                </Text>
                                            }
                                        />
                                    )}
                                </Spin>
                            </Scrollbars>
                        </TabPane>
                        <TabPane tab={<Text>Planning</Text>} key="2">
                            <Paper
                                style={{
                                    // height: "100%",
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Scheduler
                                    data={tasks}
                                    height={450}
                                    locale="fr-FR"
                                    firstDayOfWeek={1}
                                >
                                    <ViewState />
                                    <MonthView />
                                    <Appointments
                                        appointmentComponent={Appointment}
                                    />
                                    <Toolbar />
                                    <DateNavigator />
                                </Scheduler>
                            </Paper>
                        </TabPane>
                        {/* <TabPane tab="Tab 3" key="3">
                            Content of Tab Pane 3
                        </TabPane> */}
                    </Tabs>
                </div>
            </div>
            <DetailTache />
            <ModalAjouterProjetEquipe
                teamSelected={teamSelected}
                projectSelected={projectSelected}
                setProjectSelected={setProjectSelected}
            />
        </div>
    );
};

export default AllProjects;
