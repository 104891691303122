import axios from "../../axios/index";

export const team_menberServices = {
    add,
    getAllTeamMenber,
    deleteTeamMenber,
    getAllTaskTeamMember,
}

async function add (team_menber) {
    return await axios.post(`equipe_membres`, team_menber)
}

async function getAllTaskTeamMember () {
    return await axios.get(`equipes/all_task_member/menu`);
}

async function getAllTeamMenber(team_menber) {
    return await axios.get(`equipes/membreEquipe/${team_menber.id_equipe}`, team_menber);
}

async function deleteTeamMenber(team_menber) {
    return await axios.delete(`equipe_membres/${team_menber.id_equipe_membre}`, team_menber);
}