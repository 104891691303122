import React, { useEffect } from "react";
import { Modal, Form, Button, Select, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { modalActions, team_projectActions } from "../../../redux/_actions";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

const ModalAffecterProjetEquipe = (props) => {
    const closable_affect_team_project = useSelector(
        (state) => state.modal.closable_affect_team_project
    );
    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const user = useSelector((state) => state.login.item);
    const teams = useSelector((state) => state.team.items);
    const project_without_team = useSelector(
        (state) => state.project.project_without_team
    );

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            id_equipe: props.teamSelected,
        });
    });

    const submitAffectProject = (values) => {
        const data = {
            id_equipe: values.id_equipe,
            id_equipe_membre: values.id_equipe_membre,
            id_projet: values.id_projet,
            create_by: user.id_user
        }
        dispatch(team_projectActions.affectTeamProject(data));
        dispatch(modalActions.close_affect_team_project());
        form.resetFields();
    };

    return (
        <Modal
            title={<Text strong={true}>Affecter un projet</Text>}
            visible={closable_affect_team_project}
            footer={null}
            onCancel={() => dispatch(modalActions.close_affect_team_project())}
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Form form={form} onFinish={submitAffectProject} layout="vertical">
                <Form.Item>
                    <Form.Item
                        name="id_equipe"
                        label={<Text strong={true} >Équipes</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: "Veuillez sélectionner une équipe",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                        >
                            {teams.map((p) => (
                                <Option value={p.id_equipe}>{p.libelle}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="id_equipe_membre"
                        label={<Text strong={true} >Membre</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message:
                        //             "Veuillez sélectionner les membres rattachés au projet.",
                        //     },
                        // ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner les   membres "
                        >
                            {team_menbers.map((p) => (
                                <Option value={p.id_equipe_membre}>
                                    {p.first_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="id_projet"
                    label={<Text strong={true} >Projet</Text>}
                    rules={[
                        {
                            required: true,
                            message:
                                "Veuillez sélectionner le projet à affecter.",
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        placeholder="Sélectionner un projet"
                    >
                        {project_without_team.map((p) => (
                            <Option value={p.id_projet}>{p.libelle}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" type="default" onClick={() => dispatch(modalActions.close_affect_team_project())}>
                        <CloseCircleOutlined /> Annuler
                    </Button> {' '}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAffecterProjetEquipe;
