import React, { useState } from "react";
import { Card, Avatar, Skeleton, Tooltip, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
    modalActions,
    team_projectActions,
    userActions,
} from "../../../redux/_actions";
import { makeAvatar } from "../../helpers/makeAvatar";

const { Meta } = Card;
const { Text } = Typography;

const EquipeCardNew = (props) => {
    const [teamHover, setTeamHover] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [updatedEquipe, setUpdatedEquipe] = useState({});

    const dispatch = useDispatch();

    const openMoreTeam = (values) => {
        setUpdatedEquipe({
            id_equipe: values.id_equipe,
            libelle: values.libelle,
            id_user: values.id_user,
            id_direction: values.id_direction,
        });
        dispatch(
            userActions.getAllChiefWithoutTeamAndCurrentTeam(values.id_equipe)
        );
        dispatch(modalActions.show_setting_team());
    };

    return (
        <Card
            style={{
                width: "100%",
                marginTop: 5,
                padding: 5,
                borderRadius: 5,
                backgroundColor:
                    props.teamSelected === props.p.id_equipe ||
                    teamHover === props.p.id_equipe
                        ? "#40a9ff"
                        : "#f8f8ff",
                color:
                    props.teamSelected === props.p.id_equipe ||
                    teamHover === props.p.id_equipe
                        ? "#fff"
                        : null,
                border: "1px solid #dedede",
            }}
            bodyStyle={{
                padding: 7,
                color:
                    props.teamSelected === props.p.id_equipe ||
                    teamHover === props.p.id_equipe
                        ? "#fff"
                        : null,
            }}
            hoverable={true}
            onMouseEnter={() => setTeamHover(props.p.id_equipe)}
            onMouseLeave={() => {
                setTeamHover(0);
            }}
            onClick={() => {
                props.handleClick(props.p.id_equipe);
            }}
        >
            <Skeleton loading={false} avatar size="small" active>
                <Meta
                    avatar={
                        <Tooltip placement="top" title={props.p.first_name}>
                            <Avatar
                                style={{
                                    flex: 1,
                                    width: 36,
                                    height: 36,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: 5,
                                    border: "2px solid #252526",
                                    backgroundColor: "#f8f8ff",
                                    color: "#252526",
                                }}
                            >
                                {makeAvatar(props.p.first_name)}
                            </Avatar>
                        </Tooltip>
                    }
                    title={
                        <Text
                            style={{
                                dispatch: "inline-block",
                                marginBottom: 3,
                                color:
                                    props.teamSelected === props.p.id_equipe ||
                                    teamHover === props.p.id_equipe
                                        ? "#fff"
                                        : null,
                                fontSize: 13,
                            }}
                        >
                            <Tooltip title={props.p.libelle}>
                                {props.p.libelle}
                            </Tooltip>
                        </Text>
                    }
                />
            </Skeleton>
        </Card>
    );
};

export default EquipeCardNew;
