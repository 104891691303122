import { projectConstants } from "../_constants";
import { projectServices } from "../_services";
import { alertActions, loginActions } from "../_actions";

export const projectActions = {
    getAll,
    getAllProjectWithoutTeam,
    getAllProjectTask,
    addProjectTask,
    dragInDropTask,
    selectedProjectTask,
    updateProjectTask,
    stopRequest,
    addWithoutTeamProject,
    updateWithoutTeamProject,
    getAllTaskTeamMember,
    reset_task,
    filterUserTask,
    increment_under_task_number,
    delete_project_task,
    filterTaskNotDone,
    filterTaskDone,
    filterTaskAll,
    delete_project_without_team,
    active_existant,
    getAllSearch,
    getAllProjectHasTickets
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: projectConstants.GETALL_PROJECT_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.GETALL_PROJECT_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALL_PROJECT_FAILURE,
            error,
        };
    }
}

function getAllProjectHasTickets() {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAllProjectHasTickets()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: projectConstants.GETALL_PROJECT_HAVE_TICKET_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.GETALL_PROJECT_HAVE_TICKET_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALL_PROJECT_HAVE_TICKET_FAILURE,
            error,
        };
    }
}

function getAllSearch(project_name) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAllSearch(project_name)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: projectConstants.GETALLSEARCH_PROJECT_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.GETALLSEARCH_PROJECT_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALLSEARCH_PROJECT_FAILURE,
            error,
        };
    }
}

function delete_project_without_team(id_project) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .delete_project_without_team(id_project)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: projectConstants.DELETE_PROJECT_WITHOUT_TEAM_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.DELETE_PROJECT_WITHOUT_TEAM_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.DELETE_PROJECT_WITHOUT_TEAM_FAILURE,
            error,
        };
    }
}

function filterTaskAll() {
    return {
        type: projectConstants.FILTER_TASK_ALL,
    };
}

function filterTaskDone() {
    return {
        type: projectConstants.FILTER_TASK_DONE,
    };
}

function filterTaskNotDone() {
    return {
        type: projectConstants.FILTER_TASK_NOT_DONE,
    };
}

function delete_project_task(id_tache) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .delete_project_task(id_tache)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppresion effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de Suppresion."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.DELETE_PROJECT_TASK_REQUEST,
        };
    }
    function success(project_task) {
        return {
            type: projectConstants.DELETE_PROJECT_TASK_SUCCESS,
            project_task,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.DELETE_PROJECT_TASK_FAILURE,
            error,
        };
    }
}

function increment_under_task_number(id_tache) {
    return {
        type: projectConstants.INCREMENT_UNDER_TASK_NUMBER,
        id_tache,
    };
}

function filterUserTask(id) {
    return {
        type: projectConstants.FILETR_USER_TASK,
        id,
    };
}

function reset_task() {
    return {
        type: projectConstants.RESET_USER_TASK,
    };
}

function getAllTaskTeamMember(team_menber) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAllTaskTeamMember(team_menber)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.GETALL_MENBER_TASK_REQUEST,
        };
    }
    function success(team_menber) {
        return {
            type: projectConstants.GETALL_MENBER_TASK_SUCCESS,
            team_menber,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALL_MENBER_TASK_FAILURE,
            error,
        };
    }
}

function updateWithoutTeamProject(project) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .updateWithoutTeamProject(project)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.UPDATE_WITHOUT_TEAM_PROJECT_FAILURE,
            error,
        };
    }
}

function addWithoutTeamProject(project) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .addWithoutTeamProject(project)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Le projet existe déjà."));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.ADD_WITHOUT_TEAM_PROJECT_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.ADD_WITHOUT_TEAM_PROJECT_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.ADD_WITHOUT_TEAM_PROJECT_FAILURE,
            error,
        };
    }
}

function stopRequest() {
    return {
        type: projectConstants.STOP_REQUEST,
    };
}

function getAllProjectWithoutTeam() {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAllProjectWithoutTeam()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.GETALL_PROJECT_WITHOUT_TEAM_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.GETALL_PROJECT_WITHOUT_TEAM_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALL_PROJECT_WITHOUT_TEAM_FAILURE,
            error,
        };
    }
}

function getAllProjectTask(project) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .getAllProjectTask(project)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.GETALL_PROJECT_TASK_REQUEST,
        };
    }
    function success(project) {
        return {
            type: projectConstants.GETALL_PROJECT_TASK_SUCCESS,
            project,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.GETALL_PROJECT_TASK_ERROR,
            error,
        };
    }
}

function addProjectTask(task) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .addProjectTask(task)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(
                    projectActions.getAllProjectTask({
                        id_project: parseInt(res.data.message.id_projet),
                    })
                );
                dispatch(alertActions.success("L'ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
        function request() {
            return {
                type: projectConstants.ADD_PROJECT_TASK_REQUEST,
            };
        }
        function success(task) {
            return {
                type: projectConstants.ADD_PROJECT_TASK_SUCCESS,
                task,
            };
        }
        function failure(error) {
            return {
                type: projectConstants.ADD_PROJECT_TASK_FAILURE,
                error,
            };
        }
    };
}

function dragInDropTask(task) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .dragInDropTask(task)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(
                    projectActions.getAllProjectTask({
                        id_project: parseInt(task.id_projet),
                    })
                );
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.DRAG_IN_DROP_TASK_REQUEST,
        };
    }
    function success(task) {
        return {
            type: projectConstants.DRAG_IN_DROP_TASK_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.DRAG_IN_DROP_TASK_ERROR,
            error,
        };
    }
}

function selectedProjectTask(task) {
    return {
        type: projectConstants.SELECTED_PROJECT_TASK_SUCCESS,
        task,
    };
}

function updateProjectTask(task) {
    return function (dispatch) {
        dispatch(request());
        projectServices
            .updateProjectTask(task)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: projectConstants.UPDATE_TASK_PROJECT_REQUEST,
        };
    }
    function success(task) {
        return {
            type: projectConstants.UPDATE_TASK_PROJECT_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: projectConstants.UPDATE_TASK_PROJECT_FAILURE,
            error,
        };
    }
}

function active_existant(demande) {
    return {
        type: projectConstants.ACTIVE_EXISTANT,
        demande
    }
}
