import axios from "../../axios/index";

export const statutServices = {
    getAll,
    add,
    update
};

async function add(statut) {
    return await axios.post(`statuts`, statut)
}

async function update(statut) {
    return await axios.put(`statuts/${statut.id_statut}`, statut)
}
async function getAll() {
    return await axios.get(`statuts`);
}
