import axios from '../../axios';
export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    remove,
    getUserWithoutTeam,
    userNotification,
    userOpenNotification,
    getAllChiefWithoutTeam,
    getAllChiefWithoutTeamAndCurrentTeam,
    getAllMemberProject,
    checkLogin,
    checkLoginUpdate,
    checkMail,
    checkMailUpdate,
    changeUserPassword,
    sendRememberMail
};

async function sendRememberMail(user) {
    return await axios.get(`cron-job/sendRememberMail`);
}

async function remove(user) {
    return await axios.delete(`users/${user}`);
}

async function checkLogin(login) {
    return await axios.post(`users/checkLogin`, login);
}

async function checkLoginUpdate(login) {
    return await axios.post(`users/checkLoginUpdate`, login);
}

async function checkMail(mail) {
    return await axios.post(`users/checkMail`, mail);
}

async function checkMailUpdate(mail) {
    return await axios.post(`users/checkMailUpdate`, mail);
}

async function getAllMemberProject(id_project) {
    return await axios.get(`users/getAllMemberProject/${id_project}`);
}

async function getAllChiefWithoutTeamAndCurrentTeam(id_equipe) {
    return await axios.get(`users/getAllChiefWithoutTeamAndCurrentTeam/${id_equipe}`);
}

async function getAllChiefWithoutTeam() {
    return await axios.get(`users/getAllChiefWithoutTeam`);
}

async function userNotification(id_user) {
    return await axios.get(`users/notification/${id_user}`);
}

async function userOpenNotification(id_user) {
    return await axios.get(`users/notification/open/${id_user}`);
}

function login(username, password) {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ username, password })
    // };

    // return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('user', JSON.stringify(user));

    //         return user;
    //     });

    return null;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function  getAll() {
   return await axios.get(`users`);
}

function getById(id) {
   
    return null;
}

 async function register(user) { 
    return await axios.post(`users`,user);
}

async function update(user) {

    return await axios.put(`users/${user.id_user}`,user);
}

async function changeUserPassword(user) {
    return await axios.post(`users/updatePassword`,user);
}

// prefixed function name with underscore because delete is a reserved word in javascript
// function remove(id) {
    
//     return null;
// }

async function getUserWithoutTeam () {
    return await axios.get(`users/usersWithoutTeam`);
}
