import { customerConstants, taskConstants } from "../_constants";
import { taskServices } from "../_services";
import { alertActions, projectActions, loginActions } from "../_actions";
import { ticketActions } from "./ticket.actions";

export const taskActions = {
    add,
    getAll,
    update,
    selectedTask,
    getAllTaskLogs,
    addTaskLog,
    getAllUnderTask,
    addUnderTask,
    updateUnderTask,
    getAllUserTask,
    deleteUnderTask,
    reset_under_task,
    reset_task_logs,
    delDataChartTeam,
    getAllPlaningTaskOfTeam,
    getAllPlaningTaskOfUser,
    getAllPlaningTaskOfProject,
    generateTaskFromTicket,
    getAllTaskForCustomer,
    dropTaskCustomer,
    getDataChart,
    getDataChartDetail,
    getDataChartEquipe,
    getDataChartUsers,
    getDataChartAllTeam,
    getDataChartEATeam,
    getDataChartCHTeam,
    getChartEAProjets,
    getChartCHUsers,
    filter_chart,
    resetFilter_chart,
    getRecentlyTask,
    getTaskFiltred,
    reset_filtere,
    updateTaskFiltred
};

function reset_filtere() {
    return {
        type: taskConstants.RESET_FILTERED
    }
}

function updateTaskFiltred(id_task) {
    return {
        type: taskConstants.UPDATE_TASK_FILTERE,
        id_task
    }
}

function getTaskFiltred(data) {

    return function (dispatch) {
        dispatch(request());
        taskServices
            .getTaskFiltred(data)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_FILTERE_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GET_FILTERE_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_FILTERE_FAILURE,
            error,
        };
    }
}

function getRecentlyTask(id_user) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getRecentlyTask(id_user)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_RECENTLY_TASK_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_RECENTLY_TASK_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_RECENTLY_TASK_FAILURE,
            error,
        };
    }
}

function filter_chart(filtre) {
    return {
        type: taskConstants.FILTER_DATE_IN_CHART,
        filtre
    }
}

function resetFilter_chart() {
    return {
        type: taskConstants.RESET_FILTER_DATE_IN_CHART,
    }
}

function getChartEAProjets(data) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getChartEAProjets(data)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_CHART_EA_PROJECT_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_CHART_EA_PROJECT_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_CHART_EA_PROJECT_ERROR,
            error,
        };
    }
}

function getChartCHUsers(data) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getChartCHUsers(data)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_CHART_CH_USERS_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_CHART_CH_USERS_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_CHART_CH_USERS_ERROR,
            error,
        };
    }
}

function getDataChartUsers(data) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartUsers(data)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_USERS_REQUEST,
        };
    }
    function success(chart_users) {
        return {
            type: taskConstants.GET_DATA_CHART_USERS_SUCCESS,
            chart_users,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_USERS_ERROR,
            error,
        };
    }
}

function getDataChartEquipe(data) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartEquipe(data)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_EQUIPE_REQUEST,
        };
    }
    function success(chart_team) {
        return {
            type: taskConstants.GET_DATA_CHART_EQUIPE_SUCCESS,
            chart_team,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_EQUIPE_ERROR,
            error,
        };
    }
}

function getDataChartEATeam(filter_chart) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartEATeam(filter_chart)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_EA_TEAM_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_DATA_CHART_EA_TEAM_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_EA_TEAM_ERROR,
            error,
        };
    }
}

function getDataChartCHTeam(filter_chart) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartCHTeam(filter_chart)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_CH_TEAM_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_DATA_CHART_CH_TEAM_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_CH_TEAM_ERROR,
            error,
        };
    }
}

function getDataChartAllTeam(filter_chart) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartAllTeam(filter_chart)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_ALL_TEAM_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_DATA_CHART_ALL_TEAM_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_ALL_TEAM_ERROR,
            error,
        };
    }
}


function getDataChartDetail(filter_chart) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChartDetail(filter_chart)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_DETAIL_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_DATA_CHART_DETAIL_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_DETAIL_ERROR,
            error,
        };
    }
}

function getDataChart(filter_chart) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getDataChart(filter_chart)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GET_DATA_CHART_REQUEST,
        };
    }
    function success(data_chart) {
        return {
            type: taskConstants.GET_DATA_CHART_SUCCESS,
            data_chart,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GET_DATA_CHART_ERROR,
            error,
        };
    }
}

function dropTaskCustomer() {
    return {
        type: taskConstants.DROP_TASK_CLIENT
    }
}

function getAllTaskForCustomer(id_client) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllTaskForCustomer(id_client)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_TASK_OF_CUSTOMER_REQUEST,
        };
    }
    function success(allTask) {
        return {
            type: taskConstants.GETALL_TASK_OF_CUSTOMER_SUCCESS,
            allTask,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_TASK_OF_CUSTOMER_FAILURE,
            error,
        };
    }
}

function getAllPlaningTaskOfProject(id_projet) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllPlaningTaskOfProject(id_projet)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_PROJECT_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_PROJECT_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_PROJECT_FAILURE,
            error,
        };
    }
}

function getAllPlaningTaskOfUser(id_equipe_membre) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllPlaningTaskOfUser(id_equipe_membre)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_USER_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_USER_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_USER_FAILURE,
            error,
        };
    }
}

function getAllPlaningTaskOfTeam(id_equipe) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllPlaningTaskOfTeam(id_equipe)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_TEAM_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_TEAM_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_PLANING_TASK_OF_TEAM_FAILURE,
            error,
        };
    }
}

function reset_under_task() {
    return {
        type: taskConstants.RESET_UNDER_TASK,
    };
}

function reset_task_logs() {
    return {
        type: taskConstants.RESET_TASK_LOGS,
    };
}

function delDataChartTeam() {
    return {
        type: taskConstants.RESET_CHART_DATA_EQUIPE,
    };
}

function deleteUnderTask(under_task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .deleteUnderTask(under_task)
            .then((res) => {
                dispatch(success(res.data.message, under_task));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.DELETE_UNDER_TASK_REQUEST,
        };
    }
    function success(under_task, id_task) {
        return {
            type: taskConstants.DELETE_UNDER_TASK_SUCCESS,
            under_task,
            id_task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.DELETE_UNDER_TASK_FAILURE,
            error,
        };
    }
}

function getAll() {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_FAILURE,
            error,
        };
    }
}

function add(task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .add(task)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                // dispatch(alertActions.failure("Echec d'ajout !"));
                dispatch(alertActions.failure(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
        function request() {
            return {
                type: taskConstants.ADD_TASK_REQUEST,
            };
        }
        function success(task) {
            return {
                type: taskConstants.ADD_TASK_SUCCESS,
                task,
            };
        }
        function failure(error) {
            return {
                type: taskConstants.ADD_TASK_FAILURE,
                error,
            };
        }
    };
}

function update(task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .update(task)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.UPDATE_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.UPDATE_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.UPDATE_FAILURE,
            error,
        };
    }
}

function selectedTask(task) {
    return {
        type: taskConstants.SELECTED_TASK_SUCCESS,
        task,
    };
}

function getAllTaskLogs(id_task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllTaskLogs(id_task)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_TASK_LOG_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_TASK_LOG_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_TASK_LOG_FAILURE,
            error,
        };
    }
}

function addTaskLog(task_log) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .addTaskLog(task_log)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Tâche déplacée avec succès"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                // dispatch(alertActions.failure("Echec d'ajout !"));
                dispatch(alertActions.failure(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.ADD_TASK_LOG_REQUEST,
        };
    }
    function success(task_log) {
        return {
            type: taskConstants.ADD_TASK_LOG_SUCCESS,
            task_log,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.ADD_TASK_LOG_FAILURE,
            error,
        };
    }
}

function getAllUnderTask(id_task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllUnderTask(id_task)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });

        function request() {
            return {
                type: taskConstants.GETALL_UNDER_TASK_REQUEST,
            };
        }
        function success(task) {
            return {
                type: taskConstants.GETALL_UNDER_TASK_SUCCESS,
                task,
            };
        }
        function failure(error) {
            return {
                type: taskConstants.GETALL_UNDER_TASK_FAILURE,
                error,
            };
        }
    };
}

function addUnderTask(task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .add(task)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(
                    projectActions.increment_under_task_number(
                        parseInt(res.data.message.id_parent)
                    )
                );
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.ADD_UNDER_TASK_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.ADD_UNDER_TASK_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.ADD_UNDER_TASK_FAILURE,
            error,
        };
    }
}

function updateUnderTask(id_task) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .updateUnderTask(id_task)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.UPDATE_UNDER_TASK_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.UPDATE_UNDER_TASK_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.UPDATE_UNDER_TASK_FAILURE,
            error,
        };
    }
}

function getAllUserTask(id_user) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .getAllUserTask(id_user)
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GETALL_USER_TASK_REQUEST,
        };
    }
    function success(task) {
        return {
            type: taskConstants.GETALL_USER_TASK_SUCCESS,
            task,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GETALL_USER_TASK_FAILURE,
            error,
        };
    }
}

function generateTaskFromTicket(tickets) {
    return function (dispatch) {
        dispatch(request());
        taskServices
            .generateTaskFromTicket(tickets)
            .then((res) => {
                //dispatch(success(res.data.message));
                dispatch(ticketActions.filter_from_task(res.data.message))
                dispatch(alertActions.success("Création des tâches effectuée !"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error(err.data.message));
                if (err.response !== undefined && err.response.status === 403) {
                    dispatch(loginActions.logout());
                }
            });
    };
    function request() {
        return {
            type: taskConstants.GENERATE_TASK_FROM_TICKET_REQUEST,
        };
    }
    function success(tickets) {
        return {
            type: taskConstants.GENERATE_TASK_FROM_TICKET_SUCCESS,
            tickets,
        };
    }
    function failure(error) {
        return {
            type: taskConstants.GENERATE_TASK_FROM_TICKET_FAILURE,
            error,
        };
    }
}
