import React, { useState } from 'react';
import {
    Row,
    Col,
    Spin,
    Empty,
    Table,
    Button,
    Input,
    Space,
    Typography,
} from 'antd';
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import {
    SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";



var { SearchInput } = Input;
const { Text } = Typography;


const ChartEACHDetail = ({ CHuser, chooseTeam }) => {

    const [searchText, setSearchText] = useState();
    const [searchedColumn, setSearchedColumn] = useState();

    const loading_chart_equipe = useSelector((state) => state.task.loading_chart_equipe);
    const items_chartEA_project = useSelector((state) => state.task.items_chartEA_project);

    //Fonction de recherche
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        SearchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: "100%", marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 110 }}
                    >
                        Rechercher
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 110 }}
                    >
                        Réinitialiser
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => SearchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };


    const columns = [
        {
            ...getColumnSearchProps("projet"),
            title: <Text strong={true}>Projets</Text>,
            dataIndex: 'projet',
            render: text => <Text strong={true}>{text}</Text>,
            width: '24%',
        },
        {
            title: <Text strong={true}>Totale tâche</Text>,
            dataIndex: 'total_tache',
            align: 'center',
            width: '24%',
        },
        {
            title: <Text strong={true}>To validate</Text>,
            dataIndex: 'total_tache_execute',
            align: 'center',
            width: '20%',
        },
        {
            title: <Text strong={true}>Done</Text>,
            dataIndex: 'total_tache_terminate',
            align: 'center',
            width: '20%',
        },
        {
            title: <Text strong={true}>État (%)</Text>,
            dataIndex: 'etat',
            align: 'center',
            render: text => <Text style={{
                color: text > 50 ? 'green' : 'red'
            }} strong={true}>{text}</Text>,
        },
    ];

    // const data = [
    //     {
    //         key: '1',
    //         name: 'John Brown',
    //         money: '￥300,000.00',
    //         address: 'New York No. 1 Lake Park',
    //     },
    //     {
    //         key: '2',
    //         name: 'Jim Green',
    //         money: '￥1,256,000.00',
    //         address: 'London No. 1 Lake Park',
    //     },
    //     {
    //         key: '3',
    //         name: 'Joe Black',
    //         money: '￥120,000.00',
    //         address: 'Sidney No. 1 Lake Park',
    //     },
    // ];

    const optionsGrouped = {
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 176,
            }
        },
    }

    return (
        <>
            <Row>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart_equipe}
                    >
                        <h3 style={{ textAlign: 'center' }}>TABLEAU DES ETATS D'AVANCEMENT DES PROJETS</h3>
                        <div style={{ marginLeft: 5, marginRight: 15 }}>
                            {items_chartEA_project.length > 0 ? (
                                <Table
                                    columns={columns}
                                    dataSource={items_chartEA_project}
                                    bordered
                                    size="small"
                                    title={() => "Équipe : " + chooseTeam.libelle}
                                    pagination={{ pageSize: 5 }}
                                />
                            ) : (
                                <Empty description={false} />
                            )}
                        </div>
                    </Spin>
                </Col>
                <Col span={12}>
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={loading_chart_equipe}
                    >
                        <h3 style={{ textAlign: 'center' }}>LES CHARGES HORAIRES DU MOIS (176h) PAR UTILISATEURS DE L'ÉQUIPES</h3>
                        <div style={{ marginLeft: 15, marginRight: 15 }}>
                            {Object.keys(CHuser).length > 0 ? (
                                <Bar
                                    height={380}
                                    data={CHuser}
                                    options={optionsGrouped}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            ) : (
                                <>
                                    <Empty description={false} />
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>
        </>
    )
}

export default ChartEACHDetail;