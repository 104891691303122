import jsPDF from "jspdf";
const CryptoJS = require("crypto-js");

// Cette fonction permet de genere l'avatar a partir du nom et prenom.
export const makeAvatar = (value) => {
    if (value !== null && value !== undefined) {
        let tab = value.split(" ");
        let firstLetter = tab[0].substr(0, 1);
        let SecondLetter =
            tab[1] === undefined ? firstLetter : tab[1].substr(0, 1);
        return firstLetter.toUpperCase() + "." + SecondLetter.toUpperCase();
    } else {
        return null;
    }
};

export const encryptWithAES = (text) => {
    const passphrase = "123";
    var acoder = CryptoJS.AES.encrypt(text.toString(), passphrase).toString();
    let tab = acoder.split("");
    var i = 0;
    while (i < tab.length) {
        if (tab[i] === "/") {
            return encryptWithAES(text);
        }
        i++;
    }

    return tab.join("");
};

export const decryptWithAES = (ciphertext) => {
    const passphrase = "123";
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};

const myFormatDate = (inputDate) => {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return (
            date.getDate() +
            "/" +
            (parseInt(date.getMonth()) + 1) +
            "/" +
            date.getFullYear()
        );
    }
};

const dureeTotal = (durre) => {
    let sum = 0;
    let i = 0;

    while (i < durre.length) {
        sum = sum + parseFloat(durre[i]["duree"]);
        i++;
    }

    let result = parseFloat(sum) / 8;

    return result.toFixed(2);
};


export const ImprimerDemandeCotation = (demande) => {
    let cotations = demande.cotations;
    let tab = [];
    cotations.map((p) =>
        tab.push([p.module, p.tache, p.description, p.duree, p.observation])
    );

    const doc = new jsPDF();

    var num;
    if (parseInt(demande.id_demande) < 10) {
        num = `000${demande.id_demande}`;
    } else if (
        parseInt(demande.id_demande) >= 10 &&
        parseInt(demande.id_demande) < 100
    ) {
        num = `00${demande.id_demande}`;
    } else if (parseInt(demande.id_demande) >= 100) {
        num = `0${demande.id_demande}`;
    } else {
        num = `${demande.id_demande}`;
    }

    doc.setFontSize(14);
    doc.text(`FICHE DE COTATION  N° ${num}`, 70, 15);

    doc.setFontSize(10);
    doc.text(`Projet : ${demande.libelle_projet}`, 10, 23);

    var equipe;
    if (demande.libelle_equipe !== null) {
        equipe = demande.libelle_equipe;
    } else {
        equipe = "___";
    }
    doc.setFontSize(10);
    doc.text(`Équipe : ${equipe}`, 10, 31);

    var client;
    if (demande.nom_client !== null) {
        client = demande.nom_client;
    } else {
        client = "___";
    }

    doc.setFontSize(10);
    doc.text(`Client : ${client}`, 10, 39);

    var respon_non;
    if (demande.first_name !== null && demande.first_name !== undefined) {
        respon_non = demande.first_name;
    } else {
        respon_non = "___";
    }

    doc.setFontSize(10);
    doc.text(`Responsable : ${respon_non}`, 140, 23);

    doc.setFontSize(10);
    doc.text(`Date d'édition : ${myFormatDate(demande.date_edition)}`, 140, 31);

    doc.setFontSize(10);
    doc.text(
        `Durée total : ${dureeTotal(demande.cotations)} ${parseInt(dureeTotal(demande.cotations)) <= 1 ? "jour" : "jours"
        }`,
        140,
        39
    );

    doc.autoTable({ startY: 38 });

    doc.autoTable({
        style: {
            padding: 10,
            marginTop: 100,
            columnWidth: "wrap",
        },
        margin: { horizontal: 10 },
        bodyStyles: { fontSize: 8, textColor: "black" },
        columnStyles: {
            0: { cellWidth: 38 },
            1: { cellWidth: 80 },
            2: { cellWidth: 57 },
            3: { cellWidth: 15 },
            // 4: { cellWidth: 30 },
        },
        head: [
            ["Module", "tâche", "Description", "Durée" /*, "Observation"*/]
        ],
        body: tab,
    });

    doc.save("demande_cotation_.pdf");
};