import { statut_demandeConstants } from "../_constants";
import { statut_demandeServices } from "../_services";
import { alertActions } from "../_actions";

export const statut_demandeActions = {
    getAll,
    add,
    update,
    remove,
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        statut_demandeServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: statut_demandeConstants.GETALL_STATUT_DEMANDE_REQUEST,
        };
    }
    function success(statut_demande) {
        return {
            type: statut_demandeConstants.GETALL_STATUT_DEMANDE_SUCCESS,
            statut_demande,
        };
    }
    function failure(error) {
        return {
            type: statut_demandeConstants.GETALL_STATUT_DEMANDE_FAILURE,
            error,
        };
    }
}

function add(statut_demande) {
    return function (dispatch) {
        dispatch(request());
        statut_demandeServices
            .add(statut_demande)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Ajout effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec d'ajout"));
            });
    };
    function request() {
        return {
            type: statut_demandeConstants.ADD_STATUT_DEMANDE_REQUEST,
        };
    }
    function success(statut_demande) {
        return {
            type: statut_demandeConstants.ADD_STATUT_DEMANDE_SUCCESS,
            statut_demande,
        };
    }
    function failure(error) {
        return {
            type: statut_demandeConstants.ADD_STATUT_DEMANDE_FAILURE,
            error,
        };
    }
}

function update(statut_demande) {
    return function (dispatch) {
        dispatch(request());
        statut_demandeServices
            .update(statut_demande)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification"));
            });
    };
    function request() {
        return {
            type: statut_demandeConstants.UPDATE_STATUT_DEMANDE_REQUEST,
        };
    }
    function success(statut_demande) {
        return {
            type: statut_demandeConstants.UPDATE_STATUT_DEMANDE_SUCCESS,
            statut_demande,
        };
    }
    function failure(error) {
        return {
            type: statut_demandeConstants.UPDATE_STATUT_DEMANDE_FAILURE,
            error,
        };
    }
}

function remove(statut_demande) {
    return function (dispatch) {
        dispatch(request());
        statut_demandeServices
            .remove(statut_demande)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppression effectuée"));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec suppression"));
            });
    };
    function request() {
        return {
            type: statut_demandeConstants.DELETE_STATUT_DEMANDE_REQUEST,
        };
    }
    function success(statut_demande) {
        return {
            type: statut_demandeConstants.DELETE_STATUT_DEMANDE_SUCCESS,
            statut_demande,
        };
    }
    function failure(error) {
        return {
            type: statut_demandeConstants.DELETE_STATUT_DEMANDE_FAILURE,
            error,
        };
    }
}
