import React, { Children, useEffect, useState } from "react";
import "./CustomerCotation.css";
import HeaderApp from "../../parchalse/HeaderApp";
import {
    Empty,
    Button,
    Typography,
    Spin,
    Space,
    Dropdown,
    Table,
    Tooltip,
    Popconfirm,
    Menu,
    Tag,
    Popover,
    Form,
    Input
} from "antd";
import {
    CheckOutlined,
    EditOutlined,
    PrinterOutlined,
    EyeOutlined,
    DeleteOutlined,
    IssuesCloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseSquareOutlined,
    RedoOutlined,
    RetweetOutlined,
    AuditOutlined,
    CarryOutOutlined,
    CheckSquareOutlined,
    SaveOutlined,
    TagsOutlined,
    PlusSquareOutlined
} from "@ant-design/icons";
import {
    customerActions,
    modalActions,
    demande_cotationActions,
    team_menberActions,
    projectActions,
    userActions,
    team_projectActions,
} from "../../../redux/_actions";
import { useSelector, useDispatch } from "react-redux";
import ClientsDrawer from "../../components/Drawer/ClientsDrawer";
import DrawerDemandeDeCotation from "../../components/Drawer/DrawerDemandeDeCotation";
// import FicheCotation from "../../Pdf/FicheCotation";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import ModalFilterDemandeCotation from "../../components/Modal/ModalFilterDemandeCotation";
import ModalValiderDemandeCotation from "../../components/Modal/ModalValiderDemandeCotation";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { parseInt } from "lodash";

// const { Option } = Select;

// const scr = {
//     y: 380,
// };

// const scrNest = {
//     y: "auto",
// };

const CustomerCotation = () => {

    const [formGlobal] = Form.useForm();


    // creation des constante
    // les differentes profils  
    const profil_admin = 1;
    const profil_chef_equipe_dev = 2;
    const profil_user_dev = 3;
    const profil_chef_equipe_com = 4;
    const profil_user_com = 5;

    // les differente statut pour une demande de cotation;
    const statut_initial = 1;
    const valider_chef_equipe = 2;
    const approuver_dt = 3;
    const statut_accepter_client = 4;
    const statut_envoyer_client = 5;
    const statut_refuser_client = 6;
    const statut_terminer_dmnde = 7;
    const statut_facturer_dmnde = 8;
    const statut_archiver_dmnde = 9;

    const [demandeSelected, setDemandeSelected] = useState({});
    const [demandeDetail, setDemandeDetail] = useState({});
    const [typeProjet, setTypeProjet] = useState(1);
    const [loadingCotation, setLoadingCotation] = useState(true);

    const [selectedDemande, setSelectedDemande] = useState(0);

    const dispatch = useDispatch();
    const { Text } = Typography;

    // recuperation de toutes les demandes de cotation
    const demande_cotations = useSelector(
        (state) => state.demande_cotation.items
    );

    // recuperation des informations du client choisi
    const customerChoose = useSelector((state) => state.customer.item_choose);

    // filtre et recuperation des demande de cotation uniquement pour le client choisi
    const demande_client = Object.keys(customerChoose).length > 0 ?
        demande_cotations.filter((p) => p.id_client === customerChoose.id_client) : [];


    const loading = useSelector((state) => state.demande_cotation.loading);
    const auth = useSelector((state) => state.login.item);

    const filtrerDemande = useSelector(
        (state) => state.demande_cotation.filtrerDemande
    );
    const isFiltered = useSelector(
        (state) => state.demande_cotation.isFiltered
    );
    const fileredData = useSelector(
        (state) => state.demande_cotation.fileredData
    );

    const user = useSelector((state) => state.login);
    const ability = defineAbilityFor(user);

    useEffect(() => {
        dispatch(customerActions.getAll());
        // dispatch(demande_cotationActions.getAll());
        dispatch(modalActions.close_add_demande_of_quotation());
        dispatch(demande_cotationActions.not_filtered_demande_of_quotation());
        dispatch(projectActions.getAll());
    }, []);

    const myFormatDate = (inputDate) => {
        const tab_date = inputDate.split('-');
        const date_return = tab_date[2] + "/" + tab_date[1] + "/" + tab_date[0];

        return date_return;
        // var date = new Date(inputDate);
        // if (!isNaN(date.getTime())) {
        //     // Months use 0 index.
        //     return (
        //         date.getDate() +
        //         "/" +
        //         (parseInt(date.getMonth()) + 1) +
        //         "/" +
        //         date.getFullYear()
        //     );
        // }
    };

    function handleMenuReponse(e) {

        if (selectedDemande !== 0) {
            // c'est la validation du client pour la demande de cotation 
            if (parseInt(e.key) === 1) {
                dispatch(modalActions.show_validate_cotation());
            }

            // refus par le client de la demande de cotation
            else if (parseInt(e.key) === 2) {
                dispatch(demande_cotationActions.refus_customer_quoting(selectedDemande));
            }

            // on vide la demande selectionnée
            // setSelectedDemande(0)
        }

    }

    function handleMenuRenvoi(e) {

        if (selectedDemande !== 0) {

            // c'est le renvoi de la fiche de cotation au client  
            if (parseInt(e.key) === 1) {
                dispatch(demande_cotationActions.validate_send_customer(selectedDemande));
            }

            // on vide la demande selectionnée
            setSelectedDemande(0)
        }

    }

    const [cotation, setCotation] = useState({});

    const onFinish = (values) => {
        dispatch(demande_cotationActions.faturer_cotation({
            'ref': values.ref,
            'id_demande': cotation.id_demande
        }));
    };

    const content = () => {

        if (Object.keys(cotation).length > 0) {

            let demande = demande_cotations.filter((p) => p.id_demande === cotation.id_demande)[0];

            if (demande.ref_facture !== null) {
                formGlobal.setFieldsValue({
                    ref: demande.ref_facture,
                });
            } else {
                formGlobal.resetFields();
            }
        }

        return (
            <div>
                <Form
                    form={formGlobal}
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="ref" rules={[{ required: true, message: 'la référence est obligatoire!' }]}>
                        <Input style={{ width: 140 }} placeholder="la référence... " />
                    </Form.Item>
                    {/* <Form.Item name="id" rules={[{ required: true, message: 'la référence est obligatoire!' }]}>
                        <Input style={{ width: 140 }} placeholder="la référence... " />
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    const handleTitle = () => {
        return (
            <>
                <span>Référence de la facture</span>
                {/* <CloseOutlined style={{ cursor: 'pointer', float: 'right', marginTop: 5 }} /> */}
            </>
        );
    }

    // function de recuperation de la reponse du client pour traitement
    function handleMenuTache(e) {

        // c'est la validation du client pour la demande de cotation 
        if (parseInt(e.key) === 1) {
            dispatch(demande_cotationActions.create_tache_cotation({
                id: selectedDemande,
                etat: 1
            }));
        }

        // refus par le client de la demande de cotation
        else if (parseInt(e.key) === 2) {
            dispatch(demande_cotationActions.create_tache_cotation({
                id: selectedDemande,
                etat: 0
            }));
        }

    }

    // le menu du drawdrop pour la reponse du client 
    const menuTache = (
        <Menu onClick={(values) => { handleMenuTache(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Valider par le client'}>
                    <CheckCircleOutlined style={{ color: "#82dd8c", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: '#82dd8c', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Créer
                    </Button>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="2">
                <Tooltip placement="rightBottom" title={'Refuser par le client'}>
                    <CloseCircleOutlined style={{ color: "red", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Déjà créer
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    const menu = (
        <Menu onClick={(values) => { handleMenuReponse(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Valider par le client'}>
                    <CheckCircleOutlined style={{ color: "#82dd8c", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: '#82dd8c', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Valider
                    </Button>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="2">
                <Tooltip placement="rightBottom" title={'Refuser par le client'}>
                    <CloseCircleOutlined style={{ color: "red", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Rejeter
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    const menuRefus = (
        <Menu onClick={(values) => { handleMenuRenvoi(values) }}>
            <Menu.Item key="1">
                <Tooltip placement="rightBottom" title={'Renvoyer la cotation au client'}>
                    <RedoOutlined style={{ color: "orange", fontWeight: 'bold', fontSize: 18 }} />
                    <Button
                        type="link"
                        size="small"
                        style={{ color: 'black', fontWeight: 'bold', fontSize: 14 }}
                    >
                        Renvoyer
                    </Button>
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "ke",
            width: '40px',
            // render(text, record) {
            //     return {
            //         props: {
            //             style: {
            //                 background: record.color_statut,
            //                 padding: '0px'
            //             }
            //         },
            //     };
            // }
        },
        {
            title: "Libellé",
            dataIndex: "libelle_demande",
            key: "libelle_demande",
            render: (record) => (record === null ? "..." : record.length > 30 ? (
                <>
                    <Tooltip placement="top" title={record}>
                        {record.substr(0, 30) + '...'}
                    </Tooltip>
                </>
            ) : record),
            width: 250,
        },
        {
            title: "Projet",
            dataIndex: "libelle_projet",
            key: "libelle_projet",
            render: (record) => (record === null ? "..." : record.length > 20 ? (
                <>
                    <Tooltip placement="top" title={record}>
                        {record.substr(0, 20) + '...'}
                    </Tooltip>
                </>
            ) : record),
            width: 200,
        },
        {
            title: "Équipe",
            dataIndex: "libelle_equipe",
            key: "libelle_equipe",
            render: (record) => (record === null ? "---" : record),
            width: 100,
        },
        {
            title: "État",
            dataIndex: "libelle_statut_demande",
            key: "libelle_statut_demande",
            width: 100,
            render: (text, record) => (
                <>
                    <Tag color={record.color_statut} style={{ width: 90, textAlign: "center", fontWeight: "bold" }}>
                        {text.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: "Progression",
            dataIndex: "etat_demande",
            key: "etat_demande",
            width: 100,
            render: (text, record) => (
                <>
                    <Tag
                        color={record.etat_demande > 0 ? "green" : 'orange'}
                        style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        {record.etat_demande} %
                    </Tag>
                </>
            ),
        },
        {
            title: "Date d'édition",
            dataIndex: "date_edition",
            key: "date_edition",
            render: (record) => myFormatDate(record),
            width: 110,
        },
        {
            title: "Action",
            key: "operation",
            width: 150,
            render: (record) => (
                <>
                    <Space>
                        <Button
                            title="Détail"
                            type="default"
                            style={{ backgroundColor: "#fafafa" }}
                            icon={<EyeOutlined />}
                            size="small"
                            onClick={() => {
                                setDemandeDetail(record);
                                setTypeProjet(record.id_type);
                                setSelectedDemande(record.id_demande)
                                dispatch(
                                    modalActions.show_add_demande_of_quotation()
                                );
                                if (record.id_user !== null) {
                                    dispatch(
                                        userActions.getAllMemberProject(
                                            parseInt(record.id_projet)
                                        )
                                    );
                                }
                            }}
                        />
                        {/* modifier un demande */}
                        {record.id_statut !== statut_accepter_client & record.id_statut !== statut_envoyer_client & record.id_statut !== statut_terminer_dmnde & record.id_statut !== statut_facturer_dmnde & record.id_statut !== statut_archiver_dmnde ? (
                            <Can
                                I="ModifierDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Button
                                    title="Modifier"
                                    type="default"
                                    style={{ backgroundColor: "#f0e68c" }}
                                    icon={<EditOutlined />}
                                    size="small"
                                    onClick={() => {
                                        setDemandeSelected(record);
                                        setTypeProjet(record.id_type);
                                        if (record.id_equipe !== null) {
                                            dispatch(
                                                team_menberActions.getAllTeamMenber(
                                                    {
                                                        id_equipe: parseInt(
                                                            record.id_equipe
                                                        ),
                                                    }
                                                )
                                            );
                                        }
                                        if (record.id_user !== null) {
                                            dispatch(
                                                userActions.getAllMemberProject(
                                                    parseInt(record.id_projet)
                                                )
                                            );
                                        }
                                        dispatch(
                                            modalActions.show_add_demande_of_quotation()
                                        );
                                    }}
                                />
                            </Can>
                        ) : null}
                        {/* les differents type de validation d'une cotation */}
                        {record.id_statut === statut_initial ? (
                            < Can
                                I="ValiderDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir valider cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.validation_team_chef(
                                                record.id_demande
                                            )
                                        )

                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                    placement="top"
                                >
                                    <Button
                                        title="Valider"
                                        type="default"
                                        style={{ backgroundColor: "#82dd8c" }}
                                        // icon={<CheckOutlined />}
                                        size="small"
                                    >V</Button>
                                </Popconfirm>
                            </Can>
                        ) : record.id_statut === valider_chef_equipe ? (
                            <Can
                                I="ApprouverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir approver cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.validation_dt(
                                                record.id_demande
                                            )
                                        )
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                    placement="top"
                                >
                                    <Button
                                        title="Approuver"
                                        type="default"
                                        style={{ backgroundColor: "#2029ad", color: "#fff" }}
                                        // icon={<CheckSquareOutlined style={{ color: '#000' }} />}
                                        size="small"
                                    >A</Button>
                                </Popconfirm>
                            </Can>
                        ) : record.id_statut === approuver_dt || record.id_statut === statut_envoyer_client || record.id_statut === statut_refuser_client ? (
                            <Can
                                I="TerminerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                {(parseInt(record.id_statut) === approuver_dt) ?
                                    (
                                        <>
                                            <Tooltip placement="top" title={'Cotation à envoyer au client'}>
                                                <Popconfirm
                                                    title="Vous confirmez vouloir envoyé cette demande de cotation au client ?"
                                                    onConfirm={() =>
                                                        dispatch(
                                                            demande_cotationActions.validate_send_customer(
                                                                record.id_demande
                                                            )
                                                        )
                                                    }
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    placement="top"
                                                >
                                                    <Button
                                                        title="Terminer"
                                                        type="default"
                                                        style={{ backgroundColor: "#f0e68c" }}
                                                        // icon={<RetweetOutlined />}
                                                        size="small"
                                                    >E</Button>
                                                </Popconfirm>
                                            </Tooltip>
                                        </>
                                    ) :
                                    //  si c'est envoyer au client, alors on deux choix (refuser ou accepter)
                                    (parseInt(record.id_statut) === statut_envoyer_client) ?
                                        (
                                            <>
                                                <Tooltip placement="rightBottom" title={'Réponse du client ?'}>
                                                    <Dropdown
                                                        overlay={menu}
                                                        placement="bottomRight"
                                                        trigger="click"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedDemande(record.id_demande)
                                                            }}
                                                            type="default"
                                                            style={{ backgroundColor: "orange" }}
                                                            icon={<IssuesCloseOutlined />}
                                                            size="small"
                                                        >
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                            </>
                                        ) :
                                        //  si c'est refuser par le client 
                                        (parseInt(record.id_statut) === statut_refuser_client) ?
                                            (
                                                <>
                                                    <Tooltip placement="top" title={'Refuser par le client'}>
                                                        <Dropdown
                                                            overlay={menuRefus}
                                                            placement="bottomRight"
                                                            trigger="click"
                                                        >
                                                            <Button
                                                                title="Renvoyer"
                                                                onClick={() => {
                                                                    setSelectedDemande(record.id_demande)
                                                                }}
                                                                type="default"
                                                                style={{ backgroundColor: "#ff6347" }}
                                                                icon={<CloseSquareOutlined style={{ color: 'white' }} />}
                                                                size="small"
                                                            >
                                                            </Button>
                                                        </Dropdown>
                                                    </Tooltip>
                                                </>
                                            )
                                            // si c'est accepter par le client
                                            : (parseInt(record.id_statut) === statut_accepter_client) ?
                                                (
                                                    <>
                                                        <Tooltip placement="top" title={'Cette cotation à été valider'}>
                                                            <Button
                                                                type="default"
                                                                style={{ backgroundColor: "#82dd8c" }}
                                                                icon={<CheckOutlined />}
                                                                size="small"
                                                                disabled={true}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                ) : null
                                }
                            </Can>
                        ) : record.id_statut === statut_accepter_client ? (
                            < Can
                                I="TerminerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popconfirm
                                    title="Êtes-vous sûr d'avoir terminer cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.terminate_demande(
                                                record.id_demande
                                            )
                                        )
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                    placement="top"
                                >
                                    <Button
                                        title="Terminer"
                                        type="default"
                                        style={{ backgroundColor: "#118ab2" }}
                                        icon={<CarryOutOutlined style={{ color: 'white' }} />}
                                        size="small"
                                    />
                                </Popconfirm>
                            </Can>
                        ) : record.id_statut === statut_terminer_dmnde ? (
                            < Can
                                I="FacturerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popover
                                    content={content}
                                    title={handleTitle}
                                    trigger="click"
                                    width="130"
                                >
                                    <Button
                                        title="Facturer"
                                        type="default"
                                        style={{ backgroundColor: "#caf0f8" }}
                                        icon={<AuditOutlined style={{ color: '#000' }} />}
                                        size="small"
                                        onClick={() => {
                                            setCotation(record);
                                        }}
                                    />
                                </Popover>
                            </Can>
                        ) : record.id_statut === statut_facturer_dmnde ? (
                            < Can
                                I="FacturerDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popover
                                    content={content}
                                    title={handleTitle}
                                    trigger="click" width="130"
                                >
                                    <Button
                                        type="default"
                                        style={{ backgroundColor: "#caf0f8" }}
                                        icon={<AuditOutlined style={{ color: '#000' }} />}
                                        size="small"
                                        onClick={() => { setCotation(record) }}
                                    />
                                </Popover>
                            </Can>
                        ) : null}
                        {/* imprimer une demande */}
                        <Can
                            I="ImprimerDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            <Popconfirm
                                title="Êtes-vous sûr de vouloir imprimer cette demande de cotation ?"
                                onConfirm={() =>
                                    ImprimerDemandeCotation(record)
                                }
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button
                                    title="Imprimer"
                                    type="primary"
                                    icon={<PrinterOutlined />}
                                    size="small"
                                />
                            </Popconfirm>
                        </Can>
                        {/* permet de la creation des taches de la demande*/}
                        <Can
                            I="create_tache"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            {parseInt(record.is_create) === 1 ? (
                                <Tooltip placement="topLeft" title={'Création des tâches ?'}>
                                    <Dropdown
                                        overlay={menuTache}
                                        placement="bottomRight"
                                        trigger="click"
                                    >
                                        <Button
                                            title="Création des taches"
                                            onClick={() => {
                                                setSelectedDemande(record.id_demande)
                                            }}
                                            type="default"
                                            style={{ backgroundColor: "orange" }}
                                            icon={<PlusSquareOutlined />}
                                            size="small"
                                        >
                                        </Button>
                                    </Dropdown>
                                </Tooltip>
                            ) : null}
                        </Can>
                        {/* Suppression de la demande */}
                        <Can
                            I="SupprimerDemandeCotation"
                            a="Fiche de cotation"
                            ability={ability}
                        >
                            {parseInt(record.id_statut) === 1 ? (
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir supprimer cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.delete_demande_of_quotation(
                                                record.id_demande
                                            )
                                        )
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        title="Supprimer"
                                        style={{
                                            backgroundColor: "#ff6347",
                                            color: "white",
                                        }}
                                        icon={<DeleteOutlined />}
                                        size="small"
                                    />
                                </Popconfirm>
                            ) : null}
                        </Can>
                        {/* Archiver une cotation */}
                        {record.id_statut === statut_facturer_dmnde || record.id_statut === statut_refuser_client ? (
                            <Can
                                I="ArchiverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir archiver cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.archiver_demande_of_quotation(
                                                record.id_demande, 1
                                            )
                                        )
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        title="Archiver"
                                        style={{
                                            backgroundColor: "#10002b",
                                            color: "white",
                                        }}
                                        icon={<TagsOutlined />}
                                        size="small"
                                    />
                                </Popconfirm>
                            </Can>
                        ) : record.id_statut === statut_archiver_dmnde ? (
                            <Can
                                I="ArchiverDemandeCotation"
                                a="Fiche de cotation"
                                ability={ability}
                            >
                                <Popconfirm
                                    title="Êtes-vous sûr de vouloir Désarchiver cette demande de cotation ?"
                                    onConfirm={() =>
                                        dispatch(
                                            demande_cotationActions.archiver_demande_of_quotation(
                                                record.id_demande, 0
                                            )
                                        )
                                    }
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        style={{
                                            backgroundColor: "#1bb02b",
                                            color: "white",
                                        }}
                                        icon={<TagsOutlined />}
                                        size="small"
                                    />
                                </Popconfirm>
                            </Can>
                        ) : null}
                    </Space>
                </>
            ),
        },
        // {
        //     title: "Action",
        //     key: "operation",
        //     width: 40,
        //     render: (record) => (
        //         <>
        //             <Space>
        //                 <Button
        //                     type="default"
        //                     style={{ backgroundColor: "#fafafa" }}
        //                     icon={<EyeOutlined />}
        //                     size="small"
        //                     onClick={() => {
        //                         setDemandeDetail(record);
        //                         setTypeProjet(record.id_type);
        //                         dispatch(
        //                             modalActions.show_add_demande_of_quotation()
        //                         );
        //                         if (record.id_user !== null) {
        //                             dispatch(
        //                                 userActions.getAllMemberProject(
        //                                     parseInt(record.id_projet)
        //                                 )
        //                             );
        //                         }
        //                     }}
        //                 />
        //                 <Can
        //                     I="ModifierDemandeCotation"
        //                     a="Fiche de cotation"
        //                     ability={ability}
        //                 >
        //                     {(auth.id_profil === profil_admin &&
        //                         (parseInt(record.id_statut) === statut_initial ||
        //                             parseInt(record.id_statut) === valider_chef_equipe ||
        //                             parseInt(record.id_statut) === valider_dir_technique ||
        //                             parseInt(record.id_statut) === statut_refuser_client)) ||
        //                         ((parseInt(auth.id_profil) === profil_chef_equipe_dev ||
        //                             parseInt(auth.id_profil) === profil_user_com) &&
        //                             (parseInt(record.id_statut) === statut_initial ||
        //                                 parseInt(record.id_statut) === valider_chef_equipe ||
        //                                 parseInt(record.id_statut) === valider_dir_technique ||
        //                                 parseInt(record.id_statut) === statut_refuser_client)) ||
        //                         (parseInt(auth.id_profil) === profil_chef_equipe_com &&
        //                             parseInt(record.id_statut) === statut_initial &&
        //                             parseInt(record.created_by) ===
        //                             parseInt(auth.id_user)) ||
        //                         (parseInt(auth.id_profil) === profil_user_dev &&
        //                             parseInt(auth.id_user) ===
        //                             parseInt(record.id_responsable_demande) &&
        //                             parseInt(record.id_statut) === statut_initial) ? (
        //                         <Button
        //                             type="default"
        //                             style={{ backgroundColor: "#f0e68c" }}
        //                             icon={<EditOutlined />}
        //                             size="small"
        //                             onClick={() => {
        //                                 setDemandeSelected(record);
        //                                 setTypeProjet(record.id_type);
        //                                 if (record.id_equipe !== null) {
        //                                     dispatch(
        //                                         team_menberActions.getAllTeamMenber(
        //                                             {
        //                                                 id_equipe: parseInt(
        //                                                     record.id_equipe
        //                                                 ),
        //                                             }
        //                                         )
        //                                     );
        //                                 }
        //                                 if (record.id_user !== null) {
        //                                     dispatch(
        //                                         userActions.getAllMemberProject(
        //                                             parseInt(record.id_projet)
        //                                         )
        //                                     );
        //                                 }
        //                                 dispatch(
        //                                     modalActions.show_add_demande_of_quotation()
        //                                 );
        //                             }}
        //                         />
        //                     ) : null}
        //                 </Can>
        //                 <Can
        //                     I="ValiderDemandeCotation"
        //                     a="Fiche de cotation"
        //                     ability={ability}
        //                 >
        //                     {(parseInt(auth.id_profil) === profil_admin &&
        //                         (parseInt(record.id_statut) === statut_initial ||
        //                             parseInt(record.id_statut) === valider_chef_equipe)) ||
        //                         ((parseInt(auth.id_profil) === profil_chef_equipe_dev ||
        //                             parseInt(auth.id_profil) === profil_user_com) &&
        //                             parseInt(record.id_statut) === statut_initial) ?
        //                         (
        //                             <Popconfirm
        //                                 title="Êtes-vous sûr de vouloir valider cette demande de cotation ?"
        //                                 onConfirm={() =>
        //                                     auth.id_profil === profil_admin
        //                                         ? dispatch(
        //                                             demande_cotationActions.validation_dt(
        //                                                 record.id_demande
        //                                             )
        //                                         )
        //                                         : auth.id_profil === profil_chef_equipe_dev ||
        //                                             auth.id_profil === profil_user_com
        //                                             ? dispatch(
        //                                                 demande_cotationActions.validation_team_chef(
        //                                                     record.id_demande
        //                                                 )
        //                                             )
        //                                             : auth.id_profil === profil_chef_equipe_com
        //                                                 ? dispatch(
        //                                                     demande_cotationActions.validate(
        //                                                         record.id_demande
        //                                                     )
        //                                                 )
        //                                                 : null
        //                                 }
        //                                 okText="Oui"
        //                                 cancelText="Non"
        //                                 placement="top"
        //                             >
        //                                 <Button
        //                                     type="default"
        //                                     style={{ backgroundColor: "#82dd8c" }}
        //                                     icon={<CheckOutlined />}
        //                                     size="small"
        //                                 />
        //                             </Popconfirm>
        //                         ) : (parseInt(auth.id_profil) === profil_chef_equipe_com) ?
        //                             (
        //                                 // si c'est valider par le directeur technique et on doit l'envoyer au client
        //                                 (parseInt(record.id_statut) === valider_dir_technique) ?
        //                                     (
        //                                         <>
        //                                             <Tooltip placement="top" title={'Cotation à envoyer au client'}>
        //                                                 <Popconfirm
        //                                                     title="Vous confirmez vouloir envoyé cette demande de cotation au client ?"
        //                                                     onConfirm={() =>
        //                                                         auth.id_profil === profil_chef_equipe_com
        //                                                             ? dispatch(
        //                                                                 demande_cotationActions.validate_send_customer(
        //                                                                     record.id_demande
        //                                                                 )
        //                                                             )
        //                                                             : null
        //                                                     }
        //                                                     okText="Oui"
        //                                                     cancelText="Non"
        //                                                     placement="top"
        //                                                 >
        //                                                     <Button
        //                                                         type="default"
        //                                                         style={{ backgroundColor: "#f0e68c" }}
        //                                                         icon={<RetweetOutlined />}
        //                                                         size="small"
        //                                                     />
        //                                                 </Popconfirm>
        //                                             </Tooltip>
        //                                         </>
        //                                     ) :
        //                                     //  si c'est envoyer au client, alors on deux choix (refuser ou accepter)
        //                                     (parseInt(record.id_statut) === statut_envoyer_client) ?
        //                                         (
        //                                             <>
        //                                                 <Tooltip placement="rightBottom" title={'Réponse du client ?'}>
        //                                                     <Dropdown
        //                                                         overlay={menu}
        //                                                         placement="bottomRight"
        //                                                         trigger="click"
        //                                                     >
        //                                                         <Button
        //                                                             onClick={() => {
        //                                                                 setSelectedDemande(record.id_demande)
        //                                                             }}
        //                                                             type="default"
        //                                                             style={{ backgroundColor: "orange" }}
        //                                                             icon={<IssuesCloseOutlined />}
        //                                                             size="small"
        //                                                         >
        //                                                         </Button>
        //                                                     </Dropdown>
        //                                                 </Tooltip>
        //                                             </>
        //                                         ) :
        //                                         //  si c'est refuser par le client 
        //                                         (parseInt(record.id_statut) === statut_refuser_client) ?
        //                                             (
        //                                                 <>
        //                                                     <Tooltip placement="top" title={'Refuser par le client'}>
        //                                                         <Dropdown
        //                                                             overlay={menuRefus}
        //                                                             placement="bottomRight"
        //                                                             trigger="click"
        //                                                         >
        //                                                             <Button
        //                                                                 onClick={() => {
        //                                                                     setSelectedDemande(record.id_demande)
        //                                                                 }}
        //                                                                 type="default"
        //                                                                 style={{ backgroundColor: "#ff6347" }}
        //                                                                 icon={<CloseSquareOutlined style={{ color: 'white' }} />}
        //                                                                 size="small"
        //                                                             >
        //                                                             </Button>
        //                                                         </Dropdown>
        //                                                     </Tooltip>
        //                                                 </>
        //                                             )
        //                                             // si c'est accepter par le client
        //                                             : (parseInt(record.id_statut) === statut_terminer) ?
        //                                                 (
        //                                                     <>
        //                                                         <Tooltip placement="top" title={'Cette cotation à été valider'}>
        //                                                             <Button
        //                                                                 type="default"
        //                                                                 style={{ backgroundColor: "#82dd8c" }}
        //                                                                 icon={<CheckOutlined />}
        //                                                                 size="small"
        //                                                                 disabled={true}
        //                                                             />
        //                                                         </Tooltip>
        //                                                     </>
        //                                                 ) : null
        //                             ) :
        //                             (
        //                                 null
        //                                 // <Button
        //                                 //     type="default"
        //                                 //     style={{ backgroundColor: "#82dd8c" }}
        //                                 //     icon={<CheckOutlined />}
        //                                 //     size="small"
        //                                 //     disabled={true}
        //                                 // />
        //                             )}
        //                 </Can>
        //                 <Can
        //                     I="ImprimerDemandeCotation"
        //                     a="Fiche de cotation"
        //                     ability={ability}
        //                 >
        //                     <Popconfirm
        //                         title="Êtes-vous sûr de vouloir imprimer cette demande de cotation ?"
        //                         onConfirm={() =>
        //                             ImprimerDemandeCotation(record)
        //                         }
        //                         okText="Oui"
        //                         cancelText="Non"
        //                     >
        //                         <Button
        //                             type="primary"
        //                             icon={<PrinterOutlined />}
        //                             size="small"
        //                         />
        //                     </Popconfirm>
        //                 </Can>
        //                 <Can
        //                     I="SupprimerDemandeCotation"
        //                     a="Fiche de cotation"
        //                     ability={ability}
        //                 >
        //                     {parseInt(record.id_statut) === 1 ? (
        //                         <Popconfirm
        //                             title="Êtes-vous sûr de vouloir supprimer cette demande de cotation ?"
        //                             onConfirm={() =>
        //                                 dispatch(
        //                                     demande_cotationActions.delete_demande_of_quotation(
        //                                         record.id_demande
        //                                     )
        //                                 )
        //                             }
        //                             okText="Oui"
        //                             cancelText="Non"
        //                         >
        //                             <Button
        //                                 style={{
        //                                     backgroundColor: "#ff6347",
        //                                     color: "white",
        //                                 }}
        //                                 icon={<DeleteOutlined />}
        //                                 size="small"
        //                             />
        //                         </Popconfirm>
        //                     ) : (
        //                         null
        //                         // <Button
        //                         //     disabled={true}
        //                         //     style={{
        //                         //         backgroundColor: "#ff6347",
        //                         //         color: "white",
        //                         //     }}
        //                         //     icon={<DeleteOutlined />}
        //                         //     size="small"
        //                         // />
        //                     )}
        //                 </Can>
        //             </Space>
        //         </>
        //     ),
        // },
    ];

    const dureeTotal = (durre) => {
        let sum = 0;
        let i = 0;

        while (i < durre.length) {
            sum = sum + durre[i]["duree"];
            i++;
        }
        return parseFloat(sum).toFixed(2);
    };


    const ImprimerDemandeCotation = (demande) => {
        let cotations = demande.cotations;
        let tab = [];
        cotations.map((p) =>
            tab.push([p.module, p.tache, p.description, p.duree, p.observation])
        );

        const doc = new jsPDF();

        var num;
        if (parseInt(demande.id_demande) < 10) {
            num = `000${demande.id_demande}`;
        } else if (
            parseInt(demande.id_demande) >= 10 &&
            parseInt(demande.id_demande) < 100
        ) {
            num = `00${demande.id_demande}`;
        } else if (parseInt(demande.id_demande) >= 100) {
            num = `0${demande.id_demande}`;
        } else {
            num = `${demande.id_demande}`;
        }

        doc.setFontSize(14);
        doc.text(`FICHE DE COTATION  N° ${num}`, 70, 15);

        doc.setFontSize(10);
        doc.text(`Projet : ${demande.libelle_projet}`, 10, 23);

        var equipe;
        if (demande.libelle_equipe !== null) {
            equipe = demande.libelle_equipe;
        } else {
            equipe = "___";
        }
        doc.setFontSize(10);
        doc.text(`Équipe : ${equipe}`, 10, 31);

        var client;
        if (demande.nom_client !== null) {
            client = demande.nom_client;
        } else {
            client = "___";
        }

        doc.setFontSize(10);
        doc.text(`Client : ${client}`, 10, 39);

        var respon_non;
        if (demande.first_name !== null && demande.first_name !== undefined) {
            respon_non = demande.first_name;
        } else {
            respon_non = "___";
        }

        doc.setFontSize(10);
        doc.text(`Responsable : ${respon_non}`, 138, 23);

        doc.setFontSize(10);
        doc.text(
            `Date d'édition : ${myFormatDate(demande.date_edition)}`,
            138,
            31
        );

        doc.setFontSize(10);
        doc.text(
            `Durée total : ${dureeTotal(demande.cotations)} ${parseInt(dureeTotal(demande.cotations)) <= 1 ? "jour" : "jours"
            }`,
            138,
            39
        );

        doc.autoTable({ startY: 38 });

        doc.autoTable({
            style: {
                padding: 10,
                marginTop: 100,
                columnWidth: "wrap",
            },
            margin: { horizontal: 10 },
            bodyStyles: { fontSize: 8, textColor: "black" },
            columnStyles: {
                0: { cellWidth: 38 },
                1: { cellWidth: 80 },
                2: { cellWidth: 57 },
                3: { cellWidth: 15 },
                // 4: { cellWidth: 30 },
            },
            head: [
                ["Module", "tâche", "Description", "Durée" /*, "Observation"*/],
            ],
            body: tab,
        });

        doc.save("demande_cotation_.pdf");
    };

    return (
        <div
            style={{
                height: "72vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    margin: 18,
                    marginTop: 0,
                    backgroundColor: "white",
                    padding: 18,
                    paddingTop: 10,
                    paddingBottom: 0,
                    marginBottom: 0,
                }}
            >
                <Spin
                    tip="Chargement en cours..."
                    size="large"
                    spinning={loading}
                >
                    {demande_client.length > 0 ?

                        <>
                            <Table
                                // style={{
                                //     height: '75vh',
                                // }}
                                className="table-striped-rows"
                                // rowClassName={(record, index) => (parseInt(record.id_statut) === statut_envoyer_client ?
                                //     "sendClient1" : parseInt(record.id_statut) === statut_refuser_client ?
                                //         "refusClient1" : parseInt(record.id_statut) === statut_terminer ?
                                //             'validateClient1' : 'etatDemande')}
                                columns={columns}
                                pagination={{ defaultPageSize: 10 }}
                                // scroll={{ y: 440 }}
                                dataSource={demande_client}
                                size="small"
                            />
                        </>
                        : (
                            <>
                                <Empty
                                    description={
                                        <Text>
                                            Aucune demande de cotation trouvé.
                                        </Text>
                                    }
                                />
                            </>
                        )

                    }
                </Spin>
                {/* <NestedTable /> */}
            </div>
            <ClientsDrawer />
            <DrawerDemandeDeCotation
                setDemandeSelected={setDemandeSelected}
                demandeSelected={demandeSelected}
                demandeDetail={demandeDetail}
                setDemandeDetail={setDemandeDetail}
                setTypeProjet={setTypeProjet}
                typeProjet={typeProjet}
                setLoadingCotation={setLoadingCotation}
                loadingCotation={loadingCotation}
                selectedDemande={selectedDemande}
                setSelectedDemande={setSelectedDemande}
            />
            <ModalFilterDemandeCotation />
            <ModalValiderDemandeCotation
                selectedDemande={selectedDemande}
                setSelectedDemande={setSelectedDemande}
            />
        </div>
    );
};

export default CustomerCotation;
