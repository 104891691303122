import React, { useEffect } from "react";
import { Row, Col, Form, Input, Button, Spin, Image } from "antd";
import {
    UserOutlined,
    LockOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
} from "@ant-design/icons";
import { loginActions } from "../../../redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import logo from "../../../assets/logo.png";
// import logo from "../../../assets/img1.png";
// import logo from "../../../assets/img2.jpg";
// import logo from "../../../assets/img3.jpg";
import { gris } from "../../helpers/myVar";
import './Login.css'

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const info = useSelector((state) => state.login);

    const loadPage = () => {
        history.push("/accueil");
    };

    useEffect(() => {
        // if (
        //     window.localStorage.getItem("_token") !== "" &&
        //     window.localStorage.getItem("_token") !== undefined &&
        //     window.localStorage.getItem("_token") !== null
        // ) {
        //     history.push("/accueil");
        // }
        dispatch(loginActions.logout());
    }, [dispatch, history]);

    const onFinish = (values) => {
        dispatch(loginActions.login(values, loadPage));
    };

    return (
        <section class="user">
            <div class="user_options-container">
                <div class="user_options-text">
                    <div class="user_options-unregistered">
                        <h2 class="user_unregistered-signup" style={{ color: 'orange' }}>2iProject</h2>
                        <h2 class="user_unregistered-title">Pour une gestion efficiente de  vos projets !</h2>
                        {/* <h2 class="user_unregistered-title">Mieux gérer votre temps grâce à 2IPROJECT ? c'est possible !</h2> */}
                        <p class="user_unregistered-text">
                            - Gestion des demandes <br />
                            - Gestion des tickets
                        </p>
                        {/* <p class="user_unregistered-text">Avec, à la clé, un travail plus efficace, 2IPROJECT vous permez une meilleure gestion de vos projets, une collaboration sans failles au sein de votre équipe, ce qui permettra à l'entreprise un gain de temps énorme.</p> */}
                        {/* <button class="user_unregistered-signup">...</button> */}
                        <span style={{ color: 'white', paddingLeft: '10%', fontSize: 13 }}><span style={{ color: 'orange' }}>Version 3.0.2</span> © 2021 Ingéniérie Informatique Soft. Tous droits réservés </span>
                    </div>
                </div>

                <div class="user_options-forms" id="user_options-forms">
                    <div class="user_forms-login">
                        <h2 class="forms_title">Authentification</h2>
                        <Spin style={{ marginLeft: '45%', marginTop: -50 }} spinning={info.loading} />
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <fieldset class="forms_fieldset">
                                <div class="forms_field">
                                    <Form.Item
                                        name="login"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Veuillez saisir votre nom d'utilisateur !",
                                            },
                                        ]}
                                    >
                                        <Input size="large" placeholder="Nom d'utilisateur" />
                                    </Form.Item>
                                </div>
                                <div class="forms_field">
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Veuillez saisir votre mot de passe!",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="Mot de passe"
                                            autoComplete="on"
                                        />
                                    </Form.Item>
                                </div>
                            </fieldset>
                            {/* <div class="forms_buttons"> */}
                            <Form.Item>
                                <Button
                                    style={{
                                        marginTop: 15
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    Se connecter
                                </Button>
                            </Form.Item>
                            {/* </div> */}
                        </Form>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Login;
