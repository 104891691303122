export const type_ticketConstants = {
    GETALL_TYPE_TICKET_REQUEST: "GETALL_TYPE_TICKET_REQUEST",
    GETALL_TYPE_TICKET_SUCCESS: "GETALL_TYPE_TICKET_SUCCESS",
    GETALL_TYPE_TICKET_FAILURE: "GETALL_TYPE_TICKET_FAILURE",

    ADD_TYPE_TICKET_REQUEST: "ADD_TYPE_TICKET_REQUEST",
    ADD_TYPE_TICKET_SUCCESS: "ADD_TYPE_TICKET_SUCCESS",
    ADD_TYPE_TICKET_FAILURE: "ADD_TYPE_TICKET_FAILURE",

    UPDATE_TYPE_TICKET_REQUEST: "UPDATE_TYPE_TICKET_REQUEST",
    UPDATE_TYPE_TICKET_SUCCESS: "UPDATE_TYPE_TICKET_SUCCESS",
    UPDATE_TYPE_TICKET_FAILURE: "UPDATE_TYPE_TICKET_FAILURE",

    DELETE_TYPE_TICKET_REQUEST: "DELETE_TYPE_TICKET_REQUEST",
    DELETE_TYPE_TICKET_SUCCESS: "DELETE_TYPE_TICKET_SUCCESS",
    DELETE_TYPE_TICKET_FAILURE: "DELETE_TYPE_TICKET_FAILURE",
}