import { priority_ticketConstants } from "../_constants";
import { priority_ticketServices } from "../_services";
import { alertActions } from "../_actions";

export const priority_ticketActions = {
    getAll,
    add,
    update,
    remove,
};

function getAll() {
    return function (dispatch) {
        dispatch(request());
        priority_ticketServices
            .getAll()
            .then((res) => {
                dispatch(success(res.data.message));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };
    function request() {
        return {
            type: priority_ticketConstants.GETALL_PRIORITY_TICKET_REQUEST,
        };
    }
    function success(priority_ticket) {
        return {
            type: priority_ticketConstants.GETALL_PRIORITY_TICKET_SUCCESS,
            priority_ticket,
        };
    }
    function failure(error) {
        return {
            type: priority_ticketConstants.GETALL_PRIORITY_TICKET_FAILURE,
            error,
        };
    }
}

function add(priority_ticket) {
    return function (dispatch) {
        dispatch(request());
        priority_ticketServices
            .add(priority_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("L'ajout effectué."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de l'ajout."));
            });
    };
    function request() {
        return {
            type: priority_ticketConstants.ADD_PRIORITY_TICKET_REQUEST,
        };
    }
    function success(priority_ticket) {
        return {
            type: priority_ticketConstants.ADD_PRIORITY_TICKET_SUCCESS,
            priority_ticket,
        };
    }
    function failure(error) {
        return {
            type: priority_ticketConstants.ADD_PRIORITY_TICKET_FAILURE,
            error,
        };
    }
}

function update(priority_ticket) {
    return function (dispatch) {
        dispatch(request());
        priority_ticketServices
            .update(priority_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Modification effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de modification."));
            });
    };
    function request() {
        return {
            type: priority_ticketConstants.UPDATE_PRIORITY_TICKET_REQUEST,
        };
    }
    function success(priority_ticket) {
        return {
            type: priority_ticketConstants.UPDATE_PRIORITY_TICKET_SUCCESS,
            priority_ticket,
        };
    }
    function failure(error) {
        return {
            type: priority_ticketConstants.UPDATE_PRIORITY_TICKET_FAILURE,
            error,
        };
    }
}

function remove(priority_ticket) {
    return function (dispatch) {
        dispatch(request());
        priority_ticketServices
            .remove(priority_ticket)
            .then((res) => {
                dispatch(success(res.data.message));
                dispatch(alertActions.success("Suppression effectuée."));
            })
            .catch((err) => {
                dispatch(failure(err.message));
                dispatch(alertActions.error("Echec de suppression."));
            });
    };
    function request() {
        return {
            type: priority_ticketConstants.DELETE_PRIORITY_TICKET_REQUEST,
        };
    }
    function success(priority_ticket) {
        return {
            type: priority_ticketConstants.DELETE_PRIORITY_TICKET_SUCCESS,
            priority_ticket,
        };
    }
    function failure(error) {
        return {
            type: priority_ticketConstants.DELETE_PRIORITY_TICKET_FAILURE,
            error,
        };
    }
}
