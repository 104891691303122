import React, { useEffect, useState } from "react";
import {
    Modal,
    Button,
    Form,
    Input,
    Typography,
    Select,
    Avatar,
    Popconfirm
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, jalonActions, modalActions } from "../../../redux/_actions";
import { SaveOutlined, CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const ModalAjouterJalon = (props) => {
    const dispatch = useDispatch();
    const closable_add_jalon = useSelector(
        (state) => state.modal.closable_add_jalon
    );
    const colors = useSelector((state) => state.project_color.items);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            libelle: props.jalonSelected.libelle,
            color: props.jalonSelected.id_color,
            terminate: props.jalonSelected.terminate,
            execute: props.jalonSelected.execute,
        });
    });

    const submitAddJalon = (values) => {

        if (values.execute === 1 & values.terminate === 1) {
            dispatch(alertActions.error('un jalon ne peut avoir les deux types en même.'));
            dispatch(modalActions.close_add_jalon());
        } else {
            if (Object.keys(props.jalonSelected).length === 0) {
                const data = {
                    libelle: values.libelle,
                    color: values.color,
                    execute: values.execute !== undefined ? values.execute : 0,
                    terminate: values.terminate !== undefined ? values.terminate : 0,
                };
                dispatch(jalonActions.add(data));
                dispatch(modalActions.close_add_jalon());
            } else {
                const data = {
                    id_jalon: props.jalonSelected.id_jalon,
                    libelle: values.libelle,
                    color: values.color,
                    execute: values.execute,
                    terminate: values.terminate,
                };
                dispatch(jalonActions.update(data));
                dispatch(modalActions.close_add_jalon());
            }
        }

    };

    return (
        <Modal
            title={
                Object.keys(props.jalonSelected).length === 0
                    ? "Ajouter un jalon"
                    : "Modifier un jalon"
            }
            visible={closable_add_jalon}
            footer={null}
            onCancel={() => {
                dispatch(modalActions.close_add_jalon());
                props.setJalonSelected({});
            }}
            maskClosable={false}
        >
            <Form
                className="login-form"
                onFinish={submitAddJalon}
                form={form}
                layout="vertical"
            >
                <Form.Item>
                    <Form.Item
                        name="libelle"
                        style={{
                            display: "inline-block",
                            width: "calc(70% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Libelle</Text>}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez entrer le libellé du jalon !",
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Entrer le libellé du jalon"
                        />
                    </Form.Item>
                    <Form.Item
                        name="color"
                        style={{
                            display: "inline-block",
                            width: "calc(29% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Couleur</Text>}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner la couleur du jalon !",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Selectionner la couleur du jalon"
                            defaultValue={
                                props.jalonSelected !== {}
                                    ? [props.jalonSelected.id_color]
                                    : null
                            }
                        >
                            {colors.map((p) => (
                                <Option value={p.id_color}>
                                    <Avatar
                                        size="small"
                                        style={{
                                            backgroundColor: p.color,
                                        }}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="terminate"
                        style={{
                            display: "inline-block",
                            width: "calc(50% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Jalon Terminé</Text>}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Selectionner un type"
                            defaultValue={
                                Object.keys(props.jalonSelected).length > 0
                                    ? props.jalonSelected.terminate
                                    : 0
                            }
                        >
                            <Option value={0}>---</Option>
                            <Option value={1}>jalon terminé</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="execute"
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        label={<Text strong={true}>Jalon Exécuté</Text>}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Selectionner un type"
                            defaultValue={
                                Object.keys(props.jalonSelected).length > 0
                                    ? props.jalonSelected.execute
                                    : 0
                            }
                        >
                            <Option value={0}>---</Option>
                            <Option value={1}>jalon exécuté</Option>
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => {
                            dispatch(modalActions.close_add_jalon());
                            props.setJalonSelected({});
                        }}
                    >
                        <CloseCircleOutlined /> Annuler
                    </Button>{" "}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>

                    <Popconfirm
                        placement="topLeft"
                        title="Voulez-vous supprimer ce jalon ?"
                        onConfirm={() => {
                            dispatch(jalonActions.remove(props.jalonSelected.id_jalon));
                            dispatch(modalActions.close_add_jalon());
                            props.setJalonSelected({});
                        }} okText="Oui" cancelText="Non">
                        <Button style={{ float: 'right' }} size="large" danger>
                            <DeleteOutlined /> Supprimer
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterJalon;
