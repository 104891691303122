import React, { useEffect } from "react";
import { Modal, Button, Form, Input, Typography, Select, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    directionActions,
    jalonActions,
    modalActions,
} from "../../../redux/_actions";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const ModalAjouterDirection = (props) => {
    const dispatch = useDispatch();
    const closable_add_direction = useSelector(
        (state) => state.modal.closable_add_direction
    );
    const colors = useSelector((state) => state.project_color.items);
    const users = useSelector((state) => state.user.items);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            libelle: props.directionSelected.libelle,
            id_user: props.directionSelected.id_user,
        });
    });

    const submitAddJalon = (values) => {
        if (Object.keys(props.directionSelected).length === 0) {
            dispatch(directionActions.add(values));
            form.resetFields();
            dispatch(modalActions.close_add_direction());
        } else {
            const data = {
                id_direction: props.directionSelected.id_direction,
                libelle: values.libelle,
                id_user: values.id_user,
            };
            dispatch(directionActions.update(data));
            dispatch(modalActions.close_add_direction());
        }
    };

    return (
        <Modal
            title={
                Object.keys(props.directionSelected).length === 0
                    ? "Ajouter un jalon"
                    : "Modifier un jalon"
            }
            visible={closable_add_direction}
            footer={null}
            onCancel={() => {
                dispatch(modalActions.close_add_direction());
                props.setDirectionSelected({});
            }}
            maskClosable={false}
        >
            <Form
                className="login-form"
                onFinish={submitAddJalon}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="libelle"
                    label={<Text strong={true}>Libelle</Text>}
                    rules={[
                        {
                            required: true,
                            message: "Veuillez entrer le nom de la direction",
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder="Entrer le nom de la direction"
                    />
                </Form.Item>
                <Form.Item
                    name="id_user"
                    label={<Text strong={true}>Chef</Text>}
                    rules={[
                        {
                            required: true,
                            message:
                                "Veuillez sélectionner le chef de la direction",
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        size="large"
                        placeholder="Sélectionner le chef de la direction"
                        defaultValue={
                            props.directionSelected !== {}
                                ? [props.directionSelected.id_user]
                                : null
                        }
                    >
                        {users.map((p) => (
                            <Option value={p.id_user}>{p.first_name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => {
                            dispatch(modalActions.close_add_direction());
                            props.setDirectionSelected({});
                        }}
                    >
                        <CloseCircleOutlined /> Annuler
                    </Button>{" "}
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterDirection;
