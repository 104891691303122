import React, { useEffect } from "react";
import { Modal, Form, Button, Select, Input, Avatar, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { modalActions, team_projectActions } from "../../../redux/_actions";
import { SaveOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
const { Text} = Typography;

const ModalAjouterEquipeProjet = (props) => {
    
    const closable_add_project_team = useSelector(
        (state) => state.modal.closable_add_project
    );

    const project_colors = useSelector((state) => state.project_color.items);
    const team_menbers = useSelector((state) => state.team_menber.menbers);
    const teams = useSelector((state) => state.team.items);
    const auth = useSelector((state) => state.login.item);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            id_equipe: props.teamSelected,
        });
    });

    const submitAddTeamPorject = (values) => {
        const data = {
            libelle: values.libelle,
            color: values.color,
            id_equipe: values.id_equipe,
            id_equipe_membre: values.id_equipe_membre,
            id_statut: 1,
            description: values.description
        };
        dispatch(team_projectActions.addTeamProject(data));
        dispatch(modalActions.close_add_project_team());
        form.resetFields();
    };

    return (
        <Modal
            title="Ajouter un projet"
            visible={closable_add_project_team}
            footer={null}
            onCancel={() => dispatch(modalActions.close_add_project_team())}
            afterClose={() => form.resetFields()}
            maskClosable={false}
        >
            <Form form={form} onFinish={submitAddTeamPorject} layout="vertical">
                <Form.Item>
                    <Form.Item
                        name="id_equipe"
                        label={<Text strong={true}>Équipes</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner le nom de l'équipe!",
                            },
                        ]}
                        defaultValue={[auth.id_equipe]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            disabled
                        >
                            {teams.map((p) => (
                                <Option value={p.id_equipe}>{p.libelle}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="id_equipe_membre"
                        label={<Text strong={true}>Membre</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner les membres de l'équipe!!",
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Sélectionner les membres "
                        >
                            {team_menbers.map((p) => (
                                <Option value={p.id_equipe_membre}>
                                    {p.first_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="libelle"
                        label={<Text strong={true}>Libellé</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez saisir le nom du projet !",
                            },
                        ]}
                    >
                        <Input type="text" size="large" placeholder="Libellé du projet" />
                    </Form.Item>
                    <Form.Item
                        name="color"
                        label={<Text strong={true}>Couleur</Text>}
                        style={{
                            display: "inline-block",
                            width: "calc(49% - 0px)",
                            marginRight: 2,
                            marginBottom: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez sélectionner la couleur du projet !",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "100%",
                            }}
                            size="large"
                            placeholder="Choisir la couleur"
                        >
                            {project_colors.map((p) => (
                                <Option value={p.id_color}>
                                    <Avatar
                                        size="small"
                                        style={{
                                            backgroundColor: p.color,
                                        }}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item name="description" label={<Text strong={true}>Description</Text>}>
                    <TextArea
                        row={4}
                        placeholder="Veuillez saisir la description du projet !"
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" type="primary" htmlType="submit">
                        <SaveOutlined /> Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAjouterEquipeProjet;
