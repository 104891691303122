import { statutConstants } from "../_constants";

const initialState = {
    loading: false,
    items: [],
    item: {},
    error: "",
};

export const statut = (state = initialState, action) => {
    switch (action.type) {
        case statutConstants.GETALL_STATUT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statutConstants.GETALL_STATUT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.statut,
            };

        case statutConstants.GETALL_STATUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case statutConstants.ADD_STATUT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statutConstants.ADD_STATUT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.statut],
            };

        case statutConstants.ADD_STATUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case statutConstants.UPDATE_STATUT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case statutConstants.UPDATE_STATUT_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items.map(p => {
                    if(p.id_statut === action.statut.id_statut) {
                        Object.assign(p, action.statut);
                    }
                    return p;
                })]
            };

        case statutConstants.UPDATE_STATUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
};
