import React, { useEffect, useState } from "react";
import "./Accueil.css";
import HeaderApp from "../../parchalse/HeaderApp";
import {
    Typography,
    Badge,
    Button,
    Empty,
    Spin,
    Comment,
    Avatar,
    Tabs,
    Tag,
    Skeleton
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import avatar from '../../../assets/avatar.png'
import { useHistory } from "react-router-dom";
// import moment from 'moment';
import {
    loginActions,
    team_projectActions,
    profileActions,
    userActions,
    directionActions,
    teamActions,
    project_colorActions,
    actionActions,
    groupActions,
    project_statusActions,
    jalonActions,
    statutActions,
    team_menberActions,
    // statut_projetActions,
    statut_demandeActions,
    type_projectActions,
    projectActions,
    ticketActions,
    // customerActions,
    taskActions,
    modalActions,
    demande_cotationActions
} from "../../../redux/_actions";
import defineAbilityFor from "../../../config/defineAbility";
import { Can } from "../../../config/Can";
import { useDispatch, useSelector } from "react-redux";
import { encryptWithAES } from "../../helpers/makeAvatar";
// import { repeat } from "lodash";
import {
    TeamOutlined,
    ProjectOutlined,
    UserOutlined,
    FieldTimeOutlined,
    EllipsisOutlined,
    LockFilled,
    CheckCircleOutlined
} from '@ant-design/icons';
import DetailTache from "../../components/Drawer/DetailTache";



const { Text } = Typography;
const { TabPane } = Tabs;


const Accueil = () => {

    // const profil_admin = 1;
    const profil_chef_projet = 2;
    const profil_chef_commercial = 4;

    const history = useHistory();

    const [choixCard, setChoixCard] = useState(0);
    const [choixLine, setChoixLine] = useState(0);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.item);
    const user_login = useSelector((state) => state.login);
    const team_member = useSelector((state) => state.team_menber.menbers)
    const team_project = useSelector((state) => state.team_project.team_member_project);
    const loading = useSelector((state) => state.team_project.loading);
    // const teams = useSelector((state) => state.team.items);
    const filter_chart = useSelector((state) => state.task.filter_chart);
    const loading_recently = useSelector((state) => state.task.loading_recently);
    const items_recently_task = useSelector((state) => state.task.items_recently_task);
    const demande_cotation = useSelector((state) => state.demande_cotation.items).filter((p) => p.id_statut === 2);
    const loading_demande = useSelector((state) => state.demande_cotation.loading);

    const ability = defineAbilityFor(user_login);
    let date1 = new Date();

    let dateLocale = date1.toLocaleString('fr-FR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
    });

    useEffect(() => {
        document.title = "2iProject-Accueil";
        // get all data profil 
        dispatch(profileActions.getAll());
        // get all data action
        dispatch(actionActions.getProfileAction({ id_profil: user.id_profil }));
        // get All data project 
        dispatch(projectActions.getAll());
        // get All droit user connecter 
        dispatch(loginActions.getUserRole(user.id_profil));
        // get Notification du user connecter
        dispatch(userActions.userNotification(user.id_user));
        // get all data user action 
        dispatch(userActions.getAll());
        // get all droit action
        dispatch(groupActions.getAll());
        // get all data direction
        dispatch(directionActions.getAll());
        // get all team data action
        dispatch(teamActions.getAll());
        // get all color project
        dispatch(project_colorActions.getAll());
        // get all status project
        dispatch(project_statusActions.getAll());
        // dispatch(statut_projetActions.getAll());
        // get all data jalon 
        dispatch(jalonActions.getAll());
        // get all data statut 
        dispatch(statutActions.getAll());
        // get all data statut demande de cotation
        dispatch(statut_demandeActions.getAll());
        // get all data type project
        dispatch(type_projectActions.getAll());
        // get all data ticket 
        // dispatch(ticketActions.getAll());
        // get all data client
        // dispatch(customerActions.getAll());
        // get all des taches
        // dispatch(taskActions.getAll());

        // les dispatch concernant le module "Analyse"
        dispatch(taskActions.getDataChart({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));


        // Details de tache par jalon pour toutes les equipes
        dispatch(taskActions.getDataChartDetail({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));


        // Evancement de creation de tache pour les equipes par moi
        dispatch(taskActions.getDataChartAllTeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));


        // EA : Etat d'Avancement
        dispatch(taskActions.getDataChartEATeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));


        // CH : Charge Horaire
        dispatch(taskActions.getDataChartCHTeam({
            date_debut: filter_chart.length > 0 ? filter_chart[0] : null,
            date_fin: filter_chart.length > 0 ? filter_chart[1] : null,
            id_equipe: user_login.item.id_profil === profil_chef_projet || user_login.item.id_profil === profil_chef_commercial ? user_login.item.id_equipe : null
        }));

        // dispatch(
        //     team_projectActions.getAllTeamMemberProject(user.id_equipe_membre)
        // );
        if (user.id_equipe !== null) {
            dispatch(
                team_menberActions.getAllTeamMenber({
                    id_equipe: user.id_equipe,
                })
            );
            dispatch(
                team_projectActions.getAllTeamProject({
                    id_equipe: user.id_equipe,
                })
            );
            dispatch(
                team_projectActions.getAllTeamMemberProject(
                    user.id_equipe_membre
                )
            );
            dispatch(
                taskActions.getRecentlyTask(
                    user.id_user
                )
            );

            dispatch(demande_cotationActions.getAll());
        }
        dispatch(team_menberActions.resetUserTask());
    }, [
        user.libelle_profil,
        user.id_equipe,
        dispatch,
        user.id_user,
        user.id_profil,
        user.id_equipe_membre,
    ]);

    // si on click sur une taches
    const clickTask = (value) => {
        dispatch(projectActions.selectedProjectTask(value));
        dispatch(taskActions.getAllTaskLogs(parseInt(value.id_tache)));
        dispatch(taskActions.getAllUnderTask(parseInt(value.id_tache)));
        dispatch(userActions.getAllMemberProject(parseInt(value.id_projet)));
        setTimeout(() => {
            dispatch(modalActions.show_detail_task());
        }, 100);
    }

    const clickCotation = (value) => {
        history.push(`/fiche-de-cotation`);
    }

    // quand on clique sur un projet 
    const handleClickProjet = (value) => {
        dispatch(
            projectActions.getAllProjectTask({
                id_project: parseInt(value.id_projet),
            })
        );
        dispatch(userActions.getAllMemberProject(parseInt(value.id_projet)));
        dispatch(team_projectActions.selectedProject(value));
        history.push(`/mes-projets/${encryptWithAES(value.id_projet)}`);
    }

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: 50,
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    margin: 18,
                    marginTop: 0,
                    // backgroundColor: "white",
                    padding: 18,
                    paddingTop: 10,
                    paddingBottom: 0,
                    marginBottom: 0,
                    height: "85.4%"
                }}
            >
                {/* Title */}
                <div
                    style={{
                        // width: '34%',
                        // height: 40,
                        // border: '2px solid red',
                        marginTop: 15,
                        // marginLeft: 500,
                        // marginRight: 500,
                        textAlign: 'center',
                        fontWeight: "bold",
                    }}
                >
                    <p style={{ fontSize: 20 }}>{dateLocale}</p>
                    <p style={{ fontSize: 25 }}>Bonjour, {user.first_name}</p>
                </div>
                {/* Item */}
                <div
                    style={{
                        // height: 50,
                        // border: '2px solid blue',
                        marginTop: 10,
                        // marginLeft: 475,
                        // marginRight: 500,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                        // verticalAlign: 'center'
                    }}
                >
                    <div
                        style={{
                            borderColor: '2px solid red',
                            backgroundColor: 'rgb(0, 21, 41)',
                            width: 500,
                            height: 45,
                            borderRadius: 30,
                            textAlign: 'center',
                            display: 'flex',
                        }}
                    >
                        <div style={{
                            marginLeft: "10%",
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            float: 'none',
                            marginTop: 10,
                            width: '11rem'
                        }}>
                            <ProjectOutlined style={{ color: 'white', fontSize: 20, marginRight: 10 }} />
                            <Text strong style={{ color: 'white' }}>Mes Projets</Text>
                            <Badge
                                className="site-badge-count-109"
                                count={team_project.length}
                                style={{
                                    backgroundColor: 'rgb(64, 169, 255)',
                                    width: 30,
                                    marginLeft: 10,
                                    fontWeight: 'bold'
                                }}
                            />
                        </div>
                        <hr style={{ height: 30 }} />
                        <div style={{
                            marginRight: "10%",
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            float: 'none',
                            marginTop: 10,
                            width: '11rem'
                        }}>
                            <TeamOutlined style={{ color: 'white', fontSize: 20, marginRight: 10 }} />
                            <Text strong style={{ color: 'white' }}>Collaborateur</Text>
                            <Badge
                                className="site-badge-count-109"
                                count={team_member.length}
                                style={{
                                    backgroundColor: 'rgb(64, 169, 255)',
                                    width: 30,
                                    marginLeft: 10,
                                    fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* Menu */}
                <div style={{ marginTop: 20, }}>
                    {/* panel 1 */}
                    <Can I="lireTaches" a="Page d'accueil" ability={ability}>
                        <div style={{
                            marginLeft: "10%",
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            float: 'none',
                            width: '40%',
                            height: 450,
                            backgroundColor: 'white',
                            borderRadius: 14,
                        }}>
                            <div
                                style={{
                                    // border: '2px solid red',
                                    marginLeft: 10,
                                    marginTop: 10,
                                    height: 50
                                }}
                            >
                                <Comment
                                    avatar={<Avatar
                                        size="large"
                                        src={avatar} alt="Han Solo" />}
                                    content={
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                                            Mes priorités <LockFilled style={{ fontSize: '16px' }} />
                                        </p>
                                    }
                                />
                            </div>
                            <div>
                                <Tabs id="tabs" defaultActiveKey="1">
                                    <TabPane
                                        style={{
                                            // border: '2px solid red',
                                        }}
                                        tab="À venir" key="1"
                                    >
                                        <Skeleton
                                            active={loading_recently}
                                            loading={loading_recently}
                                            // avatar={{ shape: 'square' }}
                                            title={false} paragraph={{ rows: 5 }}
                                        >
                                            {
                                                items_recently_task.length > 0 ? (
                                                    items_recently_task.map((p) => (
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                                backgroundColor: p.id_tache === choixLine ? '#e9eeff' : null,
                                                                width: "85%",
                                                            }}
                                                            onMouseEnter={() => { setChoixLine(p.id_tache) }}
                                                            onMouseLeave={() => { setChoixLine(0) }}
                                                        >
                                                            <p
                                                                style={{
                                                                    // border: '2px solid red',
                                                                    cursor: 'pointer',
                                                                    textAlign: 'left'
                                                                }}
                                                                onClick={() => clickTask(p)}
                                                            >
                                                                <CheckCircleOutlined style={{ color: '#1890ff', fontSize: 16 }} />
                                                                <span style={{ marginLeft: 10 }}>
                                                                    {p.libelle_tache.length > 35 ? (
                                                                        <>
                                                                            {p.libelle_tache.substr(0, 35)}...
                                                                        </>
                                                                    ) : p.libelle_tache}
                                                                </span>
                                                                <Tag color={p.color} style={{ float: 'right', marginTop: 2, marginRight: -50 }}>
                                                                    {p.libelle_projet}
                                                                </Tag>
                                                            </p>
                                                            <div style={{ borderBottom: '1px solid #d7d7d7', marginTop: 5, width: 500 }}></div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <>
                                                        <Empty description={false} style={{
                                                            marginLeft: -100,
                                                            marginTop: 50
                                                        }} />
                                                    </>
                                                )
                                            }
                                        </Skeleton>
                                    </TabPane>
                                    <TabPane style={{
                                        marginLeft: 60
                                    }} tab="En retard" key="2" disabled >
                                        Content of Tab Pane 2
                                    </TabPane>
                                    <TabPane style={{
                                        marginLeft: 60
                                    }} tab="Terminé" key="3" disabled >
                                        Content of Tab Pane 3
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Can>
                    {/* panel 2 */}
                    <Can I="resumeCotation" a="Page d'accueil" ability={ability}>
                        <div style={{
                            marginLeft: "10%",
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            float: 'none',
                            width: '40%',
                            height: 450,
                            backgroundColor: 'white',
                            borderRadius: 14,
                        }}>
                            <div
                                style={{
                                    // border: '2px solid red',
                                    marginLeft: 10,
                                    marginTop: 10,
                                    height: 50
                                }}
                            >
                                <Comment
                                    avatar={<Avatar
                                        size="large"
                                        src={avatar} alt="Han Solo" />}
                                    content={
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                                            Mes priorités <LockFilled style={{ fontSize: '16px' }} />
                                        </p>
                                    }
                                />
                            </div>
                            <div style={{ padding: 20 }}>
                                <Skeleton
                                    active={loading_demande}
                                    loading={loading_demande}
                                    title={false} paragraph={{ rows: 2 }}
                                >
                                    {
                                        demande_cotation.length > 0 ? (
                                            demande_cotation.map((p) => (
                                                <div
                                                    style={{
                                                        marginTop: 10,
                                                        backgroundColor: p.id_demande === choixLine ? '#e9eeff' : null,
                                                        width: "85%",
                                                        marginLeft: '43px',
                                                    }}
                                                    onMouseEnter={() => { setChoixLine(p.id_demande) }}
                                                    onMouseLeave={() => { setChoixLine(0) }}
                                                >
                                                    <p
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => clickCotation(p)}
                                                    >
                                                        <span style={{ marginLeft: 10 }}>
                                                            {
                                                                p.libelle_demande === null ? (
                                                                    <>
                                                                        ...
                                                                    </>
                                                                ) : p.libelle_demande.length > 20 ? (
                                                                    <>
                                                                        {p.libelle_demande.substr(0, 20)}...
                                                                    </>
                                                                ) : p.libelle_demande}
                                                        </span>
                                                        <Tag
                                                            icon={<ProjectOutlined />}
                                                            color={p.color_statut}
                                                            style={{ float: 'right', marginTop: 2 }}
                                                        >
                                                            {p.libelle_projet}
                                                        </Tag>
                                                        <Tag
                                                            icon={<UserOutlined />}
                                                            color={p.color_statut}
                                                            style={{ float: 'right', marginTop: 2 }}
                                                        >
                                                            {p.nom_client}
                                                        </Tag>
                                                    </p>
                                                    <div style={{ borderBottom: '1px solid #d7d7d7', marginTop: 5, width: 500 }}></div>
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <Empty description={false} style={{
                                                    marginLeft: -10,
                                                    marginTop: 90
                                                }} />
                                            </>
                                        )
                                    }
                                </Skeleton>
                            </div>
                        </div>
                    </Can>
                    {/* panel 3 */}
                    <Can I="lireProjets" a="Page d'accueil" ability={ability}>
                        <div
                            className="divMenu"
                            style={{
                                // boxShadow: "5px 5px 5px rgb(0, 21, 41)",
                                // border: '2px solid blue',
                                marginLeft: 10,
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                float: 'none',
                                width: '40%',
                                height: 450,
                                backgroundColor: 'white',
                                borderRadius: 14,
                            }}>
                            <div style={{ padding: 20 }}>
                                <Scrollbars
                                    autoHide
                                    style={{
                                        background: "white",
                                        height: 410,
                                        // marginTop: 10
                                    }}
                                >
                                    <Skeleton
                                        active={loading}
                                        loading={loading}
                                        avatar={{ shape: 'square' }} title={false} paragraph={{ rows: 2 }}
                                    >
                                        {
                                            team_project.length > 0 ? (
                                                team_project.map((p) => (
                                                    <div className="divProjet"
                                                        onMouseEnter={() => { setChoixCard(p.id_projet) }}
                                                        onMouseLeave={() => { setChoixCard(0) }}
                                                        onClick={() => { handleClickProjet(p) }}
                                                        style={{
                                                            // border: '2px solid blue',
                                                            width: "43%",
                                                            height: 60,
                                                            marginTop: 10,
                                                            marginLeft: 30,
                                                            borderRadius: 14,
                                                            marginBottom: 5,
                                                            hover: 'rgb(223, 222, 222)',
                                                            cursor: 'pointer',
                                                            backgroundColor: p.id_projet === choixCard ? '#e9eeff' : null,
                                                        }}
                                                    >
                                                        <>
                                                            <div
                                                                style={{
                                                                    width: 70,
                                                                    height: 60,
                                                                    backgroundColor: 'white',
                                                                    border: `2px solid ${p.color}`,
                                                                    borderRadius: 14,
                                                                    display: 'inline-block',
                                                                    verticalAlign: 'middle',
                                                                    float: 'none',
                                                                }}
                                                            >
                                                                <ProjectOutlined
                                                                    style={{
                                                                        margin: '10px auto',
                                                                        marginLeft: 15,
                                                                        color: `${p.color}`,
                                                                        fontSize: 35
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                    display: 'inline-block',
                                                                    verticalAlign: 'middle',
                                                                    float: 'none',
                                                                }}
                                                            >
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        color: 'rgb(0, 21, 41)',
                                                                        fontSize: '110%',
                                                                    }}
                                                                >
                                                                    {p.libelle.length > 10 ? (
                                                                        <>
                                                                            {p.libelle.substr(0, 10)}...
                                                                        </>
                                                                    ) : (
                                                                        p.libelle
                                                                    )}
                                                                </Text><br />
                                                                <Text
                                                                    // strong
                                                                    style={{
                                                                        color: 'rgb(0, 21, 41)',
                                                                        fontSize: 12,
                                                                        marginBottom: -10,
                                                                    }}
                                                                >
                                                                    <FieldTimeOutlined /> {p.statut_libelle}
                                                                </Text>
                                                            </div>
                                                            {p.id_projet === choixCard ? (
                                                                <Button type="dashed"
                                                                    disabled
                                                                    icon={<EllipsisOutlined />}
                                                                    style={{ float: 'right', marginTop: 14 }}
                                                                />
                                                            ) : null}
                                                        </>
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    <Empty description={false} style={{ marginTop: '30%' }} />
                                                </>
                                            )
                                        }
                                    </Skeleton>
                                </Scrollbars>
                            </div>
                        </div>
                    </Can>
                </div>
            </div>
            <DetailTache />
        </div >
    );
};

export default Accueil;
