import axios from "../../axios/index";
export const loginService = {
    login,
    auth,
    logout,
    // changePassword,
    getUserRole,
    update,
    checkToken
};

async function checkToken(token) {
    return await axios.post(`checkToken`, token);
}

async function login(login) {
    return await axios.post(`login`, login);
}

async function auth(login) {
    return await axios.post(`auth`, login);
}
// async function changePassword(info) {
//     return await axioos.post(`chllllpswllllll`, info);
// }
function logout() {
    // remove user from local storage to log user out
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
}

async function getUserRole(id_profil) {
    return await axios.get(`profils/action/${id_profil}`)
}

async function update(user) {
    return await axios.put(`users/${user.id_user}`,user);
}
