export const directionConstants = {
    ADD_DIRECTION_REQUEST: "ADD_DIRECTION_REQUEST",
    ADD_DIRECTION_SUCCESS: "ADD_DIRECTION_SUCCESS",
    ADD_DIRECTION_FAILURE: "ADD_DIRECTION_FAILURE",

    GETALL_REQUEST: "GETALL_DIRECTION_REQUEST",
    GETALL_SUCCESS: "GETALL_DIRECTION_SUCCESS",
    GETALL_FAILURE: "GETALL_DIRECTION_FAILURE",

    UPDATE_DIRECTION_REQUEST: "UPDATE_DIRECTION_REQUEST",
    UPDATE_DIRECTION_SUCCESS: "UPDATE_DIRECTION_SUCCESS",
    UPDATE_DIRECTION_FAILURE: "UPDATE_DIRECTION_FAILURE",

    DELETE_DIRECTION_REQUEST: "DELETE_DIRECTION_REQUEST",
    DELETE_DIRECTION_SUCCESS: "DELETE_DIRECTION_SUCCESS",
    DELETE_DIRECTION_FAILURE: "DELETE_DIRECTION_FAILURE",
};
