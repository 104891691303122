import axios from "../../axios/index";

export const type_ticketServices = {
    getAll,
    add,
    update,
    remove,
};

async function add(type_ticket) {
    return await axios.post(`type_ticket`, type_ticket);
}

async function getAll() {
    return await axios.get(`type_ticket`);
}

async function update(type_ticket) {
    return await axios.put(`type_ticket/${type_ticket.id}`, type_ticket);
}

async function remove(type_ticket) {
    return await axios.get(`type_ticket/delete/${type_ticket}`);
}
