import React, { useState, useEffect } from "react";
import "./Utilisateurs.css";
import {
    Card,
    Button,
    Tooltip,
    Table,
    Form,
    Input,
    Select,
    Checkbox,
    Spin,
    Typography,
    Space,
    Popconfirm,
} from "antd";
import {
    LoginOutlined,
    MailOutlined,
    UserOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    DeleteOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseUser, faSave } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { userActions, modalActions } from "../../../../redux/_actions";
import Container from "../../../components/Container/Container";
import ConfigApp from "../ConfigApp";
import defineAbilityFor from "../../../../config/defineAbility";
import { Can } from "../../../../config/Can";
import { EditOutlined } from "@material-ui/icons";
import HeaderApp from "../../../parchalse/HeaderApp";
import Highlighter from "react-highlight-words";
import ClientsDrawer from "../../../components/Drawer/ClientsDrawer";


const { Option } = Select;
const { Text } = Typography;
var { SearchInput } = Input;

const Utilisateurs = () => {
    const [searchText, setSearchText] = useState();
    const [searchedColumn, setSearchedColumn] = useState();
    const [isCustomer, setIsCustomer] = useState(false);

    const users = useSelector((state) => state.user.items);

    const [userUpdate, setUserUpdate] = useState(0);
    const [hoverUser, setHoverUser] = useState(0);
    const [userSelected, setUserSelected] = useState(
        users[0] !== undefined ? users[0].id_user : 0
    );
    const [updatedPassword, setUpdatedPassword] = useState(false);
    const [prefix, setPrefix] = useState("+227");

    const profiles = useSelector((state) => state.profile.items);
    const add = useSelector((state) => state.user.add);
    const user_loading = useSelector((state) => state.user.loading);
    const user = useSelector((state) => state.login);
    const login_exist = useSelector((state) => state.user.login_exist);
    const email_exist = useSelector((state) => state.user.email_exist);
    const clients = useSelector((state) => state.customer.items);

    const ability = defineAbilityFor(user);

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const statut_currentUser = users.filter((p) => p.id_user === userSelected)[0];
    const [statut_user, setStatut_user] = useState(true);

    useEffect(() => {
        if (statut_currentUser != null) {
            setStatut_user(statut_currentUser.statut_activation === 1 ? true : false)
        }
        // setStatut_user(statut_currentUser.statut_activation === 1 ? true : false)
    }, [userSelected, statut_currentUser]);

    useEffect(() => {
        document.title = "2iProject-Utilisateurs";
        if (users[0] !== undefined && userSelected === users[0].id_user) {
            form.setFieldsValue({
                first_name: users[0].first_name,
                login: users[0].login,
                prefix:
                    users[0].phone !== undefined && users[0].phone !== null
                        ? users[0].phone.slice(0, 4)
                        : "",
                email: users[0].email,
                phone:
                    users[0].phone !== undefined && users[0].phone !== null
                        ? users[0].phone.slice(4, 20)
                        : "",
                id_profil: users[0].id_profil,
                password: "",
                statut_activation: users[0].statut_activation
            });
            dispatch(userActions.resetExist());
        }
        // dispatch(userActions.userNotification(user.item.id_user));
    }, [dispatch, form, user.item.id_user, userSelected, users]);

    const myFormatDate = (inputDate) => {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return (
                date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear() +
                " " +
                date.getHours() +
                ":" +
                date.getMinutes()
            );
        }
    };

    const getCurrentDate = (venu) => {
        if (venu === null) {
            return "---";
        }
        var today = new Date(venu);
        var date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate() +
            " " +
            today.getHours() +
            ":" +
            today.getMinutes();
        var maintenant = new Date();
        var maintenantdate =
            maintenant.getFullYear() +
            "-" +
            (maintenant.getMonth() + 1) +
            "-" +
            maintenant.getDate() +
            " " +
            today.getHours() +
            ":" +
            today.getMinutes();

        if (date === maintenantdate) {
            return "Aujourd'hui";
        } else {
            return myFormatDate(date);
        }
    };

    //Fonction de recherche
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        SearchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: "100%", marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 110 }}
                    >
                        Rechercher
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 110 }}
                    >
                        Réinitialiser
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filtrer
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => SearchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
        // this.setState({ searchText: "" });
    };

    const columns = [
        {
            title: <Text>Nom & Prénom</Text>,
            dataIndex: "first_name",
            key: "nomprenom",
            fixed: "left",
            render: (text) => text,
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            ...getColumnSearchProps("first_name"),
        },
        {
            title: <Text>E-mail</Text>,
            dataIndex: "email",
            key: "email",
            fixed: "left",
            width: 100,
            ellipsis: {
                showTitle: false,
            },
        },
        // {
        //     title: <Text>Téléphone</Text>,
        //     dataIndex: "phone",
        //     key: "address",
        //     fixed: "left",
        //     width: 50,
        //     ellipsis: {
        //         showTitle: false,
        //     },
        //     render: (text, record) =>
        //         record.phone !== null && record.phone.length > 4
        //             ? record.phone
        //             : "---",
        // },
        {
            title: <Text>Profil</Text>,
            key: "profile",
            fixed: "left",
            dataIndex: "libelle",
            width: 80,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: <Text>Dernière connection</Text>,
            key: "last_connect",
            fixed: "left",
            dataIndex: "last_connect",
            width: 70,
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) => getCurrentDate(record.last_connect),
        },
        {
            // title: <Text>action</Text>,
            key: "edit",
            fixed: "rigth",
            dataIndex: "libelle",
            width: 20,
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) =>
                userSelected === record.id_user ? (
                    <>
                        <Space
                            size="small"
                            style={{ float: "left", margin: "0px 3px" }}
                        >
                            <EditOutlined
                                style={{ fontSize: 18 }}
                                onClick={(e) => {
                                    setUserUpdate(record.id_user);
                                    e.stopPropagation();
                                }}
                            />
                        </Space>
                        {record.check_delete === false ? (
                            <Space
                                size="small"
                                style={{ float: "rigth", margin: "0px 3px" }}
                            >
                                <Can
                                    I="SupprimerUtilisateur"
                                    a="Utilisateurs"
                                    ability={ability}
                                >
                                    <Popconfirm
                                        placement="top"
                                        title="Voulez-vous supprimer cet utilisateur ?"
                                        onConfirm={() =>
                                            confirm(record.id_user)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteOutlined />
                                    </Popconfirm>
                                </Can>
                            </Space>
                        ) : null}
                    </>
                ) : null,
        },
    ];

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 80,
                }}
                value={prefix}
                defaultValue="+227"
                onSelect={(value) => setPrefix(value)}
                disabled={userUpdate === 0 ? true : false}
            >
                <Option value="+227">+227</Option>
                <Option value="+234">+234</Option>
            </Select>
        </Form.Item>
    );

    const submitUser = (values) => {
        const data = {
            first_name: values.first_name,
            phone:
                values.phone !== undefined ? prefix + "" + values.phone : null,
            email: values.email,
            password: values.password,
            id_profil: values.id_profil,
            login: values.login,
            statut_activation: true,
            id_client: values.client,
            statut_activation: statut_user ? 1 : 0
        };
        dispatch(userActions.register(data));
        setTimeout(() => {
            if (add) {
                setUserSelected(1);
            }
        }, 100);
        form.resetFields();
        dispatch(userActions.getAll());
        setStatut_user(statut_user)
    };

    const updateUser = (values) => {
        const data = {
            id_user: userSelected,
            first_name: values.first_name,
            phone: values.prefix + "" + values.phone,
            email: values.email,
            password: values.password === undefined ? null : values.password,
            id_profil: values.id_profil,
            login: values.login,
            statut_activation: true,
            id_client:
                values.client !== null && values.client !== undefined
                    ? values.client
                    : null,
            statut_activation: statut_user ? 1 : 0
        };
        dispatch(userActions.update(data));
        setUpdatedPassword(false);
        dispatch(userActions.getAll());
        setStatut_user(statut_user)
    };

    useEffect(() => {
        dispatch(userActions.getAll());
    }, [dispatch]);

    function confirm(value) {
        dispatch(userActions.remove(value));
        setTimeout(() => {
            form.resetFields();
        }, 100);
    }

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    height: "50",
                }}
            >
                <HeaderApp />
            </div>
            <div
                style={{
                    height: 129,
                }}
            >
                <ConfigApp />
            </div>
            <div
                style={{
                    flex: 1,
                    padding: 18,
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        flex: 6,
                        marginRight: 9,
                        // border: '2px solid red'
                    }}
                    className="utilisateur"
                >
                    <Spin
                        tip="Chargement en cours..."
                        size="large"
                        spinning={user_loading}
                    >
                        <Table
                            columns={columns}
                            pagination={{ defaultPageSize: 9 }}
                            dataSource={users.sort(function (a, b) {
                                if (a.first_name < b.first_name) {
                                    return -1;
                                }
                                if (a.first_name > b.first_name) {
                                    return 1;
                                }
                                return 0;
                            })}
                            size="small"
                            onRow={(record, rowIndex) => {
                                return {
                                    onMouseEnter: (event) => {
                                        setHoverUser(record.id_user);
                                    },
                                    onMouseLeave: (event) => {
                                        setHoverUser(0);
                                    },
                                    onClick: (event) => {
                                        form.setFieldsValue({
                                            first_name: record.first_name,
                                            login: record.login,
                                            prefix:
                                                record.phone !== undefined &&
                                                    record.phone !== null
                                                    ? record.phone.slice(0, 4)
                                                    : "",
                                            email: record.email,
                                            phone:
                                                record.phone !== undefined &&
                                                    record.phone !== null
                                                    ? record.phone.slice(4, 20)
                                                    : "",
                                            id_profil: record.id_profil,
                                            password: "",
                                            client:
                                                record.id_client !==
                                                    undefined &&
                                                    record.id_client !== null
                                                    ? record.id_client
                                                    : null,
                                            statut_activation: record.statut_activation
                                        });
                                        setUserUpdate(0);
                                        setUpdatedPassword(false);
                                        setUserSelected(record.id_user);
                                        dispatch(userActions.resetExist());
                                        if (record.id_profil === 6) {
                                            setIsCustomer(true);
                                        } else {
                                            setIsCustomer(false);
                                        }
                                    },
                                };
                            }}
                            rowClassName={(record) => {
                                if (
                                    hoverUser === parseInt(record.id_user) &&
                                    parseInt(record.id_user) !== userSelected
                                ) {
                                    return "userHover";
                                } else if (userSelected === record.id_user) {
                                    return "userSelected > td";
                                }
                            }}
                            className="table-striped-rows"
                        />
                    </Spin>
                </div>
                <div
                    style={{
                        flex: 3,
                        marginLeft: 9,
                        // border: '2px solid red'
                    }}
                >
                    <Card
                        title={<Text>Formulaire d'ajout utilisateur</Text>}
                        extra={
                            <>
                                <Can
                                    I="LireClient"
                                    a="Fiche de cotation"
                                    ability={ability}
                                >
                                    <Button
                                        type="default"
                                        onClick={() =>
                                            dispatch(modalActions.show_add_customers())
                                        }
                                        style={{ marginRight: 10 }}
                                    >
                                        <UserOutlined /> Clients
                                    </Button>
                                </Can>
                                <Can
                                    I="AjouterUtilisateur"
                                    a="Utilisateurs"
                                    ability={ability}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            form.resetFields();
                                            dispatch(userActions.resetExist());
                                            setUserSelected(0);
                                            setUserUpdate(5);
                                            setIsCustomer(false);
                                        }}
                                    >
                                        Ajouter
                                    </Button>
                                </Can>
                            </>
                        }
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <Form
                            className="login-form"
                            onFinish={
                                userSelected === 0 ? submitUser : updateUser
                            }
                            form={form}
                            style={{
                                // border: '1px solid red', 
                                marginTop: -15
                            }}
                        >
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Input
                                    disabled={userUpdate === 0 ? true : false}
                                    prefix={
                                        <UserOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Nom & Prenom"
                                />
                            </Form.Item>
                            <Form.Item
                                name="login"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Input
                                    disabled={userUpdate === 0 ? true : false}
                                    style={{
                                        border: login_exist
                                            ? "2px solid #ff4d4f"
                                            : null,
                                    }}
                                    prefix={
                                        <LoginOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Login"
                                    onBlur={(e) =>
                                        userSelected === 0
                                            ? dispatch(
                                                userActions.checkLogin({
                                                    login: e.target.value,
                                                })
                                            )
                                            : dispatch(
                                                userActions.checkLoginUpdate({
                                                    login: e.target.value,
                                                    id_user: userSelected,
                                                })
                                            )
                                    }
                                />
                            </Form.Item>
                            <Form.Item name="phone">
                                <Input
                                    disabled={userUpdate === 0 ? true : false}
                                    addonBefore={prefixSelector}
                                    placeholder="Telephone"
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire.",
                                    },
                                ]}
                            >
                                <Input
                                    disabled={userUpdate === 0 ? true : false}
                                    style={{
                                        border: email_exist
                                            ? "2px solid #ff4d4f"
                                            : null,
                                    }}
                                    prefix={<MailOutlined />}
                                    placeholder="E-mail"
                                    onBlur={(e) =>
                                        userSelected === 0
                                            ? dispatch(
                                                userActions.checkMail({
                                                    email: e.target.value,
                                                })
                                            )
                                            : dispatch(
                                                userActions.checkMailUpdate({
                                                    email: e.target.value,
                                                    id_user: userSelected,
                                                })
                                            )
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item
                                    name="id_profil"
                                    style={{
                                        display: "inline-block",
                                        width: "calc(49% - 0px)",
                                        marginRight: 2,
                                        marginBottom: 0,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Champ obligatoire.",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={
                                            userUpdate === 0 ? true : false
                                        }
                                        placeholder="Selectionner profil"
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(value) => {
                                            if (value === 6) {
                                                setIsCustomer(true);
                                                form.setFieldsValue({
                                                    client: null,
                                                });
                                            } else {
                                                setIsCustomer(false);
                                            }
                                        }}
                                    >
                                        {profiles.map((p) => (
                                            <Option value={p.id_profil}>
                                                {p.libelle}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required:
                                                userSelected === 0 ||
                                                    updatedPassword === true
                                                    ? true
                                                    : false,
                                            message: "Champ obligatoire.",
                                        },
                                    ]}
                                    style={{
                                        display: "inline-block",
                                        width: "calc(49% - 0px)",
                                        marginLeft: 2,
                                        marginBottom: 0,
                                    }}
                                >
                                    <Input.Password
                                        iconRender={(visible) =>
                                            visible ? (
                                                <EyeTwoTone />
                                            ) : (
                                                <EyeInvisibleOutlined />
                                            )
                                        }
                                        disabled={
                                            userSelected === 0 ||
                                                updatedPassword === true
                                                ? false
                                                : true
                                        }
                                        prefix={
                                            userSelected !== 0 ? (
                                                <Tooltip title="Modifier le mot de passe">
                                                    <Checkbox
                                                        checked={
                                                            updatedPassword
                                                        }
                                                        value={updatedPassword}
                                                        onChange={(e) => {
                                                            if (
                                                                updatedPassword
                                                            ) {
                                                                form.setFieldsValue(
                                                                    {
                                                                        password:
                                                                            "",
                                                                    }
                                                                );
                                                                setUpdatedPassword(
                                                                    false
                                                                );
                                                            } else {
                                                                setUpdatedPassword(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                        placeholder="Mot de passe"
                                    />
                                </Form.Item>
                            </Form.Item>
                            {isCustomer ? (
                                <Form.Item
                                    name="client"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Champ obligatoire.",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={
                                            userUpdate === 0 ? true : false
                                        }
                                        style={{
                                            border: email_exist
                                                ? "2px solid #ff4d4f"
                                                : null,
                                        }}
                                        prefix={
                                            <FontAwesomeIcon
                                                icon={faHouseUser}
                                            />
                                        }
                                        placeholder="Client"
                                    >
                                        {clients.map((p) => (
                                            <Option value={p.id_client}>
                                                {p.nom_client}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                name="statut_activation"
                            >
                                <Checkbox
                                    checked={statut_user}
                                    onChange={(e) => (
                                        setStatut_user(e.target.checked)
                                    )}
                                    disabled={
                                        userUpdate === 0 ? true : false
                                    }
                                >
                                    Actif / Désactif
                                </Checkbox>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }}>
                                <Form.Item
                                    style={{
                                        display: "inline-block",
                                        width: "calc(49% - 0px)",
                                        marginRight: 2,
                                        marginBottom: 0,
                                    }}
                                >
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={() => form.resetFields()}
                                        disabled={
                                            userUpdate === 0 ? true : false
                                        }
                                    >
                                        Annuler
                                    </Button>
                                </Form.Item>
                                <Can
                                    I="ModifierUtilisateur"
                                    a="Utilisateurs"
                                    ability={ability}
                                >
                                    <Form.Item
                                        style={{
                                            display: "inline-block",
                                            width: "calc(49% - 0px)",
                                            marginLeft: 2,
                                            marginBottom: 0,
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            htmlType="submit"
                                            disabled={
                                                userUpdate === 0 ? true : false
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSave} />{" "}
                                            Enregistrer
                                        </Button>
                                    </Form.Item>
                                </Can>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
            <ClientsDrawer />
        </div >
    );
};

export default Utilisateurs;
