export const priority_ticketConstants = {
    GETALL_PRIORITY_TICKET_REQUEST: "GETALL_PRIORITY_TICKET_REQUEST",
    GETALL_PRIORITY_TICKET_SUCCESS: "GETALL_PRIORITY_TICKET_SUCCESS",
    GETALL_PRIORITY_TICKET_FAILURE: "GETALL_PRIORITY_TICKET_FAILURE",

    ADD_PRIORITY_TICKET_REQUEST: "ADD_PRIORITY_TICKET_REQUEST",
    ADD_PRIORITY_TICKET_SUCCESS: "ADD_PRIORITY_TICKET_SUCCESS",
    ADD_PRIORITY_TICKET_FAILURE: "ADD_PRIORITY_TICKET_FAILURE",

    UPDATE_PRIORITY_TICKET_REQUEST: "UPDATE_PRIORITY_TICKET_REQUEST",
    UPDATE_PRIORITY_TICKET_SUCCESS: "UPDATE_PRIORITY_TICKET_SUCCESS",
    UPDATE_PRIORITY_TICKET_FAILURE: "UPDATE_PRIORITY_TICKET_FAILURE",

    DELETE_PRIORITY_TICKET_REQUEST: "DELETE_PRIORITY_TICKET_REQUEST",
    DELETE_PRIORITY_TICKET_SUCCESS: "DELETE_PRIORITY_TICKET_SUCCESS",
    DELETE_PRIORITY_TICKET_FAILURE: "DELETE_PRIORITY_TICKET_FAILURE",
};
