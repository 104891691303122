import { team_menberConstants, team_projectConstants } from "../_constants";
import { team_projectServices } from "../_services";
import { alertActions, loginActions } from "../_actions";
import { team_menber } from "../_reducers/team_menber.reducers";

export const team_projectActions = {
  addTeamProject,
  affectTeamProject,
  getAllTeamProject,
  updateTeamProject,
  getAllTeamMemberProject,
  selectedProject,
  getAll,
  add,
  update,
  searchProject,
  searchProjectChef,
  searchProjectDev,
  resetTeamProject,
  filterInProgressProject,
  filterArchivedProject,
  filterAllProject,
  resetSearch,
  delete_project,
  resetFilterProjectUser,
};

function delete_project(id_project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .delete_project(id_project)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
      });
  };
  function request() {
    return {
      type: team_projectConstants.DELETE_PROJECT_REQUEST,
    };
  }
  function success(project) {
    return {
      type: team_projectConstants.DELETE_PROJECT_SUCCESS,
      project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.DELETE_PROJECT_FAILURE,
      error,
    };
  }
}

function filterAllProject() {
  return {
    type: team_projectConstants.FILTER_ALL_PROJECT,
  };
}

function filterArchivedProject() {
  return {
    type: team_projectConstants.FILTER_ARCHIVED_PROJECT,
  };
}

function filterInProgressProject() {
  return {
    type: team_projectConstants.FILTER_IN_PROGRESS_PROJECT,
  };
}

function resetTeamProject() {
  return {
    type: team_projectConstants.RESET_TEAM_PROJECT,
  };
}

function update(project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .update(project)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("Modification effectué !"));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Echec de modification !"));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: team_projectConstants.UPDATE_REQUEST,
      };
    }
    function success(project) {
      return {
        type: team_projectConstants.UPDATE_SUCCESS,
        project,
      };
    }
    function failure(error) {
      return {
        type: team_projectConstants.UPDATE_FAILURE,
        error,
      };
    }
  };
}

function add(project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .add(project)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("L'ajout effectué !"));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Echec d'ajout !"));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
    function request() {
      return {
        type: team_projectConstants.ADD_REQUEST,
      };
    }
    function success(project) {
      return {
        type: team_projectConstants.ADD_SUCCESS,
        project,
      };
    }
    function failure(error) {
      return {
        type: team_projectConstants.ADD_FAILURE,
        error,
      };
    }
  };
}

function getAll() {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .getAll()
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.GETALL_REQUEST,
    };
  }
  function success(project) {
    return {
      type: team_projectConstants.GETALL_SUCCESS,
      project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.GETALL_FAILURE,
      error,
    };
  }
}

function selectedProject(team_project) {
  return {
    type: team_projectConstants.SELECTED_PROJECT,
    team_project,
  };
}

function getAllTeamMemberProject(id_equipe_membre) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .getAllTeamMemberProject(id_equipe_membre)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_REQUEST,
    };
  }
  function success(team_member_project) {
    return {
      type: team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_SUCCESS,
      team_member_project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.GETALL_TEAM_MEMBER_PROJECT_FAILURE,
      error,
    };
  }
}

function addTeamProject(team_project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .addTeamProject(team_project)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("L'ajout effectué."));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Une erreur est survenu."));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.ADD_TEAM_PROJECT_REQUEST,
    };
  }
  function success(team_project) {
    return {
      type: team_projectConstants.ADD_TEAM_PROJECT_SUCCESS,
      team_project,
    };
  }
  function failure() {
    return {
      type: team_projectConstants.ADD_TEAM_PROJECT_FAILURE,
    };
  }
}

function getAllTeamProject(team_project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .getAllTeamProject(team_project)
      .then((res) => {
        dispatch(success(res.data.message));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.GETALL_TEAM_PROJECT_REQUEST,
    };
  }
  function success(team_project) {
    return {
      type: team_projectConstants.GETALL_TEAM_PROJECT_SUCCESS,
      team_project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.GETALL_TEAM_PROJECT_FAILURE,
      error,
    };
  }
}

function affectTeamProject(team_project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .affectTeamProject(team_project)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("L'affectation effectué."));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.success("Échec d'affectation."));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.AFFECT_TEAM_PROJECT_REQUEST,
    };
  }
  function success(team_project) {
    return {
      type: team_projectConstants.AFFECT_TEAM_PROJECT_SUCCESS,
      team_project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.AFFECT_TEAM_PROJECT_FAILURE,
      error,
    };
  }
}

function updateTeamProject(team_project) {
  return function (dispatch) {
    dispatch(request());
    team_projectServices
      .updateTeamProject(team_project)
      .then((res) => {
        dispatch(success(res.data.message));
        dispatch(alertActions.success("Modification effectué !"));
      })
      .catch((err) => {
        dispatch(failure(err.message));
        dispatch(alertActions.error("Echec de modification."));
        if (err.response !== undefined && err.response.status === 403) {
          dispatch(loginActions.logout());
        }
      });
  };
  function request() {
    return {
      type: team_projectConstants.UPDATE_TEAM_PROJECT_REQUEST,
    };
  }
  function success(team_project) {
    return {
      type: team_projectConstants.UPDATE_TEAM_PROJECT_SUCCESS,
      team_project,
    };
  }
  function failure(error) {
    return {
      type: team_projectConstants.UPDATE_TEAM_PROJECT_FAILURE,
      error,
    };
  }
}

function searchProject(team_project) {
  return {
    type: team_projectConstants.SEARCH_PROJECT,
    team_project,
  };
}

function searchProjectChef(team_project) {
  return {
    type: team_projectConstants.SEARCH_PROJECT_CHEF,
    team_project,
  };
}

function searchProjectDev(team_project) {
  return {
    type: team_projectConstants.SEARCH_PROJECT_DEV,
    team_project,
  };
}

function resetSearch() {
  return {
    type: team_projectConstants.RESET_SEARCH,
  };
}

function resetFilterProjectUser() {
  return {
    type: team_projectConstants.RESET_FILTER_PROJECT_USER,
  };
}
