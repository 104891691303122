import { taskConstants } from "../_constants";

const initialState = {
    loading: false,
    loading_chart: false,
    loading_filter: false,
    loading_recently: false,
    loadingTask_EATeam: false,
    loadingTask_CHTeam: false,
    loading_chart_equipe: false,
    loading_chart_user: false,
    task_customer_loading: false,
    task_logs_loading: false,
    items: [],
    items_chart: [],
    items_filter: [],
    items_recently_task: [],
    items_chartEA_project: [],
    items_chartCH_users: [],
    items_chartEA_team: [],
    items_chartCH_team: [],
    items_chart_detail: [],
    items_chartTask_team: [],
    items_chart_equipe: [],
    items_chart_user: [],
    filter_chart: [],
    item: {},
    items_customer: [],
    task_logs: [],
    under_task: [],
    user_tasks: [],
    allTeamPlaningTask: [],
    error: "",
};

export const task = (state = initialState, action) => {
    switch (action.type) {

        case taskConstants.DROP_TASK_CLIENT:
            return {
                ...state,
                items_customer: [],
            };

        case taskConstants.FILTER_DATE_IN_CHART:
            return {
                ...state,
                filter_chart: action.filtre,
            };

        case taskConstants.UPDATE_TASK_FILTERE:
            return {
                ...state,
                items_filter: [
                    ...state.items_filter.filter(
                        (p) => parseInt(p.id) !== action.id_task
                    )
                ],
            };

        case taskConstants.RESET_FILTER_DATE_IN_CHART:
            return {
                ...state,
                filter_chart: [],
            };

        case taskConstants.GETALL_TASK_OF_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_TASK_OF_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                items_customer: action.allTask,
            };

        case taskConstants.GETALL_TASK_OF_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.GET_FILTERE_REQUEST:
            return {
                ...state,
                loading_filter: true,
            };

        case taskConstants.GET_FILTERE_SUCCESS:
            return {
                ...state,
                loading_filter: false,
                items_filter: action.task,
            };

        case taskConstants.GET_FILTERE_FAILURE:
            return {
                ...state,
                loading_filter: false,
                error: action.error,
            };

        case taskConstants.RESET_FILTERED:
            return {
                ...state,
                items_filter: [],
            };

        case taskConstants.GETALL_RECENTLY_TASK_REQUEST:
            return {
                ...state,
                loading_recently: true,
            };

        case taskConstants.GETALL_RECENTLY_TASK_SUCCESS:
            return {
                ...state,
                loading_recently: false,
                items_recently_task: action.task,
            };

        case taskConstants.GETALL_RECENTLY_TASK_FAILURE:
            return {
                ...state,
                loading_recently: false,
                error: action.error,
            };

        case taskConstants.ADD_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.ADD_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.task],
            };

        case taskConstants.ADD_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.task,
            };

        case taskConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case taskConstants.GET_DATA_CHART_REQUEST:
            return {
                ...state,
                loading_chart: true,
            };

        case taskConstants.GET_DATA_CHART_SUCCESS:
            return {
                ...state,
                loading_chart: false,
                items_chart: action.data_chart,
            };

        case taskConstants.GET_DATA_CHART_ERROR:
            return {
                ...state,
                loading_chart: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_ALL_TEAM_REQUEST:
            return {
                ...state,
                loadingTask_allTeam: true,
            };

        case taskConstants.GET_DATA_CHART_ALL_TEAM_SUCCESS:
            return {
                ...state,
                loadingTask_allTeam: false,
                items_chartTask_team: action.data_chart,
            };

        case taskConstants.GET_DATA_CHART_ALL_TEAM_ERROR:
            return {
                ...state,
                loadingTask_allTeam: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_EA_TEAM_REQUEST:
            return {
                ...state,
                loadingTask_EATeam: true,
            };

        case taskConstants.GET_DATA_CHART_EA_TEAM_SUCCESS:
            return {
                ...state,
                loadingTask_EATeam: false,
                items_chartEA_team: action.data_chart,
            };

        case taskConstants.GET_DATA_CHART_EA_TEAM_ERROR:
            return {
                ...state,
                loadingTask_EATeam: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_CH_TEAM_REQUEST:
            return {
                ...state,
                loadingTask_CHTeam: true,
            };

        case taskConstants.GET_DATA_CHART_CH_TEAM_SUCCESS:
            return {
                ...state,
                loadingTask_CHTeam: false,
                items_chartCH_team: action.data_chart,
            };

        case taskConstants.GET_DATA_CHART_CH_TEAM_ERROR:
            return {
                ...state,
                loadingTask_CHTeam: false,
                error: action.error,
            };

        case taskConstants.GET_CHART_EA_PROJECT_REQUEST:
            return {
                ...state,
                loading_chart: true,
            };

        case taskConstants.GET_CHART_EA_PROJECT_SUCCESS:
            return {
                ...state,
                loading_chart: false,
                items_chartEA_project: action.data_chart,
            };

        case taskConstants.GET_CHART_EA_PROJECT_ERROR:
            return {
                ...state,
                loading_chart: false,
                error: action.error,
            };

        case taskConstants.GET_CHART_CH_USERS_REQUEST:
            return {
                ...state,
                loading_chart: true,
            };

        case taskConstants.GET_CHART_CH_USERS_SUCCESS:
            return {
                ...state,
                loading_chart: false,
                items_chartCH_users: action.data_chart,
            };

        case taskConstants.GET_CHART_CH_USERS_ERROR:
            return {
                ...state,
                loading_chart: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_DETAIL_REQUEST:
            return {
                ...state,
                loading_chart: true,
            };

        case taskConstants.GET_DATA_CHART_DETAIL_SUCCESS:
            return {
                ...state,
                loading_chart: false,
                items_chart_detail: action.data_chart,
            };

        case taskConstants.GET_DATA_CHART_DETAIL_ERROR:
            return {
                ...state,
                loading_chart: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_EQUIPE_REQUEST:
            return {
                ...state,
                loading_chart_equipe: true,
            };

        case taskConstants.GET_DATA_CHART_EQUIPE_SUCCESS:
            return {
                ...state,
                loading_chart_equipe: false,
                items_chart_equipe: action.chart_team,
            };

        case taskConstants.GET_DATA_CHART_EQUIPE_ERROR:
            return {
                ...state,
                loading_chart_equipe: false,
                error: action.error,
            };

        case taskConstants.GET_DATA_CHART_USERS_REQUEST:
            return {
                ...state,
                loading_chart_user: true,
            };

        case taskConstants.GET_DATA_CHART_USERS_SUCCESS:
            return {
                ...state,
                loading_chart_user: false,
                items_chart_user: action.chart_users,
            };

        case taskConstants.GET_DATA_CHART_USERS_ERROR:
            return {
                ...state,
                loading_chart_user: false,
                error: action.error,
            };

        case taskConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [
                    ...state.items.map((p) => {
                        if (p.id === action.task.id) {
                            p.id_jalon = action.task.id_jalon;
                            p.ordre = action.task.ordre;
                        }
                        return p;
                    }),
                ],
            };

        case taskConstants.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.SELECTED_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.task,
            };

        case taskConstants.GETALL_TASK_LOG_REQUEST:
            return {
                ...state,
                task_logs_loading: true,
            };

        case taskConstants.GETALL_TASK_LOG_SUCCESS:
            return {
                ...state,
                task_logs_loading: false,
                task_logs: action.task,
            };

        case taskConstants.GETALL_TASK_LOG_FAILURE:
            return {
                ...state,
                task_logs_loading: false,
                error: action.error,
            };

        case taskConstants.ADD_TASK_LOG_REQUEST:
            return {
                ...state,
                task_logs_loading: true,
            };

        case taskConstants.ADD_TASK_LOG_SUCCESS:
            return {
                ...state,
                task_logs_loading: false,
                task_logs: [...state.task_logs, action.task_log],
            };

        case taskConstants.ADD_TASK_LOG_FAILURE:
            return {
                ...state,
                task_logs_loading: false,
                error: action.error,
            };

        case taskConstants.GETALL_UNDER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_UNDER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                under_task: action.task,
            };

        case taskConstants.GETALL_UNDER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.ADD_UNDER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.ADD_UNDER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                under_task: [action.task, ...state.under_task],
            };

        case taskConstants.ADD_UNDER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.UPDATE_UNDER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.UPDATE_UNDER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                under_task: [
                    ...state.under_task.map((p) => {
                        if (parseInt(p.id_tache) === parseInt(action.task.id)) {
                            Object.assign(p, action.task);
                        }
                        return p;
                    }),
                ],
            };

        case taskConstants.UPDATE_UNDER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.GETALL_USER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_USER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                user_tasks: action.task,
            };

        case taskConstants.GETALL_USER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.DELETE_UNDER_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.DELETE_UNDER_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                under_task: [
                    ...state.under_task.filter(
                        (p) => parseInt(p.id_tache) !== parseInt(action.id_task)
                    ),
                ],
            };

        case taskConstants.DELETE_UNDER_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.RESET_USER_TASK:
            return {
                ...state,
                user_tasks: [],
            };

        case taskConstants.RESET_UNDER_TASK:
            return {
                ...state,
                under_task: [],
            };

        case taskConstants.RESET_TASK_LOGS:
            return {
                ...state,
                task_logs: [],
            };

        case taskConstants.RESET_CHART_DATA_EQUIPE:
            return {
                ...state,
                items_chart_equipe: [],
                items_chart_user: [],
                items_chartEA_project: [],
                items_chartCH_users: [],
            };

        case taskConstants.GETALL_PLANING_TASK_OF_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                allTeamPlaningTask: action.task,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                allTeamPlaningTask: action.task,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                allTeamPlaningTask: action.task,
            };

        case taskConstants.GETALL_PLANING_TASK_OF_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
